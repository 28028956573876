import React from 'react';
import checkImg from './../../../Assets/Image/check.png'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios';
import loadingGIf from './../../../Assets/Image/loading.gif'

const RegistrationSuccess = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    console.log(searchParams.get('id'));

    const apiAddress = process.env.REACT_APP_SECRET;

    const [laoding, setLoading] = React.useState(true);
    React.useEffect(() => {
        var url = apiAddress + "/api/GoTasteUser/" + searchParams.get('id') + "/true"
        axios({
            method: 'post',
            url: url,
        })
            .then(function (response) {
                console.log("res: " + response.data);
                setLoading(false);
                // window.location.href = "/";
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])
    return (
        <div style={{ height: "100vh" }}>
            <div className={`${laoding ? "d-none" : "h-100"}`} >
                <div className='d-flex align-items-center justify-content-center h-100' style={{ flexDirection: "column" }}>
                    <img src={checkImg} alt="" style={{ width: "5rem" }} />
                    <h2>Verification Success</h2>
                    <p className='text-center'>Thank your for your verification. We have successfully verified your account.<br></br>
                        You can now login and order</p>
                    <h6 style={{ cursor: "pointer" }} onClick={() => { window.location.href = "/"; }}>Go to home</h6>
                </div>
            </div>
            <div className={`${laoding ? "d-flex align-items-center justify-content-center h-100" : "d-none"}`}>
                <img src={loadingGIf} alt="" />
            </div>
        </div>
    );
};

export default RegistrationSuccess;