import React, { useState, useEffect } from 'react';
import "./GalleryModal.css"
import CommentDisplay from './../PostDisplay/CommentDisplay/CommentDisplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import { Autoplay, Navigation, EffectCoverflow, Pagination, Zoom } from 'swiper';
import "swiper/css/navigation";
import previewIcon from "./../../Assets/Icons/previous1.png"
import removeIcon from "./../../Assets/Icons/remove.png"
import removeWhiteIcon from "./../../Assets/Icons/removeBlack.png"
import product1 from "./../../Assets/Image/product (1).jpg"
import moviePoster from "./../../Assets/Image/MoviePoster 2.png"
import LeoImg from './../../Assets/Image/Leo.png';
import profileImage from './../../Assets/Image/product (1).jpg';
import $ from 'jquery';
import sendIcon from './../../Assets/Image/send.png'
import ReplyComponent from './../PostDisplay/ReplyComponent/ReplyComponent';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import CommentComponent from './../PostDisplay/CommentComponent/CommentComponent';
import PhotoGridGalleryComponent from '../Restaurant/PhotoGridGallery/Components/PhotoGridGalleryComponent';
import GalleryTestSwipper from './../Test5';


const GalleryModal = (props) => {

    const hideModalInfo = () => {
        window.$("#galleryModal").modal("hide");
    };
    const [showSend, setShowSend] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");

    const [showPage, setshowPage] = useState(0);

    useEffect(() => {
        window.addEventListener("click", () => {
            // setShowSend(false)
            if ($('.sendDiv').not('.sendDiv').length) {
                setShowSend(false);
            }
        })

    })

    const hideCollageModalInfo = (props) => {
        setshowPage(props)
    };
    const showCollageModalInfo = (props) => {
        setshowPage(props)
    };


    return (
        <div>

            <div className={` galleryModalDiv ${showPage === 0 ? "d-flex" : "d-none"}`}>
                <div className='d-flex align-items-center justify-content-center galleryModalImgDiv'>
                    <button type="button " class={"close gallaryModelCloseButton"} onClick={hideModalInfo} style={{ position: "absolute", right: "0.8rem", top: "1rem", width: "44px", background: "transparent", border: "none" }}>
                        <span aria-hidden="true">

                            <img src={removeWhiteIcon} className="removeImg desktopDisplay" style={{ height: '30px", width: "1rem' }} alt="" /></span>
                    </button>

                    <Swiper
                        style={{
                            "--swiper-navigation-color": "#fff",
                            "--swiper-pagination-color": "#fff",
                        }}

                        navigation={true}
                        spaceBetween={10}
                        slidesPerView={1}
                        modules={[Navigation]}
                        className="galleryModalSwipper"
                    >

                        <SwiperSlide  >
                            <TransformWrapper>
                                <TransformComponent>
                                    <img src={props.selectedImage} alt="test" className='img-fluid selectedImage' onClick={() => {
                                        setSelectedImage(props.selectedImage);
                                        setshowPage(1);
                                    }
                                    } />
                                </TransformComponent>
                            </TransformWrapper>

                        </SwiperSlide>
                        <SwiperSlide>
                            <TransformWrapper>
                                <TransformComponent>
                                    <img src={props.selectedImage} alt="test" className='img-fluid selectedImage' onClick={() => {
                                        setSelectedImage(props.selectedImage);
                                        setshowPage(1);
                                    }
                                    } />
                                </TransformComponent>
                            </TransformWrapper>
                        </SwiperSlide>
                        <SwiperSlide>
                            <TransformWrapper>
                                <TransformComponent>
                                    <img src={product1} alt="test" className='img-fluid selectedImage' onClick={() => {
                                        setSelectedImage(product1);
                                        setshowPage(1);
                                    }
                                    } />
                                </TransformComponent>
                            </TransformWrapper>
                        </SwiperSlide>

                    </Swiper>



                </div>
                <div className='position-relative galleryModalDivCommentScroll'>
                    <h3>Chicken</h3>

                    <hr></hr>
                    <div className='commentScroll'>
                        <CommentDisplay></CommentDisplay>
                        <div className='reply'>
                            <CommentDisplay></CommentDisplay>
                        </div>
                        <CommentDisplay></CommentDisplay>
                        <div className='reply'>
                            <CommentDisplay></CommentDisplay>
                        </div>
                        <CommentDisplay></CommentDisplay>
                        <div className='reply'>
                            <CommentDisplay></CommentDisplay>
                        </div>
                        <div className='reply'>
                            <CommentDisplay></CommentDisplay>
                        </div>
                        <CommentDisplay></CommentDisplay>
                        <div className='reply'>
                            <CommentDisplay></CommentDisplay>
                        </div>
                    </div>
                    <div className='w-100 CommentComponentDivDesktopView'>
                        <CommentComponent />
                    </div>


                </div>

            </div>
            <div className={` galleryModalDiv ${showPage === 1 ? "d-flex" : "d-none"}`}>
                <button className='previewBtn' onClick={() => setshowPage(0)}>
                    <img src={previewIcon} className='previewBtn' />
                </button>

                <PhotoGridGalleryComponent selectedImage={selectedImage} />
            </div>
            {/* <Swiper
                className='galleryModalSwiper'
                spaceBetween={30}
                 grabCursor={true}
                centeredSlides={false}
                slidesPerView={1}
                hashNavigation={{
                    watchState: true,
                }}
                // autoplay={{
                //     delay: 2200,
                //     disableOnInteraction: true,
                // }}

                navigation={true}
                modules={[Autoplay, Navigation, Pagination]}
                pagination={{
                    dynamicBullets: true,
                    clickable: true,

                }}
            >

                <SwiperSlide>
                    <div className='d-flex galleryModalDiv'>
                        <div className='d-flex align-items-center galleryModalImgDiv'>
                            <button type="button " class={"close gallaryModelCloseButton"} onClick={hideModalInfo} style={{ position: "absolute", right: "0.8rem", top: "1rem", width: "44px", background: "transparent", border: "none" }}>
                                <span aria-hidden="true"><img src={removeIcon} style={{ height: "30px" }} /></span>
                            </button>
                            <img src={props.selectedImage} alt="" className='img-fluid selectedImage' />
                        </div>
                        <div className='position-relative galleryModalDivCommentScroll'>
                            <h3>Chicken</h3>
                            <hr></hr>
                            <div className='commentScroll'>
                                <CommentDisplay></CommentDisplay>
                                <div className='reply'>
                                    <CommentDisplay></CommentDisplay>
                                </div>
                                <CommentDisplay></CommentDisplay>
                                <div className='reply'>
                                    <CommentDisplay></CommentDisplay>
                                </div>
                                <CommentDisplay></CommentDisplay>
                                <div className='reply'>
                                    <CommentDisplay></CommentDisplay>
                                </div>
                            </div>
                            <div>
                                <textarea name="" id="" placeholder='Write a comment' className='form-control'></textarea>
                                <div className='d-flex justify-content-end mt-2'>
                                    <button className='btn-custom '>Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='d-flex galleryModalDiv '>
                        <div className='d-flex align-items-center galleryModalImgDiv'>
                            <button type="button " class={"close gallaryModelCloseButton"} onClick={hideModalInfo} style={{ position: "absolute", right: "0.8rem", top: "1rem", width: "44px", background: "transparent", border: "none" }}>
                                <span aria-hidden="true"><img src={removeIcon} style={{ height: "30px" }} /></span>
                            </button>
                            <img src={moviePoster} alt="" />
                        </div>
                        <div className='position-relative galleryModalDivCommentScroll'>
                            <h3>Red Valvet Cake 2</h3>
                            <hr></hr>
                            <div className='commentScroll'>
                                <CommentDisplay></CommentDisplay>
                                <div className='reply'>
                                    <CommentDisplay></CommentDisplay>
                                </div>
                                <CommentDisplay></CommentDisplay>
                                <div className='reply'>
                                    <CommentDisplay></CommentDisplay>
                                </div>
                                <CommentDisplay></CommentDisplay>
                                <div className='reply'>
                                    <CommentDisplay></CommentDisplay>
                                </div>
                            </div>
                            <div>
                                <textarea name="" id="" placeholder='Write a comment' className='form-control'></textarea>
                                <div className='d-flex justify-content-end mt-2'>
                                    <button className='btn-custom '>Send</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </SwiperSlide>
            </Swiper> */}


        </div>
    );
};

export default GalleryModal;