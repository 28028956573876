import React, { useRef, useState, useEffect, useCallback } from "react";
import './test.css'
import './Test2.css'
import img1 from "./../Assets/Image/1 (1).jpg"
import img2 from "./../Assets/Image/1 (2).jpg"
import img3 from "./../Assets/Image/1 44.png"
import star from "./../Assets/Image/Star 14.png"
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Pagination } from 'swiper'

import airbnb1 from './../Assets/Image/airbnb.webp'
import airbnb2 from './../Assets/Image/aibnb2.webp'
import ImageGallery from 'react-image-gallery';
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import GalleryView from "./GalleryView/GalleryView";
import removeBlack from "./../Assets/Icons/remove.png"
import removeIcon from "./../Assets/Icons/removeBlack.png"
import GalleryModal from "./GalleryModal/GalleryModal";
import image1 from './../Assets/Image/product (1).jpg'
import image2 from './../Assets/Image/1 51.png'
import CommentComponent from "./PostDisplay/CommentComponent/CommentComponent";
export default function Test2() {

    const [currentSlide, setCurrentSlide] = useState(0);

    // const carouselInner = useRef(null);

    // const slideChanged = useCallback(() => {
    //     const activeItem = carouselInner.current.querySelector(".active");
    //     setCurrentSlide(
    //         Array.from(carouselInner.current.children).indexOf(activeItem)
    //     );
    // }, []);

    // const changeSlide = useCallback((position) => {
    //     Array.from(carouselInner.current.children).forEach((el, i) => {
    //         if (i !== position) {
    //             el.classNameList.remove("active");
    //         } else {
    //             el.classNameList.add("active");
    //             slideChanged();
    //         }
    //     });
    // }, []);
    const [selectedImage, setSelectedImage] = useState("");
    const hideModalInfo = () => {
        window.$("#galleryModal").modal("hide");
    };
    const showModalInfo = (props) => {
        setSelectedImage(props);
        console.log("selectedImage: " + selectedImage);
        window.$("#galleryModal").modal("show");
    };
    const images = [
        {
            original: airbnb2,
            thumbnail: airbnb2,
        },
        {
            original: airbnb1,
            thumbnail: airbnb1,
        },
        {
            original: 'https://picsum.photos/id/1015/1000/600/',
            thumbnail: 'https://picsum.photos/id/1015/250/150/',
        },
        {
            original: 'https://picsum.photos/id/1019/1000/600/',
            thumbnail: 'https://picsum.photos/id/1019/250/150/',
        },
        {
            original: airbnb2,
            thumbnail: airbnb2,
        },
        {
            original: airbnb1,
            thumbnail: airbnb1,
        },
        {
            original: 'https://picsum.photos/id/1015/1000/600/',
            thumbnail: 'https://picsum.photos/id/1015/250/150/',
        },
        {
            original: 'https://picsum.photos/id/1019/1000/600/',
            thumbnail: 'https://picsum.photos/id/1019/250/150/',
        },
    ]
    const [thumbsSwiper, setThumbsSwiper] = useState();
    const imageGalleryCLick = (e) => {
        console.log(e);
        console.log(e.target.currentSrc);
        showModalInfo(e.target.currentSrc);
    }
    return (
        <>
            <div className="row">
                <div className="col-lg-3">
                    <div className="row desktopDisplay ">
                        <div className="recommendedSideBar">
                            <div className="col-12 d-flex justify-content-center">
                                <h3 className=" recomended-text ">Recommended</h3>
                            </div>
                            <div className="col-12 d-flex justify-content-center">

                                <div className="card card-Test2" >
                                    <img src={img1} className="card-img-top card-img-test2" alt="..." />
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="card-title " style={{ fontSize: "1rem" }}>Cheese-Stuffed Meatballs</h5>
                                            <div className="d-flex align-items-center">
                                                <img src={star} style={{ width: "25px" }} className=" " />
                                                <h4 className='m-0 p-0 card-test2-font-size' style={{ fontSize: "1rem" }}>4.5</h4>
                                            </div>
                                        </div>

                                        <p className="card-text card-test2-font-size-p" style={{ fontSize: ".8rem" }}>Ground beef, string cheese, ground
                                            pork, parmesan, olive oil....</p>
                                        <h4 className="card-test2-font-size" style={{ fontSize: "1rem" }}>10.5$</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center" >
                                <div className="card card-Test2" >
                                    <img src={img1} className="card-img-top card-img-test2" alt="..." />
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="card-title" style={{ fontSize: "1rem" }}>Cheese-Stuffed Meatballs</h5>
                                            <div className="d-flex align-items-center">
                                                <img src={star} style={{ width: "25px" }} className=" " />
                                                <h4 className='m-0 p-0' style={{ fontSize: "1rem" }}>4.5</h4>
                                            </div>
                                        </div>

                                        <p className="card-text" style={{ fontSize: ".8rem" }}>Ground beef, string cheese, ground
                                            pork, parmesan, olive oil....</p>
                                        <h4 style={{ fontSize: "1rem" }}>10.5$</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center" >
                                <div className="card card-Test2" >
                                    <img src={img1} className="card-img-top card-img-test2" alt="..." />
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="card-title" style={{ fontSize: "1rem" }}>Cheese-Stuffed Meatballs</h5>
                                            <div className="d-flex align-items-center">
                                                <img src={star} style={{ width: "25px" }} className=" " />
                                                <h4 className='m-0 p-0' style={{ fontSize: "1rem" }}>4.5</h4>
                                            </div>
                                        </div>

                                        <p className="card-text" style={{ fontSize: ".8rem" }}>Ground beef, string cheese, ground
                                            pork, parmesan, olive oil....</p>
                                        <h4 style={{ fontSize: "1rem" }}>10.5$</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center" >
                                <div className="card card-Test2" >
                                    <img src={img1} className="card-img-top card-img-test2" alt="..." />
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="card-title" style={{ fontSize: "1rem" }}>Cheese-Stuffed Meatballs</h5>
                                            <div className="d-flex align-items-center">
                                                <img src={star} style={{ width: "25px" }} className=" " />
                                                <h4 className='m-0 p-0' style={{ fontSize: "1rem" }}>4.5</h4>
                                            </div>
                                        </div>

                                        <p className="card-text" style={{ fontSize: ".8rem" }}>Ground beef, string cheese, ground
                                            pork, parmesan, olive oil....</p>
                                        <h4 style={{ fontSize: "1rem" }}>10.5$</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
                <div className="col-lg-9">
                    <div className="mt-2 me-2 test2">
                        {/* <swiper-container className="mySwiper"
                            thumbs-swiper=".mySwiper2" loop="true" space-between="10" navigation="true"
                            pagination="true" pagination-clickable="true"
                            autoplay-delay="2500" autoplay-disable-on-interaction="false"
                        >
                            <swiper-slide >
                                <img src="https://swiperjs.com/demos/images/nature-1.jpg" className="swiperPreviewImage" />
                            </swiper-slide>
                            <swiper-slide >
                                <img src="https://swiperjs.com/demos/images/nature-2.jpg" className="swiperPreviewImage" />
                            </swiper-slide>
                            <swiper-slide >
                                <img src="https://swiperjs.com/demos/images/nature-3.jpg" className="swiperPreviewImage" />
                            </swiper-slide>
                            <swiper-slide >
                                <img src="https://swiperjs.com/demos/images/nature-4.jpg" className="swiperPreviewImage" />
                            </swiper-slide>
                            <swiper-slide >
                                <img src="https://swiperjs.com/demos/images/nature-5.jpg" className="swiperPreviewImage" />
                            </swiper-slide>
                            <swiper-slide >
                                <img src="https://swiperjs.com/demos/images/nature-6.jpg" className="swiperPreviewImage" />
                            </swiper-slide>
                            <swiper-slide >
                                <img src="https://swiperjs.com/demos/images/nature-7.jpg" className="swiperPreviewImage" />
                            </swiper-slide>
                            <swiper-slide >
                                <img src="https://swiperjs.com/demos/images/nature-8.jpg" className="swiperPreviewImage" />
                            </swiper-slide>
                            <swiper-slide >
                                <img src="https://swiperjs.com/demos/images/nature-9.jpg" className="swiperPreviewImage" />
                            </swiper-slide>
                            <swiper-slide >
                                <img src="https://swiperjs.com/demos/images/nature-10.jpg" className="swiperPreviewImage" />
                            </swiper-slide>
                        </swiper-container>
                        <div className="d-flex justify-content-center swiper-with-btn"  >
                            <swiper-container className="mySwiper2" loop="true" space-between="10" slides-per-view="4" free-mode="true"
                                watch-slides-progress="true">
                                <swiper-slide>
                                    <img src="https://swiperjs.com/demos/images/nature-1.jpg" className="imagePreviewDisplay" />
                                </swiper-slide>
                                <swiper-slide>
                                    <img src="https://swiperjs.com/demos/images/nature-2.jpg" className="imagePreviewDisplay" />
                                </swiper-slide>
                                <swiper-slide>
                                    <img src="https://swiperjs.com/demos/images/nature-3.jpg" className="imagePreviewDisplay" />
                                </swiper-slide>
                                <swiper-slide>
                                    <img src="https://swiperjs.com/demos/images/nature-4.jpg" className="imagePreviewDisplay" />
                                </swiper-slide>
                                <swiper-slide>
                                    <img src="https://swiperjs.com/demos/images/nature-5.jpg" className="imagePreviewDisplay" />
                                </swiper-slide>
                                <swiper-slide>
                                    <img src="https://swiperjs.com/demos/images/nature-6.jpg" className="imagePreviewDisplay" />
                                </swiper-slide>
                                <swiper-slide>
                                    <img src="https://swiperjs.com/demos/images/nature-7.jpg" className="imagePreviewDisplay" />
                                </swiper-slide>
                                <swiper-slide>
                                    <img src="https://swiperjs.com/demos/images/nature-8.jpg" className="imagePreviewDisplay" />
                                </swiper-slide>
                                <swiper-slide>
                                    <img src="https://swiperjs.com/demos/images/nature-9.jpg" className="imagePreviewDisplay" />
                                </swiper-slide>
                                <swiper-slide>
                                    <img src="https://swiperjs.com/demos/images/nature-10.jpg" className="imagePreviewDisplay" />
                                </swiper-slide>

                            </swiper-container>
                            <button className=" plusButton"><i className="fa-solid fa-plus"></i></button>
                        </div> */}
                        {/* <Swiper
                            style={{
                                "--swiper-navigation-color": "#fff",
                                "--swiper-pagination-color": "#fff",
                            }}
                            spaceBetween={10}
                            navigation={true}
                            // thumbs={{ swiper: thumbsSwiper }}
                            // thumbs={{ swiper: thumbsSwiper }}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper24"
                        >
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-1.jpg" className="swiperPreviewImage" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-2.jpg" className="swiperPreviewImage" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-3.jpg" className="swiperPreviewImage" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-4.jpg" className="swiperPreviewImage" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-5.jpg" className="swiperPreviewImage" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-6.jpg" className="swiperPreviewImage" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-7.jpg" className="swiperPreviewImage" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-8.jpg" className="swiperPreviewImage" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-9.jpg" className="swiperPreviewImage" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-10.jpg" className="swiperPreviewImage" />
                            </SwiperSlide>
                        </Swiper>
                        <Swiper
                            onSwiper={setThumbsSwiper}
                            spaceBetween={10}
                            slidesPerView={4}
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper23"
                        >
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-1.jpg" className="imagePreviewDisplay" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-2.jpg" className="imagePreviewDisplay" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-3.jpg" className="imagePreviewDisplay" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-4.jpg" className="imagePreviewDisplay" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-5.jpg" className="imagePreviewDisplay" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-6.jpg" className="imagePreviewDisplay" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-7.jpg" className="imagePreviewDisplay" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-8.jpg" className="imagePreviewDisplay" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-9.jpg" className="imagePreviewDisplay" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="https://swiperjs.com/demos/images/nature-10.jpg" className="imagePreviewDisplay" />
                            </SwiperSlide>
                        </Swiper> */}

                        {/* <div className="modal fade modal-xl" id="galleryModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered galleryModal" role="document">
                                <div className="modal-content">

                                    <div className="modal-header d-flex justify-content-between loginModalCloseBtn">

                                        <span></span>
                                        <span></span>
                                        <button type="button " className={"close "} onClick={hideModalInfo}>
                                            <span aria-hidden="true"><img src={removeIcon} style={{ height: "30px" }} alt="" /></span>
                                        </button>

                                    </div>
                                    <div className="modal-body">
                                        <GalleryModal selectedImage={selectedImage} />
                                    </div>

                                </div>
                            </div>
                        </div> */}

                        {/* <div className="modal fade" id="galleryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-fullscreen modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-body galleryModalBody">
                                        <div className=" gallaryViewModelHeader d-flex justify-content-between loginModalCloseBtn">
                                            <span></span>
                                            <span></span>
                                            <button type="button " className={'close '} onClick={hideModalInfo}>
                                                <span aria-hidden="true">
                                                    <img src={removeIcon} className="removeImg desktopDisplay" style={{ height: '30px", width: "1rem' }} alt="" />
                                                </span>
                                            </button>
                                        </div>
                                        <button type="button " className={"close gallaryModelCloseButton"} onClick={hideModalInfo} style={{ position: "absolute", right: "0.8rem", top: "1rem", width: "44px", background: "transparent", border: "none" }}>
                                            <span aria-hidden="true">
                                                <img src={removeBlack} className="removeImg mobileDisplay " style={{ height: '30px", width: "1rem' }} alt="" /></span>
                                        </button>
                                        <Swiper
                                            className='gallerySwiper'
                                            spaceBetween={30}
                                            grabCursor={false}
                                            centeredSlides={false}
                                            slidesPerView={1}
                                            hashNavigation={{
                                                watchState: true,
                                            }}
                                            navigation={true}
                                            modules={[Navigation, Pagination]}
                                            pagination={{
                                                dynamicBullets: true,
                                                clickable: true,
                                            }}
                                        >
                                            <SwiperSlide>
                                                <GalleryModal selectedImage={selectedImage} />
                                            </SwiperSlide>
                                        </Swiper>

                                    </div>

                                </div>
                            </div>
                        </div> */}

                        <div className="modal fade" id="galleryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-fullscreen modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-body galleryModalBody">
                                        <div className='w-100 CommentComponentDiv'>
                                            <CommentComponent />
                                        </div>
                                        <div className=" gallaryViewModelHeader d-flex justify-content-between loginModalCloseBtn">
                                            <span></span>
                                            <span></span>
                                            <button type="button " className={'close '} onClick={hideModalInfo}>
                                                <span aria-hidden="true">
                                                    <img src={removeIcon} className="removeImg desktopDisplay" style={{ height: '30px", width: "1rem' }} alt="" />
                                                </span>
                                            </button>
                                        </div>
                                        <button type="button " className={"close gallaryModelCloseButton"} onClick={hideModalInfo} style={{ position: "absolute", right: "0.8rem", top: "1rem", width: "44px", background: "transparent", border: "none" }}>
                                            <span aria-hidden="true">
                                                <img src={removeBlack} className="removeImg mobileDisplay " style={{ height: '30px", width: "1rem' }} alt="" /></span>
                                        </button>
                                        <Swiper
                                            className='gallerySwiper'
                                            spaceBetween={30}
                                            grabCursor={false}
                                            centeredSlides={false}
                                            slidesPerView={1}
                                            hashNavigation={{
                                                watchState: true,
                                            }}
                                            navigation={true}
                                            modules={[Navigation, Pagination]}
                                            pagination={{
                                                dynamicBullets: true,
                                                clickable: true,
                                            }}
                                        >
                                            <SwiperSlide>
                                                <GalleryModal selectedImage={selectedImage} />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <GalleryModal selectedImage={selectedImage} />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <GalleryModal selectedImage={selectedImage} />
                                            </SwiperSlide>
                                        </Swiper>

                                    </div>

                                </div>
                            </div>
                        </div>


                        {/* <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-mdb-ride="carousel">
                            <div className="carousel-inner mb-5 ">
                                <div className="carousel-item carousel-size active" onClick={() => showModalInfo("https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(88).webp")}>
                                    <img src="https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(88).webp" className="d-block " alt="..." />
                                </div>
                                <div className="carousel-item carousel-size" onClick={() => showModalInfo("https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(121).webp")}>
                                    <img src="https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(121).webp" className="d-block "
                                        alt="..." />
                                </div>
                                <div className="carousel-item carousel-size" onClick={() => showModalInfo("https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(31).webp")}>
                                    <img src="https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(31).webp" className="d-block " alt="..." />
                                </div>
                                <div className="carousel-item carousel-size" onClick={() => showModalInfo("https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(31).webp")}>
                                    <img src="https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(31).webp" className="d-block " alt="..." />
                                </div>
                                <div className="carousel-item carousel-size" onClick={() => showModalInfo("https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(88).webp")}>
                                    <img src="https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(88).webp" className="d-block " alt="..." />
                                </div>
                                <div className="carousel-item carousel-size" onClick={() => showModalInfo("https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(31).webp")}>
                                    <img src="https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(31).webp" className="d-block " alt="..." />
                                </div>

                            </div>
                            <button className="carousel-control-prev" type="button" data-mdb-target="#carouselExampleIndicators"
                                data-mdb-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-mdb-target="#carouselExampleIndicators"
                                data-mdb-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>

                            <div className="carousel-indicators">
                                <button type="button" data-mdb-target="#carouselExampleIndicators" data-mdb-slide-to="0" className="active btn-Preview"
                                    aria-current="true" aria-label="Slide 1" >
                                    <img className="d-block w-100 imagePreviewDisplay"
                                        src="https://mdbcdn.b-cdn.net/img/Photos/Others/Carousel-thumbs/img%20(88).webp" />
                                </button>
                                <button type="button" data-mdb-target="#carouselExampleIndicators" data-mdb-slide-to="1"
                                    aria-label="Slide 2" className="btn-Preview" >
                                    <img className="d-block w-100 imagePreviewDisplay"
                                        src="https://mdbcdn.b-cdn.net/img/Photos/Others/Carousel-thumbs/img%20(121).webp" />
                                </button>
                                <button type="button" data-mdb-target="#carouselExampleIndicators" data-mdb-slide-to="2"
                                    aria-label="Slide 3" className="btn-Preview">
                                    <img className="d-block w-100 imagePreviewDisplay"
                                        src="https://mdbcdn.b-cdn.net/img/Photos/Others/Carousel-thumbs/img%20(31).webp" />
                                </button>
                                <button type="button" data-mdb-target="#carouselExampleIndicators" data-mdb-slide-to="3"
                                    aria-label="Slide 4" className="btn-Preview">
                                    <img className="d-block w-100 imagePreviewDisplay"
                                        src="https://mdbcdn.b-cdn.net/img/Photos/Others/Carousel-thumbs/img%20(31).webp" />
                                </button>
                                <button type="button" data-mdb-target="#carouselExampleIndicators" data-mdb-slide-to="4" className="active btn-Preview"
                                    aria-current="true" aria-label="Slide 5" >
                                    <img className="d-block w-100 imagePreviewDisplay"
                                        src="https://mdbcdn.b-cdn.net/img/Photos/Others/Carousel-thumbs/img%20(88).webp" />
                                </button>
                                <button type="button" data-mdb-target="#carouselExampleIndicators" data-mdb-slide-to="5"
                                    aria-label="Slide 6" className="btn-Preview">
                                    <img className="d-block w-100 imagePreviewDisplay"
                                        src="https://mdbcdn.b-cdn.net/img/Photos/Others/Carousel-thumbs/img%20(31).webp" />
                                </button>

                            </div>
                        </div> */}

                        {/* <MDBContainer className="mt-5">
                            <MDBCarousel
                                id="carouselExampleIndicators"
                                showControls
                                fade
                                onSlide={slideChanged}
                            >
                                <MDBCarouselInner ref={carouselInner} className="rounded-3 shadow-1-strong">
                                    <MDBCarouselItem className="active">
                                        <MDBCarouselElement
                                            src="https://mdbootstrap.com/img/Photos/Slides/img%20(88).webp"
                                            alt="..."
                                        />
                                    </MDBCarouselItem>

                                    <MDBCarouselItem>
                                        <MDBCarouselElement
                                            src="https://mdbootstrap.com/img/Photos/Slides/img%20(121).webp"
                                            alt="..."
                                        />
                                    </MDBCarouselItem>

                                    <MDBCarouselItem>
                                        <MDBCarouselElement
                                            src="https://mdbootstrap.com/img/Photos/Slides/img%20(31).webp"
                                            alt="..."
                                        />
                                    </MDBCarouselItem>
                                </MDBCarouselInner>

                                <div className="carousel-indicators" style={{ marginBottom: "-20px" }}>
                                    <button
                                        className={`carousel-indicator ${currentSlide === 0 ? "active" : ""}`}
                                        onClick={() => changeSlide(0)}
                                        style={{ width: "100px" }}
                                    >
                                        <img
                                            className="d-block w-100 img-fluid shadow-1-strong rounded"
                                            src="https://mdbootstrap.com/img/Photos/Slides/img%20(88).webp"
                                        />
                                    </button>
                                    <button
                                        className={`carousel-indicator ${currentSlide === 1 ? "active" : ""}`}
                                        onClick={() => changeSlide(1)}
                                        style={{ width: "100px" }}
                                    >
                                        <img
                                            className="d-block w-100 img-fluid shadow-1-strong rounded"
                                            src="https://mdbootstrap.com/img/Photos/Slides/img%20(121).webp"
                                        />
                                    </button>
                                    <button
                                        className={`carousel-indicator ${currentSlide === 2 ? "active" : ""}`}
                                        onClick={() => changeSlide(2)}
                                        style={{ width: "100px" }}
                                    >
                                        <img
                                            className="d-block w-100 img-fluid shadow-1-strong rounded"
                                            src="https://mdbootstrap.com/img/Photos/Slides/img%20(31).webp"
                                        />
                                    </button>
                                </div>
                            </MDBCarousel>
                        </MDBContainer> */}
                        <div className="imageGalleryView">
                            <ImageGallery items={images} onClick={(e) => { imageGalleryCLick(e) }} showFullscreenButton={false} fullscreen={true} />
                        </div>


                    </div>
                </div>

            </div>

        </>

    );

}
