import React from 'react';
import productPageBanner from './../../Assets/Image/ProductpageBanner.png'
import './ProductPage.css'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Autoplay } from "swiper";

import img1 from './../../Assets/Image/product (1).jpg'
import img2 from './../../Assets/Image/product (2).jpg'
import img3 from './../../Assets/Image/product (3).jpg'
import img4 from './../../Assets/Image/product (4).jpg'

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
const ProductPage = () => {
    return (

        <div className="productPage">
            <div className="productPageBanner">
                <img className='img-fluid w-100' src={productPageBanner} alt="" />
            </div>
            <div className="customContainer productPageContainer">
                <div className="productpageSlider my-5">
                    <Swiper
                        className='review-container'
                        spaceBetween={30}
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={"auto"}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,

                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }}
                        pagination={{
                            dynamicBullets: true,
                            clickable: true,

                        }}

                        modules={[EffectCoverflow, Autoplay, Pagination]}
                    >
                        <SwiperSlide className="sliderBox" >
                            <img className="img-fluid" src="https://swiperjs.com/demos/images/nature-1.jpg" />
                        </SwiperSlide>
                        <SwiperSlide className="sliderBox" >
                            <img className="img-fluid" src="https://swiperjs.com/demos/images/nature-2.jpg" />
                        </SwiperSlide>
                        <SwiperSlide className="sliderBox" >
                            <img className="img-fluid" src="https://swiperjs.com/demos/images/nature-3.jpg" />
                        </SwiperSlide>
                        <SwiperSlide className="sliderBox" >
                            <img className="img-fluid" src="https://swiperjs.com/demos/images/nature-4.jpg" />
                        </SwiperSlide>
                        <SwiperSlide className="sliderBox" >
                            <img className="img-fluid" src="https://swiperjs.com/demos/images/nature-5.jpg" />
                        </SwiperSlide>
                        <SwiperSlide className="sliderBox" >
                            <img className="img-fluid" src="https://swiperjs.com/demos/images/nature-6.jpg" />
                        </SwiperSlide>
                        <SwiperSlide className="sliderBox" >
                            <img className="img-fluid" src="https://swiperjs.com/demos/images/nature-7.jpg" />
                        </SwiperSlide>
                        <SwiperSlide className="sliderBox" >
                            <img className="img-fluid" src="https://swiperjs.com/demos/images/nature-8.jpg" />
                        </SwiperSlide>
                        <SwiperSlide className="sliderBox" >
                            <img className="img-fluid" src="https://swiperjs.com/demos/images/nature-9.jpg" />
                        </SwiperSlide>
                    </Swiper>
                </div>



                <div className="details productPageBody my-5 my-5 ms-5 me-5 ps-5 pe-5">

                    <h2 className='SecondaryHeader d-flex justify-content-center'>Pot Kettle Black</h2>
                    <hr className='SecondaryHeaderHr' />
                    <p className='mainParagraph productPageText'>Details: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unkown printer took a galley of type Details: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unkown printer took a galley of type </p>
                </div>

                <div class="d-flex productPageBody my-5 my-5 my-5 ms-5 me-5 ps-5 pe-5">
                    <div class="row">
                        <div class="col-md-3 order-md-first">
                            <img class="img-fluid crop-image" src={img2} alt="" />
                        </div>
                        <div class="col-md-9">
                            <h2 class="SecondaryHeader">Pot Kettle Black</h2>
                            <p class="mainParagraph productPageText">
                                Details: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unkown printer took a galley of type Details: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unkown printer took a galley of type
                            </p>
                        </div>
                    </div>
                </div>

                <div class="d-flex productPageBody my-5 my-5 my-5 ms-5 me-5 ps-5 pe-5">
                    <div class="row">
                        <div class="col-md-9">
                            <h2 class="SecondaryHeader">Pot Kettle Black</h2>
                            <p class="mainParagraph productPageText">
                                Details: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unkown printer took a galley of type Details: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unkown printer took a galley of type
                            </p>
                        </div>
                        <div class="col-md-3 order-md-last-last justify-content-end">
                            <img class="img-fluid crop-image" src={img1} alt="" />
                        </div>
                    </div>
                </div>
                <div class="d-flex productPageBody my-5 my-5 my-5 ms-5 me-5 ps-5 pe-5">
                    <div class="row">
                        <div class="col-md-3 order-md-first">
                            <img class="img-fluid crop-image" src={img3} alt="" />
                        </div>
                        <div class="col-md-9">
                            <h2 class="SecondaryHeader">Pot Kettle Black</h2>
                            <p class="mainParagraph productPageText">
                                Details: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unkown printer took a galley of type Details: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unkown printer took a galley of type
                            </p>
                        </div>
                    </div>
                </div>


            </div>
            <div className="productPageBanner productPageBannerBottom position-relative mt-5 pt-5">
                <img className='img-fluid w-100 ' src={img4} alt="" />
                <div className="position-absolute translate-right productPhotoTextCardDiv">
                    <div className="card productPhotoTextCard">
                        <div className="card-body">
                            <p className='p-4 ImageTextInside'>Details: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unkown printer took a galley of type Details: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unkown printer took a galley of type </p>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
};

export default ProductPage;