import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import sixthImg from './../Assets/Image/1 54.png'
import { Link } from "react-router-dom";

const Tes7 = () => {
    return (

        <div className="thumbslider">
            <Carousel autoPlay interval="500" transitionTime="500">
                <div className='sliderCart h-100'>
                    <img src={sixthImg} alt="" className="img-fluid sliderCart" />
                    <div className="subscriptionDiv ">
                        <div className="subscriptionDivDetails ">
                            <div className=' p-2 d-flex  justify-content-between'>
                                <div className='d-flex flex-column'>
                                    <h3 >Platinum</h3>
                                    <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                </div>
                                <div className='d-flex flex-column '>
                                    {/* <p>Ratings 4.5/5</p> */}
                                    <button className='btn btn-buyNow'>Order Now </button>
                                    <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                        <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='sliderCart'>
                    <img src={sixthImg} alt="" className="img-fluid sliderCart" />
                    <div className="subscriptionDiv ">
                        <div className="subscriptionDivDetails ">
                            <div className=' p-2 d-flex  justify-content-between'>
                                <div className='d-flex flex-column'>
                                    <h3 >Platinum</h3>
                                    <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                </div>
                                <div className='d-flex flex-column '>
                                    {/* <p>Ratings 4.5/5</p> */}
                                    <button className='btn btn-buyNow'>Order Now </button>
                                    <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                        <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='sliderCart'>
                    <img src={sixthImg} alt="" className="img-fluid sliderCart" />
                    <div className="subscriptionDiv ">
                        <div className="subscriptionDivDetails ">
                            <div className=' p-2 d-flex  justify-content-between'>
                                <div className='d-flex flex-column'>
                                    <h3 >Platinum</h3>
                                    <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                </div>
                                <div className='d-flex flex-column '>
                                    {/* <p>Ratings 4.5/5</p> */}
                                    <button className='btn btn-buyNow'>Order Now </button>
                                    <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                        <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='sliderCart'>
                    <img src={sixthImg} alt="" className="img-fluid sliderCart" />
                    <div className="subscriptionDiv ">
                        <div className="subscriptionDivDetails ">
                            <div className=' p-2 d-flex  justify-content-between'>
                                <div className='d-flex flex-column'>
                                    <h3 >Platinum</h3>
                                    <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                </div>
                                <div className='d-flex flex-column '>
                                    {/* <p>Ratings 4.5/5</p> */}
                                    <button className='btn btn-buyNow'>Order Now </button>
                                    <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                        <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Carousel>
        </div>

    );
};

export default Tes7;