import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import PostDisplay from './../PostDisplay/PostDisplay'
import Test2 from '../Test2'
import './OrderPage.css'
import starImg from './../../Assets/Image/Star 14.png'
import img1 from './../../Assets/Image/1 (1).jpg'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Autoplay, Navigation } from 'swiper'
import 'swiper/css/navigation'
import OrderCart from './OrderCart/OrderCart'
import Suggestion from './Suggestion/Suggestion'
import pkbImg from './../../Assets/Image/PKB.png'
import AllPostDisplay from './../PostDisplay/AllPostDisplay/AllPostDisplay'
import AllPostDisplayComponent from '../PostDisplay/Components/AllPostDisplayComponent'

const OrderPage = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [currentScrollPosition, setCurrentScrollPosition] = useState(1300)
  const [stickyPosition, setstickyPosition] = useState(true)
  const [scrollUp, setscrollUp] = useState(-527)
  const rightSidePanelRef = useRef(null)
  useEffect(() => { }, [window.scrollY])
  const [showMore, setShowMore] = useState(false)
  const text =
    "Ground beef, string cheese, ground pork, parmesan, olive oilLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an"
  const [showMoreText2, setShowMoreText2] = useState(false)
  const text2 =
    "Ground beef, string cheese, ground pork, parmesan, olive oilLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an";
  useEffect(() => {
    const setStyle = (element, cssProperty) => {
      for (let property in cssProperty) {
        element.style[property] = cssProperty[property];
      }
    };

    const destroySticky = (element) => {
      setStyle(element, {
        top: '',
        left: '',
        bottom: '',
        width: '',
        position: '',
      });
    };

    const getOffset = (el) => {
      el = el.getBoundingClientRect();
      return {
        left: el.left + window.scrollX,
        top: el.top + window.scrollY,
      };
    };

    const simpleStickySidebar = (element, options) => {
      // Global options
      const sticky = document.querySelector(element); // Sticky sidebar
      const container = document.querySelector(options.container); // Sticky sidebar container
      const topSpace = options.topSpace ? options.topSpace : 0; // Top spacing after sticky
      const bottomSpace = options.bottomSpace ? options.bottomSpace : 0; // Bottom spacing after sticky

      // vars
      const $window = window; // window
      const stickyHeight = sticky.getBoundingClientRect().height; // Sticky sidebar height
      const stickyOffsetTop = getOffset(sticky).top; // Sticky sidebar top offset
      const stickyOffsetBottom =
        getOffset(sticky).top + sticky.getBoundingClientRect().height; // Sticky sidebar bottom offset
      const stickyOffsetLeft = getOffset(sticky).left; // Sticky sidebar left offset
      let topFixed = false; // checkpoint
      let bottomFixed = false; // checkpoint
      let lastScrollVal = 0; // checkpoint

      const getStickyHeight = () => {
        return document.querySelector(element).getBoundingClientRect().height;
      };

      // scrolling
      const handleScroll = () => {
        const scrollTop = window.scrollY;
        // when scroll position touches the "Sidebar"
        if (scrollTop > stickyOffsetTop - topSpace) {
          // if "Sidebar" is smaller than the viewport
          if (getStickyHeight() <= $window.innerHeight - topSpace) {
            // fix "Sidebar" from top
            setStyle(sticky, {
              top: topSpace + 'px',
              left: stickyOffsetLeft + 'px',
              bottom: '',
              width: sticky.getBoundingClientRect().width + 'px',
              position: 'fixed',
            });
          } else {
            // scrolling down
            if (scrollTop > lastScrollVal) {
              console.log("topFixed: " + topFixed);
              // if "Sidebar" fixed from top during scrolling down
              if (topFixed) {
                // get new offset of "Sidebar"
                const absoluteStickyOffsetTop = getOffset(sticky).top;

                setStyle(sticky, {
                  top: absoluteStickyOffsetTop - getOffset(container).top + 'px',
                  left: '',
                  bottom: '',
                  width: '',
                  position: 'absolute',
                });
                topFixed = false;
              }
              // make "Sidebar" fixed from bottom when bottom area is visible in viewport
              var v = stickyOffsetBottom - $window.innerHeight;
              console.log("V: " + v);
              console.log("scrollTop: " + scrollTop);
              if (scrollTop > v) {
                console.log("scrolling Down");
                setStyle(sticky, {
                  top: '',
                  left: stickyOffsetLeft + 'px',
                  bottom: '0px',
                  width: sticky.getBoundingClientRect().width + 'px',
                  position
                    : 'fixed',
                });
                bottomFixed = true;
              }
            } else {
              console.log("scrolling up");

              // scrolling up
              // get new offset of "Sidebar" during scrolling up
              const absoluteStickyOffsetTop = getOffset(sticky).top;
              // if "Sidebar" fixed from bottom, stop sticky to its position
              console.log("absoluteStickyOffsetTop - getOffset(container).top: " + (absoluteStickyOffsetTop - getOffset(container).top));
              if (bottomFixed) {
                setStyle(sticky, {
                  top: absoluteStickyOffsetTop - getOffset(container).top + 'px',
                  left: '',
                  bottom: '',
                  width: '',
                  position: 'absolute',
                });
                bottomFixed = false;
              }
              // make "Sidebar" fixed from top when top area is visible in viewport
              if (scrollTop < absoluteStickyOffsetTop - topSpace) {
                setStyle(sticky, {
                  top: topSpace + 'px',
                  left: stickyOffsetLeft + 'px',
                  bottom: '',
                  width: sticky.getBoundingClientRect().width + 'px',
                  position: 'fixed',
                });
                topFixed = true;
              }
            }
            lastScrollVal = scrollTop;
          }
        } else {
          // make sidebar return to its default position
          destroySticky(sticky);
        }
      };

      window.addEventListener('scroll', handleScroll);

      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    };

    simpleStickySidebar('.sidebar-inner', {
      container: '.sidebar',
      topSpace: document.querySelector('.main-header').getBoundingClientRect().height + 20,
      bottomSpace: 20,
    });
  }, []);
  return (
    <div>
      <div className="orderpageMainDiv">
        <div>
          <div className="mobileDisplay">
            <h3 className=" recomended-text ms-1">Recommended</h3>
            <Swiper
              className=""
              spaceBetween={10}
              grabCursor={false}
              centeredSlides={false}
              slidesPerView={2}
              hashNavigation={{
                watchState: true,
              }}
              // autoplay={{
              //   delay: 2200,
              //   disableOnInteraction: true,
              // }}

              navigation={true}
              modules={[Autoplay, Navigation]}
            >
              {/* this part will loop  */}
              <SwiperSlide>
                <div className="card card-Test2">
                  <img
                    src={img1}
                    className="card-img-top card-img-test2"
                    alt="..."
                  />
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <h5
                        className="card-title swiperH5"
                        style={{ fontSize: '1rem' }}
                      >
                        Cheese-Stuffed Meatballs
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <img
                          src={starImg}
                          style={{ width: '25px' }}
                          className=" "
                        />
                        <h4
                          className="m-0 p-0 card-test2-font-size"
                          style={{ fontSize: '1rem' }}
                        >
                          4.5
                        </h4>
                      </div>
                      <h4
                        className="card-test2-font-size"
                        style={{ fontSize: '1rem', marginBottom: '0px' }}
                      >
                        10.5$
                      </h4>
                    </div>

                    <p
                      className="card-text card-test2-font-size-p mt-2"
                      style={{ fontSize: '.8rem' }}
                    >
                      Ground beef, string cheese, ground....
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card card-Test2">
                  <img
                    src={img1}
                    className="card-img-top card-img-test2"
                    alt="..."
                  />
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <h5
                        className="card-title swiperH5"
                        style={{ fontSize: '1rem' }}
                      >
                        Cheese-Stuffed Meatballs
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <img
                          src={starImg}
                          style={{ width: '25px' }}
                          className=" "
                        />
                        <h4
                          className="m-0 p-0 card-test2-font-size"
                          style={{ fontSize: '1rem' }}
                        >
                          4.5
                        </h4>
                      </div>
                      <h4
                        className="card-test2-font-size"
                        style={{ fontSize: '1rem', marginBottom: '0px' }}
                      >
                        10.5$
                      </h4>
                    </div>

                    <p
                      className="card-text card-test2-font-size-p mt-2"
                      style={{ fontSize: '.8rem' }}
                    >
                      Ground beef, string cheese, ground....
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card card-Test2">
                  <img
                    src={img1}
                    className="card-img-top card-img-test2"
                    alt="..."
                  />
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <h5
                        className="card-title swiperH5"
                        style={{ fontSize: '1rem' }}
                      >
                        Cheese-Stuffed Meatballs
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <img
                          src={starImg}
                          style={{ width: '25px' }}
                          className=" "
                        />
                        <h4
                          className="m-0 p-0 card-test2-font-size"
                          style={{ fontSize: '1rem' }}
                        >
                          4.5
                        </h4>
                      </div>
                      <h4
                        className="card-test2-font-size"
                        style={{ fontSize: '1rem', marginBottom: '0px' }}
                      >
                        10.5$
                      </h4>
                    </div>

                    <p
                      className="card-text card-test2-font-size-p mt-2"
                      style={{ fontSize: '.8rem' }}
                    >
                      Ground beef, string cheese, ground....
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card card-Test2">
                  <img
                    src={img1}
                    className="card-img-top card-img-test2"
                    alt="..."
                  />
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <h5
                        className="card-title swiperH5"
                        style={{ fontSize: '1rem' }}
                      >
                        Cheese-Stuffed Meatballs
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <img
                          src={starImg}
                          style={{ width: '25px' }}
                          className=" "
                        />
                        <h4
                          className="m-0 p-0 card-test2-font-size"
                          style={{ fontSize: '1rem' }}
                        >
                          4.5
                        </h4>
                      </div>
                      <h4
                        className="card-test2-font-size"
                        style={{ fontSize: '1rem', marginBottom: '0px' }}
                      >
                        10.5$
                      </h4>
                    </div>

                    <p
                      className="card-text card-test2-font-size-p mt-2"
                      style={{ fontSize: '.8rem' }}
                    >
                      Ground beef, string cheese, ground....
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        <Test2></Test2>
        <div className="customContainer mt-5 customContainer-order-page">
          <div className="row ">
            <div className="col-12 customOrderCartPadding">
              <div className="row">
                <div className="d-flex justify-content-between distanceBottom">
                  <div className="card-body">
                    <h2 className="productHeading">Cheese-Stuffed Meatballs</h2>

                    <div className="d-flex align-item-center desktopDisplay">
                      <img src={pkbImg} alt="" className="restaurantlogo" />

                      <div className="card-body ">
                        <div className="d-flex align-item-center ">
                          <h3 className="restaurantName">Pot Kettle Black</h3>
                          <div className="d-flex justify-content-between">
                            {/* <img
                              src={starImg}
                              alt=""
                              className="restaurantRating"
                            />
                            <h3 className="OrderPageratingStar">4.5</h3> */}
                            <button className="followButton">Follow</button>
                          </div>
                        </div>

                        <div className="d-flex ResturentCategory">
                          <h6>Burgers | </h6>
                          <h6>&nbsp; Pizza | </h6>
                          <h6>&nbsp; Deserts</h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between">
                    <img src={starImg} alt="" className="starImage" />
                    <h3 className="ratingNumber">4.5</h3>
                  </div>
                </div>
                <div className="mobileDisplay distanceBottom">
                  <div
                    className="d-flex align-item-center resturantDetailsOnMobile "
                  >
                    <img src={pkbImg} alt="" className="restaurantlogo" />

                    <div className="card-body ">
                      <div className="d-flex align-item-center ">
                        <h3 className="restaurantName">Pot Kettle Black</h3>
                        <div className="d-flex justify-content-between">
                          {/* <img
                              src={starImg}
                              alt=""
                              className="restaurantRating"
                            />
                            <h3 className="OrderPageratingStar">4.5</h3> */}
                          <button className="followButton">Follow</button>
                        </div>
                      </div>

                      <div className="d-flex ResturentCategory">
                        <h6>Burgers | </h6>
                        <h6>&nbsp; Pizza | </h6>
                        <h6>&nbsp; Deserts</h6>
                      </div>
                    </div>
                  </div>
                </div>

                {screenWidth < 688 ? (
                  <p className="my-1 p-3 paragraph ">
                    {showMore
                      ? text
                      : `${text.split(/\s+/).slice(0, 20).join(' ')}`}

                    <a
                      className="text-decoration-none ms-3"
                      onClick={() => setShowMore(!showMore)}
                      style={{
                        color: '#3D1F1B',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                      }}
                    >
                      {showMore ? 'Show less' : 'Show more'}
                    </a>
                  </p>
                ) : null}

                {screenWidth > 688 ? (
                  <p className="my-1  paragraph ">{text}</p>
                ) : null}

                {/*
                   <p className="my-1 paragraph ">
                  {showMore
                    ? text
                    : `${text.split(/\s+/).slice(0, 20).join(" ")}`}

                    <a
                      className="text-decoration-none ms-3"
                      onClick={() => setShowMore(!showMore)}
                      style={{
                        color: "#3D1F1B",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                    >
                      {showMore ? "Show less" : "Show more"}
                    </a>
                  </p>
                ) : null}

                {screenWidth > 688 ? (
                  <p className="my-1 paragraph ">{text}</p>
                ) : null}

                {/*
                   <p className="my-1 paragraph ">
                  {showMore
                    ? text
                    : `${text.split(/\s+/).slice(0, 20).join(' ')}`}

                  <a
                    className="text-decoration-none ms-3"
                    onClick={() => setShowMore(!showMore)}
                    style={{
                      color: "#3D1F1B",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    {showMore ? 'Show less' : 'Show more'}
                  </a> 
                  </p>
                  */}
              </div>
              <div className="col-12 mobileDisplay">
                <div className="">
                  <div className="col-12 p-3 d-flex justify-content-between distanceBottom">
                    <button
                      data-mdb-ripple-color="dark"
                      type="button"
                      className=" dsrButton "
                    >
                      Details
                    </button>
                    <button
                      data-mdb-ripple-color="dark"
                      type="button"
                      className=" dsrButton "
                    >
                      Story
                    </button>
                    <button
                      data-mdb-ripple-color="dark"
                      type="button"
                      className=" dsrButton "
                    >
                      Instructions
                    </button>
                  </div>
                </div>
                <div className=" orderCartRow">
                  <div className="col-12 d-flex justify-content-center">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="card OrderfoodcardDetailsParagraph my-1 p-2  paragraph card-design">
                        {screenWidth < 688 ? (
                          <p>
                            {showMoreText2
                              ? text2
                              : `${text2.split(/\s+/).slice(0, 20).join(' ')}`}

                            <a
                              className="text-decoration-none ms-3"
                              onClick={() => setShowMoreText2(!showMoreText2)}
                              style={{
                                color: '#3D1F1B',
                                cursor: 'pointer',
                                fontWeight: 'bold',
                              }}
                            >
                              {showMoreText2 ? 'Show less' : 'Show more'}
                            </a>
                          </p>
                        ) : null}
                        {screenWidth > 688 ? <p>{text2}</p> : null}

                        {/* <p>
                        {showMoreText2
                          ? text2
                          : `${text2.split(/\s+/).slice(0, 20).join(" ")}`}

                        <a
                          className="text-decoration-none ms-3"
                          onClick={() => setShowMoreText2(!showMoreText2)}
                          style={{
                            color: "#3D1F1B",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                        >
                          {showMoreText2 ? "Show less" : "Show more"}
                        </a>
                      </p> */}
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div className="col-12 ">
                    <OrderCart
                      foodItem={'Cheese-Stuffed meatballs'}
                    ></OrderCart>
                  </div>
                  {/* customContainer end */}
                  {/* </div> */}
                  {/* <div className="col-12 ">
                    <div>
                      <Suggestion />
                    </div>
                  </div> */}

                  {/* card test end */}
                </div>
                {/* mobileDisplay end */}
              </div>
              {/* customContainer end */}
            </div>
          </div>
        </div>
        <br />
        <div className="col-12 mobileDisplay ">
          <div>
            <Suggestion />
          </div>
        </div>
        {/* customContainer start */}
        <div className="customContainer mt-5 customContainer-order-page">


          <div className="row  d-flex">
            <div className=" col-12 col-md-8 ">
              {/* <PostDisplay /> */}
              <AllPostDisplayComponent />
              <br></br>
              <a href="allpost" className="allPostATag">
                See More
              </a>
            </div>

            <div className={`col-0 col-md-4 desktopDisplay`}>
              <div className='sidebar'>
                <div className="sidebar-inner">
                  <div className="">
                    <div className="row">
                      <div className="col-12 p-3 d-flex justify-content-between">
                        <button
                          data-mdb-ripple-color="dark"
                          type="button"
                          className=" dsrButton "
                        >
                          Details
                        </button>
                        <button
                          data-mdb-ripple-color="dark"
                          type="button"
                          className=" dsrButton "
                        >
                          Story
                        </button>
                        <button
                          data-mdb-ripple-color="dark"
                          type="button"
                          className=" dsrButton "
                        >
                          Instructions
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center">
                        <div className="card OrderfoodcardDetails paragraph">
                          {screenWidth < 688 ? (
                            <p>
                              {showMoreText2
                                ? text2
                                : `${text2.split(/\s+/).slice(0, 20).join(' ')}`}

                              <a
                                className="text-decoration-none ms-3"
                                onClick={() => setShowMoreText2(!showMoreText2)}
                                style={{
                                  color: '#3D1F1B',
                                  cursor: 'pointer',
                                  fontWeight: 'bold',
                                }}
                              >
                                {showMoreText2 ? 'Show less' : 'Show more'}
                              </a>
                            </p>
                          ) : null}
                          {screenWidth > 688 ? <p>{text2}</p> : null}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="row ">
                        <div className="col-12 d-flex justify-content-center">
                          <OrderCart
                            foodItem={'Cheese-Stuffed meatballs'}
                          ></OrderCart>
                        </div>
                      </div>
                      <div className="">
                        <Suggestion />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          {/* mobileDisplay start */}
          {/* 
          <div className="col-12 mobileDisplay">
            <div className="">
              <div className=" col-md-1 "></div>
              <div className="col-12 col-md-10 OrderPostDisplay">
                <PostDisplay></PostDisplay>
              </div>
              <div className="col-md-1 "></div>
            </div>
            <div className="row">
              <div className=" col-md-1 "></div>
              <div className="col-12 col-md-10 OrderPostDisplay">
                <PostDisplay></PostDisplay>
              </div>
              <div className=" col-md-1 "></div>
            </div>
          </div> */}
          <div className='main-header'>

          </div>

        </div>
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  )
}

export default OrderPage