import React, { useState, useEffect, useRef } from 'react';
import Test3 from '../../Test3'
import Product from '../product/Product'
import FilterBar from '../../Restaurant/MenuSelection/FilterBar/FilterBar';

//navbar start


import { Modal, Tab, Nav, Button } from 'react-bootstrap';
import $ from "jquery";
import backBtn from './../../../Assets/Icons/back.png'
import logo from './../../../Assets/Image/LogoTransparent.png'
import { useSelector, useDispatch } from 'react-redux';


import searchIcont from './../../../Assets/Icons/searchIcon2.png'
import searchIcontCoffee from './../../../Assets/Icons/searchIcon.png'
import searchair from './../../../Assets/Image/SearchAir.png'

import loginImg from './../../../Assets/Image/login.png'
import loginDesktopImg from './../../../Assets/Image/login-Desktop.png'
import homeImg from './../../../Assets/Image/home.png'
import menuImg from './../../../Assets/Image/menu.png'

import leo from './../../../Assets/Image/Leo.png'


import fuchka from './../../../Assets/Image/1 51.png'
import star from './../../../Assets/Icons/starCoffe.png'
import PKB from './../../../Assets/Image/PKB.png'
import pasta from './../../../Assets/Image/Pasta 1.png'
import search from './../../../Assets/Icons/searchIcon.png'
import './SearchResult.css'
// import Cart from '../Cart/Cart';

//navbar end


function SearchResult() {
      const times = 6;

      const currentState = useSelector(state => state.changeLoad);
    const isRegisterPage = useSelector(state => state.openRegisterPage);
    const isReloadPage = useSelector(state => state.testReducers);
    // console.log("currentState: " + currentState.changeLoad)
    const dispatch = useDispatch();
    const [userVal, setUserValue] = useState("");
    const [profileImage, setProfileImage] = useState("");
   

    const [navbarOnTop, setNavbarOnTop] = useState(true);
    const [scrollValue, setscrollValue] = useState(0);
    const [slideUp, setSlideUp] = useState(false);
    const navbarTopRef = useRef(null)
    const [showModal, setShowModal] = useState(false);
    const [allSelected, setAllSelected] = useState(false)
    const [foodSelected, setFoodSelected] = useState(false)
    const [resturantSelected, setRestaurantSelected] = useState(false)
    const [show, setShow] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const searchDialogdivRef = useRef(null);
    const [searchInput, setSearchInput] = useState('')
    const [category, setCategory] = useState('')


    //  test start
    const handleClickOutside = (event) => {
        if (searchDialogdivRef.current && !searchDialogdivRef.current.contains(event.target)) {
            setShowModal(false);
        }
    };



    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // test end

    function handleSearchIcon() {
        setShowModal(!showModal)
    }

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.pageYOffset;
            setIsVisible(scrollPosition === 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function handleShow() {

        setShow(true);
    }
    useEffect(() => {

        
        
        window.addEventListener("scroll", (event) => {
            const filterBarRect = navbarTopRef.current.getBoundingClientRect();
            const isAboveViewport = filterBarRect.top === 0 ? true : false;
            setNavbarOnTop(isAboveViewport);
            var top = (filterBarRect.top * (-1));
            var scrollval = (scrollValue * (-1));
            if (top > scrollval) {
                setSlideUp(true);
            }
            else if (top <= 0) {
                setSlideUp(false);
                setShowModal(false);
            }
            else {
                setSlideUp(false);
                setShowModal(false);
            }

            var s = filterBarRect.top;
            setscrollValue(s);
        })
    });

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = `/`;
    }
    const goToHome = () => {
        window.location.href = `/`;
    }
    const hideModalInfo = () => {
        // dispatch(changeLoadFalse());
        // dispatch(openRegisterPageFalse());
        window.$("#exampleModalCenter").modal("hide");
    };
    const showModalInfo = () => {
        // dispatch(changeLoadFalse());
        // dispatch(openRegisterPageFalse());
        window.$("#exampleModalCenter").modal("show");
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    // function handleSearchIcon(){
    //     setShowModal(!showModal)
    // }
    function handleAllButtonClick() {
        setShowModal(!showModal);
        setAllSelected(true)
        setFoodSelected(false)
        setRestaurantSelected(false)
        // window.$("#root").css("filter", "brightness(0.5)");
    }
    function handleFoodButtonClick() {
        setShowModal(!showModal);
        setAllSelected(false)
        setFoodSelected(true)
        setRestaurantSelected(false)
    }
    function handleRestaurantButtonClick() {
        setShowModal(!showModal);
        setAllSelected(false)
        setFoodSelected(false)
        setRestaurantSelected(true)
    }

    const handleInputChange = (event) => {

        setSearchInput(event.target.value);
        console.log(searchInput)
        if (searchInput === 'b') {
            setCategory('Burger')
        }
        else if (searchInput === 'p') {
            setCategory('Pizza')
        }
        else if (searchInput === 'pa') {
            setCategory('Pasta')
        }

    };

    // props.handleNavbar(showModal)
  return (
<>
   <div>
   <div ref={navbarTopRef}>

</div>
    <div className='desktopDisplay' >
                <div className={`navbarGoTaste  ${slideUp ? "slideDown" : " slideUp "} justify-content-center`}  >
                    {/* <div className='brand '>
                        <a className={` brandname ${navbarOnTop ? "brandname-color-white" : "brandname-color-coffee"}`} href="/"><img src={logo} alt='' style={{ width: "3rem" }} /> Go <b>Taste</b></a>
                    </div> */}
                    <div style={{ position: 'relative', width: '42rem' }} ref={searchDialogdivRef} >
                        <div className='searchInput '>
                            {showModal == false &&
                                <div className={` ${navbarOnTop ? "search-field-color-white " : "search-field-color-coffee "}search-field d-flex align-items-center`} style={{boxShadow:'-1px 4px 7px 3px rgba(0, 0, 0, 0.12)'}}>

                                    <>
                                        <button className='btn btn-Search-Prop' onClick={handleAllButtonClick}><b>All</b></button>
                                        <div class="vl"></div>
                                        <button className='btn btn-Search-Prop' onClick={handleFoodButtonClick}><b>Food</b></button>
                                        <div class="vl"></div>
                                        <button className='btn btn-Search-Prop' onClick={handleRestaurantButtonClick}><b>Restaurant</b></button>
                                        <div class="vl"></div>
                                        <button className='btn btn-Search-Prop'><b>Post</b></button>
                                    </>

                                    <button className='btn btn-search' onClick={handleSearchIcon}>
                                        <img src={searchIcont} alt="" className='searchIcon' />
                                    </button>

                                </div>
                            }
                            {showModal &&
                                <div className='search-field-color-white search-field d-flex align-items-center searchInputFieldParent'>
                                    <input
                                        type="text"
                                        placeholder="Search all"
                                        className='searchInputField'
                                        onChange={handleInputChange}
                                    />
                                    <button className='btn btn-search' onClick={handleSearchIcon}>
                                        <img src={searchIcont} alt="" className='searchIcon' />
                                    </button>
                                </div>
                            }
                        </div>
                        {(showModal && !slideUp) &&

                            <div id="searchDialogId">
                                <div className='container searchDialog '  >
                                    <ul class="nav nav-tabs pt-3" id="myTab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class={`nav-link ${allSelected ? " active" : ''}`} id="home-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="home" aria-selected="true">All</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class={`nav-link ${foodSelected ? " active" : ''}`} id="profile-tab" data-bs-toggle="tab" data-bs-target="#food" type="button" role="tab" aria-controls="profile" aria-selected="false">Food</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class={`nav-link ${resturantSelected ? " active" : ''}`} id="contact-tab" data-bs-toggle="tab" data-bs-target="#restaurant" type="button" role="tab" aria-controls="contact" aria-selected="false">Restaurant</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#posts" type="button" role="tab" aria-controls="contact" aria-selected="false">Posts</button>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div class={`tab-pane fade ${allSelected ? "show active" : ''}  `} id="all" role="tabpanel" aria-labelledby="home-tab">
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={fuchka} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>
                                                        <img src={star} alt='star' className='searchStar' />
                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#3D1E19' }}>4.5</p>
                                                    </div>
                                                </div>
                                                <div className='searchLabel'>
                                                    <p className='searchText' >Food</p>
                                                </div>

                                            </div>
                                            <hr />
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={pasta} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Pot kettle black</p>
                                                    </div>
                                                </div>
                                                <div className='searchLabelPost'>
                                                    <p className='searchText' >Post</p>
                                                </div>

                                            </div>
                                            <hr />
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={PKB} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Pot kettle black</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Burgers | pizza | Desserts</p>
                                                    </div>
                                                </div>
                                                <div className='searchLabelRestaurant'>
                                                    <p className='searchText' >Restaurant</p>
                                                </div>

                                            </div>
                                            <hr />
                                            {category === '' &&
                                                <div className='pt-2'>

                                                    <h5 style={{ fontWeight: '700' }}>Top Categories</h5>
                                                </div>
                                            }
                                            <div className='pt-2' style={{ marginLeft: '10px' }}>
                                                <div className='d-flex flex-row '>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />

                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? category : 'Chinese'}</p>

                                                </div>
                                                <hr />

                                                <div className='d-flex flex-row'>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? 'Cheese ' + category : 'Pizza'}</p>


                                                </div>
                                                <hr />
                                                <div className='d-flex flex-row'>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? 'Normal ' + category : 'Pasta'}</p>


                                                </div>
                                            </div>
                                        </div>
                                        <div class={`tab-pane fade ${foodSelected ? "show active" : ''} `} id="food" role="tabpanel" aria-labelledby="profile-tab">
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={fuchka} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>
                                                        <img src={star} alt='star' className='searchStar' />
                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#3D1E19' }}>4.5</p>
                                                    </div>
                                                </div>
                                                {/* <div className='searchLabel'>
                                                    <p className='searchText' >Food</p>
                                                </div> */}

                                            </div>
                                            <hr />
                                            {/* <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={pasta} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Pot kettle black</p>
                                                    </div>
                                                </div>
                                                <div className='searchLabelPost'>
                                                    <p className='searchText' >Post</p>
                                                </div>

                                            </div> */}
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={fuchka} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>
                                                        <img src={star} alt='star' className='searchStar' />
                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#3D1E19' }}>4.5</p>
                                                    </div>
                                                </div>
                                                {/* <div className='searchLabel'>
                                                    <p className='searchText' >Food</p>
                                                </div> */}

                                            </div>
                                            <hr />
                                            {/* <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={PKB} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Pot kettle black</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Burgers | pizza | Desserts</p>
                                                    </div>
                                                </div>
                                                <div className='searchLabelRestaurant'>
                                                    <p className='searchText' >Restaurant</p>
                                                </div>

                                            </div> */}
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={fuchka} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>
                                                        <img src={star} alt='star' className='searchStar' />
                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#3D1E19' }}>4.5</p>
                                                    </div>
                                                </div>
                                                {/* <div className='searchLabel'>
                                                    <p className='searchText' >Food</p>
                                                </div> */}

                                            </div>
                                            <hr />
                                            {category === '' &&
                                                <div className='pt-2'>

                                                    <h5 style={{ fontWeight: '700' }}>Top Categories</h5>
                                                </div>
                                            }
                                            <div className='pt-2' style={{ marginLeft: '10px' }}>
                                                <div className='d-flex flex-row '>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />

                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? category : 'Chinese'}</p>

                                                </div>
                                                <hr />

                                                <div className='d-flex flex-row'>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? 'Cheese ' + category : 'Pizza'}</p>


                                                </div>
                                                <hr />
                                                <div className='d-flex flex-row'>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? 'Normal ' + category : 'Pasta'}</p>


                                                </div>
                                            </div>
                                        </div>
                                        <div class={`tab-pane fade ${resturantSelected ? "show active" : ''} `} id="restaurant" role="tabpanel" aria-labelledby="contact-tab">
                                            {/* <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={fuchka} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>
                                                        <img src={star} alt='star' className='searchStar' />
                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#3D1E19' }}>4.5</p>
                                                    </div>
                                                </div>
                                                <div className='searchLabel'>
                                                    <p className='searchText' >Food</p>
                                                </div>

                                            </div> */}
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={PKB} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Pot kettle black</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Burgers | pizza | Desserts</p>
                                                    </div>
                                                </div>
                                                {/* <div className='searchLabelRestaurant'>
                                                    <p className='searchText' >Restaurant</p>
                                                </div> */}

                                            </div>
                                            <hr />
                                            {/* <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={pasta} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Pot kettle black</p>
                                                    </div>
                                                </div>
                                                <div className='searchLabelPost'>
                                                    <p className='searchText' >Post</p>
                                                </div>

                                            </div> */}
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={PKB} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Pot kettle black</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Burgers | pizza | Desserts</p>
                                                    </div>
                                                </div>
                                                {/* <div className='searchLabelRestaurant'>
                                                    <p className='searchText' >Restaurant</p>
                                                </div> */}

                                            </div>
                                            <hr />
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={PKB} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Pot kettle black</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Burgers | pizza | Desserts</p>
                                                    </div>
                                                </div>
                                                {/* <div className='searchLabelRestaurant'>
                                                    <p className='searchText' >Restaurant</p>
                                                </div> */}

                                            </div>
                                            <hr />
                                            {category === '' &&
                                                <div className='pt-2'>

                                                    <h5 style={{ fontWeight: '700' }}>Top Categories</h5>
                                                </div>
                                            }
                                            <div className='pt-2' style={{ marginLeft: '10px' }}>
                                                <div className='d-flex flex-row '>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />

                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? category : 'Chinese'}</p>

                                                </div>
                                                <hr />

                                                <div className='d-flex flex-row'>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? 'Cheese ' + category : 'Pizza'}</p>


                                                </div>
                                                <hr />
                                                <div className='d-flex flex-row'>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? 'Normal ' + category : 'Pasta'}</p>


                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="posts" role="tabpanel" aria-labelledby="contact-tab">
                                            {/* <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={fuchka} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>
                                                        <img src={star} alt='star' className='searchStar' />
                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#3D1E19' }}>4.5</p>
                                                    </div>
                                                </div>
                                                <div className='searchLabel'>
                                                    <p className='searchText' >Food</p>
                                                </div>

                                            </div> */}
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={pasta} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Pot kettle black</p>
                                                    </div>
                                                </div>
                                                {/* <div className='searchLabelPost'>
                                                    <p className='searchText' >Post</p>
                                                </div> */}

                                            </div>
                                            <hr />
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={pasta} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Pot kettle black</p>
                                                    </div>
                                                </div>
                                                {/* <div className='searchLabelPost'>
                                                    <p className='searchText' >Post</p>
                                                </div> */}

                                            </div>
                                            <hr />
                                            {/* <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={PKB} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Pot kettle black</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Burgers | pizza | Desserts</p>
                                                    </div>
                                                </div>
                                                <div className='searchLabelRestaurant'>
                                                    <p className='searchText' >Restaurant</p>
                                                </div>

                                            </div> */}
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={pasta} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Pot kettle black</p>
                                                    </div>
                                                </div>
                                                {/* <div className='searchLabelPost'>
                                                    <p className='searchText' >Post</p>
                                                </div> */}

                                            </div>
                                            <hr />
                                            {category === '' &&
                                                <div className='pt-2'>

                                                    <h5 style={{ fontWeight: '700' }}>Top Categories</h5>
                                                </div>
                                            }
                                            <div className='pt-2' style={{ marginLeft: '10px' }}>
                                                <div className='d-flex flex-row '>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />

                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? category : 'Chinese'}</p>

                                                </div>
                                                <hr />

                                                <div className='d-flex flex-row'>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? 'Cheese ' + category : 'Pizza'}</p>


                                                </div>
                                                <hr />
                                                <div className='d-flex flex-row'>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? 'Normal ' + category : 'Pasta'}</p>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                    



                </div>

    </div>
    <div className='mobileDisplay'>
                <div className={`navbarGoTaste ${navbarOnTop ? "backgroundTranparent" : "backgroundWhite"} ${slideUp ? "slideDown" : " slideUp "}`}  >
                    <div className='searchInput mobileDisplay'>
                        <div className={` ${navbarOnTop ? "search-field-color-white " : "search-field-color-coffee "}search-field d-flex align-items-center`}>
                            <button className='btn btn-search-Mobile'>
                                <div className='d-flex'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <div>
                                            <img src={searchIcontCoffee} alt="" className='searchIconcoffee' onClick={handleShow} />
                                        </div>

                                    </div>
                                    <div className='d-flex searchBtnText'>
                                        <b className='text-muted searchForFood'>Search For Food</b>
                                        <span>All | Food | Restaurant | Post</span>
                                    </div>
                                </div>


                            </button>
                            {/* <button className='btn btn-search'>
                            <img src={searchIcont} alt="" className='searchIcon' />
                        </button> */}
                        </div>

                    </div>


                </div>
            </div>
   </div>
    <div className='row ' style={{marginTop:'5rem'}}>
          
          <div>
           
          </div>
    {/* <div className='col-1'></div> */}
      <div className='col-12' style={{marginLeft:'1.5rem'}}>
         <FilterBar />
      </div>
   {/* <div className='col-1'></div> */}

    </div>
    <div className='row'>

    <div className='col-12 col-md-7 d-flex flex-wrap justify-content-center' style={{gap:'10px'}}>
      {[...Array(times)].map((_, index) => (
          
          <Product key={index} item={`Item ${index + 1}`} />
          
        ))}
      </div>
           
      <div className='col-12 col-md-5' >

      {/* <div class="mapouter">
        <div class="gmap_canvas">
            <iframe width="100%" height="100%" id="gmap_canvas" src="https://maps.google.com/maps?q=london&t=&z=10&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                </iframe>
                
                </div>
                </div> */}

<div class="mapouter">
    <div class="gmap_canvas">
        <iframe width="770" height="791" id="gmap_canvas" src="https://maps.google.com/maps?q=london&t=&z=10&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        {/* <a href="https://2yu.co">2yu</a>
        <br>
        <style>.mapouter{position:relative;text-align:right;height:791px;width:770px;}</style>
        <a href="https://embedgooglemap.2yu.co">html embed google map</a>
        <style>.gmap_canvas {overflow:hidden;background:none!important;height:791px;width:770px;}</style> */}
        </div>
        </div>
               
          </div>
    
          
    </div>
    </>
  )
}

export default SearchResult