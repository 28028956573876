import React from 'react';
import FoodSelectionCardDisplay from '../../CardView/FoodSelectionCard/FoodSelectionCardDisplay';
import Starter from './../../../Assets/Icons/snacks.png'
import mainDish from './../../../Assets/Icons/main-course.png'
import dessert from './../../../Assets/Icons/sweets.png'
import './FoodSelection.css'

import cardImage from "./../../../Assets/Image/banner.jpg";
import img1 from "./../../../Assets/Image/1 (1).jpg";
import img2 from "./../../../Assets/Image/1 (1).png";
import img3 from "./../../../Assets/Image/1 (2).jpg";
import cakeImg from './../../../Assets/Image/cake1 1.png';
import chickenImg from './../../../Assets/Image/delicious-chicken-table 3.png';
import kababImg from './../../../Assets/Image/Kabab 1.png';
import latteCoffeeImg from './../../../Assets/Image/latte-coffee-cup 1.png';
import pastaImg from './../../../Assets/Image/Pasta 1.png';
import soupImg from './../../../Assets/Image/Soup 3.png';
import bannerImg from './../../../Assets/Image/banner.jpg';
import LeoImg from './../../../Assets/Image/Leo.png';
import "swiper/css/navigation";



// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Navigation, FreeMode } from "swiper";
import Rating from "react-rating";
import { useEffect, useState, useRef } from 'react';


import Test3 from '../../Test3';

const FoodSelection = () => {
    // const { ref: myRef, inView: myElementIsVisible } = useInView();
    const myRef = useRef();
    const [intersecting, setIntersecting] = useState(false);
    const [starterSelection, setstarterSelection] = useState(false);
    const [mainCourseSelection, setmainCourseSelection] = useState(false);
    const [DessertSelection, setDessertSelection] = useState(false);
    const [lastScrollValue, setLastScrollValue] = useState(0);

    const filterBottomRef = useRef(null);
    const [scrollValue, setscrollValue] = useState(0);
    const [slideUp, setSlideUp] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", (event) => {
            if (filterBottomRef.current != undefined) {
                const filterBarRect = filterBottomRef.current.getBoundingClientRect();
                const isAboveViewport = filterBarRect.top < 0 ? true : filterBarRect.top == 0 ? false : false;
                setIntersecting(isAboveViewport);
                var top = (filterBarRect.top * (-1));
                var scrollval = (scrollValue * (-1));
                if (top > scrollval) {
                    setSlideUp(true);
                }
                else {
                    setSlideUp(false);
                }
                var s = filterBarRect.top;
                setscrollValue(s);
            }

        })

    });
    const [clickedId, setClickedId] = useState(null);
    console.log(clickedId);
    const handleItemClick = (id) => {
        setClickedId(id);
    };
    return (
        <div id='foodSelectionMainDiv'>
            <div >

                <div className={`FoodSelection customContainer `} >
                    <div className={`row row-cols-1  row-cols-md-4 g-4 mx-auto justify-content-center my-5 selectionButton scrollbtnMobile  ${intersecting ? "HideSelectionSingleButton" : ""}`} ref={myRef} >

                        <div className="d-flex cardShadow  align-items-center justify-content-between p-3 py-3 m-2 selectionSingleButton" onClick={() => handleItemClick("Starter")}>
                            <h2 >Starter</h2>
                            <img className='img-fluid' src={Starter} alt="..." />
                        </div>
                        <div className="d-flex cardShadow align-items-center justify-content-between p-3 m-2 selectionSingleButton"
                            onClick={() => handleItemClick("MainDish")}>
                            <h2 >Main Dish</h2>
                            <img className='img-fluid' src={mainDish} alt="..." />


                        </div>
                        <div className="d-flex cardShadow align-items-center justify-content-between p-3 m-2 selectionSingleButton" onClick={() => handleItemClick("Dessert")}>
                            <h2 >Dessert</h2>
                            <img src={dessert} alt="..." />
                        </div>
                    </div>
                </div >
                <div ref={filterBottomRef}>

                </div>

                <div className={`pillSticky ${intersecting ? slideUp ? "slide-down " : "slideDownMore" : "slide-up"}`}>

                    <div className='pillDisplay'>
                        <div className='d-flex pillItem'>
                            <div className={`pillCard pillBtnOne d-flex align-items-center  ${starterSelection ? "pillCardSelect" : ""}`}
                                onClick={() => { setstarterSelection(!starterSelection) }}>
                                <img className='img-fluid' src={Starter} alt="..." />
                                <h2 >Starter</h2>
                            </div>
                            <div className={`pillCard pillBtnTwo d-flex align-items-center  ${mainCourseSelection ? "pillCardSelect" : ""}`} onClick={() => { setmainCourseSelection(!mainCourseSelection) }}>
                                <img className='img-fluid' src={mainDish} alt="..." />
                                <h2 >Main Dish</h2>
                            </div>
                            <div className={`pillCard pillBtnThree d-flex align-items-center  ${DessertSelection ? "pillCardSelect" : ""}`} onClick={() => { setDessertSelection(!DessertSelection) }}>
                                <img src={dessert} alt="..." />
                                <h2 >Dessert</h2>
                            </div>
                        </div>


                        {/* <Swiper
                            slidesPerView={2}
                            centeredSlides={false}
                            spaceBetween={5}
                            grabCursor={true}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[]}
                            className="pillSwiper"
                        >
                            <SwiperSlide>
                                <button className={`pillCard  d-flex align-items-center justify-content-between ${starterSelection ? "pillCardSelect" : ""}`}
                                    onClick={() => { setstarterSelection(!starterSelection) }}>
                                    <img className='img-fluid' src={Starter} alt="..." />
                                    <h2 >Starter</h2>
                                </button>
                            </SwiperSlide>
                            <SwiperSlide>
                                <button className={`pillCard  d-flex align-items-center justify-content-between ${mainCourseSelection ? "pillCardSelect" : ""}`} onClick={() => { setmainCourseSelection(!mainCourseSelection) }}>
                                    <img className='img-fluid' src={mainDish} alt="..." />
                                    <h2 >Main Dish</h2>
                                </button>
                            </SwiperSlide>
                            <SwiperSlide>
                                <button className={`pillCard  d-flex align-items-center justify-content-between ${DessertSelection ? "pillCardSelect" : ""}`} onClick={() => { setDessertSelection(!DessertSelection) }}>
                                    <img src={dessert} alt="..." />
                                    <h2 >Dessert</h2>
                                </button>
                            </SwiperSlide>
                        </Swiper> */}
                    </div>
                </div>

                <Test3 restaurantName={'Pot kettle'}></Test3>



                <div className='d-none'>
                    <div className='d-flex flex-wrap justify-content-center'  >

                        <div className="foodcart test m-3">

                            <div className="d-flex justify-content-end cartIcon">

                                <i className="fa fa-plus-circle "></i>
                            </div>

                            <Swiper
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="foodcartimg"
                            >

                                <SwiperSlide><img src={img3} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={cardImage} className="card-img-top img-fluid " alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img1} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img2} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                            </Swiper>


                            <div className='foodCartText mt-2'>
                                <div className="d-flex justify-content-between commentLove">
                                    <div className="d-flex commentLove">
                                        <i className="fa-regular fa-heart "></i>
                                        <i className="fa-sharp fa-regular fa-comment-dots"></i>
                                    </div>
                                    {/* <div className='d-flex align-items-center starRating'>

                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <h4 className='m-0 p-0'>4.5</h4>

                                </div> */}
                                    <div className='rating-Font mb-1 d-flex align-items-center starRating'> <Rating className="ratingStar"
                                        initialRating={Number(4.5)}
                                        emptySymbol="far fa-star icon-color"
                                        fullSymbol="fas fa-star icon-color"
                                        readonly ></Rating>4.5
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between ">

                                    <h3 className='m-0 p-0'>Bread Toast</h3>
                                    <h4 className='m-0 p-0'>10.5$</h4>

                                </div>
                                <p className='my-1'>Topped with tomato, bocconcini...  </p>
                                <div className="d-flex justify-content-between align-items-center">

                                    <div className='d-flex align-items-center'>
                                        <h6>Red Flame  Resta...</h6>
                                        <div className='d-flex align-items-center starRating'>

                                            <i className="fa-sharp fa-solid fa-star"></i>
                                            <h4 className='m-0 p-0'>4.5</h4>

                                        </div>
                                    </div>

                                    <button className='follow-BUtton btn ms-1'>Follow</button>


                                </div>




                                <div className='d-flex align-items-center'>
                                    <img src={LeoImg} className='me-1 userImg' alt="" />
                                    <p>Leo Love the art of travel. So many plac So many  foods... </p>
                                </div>


                            </div>

                            {/* <div className='foodCartText mt-2'>
                            <div className="d-flex justify-content-between commentLove">
                                <div className="d-flex commentLove">
                                    <i className="fa-regular fa-heart "></i>
                                    <i className="fa-sharp fa-regular fa-comment-dots"></i>
                                </div>
                                <div className='d-flex align-items-center starRating'>

                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <h4 className='m-0 p-0'>4.5</h4>

                                </div>
                                {/* <div className='rating-Font mb-1'>4.5 <Rating className="ratingStar"
                                    initialRating={Number(4.5)}
                                    emptySymbol="far fa-star icon-color"
                                    fullSymbol="fas fa-star icon-color"
                                    readonly ></Rating>
                                </div> */}
                            {/* </div>
                    <div className="d-flex justify-content-between ">

                        <h3 className='m-0 p-0'>Bread Toast</h3>
                        <h4 className='m-0 p-0'>10.5$</h4>

                    </div>
                    <p className='my-1'>Topped with tomato, bocconcini...  </p>
                    <div className="d-flex justify-content-between align-items-center">

                        <h6>Red Flame Restaurant</h6>
                        <button className='follow-BUtton btn ms-1'>Follow</button>


                    </div>




                    <div className='d-flex align-items-center'>
                        <img src={LeoImg} className='me-1 userImg' alt="" />
                        <p>Leo Love the art of travel. So many plac So many  foods... </p>
                    </div>


                </div> */}


                        </div>


                        <div className="foodcart test m-3">

                            <div className="d-flex justify-content-end cartIcon">
                                <i className="fa fa-plus-circle "></i>
                            </div>

                            <Swiper
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="foodcartimg"
                            >
                                <SwiperSlide><img src={cakeImg} className="card-img-top img-fluid " alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img1} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img2} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img3} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                            </Swiper>


                            <div className='foodCartText mt-2'>
                                <div className="d-flex justify-content-between commentLove">
                                    <div className="d-flex commentLove">
                                        <i className="fa-regular fa-heart "></i>
                                        <i className="fa-sharp fa-regular fa-comment-dots"></i>
                                    </div>
                                    {/* <div className='d-flex align-items-center starRating'>

                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <h4 className='m-0 p-0'>4.5</h4>

                                </div> */}
                                    <div className='rating-Font mb-1 d-flex align-items-center starRating'> <Rating className="ratingStar"
                                        initialRating={Number(4.5)}
                                        emptySymbol="far fa-star icon-color"
                                        fullSymbol="fas fa-star icon-color"
                                        readonly ></Rating>4.5
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between ">

                                    <h3 className='m-0 p-0'>Bread Toast</h3>
                                    <h4 className='m-0 p-0'>10.5$</h4>

                                </div>
                                <p className='my-1'>Topped with tomato, bocconcini...  </p>
                                <div className="d-flex justify-content-between align-items-center">

                                    <div className='d-flex align-items-center'>
                                        <h6>Red Flame  Resta...</h6>
                                        <div className='d-flex align-items-center starRating'>

                                            <i className="fa-sharp fa-solid fa-star"></i>
                                            <h4 className='m-0 p-0'>4.5</h4>

                                        </div>
                                    </div>

                                    <button className='follow-BUtton btn ms-1'>Follow</button>


                                </div>




                                <div className='d-flex align-items-center'>
                                    <img src={LeoImg} className='me-1 userImg' alt="" />
                                    <p>Leo Love the art of travel. So many plac So many  foods... </p>
                                </div>


                            </div>

                        </div>


                        <div className="foodcart test m-3">

                            <div className="d-flex justify-content-end cartIcon">
                                <i className="fa fa-plus-circle "></i>
                            </div>

                            <Swiper
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="foodcartimg"
                            >
                                <SwiperSlide><img src={soupImg} className="card-img-top img-fluid " alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img1} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img2} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img3} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                            </Swiper>

                            <div className='foodCartText mt-2'>
                                <div className="d-flex justify-content-between commentLove">
                                    <div className="d-flex commentLove">
                                        <i className="fa-regular fa-heart "></i>
                                        <i className="fa-sharp fa-regular fa-comment-dots"></i>
                                    </div>
                                    {/* <div className='d-flex align-items-center starRating'>

                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <h4 className='m-0 p-0'>4.5</h4>

                                </div> */}
                                    <div className='rating-Font mb-1 d-flex align-items-center starRating'> <Rating className="ratingStar"
                                        initialRating={Number(4.5)}
                                        emptySymbol="far fa-star icon-color"
                                        fullSymbol="fas fa-star icon-color"
                                        readonly ></Rating>4.5
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between ">

                                    <h3 className='m-0 p-0'>Bread Toast</h3>
                                    <h4 className='m-0 p-0'>10.5$</h4>

                                </div>
                                <p className='my-1'>Topped with tomato, bocconcini...  </p>
                                <div className="d-flex justify-content-between align-items-center">

                                    <div className='d-flex align-items-center'>
                                        <h6>Red Flame  Resta...</h6>
                                        <div className='d-flex align-items-center starRating'>

                                            <i className="fa-sharp fa-solid fa-star"></i>
                                            <h4 className='m-0 p-0'>4.5</h4>

                                        </div>
                                    </div>

                                    <button className='follow-BUtton btn ms-1'>Follow</button>


                                </div>




                                <div className='d-flex align-items-center'>
                                    <img src={LeoImg} className='me-1 userImg' alt="" />
                                    <p>Leo Love the art of travel. So many plac So many  foods... </p>
                                </div>


                            </div>


                        </div>


                        <div className="foodcart test m-3">

                            <div className="d-flex justify-content-end cartIcon">
                                <i className="fa fa-plus-circle "></i>
                            </div>

                            <Swiper
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="foodcartimg"
                            >
                                <SwiperSlide><img src={bannerImg} className="card-img-top img-fluid " alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img1} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img2} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img3} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                            </Swiper>
                            <div className='foodCartText mt-2'>
                                <div className="d-flex justify-content-between commentLove">
                                    <div className="d-flex commentLove">
                                        <i className="fa-regular fa-heart "></i>
                                        <i className="fa-sharp fa-regular fa-comment-dots"></i>
                                    </div>
                                    {/* <div className='d-flex align-items-center starRating'>

                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <h4 className='m-0 p-0'>4.5</h4>

                                </div> */}
                                    <div className='rating-Font mb-1 d-flex align-items-center starRating'> <Rating className="ratingStar"
                                        initialRating={Number(4.5)}
                                        emptySymbol="far fa-star icon-color"
                                        fullSymbol="fas fa-star icon-color"
                                        readonly ></Rating>4.5
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between ">

                                    <h3 className='m-0 p-0'>Bread Toast</h3>
                                    <h4 className='m-0 p-0'>10.5$</h4>

                                </div>
                                <p className='my-1'>Topped with tomato, bocconcini...  </p>
                                <div className="d-flex justify-content-between align-items-center">

                                    <div className='d-flex align-items-center'>
                                        <h6>Red Flame  Resta...</h6>
                                        <div className='d-flex align-items-center starRating'>

                                            <i className="fa-sharp fa-solid fa-star"></i>
                                            <h4 className='m-0 p-0'>4.5</h4>

                                        </div>
                                    </div>

                                    <button className='follow-BUtton btn ms-1'>Follow</button>


                                </div>




                                <div className='d-flex align-items-center'>
                                    <img src={LeoImg} className='me-1 userImg' alt="" />
                                    <p>Leo Love the art of travel. So many plac So many  foods... </p>
                                </div>


                            </div>



                        </div>


                        <div className="foodcart test m-3">

                            <div className="d-flex justify-content-end cartIcon">
                                <i className="fa fa-plus-circle "></i>
                            </div>

                            <Swiper
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="foodcartimg"
                            >
                                <SwiperSlide><img src={pastaImg} className="card-img-top img-fluid " alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img1} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img2} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img3} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                            </Swiper>
                            <div className='foodCartText mt-2'>
                                <div className="d-flex justify-content-between commentLove">
                                    <div className="d-flex commentLove">
                                        <i className="fa-regular fa-heart "></i>
                                        <i className="fa-sharp fa-regular fa-comment-dots"></i>
                                    </div>
                                    {/* <div className='d-flex align-items-center starRating'>

                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <h4 className='m-0 p-0'>4.5</h4>

                                </div> */}
                                    <div className='rating-Font mb-1 d-flex align-items-center starRating'> <Rating className="ratingStar"
                                        initialRating={Number(4.5)}
                                        emptySymbol="far fa-star icon-color"
                                        fullSymbol="fas fa-star icon-color"
                                        readonly ></Rating>4.5
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between ">

                                    <h3 className='m-0 p-0'>Bread Toast</h3>
                                    <h4 className='m-0 p-0'>10.5$</h4>

                                </div>
                                <p className='my-1'>Topped with tomato, bocconcini...  </p>
                                <div className="d-flex justify-content-between align-items-center">

                                    <div className='d-flex align-items-center'>
                                        <h6>Red Flame  Resta...</h6>
                                        <div className='d-flex align-items-center starRating'>

                                            <i className="fa-sharp fa-solid fa-star"></i>
                                            <h4 className='m-0 p-0'>4.5</h4>

                                        </div>
                                    </div>

                                    <button className='follow-BUtton btn ms-1'>Follow</button>


                                </div>




                                <div className='d-flex align-items-center'>
                                    <img src={LeoImg} className='me-1 userImg' alt="" />
                                    <p>Leo Love the art of travel. So many plac So many  foods... </p>
                                </div>


                            </div>


                        </div>


                        <div className="foodcart test m-3">

                            <div className="d-flex justify-content-end cartIcon">
                                <i className="fa fa-plus-circle "></i>
                            </div>

                            <Swiper
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="foodcartimg"
                            >
                                <SwiperSlide><img src={latteCoffeeImg} className="card-img-top img-fluid " alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img1} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img2} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img3} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                            </Swiper>
                            <div className='foodCartText mt-2'>
                                <div className="d-flex justify-content-between commentLove">
                                    <div className="d-flex commentLove">
                                        <i className="fa-regular fa-heart "></i>
                                        <i className="fa-sharp fa-regular fa-comment-dots"></i>
                                    </div>
                                    {/* <div className='d-flex align-items-center starRating'>

                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <h4 className='m-0 p-0'>4.5</h4>

                                </div> */}
                                    <div className='rating-Font mb-1 d-flex align-items-center starRating'> <Rating className="ratingStar"
                                        initialRating={Number(4.5)}
                                        emptySymbol="far fa-star icon-color"
                                        fullSymbol="fas fa-star icon-color"
                                        readonly ></Rating>4.5
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between ">

                                    <h3 className='m-0 p-0'>Bread Toast</h3>
                                    <h4 className='m-0 p-0'>10.5$</h4>

                                </div>
                                <p className='my-1'>Topped with tomato, bocconcini...  </p>
                                <div className="d-flex justify-content-between align-items-center">

                                    <div className='d-flex align-items-center'>
                                        <h6>Red Flame  Resta...</h6>
                                        <div className='d-flex align-items-center starRating'>

                                            <i className="fa-sharp fa-solid fa-star"></i>
                                            <h4 className='m-0 p-0'>4.5</h4>

                                        </div>
                                    </div>

                                    <button className='follow-BUtton btn ms-1'>Follow</button>


                                </div>




                                <div className='d-flex align-items-center'>
                                    <img src={LeoImg} className='me-1 userImg' alt="" />
                                    <p>Leo Love the art of travel. So many plac So many  foods... </p>
                                </div>


                            </div>


                        </div>


                        <div className="foodcart test m-3">

                            <div className="d-flex justify-content-end cartIcon">
                                <i className="fa fa-plus-circle "></i>
                            </div>

                            <Swiper
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="foodcartimg"
                            >
                                <SwiperSlide><img src={chickenImg} className="card-img-top img-fluid " alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img1} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img2} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img3} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                            </Swiper>
                            <div className='foodCartText mt-2'>
                                <div className="d-flex justify-content-between commentLove">
                                    <div className="d-flex commentLove">
                                        <i className="fa-regular fa-heart "></i>
                                        <i className="fa-sharp fa-regular fa-comment-dots"></i>
                                    </div>
                                    {/* <div className='d-flex align-items-center starRating'>

                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <h4 className='m-0 p-0'>4.5</h4>

                                </div> */}
                                    <div className='rating-Font mb-1 d-flex align-items-center starRating'> <Rating className="ratingStar"
                                        initialRating={Number(4.5)}
                                        emptySymbol="far fa-star icon-color"
                                        fullSymbol="fas fa-star icon-color"
                                        readonly ></Rating>4.5
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between ">

                                    <h3 className='m-0 p-0'>Bread Toast</h3>
                                    <h4 className='m-0 p-0'>10.5$</h4>

                                </div>
                                <p className='my-1'>Topped with tomato, bocconcini...  </p>
                                <div className="d-flex justify-content-between align-items-center">

                                    <div className='d-flex align-items-center'>
                                        <h6>Red Flame  Resta...</h6>
                                        <div className='d-flex align-items-center starRating'>

                                            <i className="fa-sharp fa-solid fa-star"></i>
                                            <h4 className='m-0 p-0'>4.5</h4>

                                        </div>
                                    </div>

                                    <button className='follow-BUtton btn ms-1'>Follow</button>


                                </div>




                                <div className='d-flex align-items-center'>
                                    <img src={LeoImg} className='me-1 userImg' alt="" />
                                    <p>Leo Love the art of travel. So many plac So many  foods... </p>
                                </div>


                            </div>

                        </div>


                        <div className="foodcart test m-3">

                            <div className="d-flex justify-content-end cartIcon">
                                <i className="fa fa-plus-circle "></i>
                            </div>

                            <Swiper
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="foodcartimg"
                            >
                                <SwiperSlide><img src={img2} className="card-img-top img-fluid " alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img1} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img2} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img3} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                            </Swiper>
                            <div className='foodCartText mt-2'>
                                <div className="d-flex justify-content-between commentLove">
                                    <div className="d-flex commentLove">
                                        <i className="fa-regular fa-heart "></i>
                                        <i className="fa-sharp fa-regular fa-comment-dots"></i>
                                    </div>
                                    {/* <div className='d-flex align-items-center starRating'>

                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <h4 className='m-0 p-0'>4.5</h4>

                                </div> */}
                                    <div className='rating-Font mb-1 d-flex align-items-center starRating'> <Rating className="ratingStar"
                                        initialRating={Number(4.5)}
                                        emptySymbol="far fa-star icon-color"
                                        fullSymbol="fas fa-star icon-color"
                                        readonly ></Rating>4.5
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between ">

                                    <h3 className='m-0 p-0'>Bread Toast</h3>
                                    <h4 className='m-0 p-0'>10.5$</h4>

                                </div>
                                <p className='my-1'>Topped with tomato, bocconcini...  </p>
                                <div className="d-flex justify-content-between align-items-center">

                                    <div className='d-flex align-items-center'>
                                        <h6>Red Flame  Resta...</h6>
                                        <div className='d-flex align-items-center starRating'>

                                            <i className="fa-sharp fa-solid fa-star"></i>
                                            <h4 className='m-0 p-0'>4.5</h4>

                                        </div>
                                    </div>

                                    <button className='follow-BUtton btn ms-1'>Follow</button>


                                </div>




                                <div className='d-flex align-items-center'>
                                    <img src={LeoImg} className='me-1 userImg' alt="" />
                                    <p>Leo Love the art of travel. So many plac So many  foods... </p>
                                </div>


                            </div>

                        </div>


                        <div className="foodcart test m-3">

                            <div className="d-flex justify-content-end cartIcon">
                                <i className="fa fa-plus-circle "></i>
                            </div>

                            <Swiper
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="foodcartimg"
                            >
                                <SwiperSlide><img src={latteCoffeeImg} className="card-img-top img-fluid " alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img1} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img2} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img3} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                            </Swiper>
                            <div className='foodCartText mt-2'>
                                <div className="d-flex justify-content-between commentLove">
                                    <div className="d-flex commentLove">
                                        <i className="fa-regular fa-heart "></i>
                                        <i className="fa-sharp fa-regular fa-comment-dots"></i>
                                    </div>
                                    {/* <div className='d-flex align-items-center starRating'>

                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <h4 className='m-0 p-0'>4.5</h4>

                                </div> */}
                                    <div className='rating-Font mb-1 d-flex align-items-center starRating'> <Rating className="ratingStar"
                                        initialRating={Number(4.5)}
                                        emptySymbol="far fa-star icon-color"
                                        fullSymbol="fas fa-star icon-color"
                                        readonly ></Rating>4.5
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between ">

                                    <h3 className='m-0 p-0'>Bread Toast</h3>
                                    <h4 className='m-0 p-0'>10.5$</h4>

                                </div>
                                <p className='my-1'>Topped with tomato, bocconcini...  </p>
                                <div className="d-flex justify-content-between align-items-center">

                                    <div className='d-flex align-items-center'>
                                        <h6>Red Flame  Resta...</h6>
                                        <div className='d-flex align-items-center starRating'>

                                            <i className="fa-sharp fa-solid fa-star"></i>
                                            <h4 className='m-0 p-0'>4.5</h4>

                                        </div>
                                    </div>

                                    <button className='follow-BUtton btn ms-1'>Follow</button>


                                </div>




                                <div className='d-flex align-items-center'>
                                    <img src={LeoImg} className='me-1 userImg' alt="" />
                                    <p>Leo Love the art of travel. So many plac So many  foods... </p>
                                </div>


                            </div>

                        </div>


                        <div className="foodcart test m-3">

                            <div className="d-flex justify-content-end cartIcon">
                                <i className="fa fa-plus-circle "></i>
                            </div>

                            <Swiper
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="foodcartimg"
                            >
                                <SwiperSlide><img src={kababImg} className="card-img-top img-fluid " alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img1} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img2} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img3} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                            </Swiper>
                            <div className='foodCartText mt-2'>
                                <div className="d-flex justify-content-between commentLove">
                                    <div className="d-flex commentLove">
                                        <i className="fa-regular fa-heart "></i>
                                        <i className="fa-sharp fa-regular fa-comment-dots"></i>
                                    </div>
                                    {/* <div className='d-flex align-items-center starRating'>

                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <h4 className='m-0 p-0'>4.5</h4>

                                </div> */}
                                    <div className='rating-Font mb-1 d-flex align-items-center starRating'> <Rating className="ratingStar"
                                        initialRating={Number(4.5)}
                                        emptySymbol="far fa-star icon-color"
                                        fullSymbol="fas fa-star icon-color"
                                        readonly ></Rating>4.5
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between ">

                                    <h3 className='m-0 p-0'>Bread Toast</h3>
                                    <h4 className='m-0 p-0'>10.5$</h4>

                                </div>
                                <p className='my-1'>Topped with tomato, bocconcini...  </p>
                                <div className="d-flex justify-content-between align-items-center">

                                    <div className='d-flex align-items-center'>
                                        <h6>Red Flame  Resta...</h6>
                                        <div className='d-flex align-items-center starRating'>

                                            <i className="fa-sharp fa-solid fa-star"></i>
                                            <h4 className='m-0 p-0'>4.5</h4>

                                        </div>
                                    </div>

                                    <button className='follow-BUtton btn ms-1'>Follow</button>


                                </div>




                                <div className='d-flex align-items-center'>
                                    <img src={LeoImg} className='me-1 userImg' alt="" />
                                    <p>Leo Love the art of travel. So many plac So many  foods... </p>
                                </div>


                            </div>


                        </div>
                        <div className="foodcart test m-3">

                            <div className="d-flex justify-content-end cartIcon">
                                <i className="fa fa-plus-circle "></i>
                                {/* <i className="fa-regular fa-circle-plus"></i> */}
                            </div>

                            <Swiper
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="foodcartimg"
                            >
                                <SwiperSlide><img src={cardImage} className="card-img-top img-fluid " alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img1} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img2} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img3} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                            </Swiper>
                            <div className='foodCartText mt-2'>
                                <div className="d-flex justify-content-between commentLove">
                                    <div className="d-flex commentLove">
                                        <i className="fa-regular fa-heart "></i>
                                        <i className="fa-sharp fa-regular fa-comment-dots"></i>
                                    </div>
                                    {/* <div className='d-flex align-items-center starRating'>

                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <h4 className='m-0 p-0'>4.5</h4>

                                </div> */}
                                    <div className='rating-Font mb-1 d-flex align-items-center starRating'> <Rating className="ratingStar"
                                        initialRating={Number(4.5)}
                                        emptySymbol="far fa-star icon-color"
                                        fullSymbol="fas fa-star icon-color"
                                        readonly ></Rating>4.5
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between ">

                                    <h3 className='m-0 p-0'>Bread Toast</h3>
                                    <h4 className='m-0 p-0'>10.5$</h4>

                                </div>
                                <p className='my-1'>Topped with tomato, bocconcini...  </p>
                                <div className="d-flex justify-content-between align-items-center">

                                    <div className='d-flex align-items-center'>
                                        <h6>Red Flame  Resta...</h6>
                                        <div className='d-flex align-items-center starRating'>

                                            <i className="fa-sharp fa-solid fa-star"></i>
                                            <h4 className='m-0 p-0'>4.5</h4>

                                        </div>
                                    </div>

                                    <button className='follow-BUtton btn ms-1'>Follow</button>


                                </div>




                                <div className='d-flex align-items-center'>
                                    <img src={LeoImg} className='me-1 userImg' alt="" />
                                    <p>Leo Love the art of travel. So many plac So many  foods... </p>
                                </div>


                            </div>

                        </div>


                        <div className="foodcart test m-3">

                            <div className="d-flex justify-content-end cartIcon">
                                <i className="fa fa-plus-circle "></i>
                                {/* <i className="fa-regular fa-circle-plus"></i> */}
                            </div>

                            <Swiper
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="foodcartimg"
                            >
                                <SwiperSlide><img src={img1} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={cardImage} className="card-img-top img-fluid " alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img2} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                                <SwiperSlide><img src={img3} className="card-img-top img-fluid" alt="..." /></SwiperSlide>
                            </Swiper>
                            <div className='foodCartText mt-2'>
                                <div className="d-flex justify-content-between commentLove">
                                    <div className="d-flex commentLove">
                                        <i className="fa-regular fa-heart "></i>
                                        <i className="fa-sharp fa-regular fa-comment-dots"></i>
                                    </div>
                                    {/* <div className='d-flex align-items-center starRating'>

                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <h4 className='m-0 p-0'>4.5</h4>

                                </div> */}
                                    <div className='rating-Font mb-1 d-flex align-items-center starRating'> <Rating className="ratingStar"
                                        initialRating={Number(4.5)}
                                        emptySymbol="far fa-star icon-color"
                                        fullSymbol="fas fa-star icon-color"
                                        readonly ></Rating>4.5
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between ">

                                    <h3 className='m-0 p-0'>Bread Toast</h3>
                                    <h4 className='m-0 p-0'>10.5$</h4>

                                </div>
                                <p className='my-1'>Topped with tomato, bocconcini...  </p>
                                <div className="d-flex justify-content-between align-items-center">

                                    <div className='d-flex align-items-center'>
                                        <h6>Red Flame  Resta...</h6>
                                        <div className='d-flex align-items-center starRating'>

                                            <i className="fa-sharp fa-solid fa-star"></i>
                                            <h4 className='m-0 p-0'>4.5</h4>

                                        </div>
                                    </div>

                                    <button className='follow-BUtton btn ms-1'>Follow</button>


                                </div>




                                <div className='d-flex align-items-center'>
                                    <img src={LeoImg} className='me-1 userImg' alt="" />
                                    <p>Leo Love the art of travel. So many plac So many  foods... </p>
                                </div>


                            </div>


                        </div>




                    </div>

                </div>
                {/* 
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4  row-cols-xl-5 g-4" >


                    {getCards()}
                </div > */}
            </div >
        </div >



    );

};

function getCards() {
    let count = 10;
    let cards = [];
    for (let index = 0; index < count; index++) {

        cards.push(<FoodSelectionCardDisplay></FoodSelectionCardDisplay>)
    }
    return cards;

}
function scroll() {
    console.log("Scroll");
}



export default FoodSelection;


