import React, { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { setSearchName } from './../../../../actions/index'
import boiled from '../../../../Assets/Icons/boiled.png'
import cake from '../../../../Assets/Icons/cake.png'
import coffee from '../../../../Assets/Icons/coffee.png'
import filter from '../../../../Assets/Icons/filter.png'
import filterWhite from '../../../../Assets/Icons/filter-white.png'
import soup from '../../../../Assets/Icons/hot-soup.png'
import spaguetti from '../../../../Assets/Icons/spaguetti.png'
import next from '../../../../Assets/Icons/Nextx.png'
import backLeft from '../../../../Assets/Icons/left.png'
import special from '../../../../Assets/Icons/badge.png'
import './FilterBar.css'
import menu from '../../../../Assets/Icons/menu.png'
import FilterDialogue from '../FilterDialogue/FilterDialogue'
import subscription from '../../../../Assets/Icons/notification.png'
import subscriptionWhite from '../../../../Assets/Icons/notification-white.png'
import Corporate from '../../../../Assets/Icons/corporate.png'
import CorporateWhite from '../../../../Assets/Icons/corporate-white.png'
import store from '../../../../Assets/Icons/shop.png'
import shop from '../../../../Assets/Icons/shopping-cart.png'
import storeWhite from '../../../../Assets/Icons/shop-white.png'
import dots from '../../../../Assets/Icons/dots.png'
import Apps from '../../../../Assets/Icons/app.png'
import BottomBar from '../BottomBar/BottomBar'
import SearchBar from '../../../SearchBar/SearchBar'
import pkbImg from '../../../../Assets/Image/PKB.png'

const FilterBar = (props) => {
  //   const searchName = useSelector((state) => state.changeSearchName)
  const dispatch = useDispatch()
  const searchClick = useSelector((state) => state.searchiconClick)
  const containerRef = useRef(null)
  const [scrollAmount, setScrollAmount] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [searchValue, setSearchValue] = useState('search')

  //filter modal popoup functionality
  const openModal = () => {
    document.body.classList.add('modal-open')
    setShowModal(true)
  }

  const closeModal = () => {
    document.body.classList.remove('modal-open')
    setShowModal(false)
  }
  //bottombar modal opening closing functionality
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = () => {
    setIsModalVisible((prevIsModalVisible) => !prevIsModalVisible);
  };

  const handleDropDownClick = (event) => {
    event.stopPropagation();
    toggleModal();
  };

  //right arrow button functionality
  const handleScrollRight = () => {
    const container = containerRef.current
    const containerWidth = container.clientWidth
    const maxScrollAmount = container.scrollWidth - containerWidth
    const newScrollAmount = Math.min(
      scrollAmount + containerWidth,
      maxScrollAmount,
    )
    container.scrollTo({ left: newScrollAmount, behavior: 'smooth' })
    setScrollAmount(newScrollAmount)
  }
  //left arrow button functionality
  const handleScrollLeft = () => {
    const container = containerRef.current;
    const containerWidth = container.clientWidth;
    const maxScrollAmount = container.scrollWidth - containerWidth;
    const newScrollAmount = Math.max(scrollAmount - containerWidth, 0);

    // Check if there is anything left to scroll to the left
    if (newScrollAmount === scrollAmount) {
      return;
    }

    container.scrollTo({ left: newScrollAmount, behavior: "smooth" });
    setScrollAmount(newScrollAmount);
  };

  // filter select and auto scroll functionality
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedPreviousFilter, setselectedPreviousFilter] = useState(null);
  // when props.isFilterBarFixed is false no filter will be selected initially start
  // const handleFilterClick = (filter) => {
  //   if (!props.isFilterBarFixed) {
  //     setSelectedFilter(filter === selectedFilter ? null : filter);
  //     return;
  //   }

  //   setSelectedFilter(filter === selectedFilter ? null : filter);
  // };
  // when props.isFilterBarFixed is false no filter will be selected initially end

  //All filter will selected initially when props.isFilterBarFixed is false start
  // const handleFilterClick = (filter) => {
  //   setSelectedFilter(filter === selectedFilter ? null : filter);
  // };
  //All filter will selected initially when props.isFilterBarFixed is false end

  //when props.isFilterBarFixed became false from true then automatically selected filter will became unselected start
  const topbarForFilterBar = useRef(null)
  const [scrollValue, setscrollValue] = useState(0);
  useEffect(() => {
    if (!props.isFilterBarFixed) {
      setSelectedFilter(null);
    }
  }, [props.isFilterBarFixed]);

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter === selectedFilter ? null : filter);
  };
  //when props.isFilterBarFixed became false from true then automatically selected filter will became unselected end
  useEffect(() => {
    const handleScroll = () => {
      if (!props.isFilterBarFixed) {
        return; // Exit early if isFilterBarFixed is falsy
      }
      var val = 0;
      const filterBarRect = topbarForFilterBar.current.getBoundingClientRect();
      const isAboveViewport = filterBarRect.top === 0 ? true : false;
      var top = (filterBarRect.top * (-1));
      var scrollval = (scrollValue * (-1));
      if (top > scrollval) {
        val = 0;
      }
      else if (top === 0) {
        val = 1;
      }
      else {
        val = 0;
      }
      var s = filterBarRect.top;
      const scrollY = window.scrollY;
      const filterElements = document.querySelectorAll('.filterbar-img-div');
      const filterCount = filterElements.length;

      // Calculate the index of the selected filter based on the scroll position
      let selectedFilterIndex = Math.floor((scrollY - 2600) / 800) % filterCount;
      var previousSelectedVal = selectedFilterIndex - 1;

      // Adjust the calculation to start selecting from the first filter
      selectedFilterIndex = selectedFilterIndex >= 0 ? selectedFilterIndex : filterCount - 1;

      // Set the selected filter based on the index
      const selectedFilter = filterElements[selectedFilterIndex]?.getAttribute('data-filter');
      setSelectedFilter(selectedFilter);
      // Scroll the container to the selected filter
      if (val === 1) {
        const selectedFilterElement = filterElements[selectedFilterIndex + 1];
        if (selectedFilterElement) {
          selectedFilterElement.scrollIntoView({});
        }
      }
      else {
        const selectedFilterElement = filterElements[selectedFilterIndex];
        if (selectedFilterElement) {
          selectedFilterElement.scrollIntoView({});
        }
      }


    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up function to remove scroll event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });



  const [selectedBtn, setSelectedBtn] = useState(0)

  function handleButtonClick(index) {
    setSelectedBtn(index)
    const dropdownItems = document.querySelectorAll('.dropdown-item')

    dropdownItems.forEach((item) => {
      item.classList.remove('selected')
    })

    const selectedDropdownItem = document.querySelector(
      `.dropdown-item:nth-child(${index})`,
    )
    selectedDropdownItem.classList.add('selected')
    if (index === 4) {
      setShowModal(true)
    }
  }
  const handleShop = (props) => {
    setSearchValue(props)
  }
  //Humberger & search popup functionality
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div>

      <BottomBar isModalVisible={isModalVisible} toggleModal={toggleModal} />
      <div className={` d-flex justify-content-center 
      ${props.isFilterBarFixed && searchClick ? 'filterbarGapOnClick filterBarSticky filterbar-on-searchshow' : 'filterbar filterbarGap'}
      ${!props.isFilterBarFixed && searchClick ? 'filterbar-off-searchshow' : ''}`}>
        <div className={`d-flex `} style={{ width: '100%', margin: '0px' }}>
          <div className="" style={{ display: props.isFilterBarFixed && windowWidth > 766 ? 'block' : 'none', }}>
            <div className="" style={{ display: 'inline-block' }} ref={containerRef}>
              <div className="menuSelectionImgDiv d-flex align-items-center">
                <SearchBar />
              </div>
            </div>
          </div>
          {scrollAmount > 0 && (
            <div className="col-1 d-flex flex-column justify-content-center m-3 arrow-button-div d-none d-md-flex p-1 rounded-circle">
              <div className="btn arrow-button-div-btn p-0" onClick={handleScrollLeft}>
                <img className="object-fit-contain w-100 h-100" src={backLeft} />
              </div>
            </div>
          )}
          <div className="col" style={{ display: props.isFilterBarFixed && windowWidth < 767 ? 'block' : 'none', }}>
            <div className="" style={{ display: 'inline-block' }} ref={containerRef}>
              <div className="menuSelectionImgDiv d-flex align-items-center">
                <SearchBar />
                <img className="dropDownfilterBarImageIcon" src={dots} onClick={handleDropDownClick} alt="Shop Icon" />
              </div>
            </div>
          </div>
          <div className={`${searchClick ? 'menuSelection-filterOnClick menuSelection-filter' : 'menuSelection-filter'} col-9`} ref={containerRef}>
            <div ref={topbarForFilterBar}>

            </div>
            <div className="d-flex " style={{ gap: '10px' }}>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'Apps' ? 'selected' : ''}`} data-filter="Apps" onClick={() => handleFilterClick('Apps')}>
                <img className="" src={Apps} alt="Apps" />
                <div className="text-img">All</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'Special' ? 'selected' : ''}`} data-filter="Special" onClick={() => handleFilterClick('Special')}>
                <img className="" src={special} alt="Special" />
                <div className="text-img">Special</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'Shop' ? 'selected' : ''}`} data-filter="Shop" onClick={() => handleFilterClick('Shop')}>
                <img src={shop} alt="shop" />
                <div className="text-img"> Shop </div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'Chicken' ? 'selected' : ''}`} data-filter="Chicken" onClick={() => handleFilterClick('Chicken')}>
                <img src={spaguetti} alt="spaguetti" />
                <div className="text-img">Chicken</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'boiled' ? 'selected' : ''}`} data-filter="boiled" onClick={() => handleFilterClick('boiled')}>
                <img src={boiled} alt="boiled" />
                <div className="text-img">Chicken</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'boiled1' ? 'selected' : ''}`} data-filter="boiled1" onClick={() => handleFilterClick('boiled1')}>
                <img src={coffee} alt="boiled1" />
                <div className="text-img">Chicken</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'boiled2' ? 'selected' : ''}`} data-filter="boiled2" onClick={() => handleFilterClick('boiled2')}>
                <img src={cake} alt="boiled2" />
                <div className="text-img">Chicken</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'boiled3' ? 'selected' : ''}`} data-filter="boiled3" onClick={() => handleFilterClick('boiled3')}>
                <img src={soup} alt="boiled3" />
                <div className="text-img">Chicken</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'boiled4' ? 'selected' : ''}`} data-filter="boiled4" onClick={() => handleFilterClick('boiled4')}>
                <img src={spaguetti} alt="boiled4" />
                <div className="text-img">Chicken</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'boiled5' ? 'selected' : ''}`} data-filter="boiled5" onClick={() => handleFilterClick('boiled5')}>
                <img src={boiled} alt="boiled5" />
                <div className="text-img">Chicken</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'boiled6' ? 'selected' : ''}`} data-filter="boiled6" onClick={() => handleFilterClick('boiled6')}>
                <img src={cake} alt="boiled6" />
                <div className="text-img">Chicken</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'boiled7' ? 'selected' : ''}`} data-filter="boiled7" onClick={() => handleFilterClick('boiled7')}>
                <img src={spaguetti} alt="boiled7" />
                <div className="text-img">Chicken</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'boiled8' ? 'selected' : ''}`} data-filter="boiled8" onClick={() => handleFilterClick('boiled8')}>
                <img src={coffee} alt="boiled8" />
                <div className="text-img">Chicken</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'boiled9' ? 'selected' : ''}`} data-filter="boiled9" onClick={() => handleFilterClick('boiled9')}>
                <img src={boiled} alt="boiled9" />
                <div className="text-img">Chicken</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'boiled10' ? 'selected' : ''}`} data-filter="boiled10" onClick={() => handleFilterClick('boiled10')}>
                <img src={coffee} alt="boiled10" />
                <div className="text-img">Chicken</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'boiled11' ? 'selected' : ''}`} data-filter="boiled11" onClick={() => handleFilterClick('boiled11')}>
                <img src={spaguetti} alt="boiled11" />
                <div className="text-img">Chicken</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'boiled12' ? 'selected' : ''}`} data-filter="boiled12" onClick={() => handleFilterClick('boiled12')}>
                <img src={boiled} alt="boiled12" />
                <div className="text-img">Chicken</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'boiled13' ? 'selected' : ''}`} data-filter="boiled13" onClick={() => handleFilterClick('boiled13')}>
                <img src={soup} alt="boiled13" />
                <div className="text-img">Chicken</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'boiled14' ? 'selected' : ''}`} data-filter="boiled14" onClick={() => handleFilterClick('boiled14')}>
                <img src={boiled} alt="boiled14" />
                <div className="text-img">Chicken</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'boiled15' ? 'selected' : ''}`} data-filter="boiled15" onClick={() => handleFilterClick('boiled15')}>
                <img src={coffee} alt="boiled15" />
                <div className="text-img">Chicken</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'boiled16' ? 'selected' : ''}`} data-filter="boiled16" onClick={() => handleFilterClick('boiled16')}>
                <img src={spaguetti} alt="boiled16" />
                <div className="text-img">Chicken</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'boiled17' ? 'selected' : ''}`} data-filter="boiled17" onClick={() => handleFilterClick('boiled17')}>
                <img src={boiled} alt="boiled17" />
                <div className="text-img">Chicken</div>
              </div>
              <div className={`text-center filterbar-img-div ${selectedFilter === 'boiled18' ? 'selected' : ''}`} data-filter="boiled18" onClick={() => handleFilterClick('boiled18')}>
                <img src={soup} alt="boiled18" />
                <div className="text-img">Chicken</div>
              </div>
            </div>
          </div>

          <div className="col-1 d-flex flex-column justify-content-center m-3 arrow-button-div d-none d-md-flex p-1 rounded-circle">
            <div className="btn arrow-button-div-btn p-0" onClick={handleScrollRight}>
              <img className="object-fit-contain w-100 h-100" src={next} />
            </div>
          </div>
          <div className="col-1 d-flex flex-column justify-content-center filter-button-div d-none d-md-flex">
            <div className="btn filter-button-div-btn justify-content-center d-flex align-content-center" onClick={openModal}>
              <img className="object-fit-contain" src={filter} />
              <span className="ms-2">Filters</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={`modal-backdrop fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }}></div>
        <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none', zIndex: 9999 }}
          onClick={(e) => { if (e.target === e.currentTarget) { closeModal() } }}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content model-content-filterDialogue">
              <div className="modal-header p-1 ps-3">
                <div className="btn btn-close-filterdialogue justify-content-center align-content-center" onClick={closeModal}>
                  <span aria-hidden="true">&times;</span>
                </div>
                <h5 className="modal-title m-auto">Filters</h5>
              </div>
              <div className="modal-body">
                <FilterDialogue />
              </div>
              <div className="modal-footer">
                <div className="btn btn-close-filterdialogue me-auto fs-6" onClick={closeModal}>
                  <span aria-hidden="true">Clear all</span>
                </div>
                <div className="btn btn-close-filterdialogueApply text-white w-25 ms-auto fs-6 " onClick={closeModal}>
                  <span aria-hidden="true">Apply</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const getValue = (props) => {
  return 1
}

export default FilterBar