import React from 'react';
import { useState } from 'react';
import './Signup.css'
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import Swal from 'sweetalert2/dist/sweetalert2'
import { uploadFiles } from '../../FileUploader/FileUploader';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import loadingImg from './../../Assets/Image/loading.gif'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SigenUp = () => {

    const apiAddress = process.env.REACT_APP_SECRET;

    const [CompanyName, setCompanyName] = useState("");
    const [Address, setAddress] = useState("");
    const [CompanyRegistrationNumber, setCompanyRegistrationNumber] = useState("");
    const [FirstName, setFirstName] = useState("");
    const [SurName, setSurName] = useState("");

    const [EmailAddress, seEmailAddress] = useState("");
    const [WebsiteAddress, seEWebsiteAddress] = useState("");
    // const [fileUrl, setFileUrl]=useState();
    const [fileName, setFileName] = useState();
    const [isChecked, setIsChecked] = useState(false)
    const [image, setImage] = useState(false)


    const [Phone, setPhone] = useState("");

    const [error, setError] = useState('');

    const validatePhoneNumber = () => {

        const phonePattern = /^[0-9]{11}$/;

        if (!phonePattern.test(Phone)) {
            setError('Please enter a valid 11-digit phone number.');
            return false;
        }

        setError('');
        return true;
    };

    const [businessTypeData, seBusinessTypeData] = React.useState([]);

    const loadData = () => {
        axios
            .get(apiAddress + "/api/RestaurantRegistration/GetAllBusinesstype")
            .then((response) => {
                console.log(response.data[0])
                seBusinessTypeData(response.data)
            });
    }

    React.useEffect(() => {
        console.log('first')
        loadData();
    }, []);


    const [typeId, settypeId] = useState('');
    const [businessTypeValid, setbusinessTypeValid] = useState(true);
    const handleBusinessTypeChange = (event) => {
        settypeId(event.target.value)
        setbusinessTypeValid(true);
    };


    const isFileSizeValid = (file, maxSizeInBytes) => {
        if (file && file.size <= maxSizeInBytes) {
            return file;
        }

    };
    const fileUploadChange = (event) => {
        const file = event.target.files[0]
        const maxSizeInBytes = 50 * 1024 * 1024;

        if (isFileSizeValid(file, maxSizeInBytes)) {
            setFileName(file)
        } else {

            alert("file size must be less than 50 mb")
        }
    }

    async function uploadDocument(data) {
        try {
            const resUrl = await uploadFiles(data);
            return resUrl[0];
        } catch (err) {
            console.log(err);
            throw err;
        }

    }

    const handletermsAndCondition = () => {
        setIsChecked(!isChecked)
    }


    let handleSubmit = async (e) => {
        e.preventDefault();
        setShow(true);

        try {
            if (validatePhoneNumber()) {

                const fileUrl = await uploadDocument(fileName)
                console.log(fileUrl);
                if (fileUrl == undefined) {
                    setImage(true)
                    setShow(false);
                }
                else {
                    setImage(false)
                }
                console.log(typeId);
                var isvalid = true;
                if (typeId == '') {
                    console.log(typeId);
                    setbusinessTypeValid(false);
                    setShow(false);
                    isvalid = false;
                }

                if (validatePhoneNumber() && fileUrl != undefined && isvalid) {
                    console.log('Api called')
                    let res = await fetch(apiAddress + "/api/RestaurantRegistration", {
                        method: "POST",
                        headers: { 'Content-type': 'application/json' },
                        body: JSON.stringify({
                            id: 'F07294B7-C94D-4D5E-A064-B1282D5B5532',
                            companyLegalName: CompanyName,
                            address: Address,
                            companyRegistrationNumber: CompanyRegistrationNumber,
                            businessTypeId: typeId,
                            firstName: FirstName,
                            surName: SurName,
                            phoneNumberCountryCode: "GB",
                            phoneNumber: Phone,
                            email: EmailAddress,
                            webSiteAddress: WebsiteAddress,
                            uploadedDocImagePath: fileUrl,
                            approvalStatusId: 'F07294B7-C94D-4D5E-A064-B1282D5B5532'
                        }),
                    }).then(function (response) {
                        setShow(false);
                        if (response.status == 400) {
                            toast.error("One or more validation error", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                            });
                        }
                        else {
                            confirmAlert({
                                customUI: ({ onClose }) => {
                                    return (
                                        <div className='custom-ui'>
                                            <h2>Your request submitted successfully</h2>
                                            <p>Please wait our admin will review your request</p>
                                            <button className='btn-yes btn-common'
                                                onClick={() => {
                                                    // this.handleClickDelete();
                                                    window.location.href = "/";
                                                    onClose();
                                                }}
                                            >
                                                Ok
                                            </button>
                                        </div>
                                    );
                                }
                            })
                        }

                    })

                    let resJson = res.json();
                    console.log(resJson);
                }
            }
            else {
                setShow(false);
            }
        } catch (err) {
            setShow(false);
            console.log(err);
        };

    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className='signUp d-flex'>
            <div className='modalLoader'>
                <Modal show={show} onHide={handleClose} backdrop="static" centered className='modalLoader'>
                    <Modal.Body>
                        <img src={loadingImg} alt="" />
                    </Modal.Body>
                </Modal>
            </div>

            <div className="row d-flex justify-content-end d-flex align-items-center m-3">
                <div className="col-12 col-md-4 bg-light rounded signUpForm container">
                    <div className='d-flex justify-content-center'>
                        <div className='text-center'>
                            <h2 className='MainHeader'>Get Started </h2>
                            {/* <h6 className='my-3'>Getting started is free and easy</h6> */}
                        </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <ToastContainer />
                        <div className="row g-4">
                            <div className="col-12 ">
                                <div class="form-group">
                                    <input type="text" class="form-control p-3" id="CompanyName" placeholder="Legal Name of Company" value={CompanyName} onChange={(e) => setCompanyName(e.target.value)} required />
                                </div>
                            </div>

                            <div className="col-12">
                                <div class="form-group">
                                    <input type="text" class="form-control p-3" id="Address" placeholder="Address" value={Address} onChange={(e) => setAddress(e.target.value)} required />
                                </div>
                            </div>

                            <div className="col-12 ">
                                <div class="form-group">
                                    <input type="text" class="form-control p-3" id="CompanyRegistrationNumber" placeholder="Company Registration Number" value={CompanyRegistrationNumber} onChange={(e) => setCompanyRegistrationNumber(e.target.value)} required />
                                </div>
                            </div>

                            <div className="col-12 ">
                                <div className="form-group">
                                    <select className="form-control newCOlor" aria-label="Default select example" required onChange={handleBusinessTypeChange}>
                                        <option selected>Business Type</option>
                                        {businessTypeData.map((name, index) => (
                                            // <p key={index}>{name}</p>
                                            <option Value={name.id} >{name.businessTypeDescription}</option>
                                        ))}

                                        {/* <option selected>Business Type</option>
                                        <option value="1">Individual</option>
                                        <option value="2">Chain</option>
                                        <option value="3">Others</option> */}
                                    </select>
                                    <span className={`${businessTypeValid ? 'd-none' : ''}`} style={{ color: "red" }}>Business Type Required</span>
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div class="form-group">
                                    <input type="text" class="form-control p-3" id="FirstName" placeholder="First Name" value={FirstName} onChange={(e) => setFirstName(e.target.value)} required />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div class="form-group">
                                    <input type="text" class="form-control p-3" id="SurName" placeholder="Sur Name" value={SurName} onChange={(e) => setSurName(e.target.value)} required />
                                </div>
                            </div>
                            <div className="col-12  ">
                                <div class="form-group">
                                    <div className='d-flex' style={{ gap: "10px" }}>
                                        <select className="form-control newCOlor" aria-label="Default select example" style={{ width: "5rem" }} required>
                                            <option selected>GB</option>
                                            <option value="1">BD</option>
                                            <option value="2">IND</option>
                                        </select>

                                        <input type="number" class="form-control p-3" id="Phone" placeholder="Phone" value={Phone} onChange={(e) => setPhone(e.target.value)} required />


                                    </div>
                                    <div className='d-flex justify-content-end'>
                                        {error && <span style={{ color: 'red' }}>{error}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div class="form-group">
                                    <input type="email" class="form-control p-3" id="EmailAddress" placeholder="Email Address" value={EmailAddress} onChange={(e) => seEmailAddress(e.target.value)} required />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div class="form-group">
                                    <input type="text" class="form-control p-3" id="WebsiteAddress" placeholder="Website Address (Optional)" value={WebsiteAddress} onChange={(e) => seEWebsiteAddress(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                {fileName &&
                                    <li style={{ color: 'blue' }}>{fileName.name}</li>
                                }
                                <div class="form-group">
                                    <input type="file" name="uploadfile" id="img" className='d-none' onChange={fileUploadChange} accept=".pdf, .jpg, .png, .jpeg, .img" multiple />
                                    <label class="form-control filePlaceholder p-3" for="img"><span className='me-1'><i class="fa-solid fa-file-arrow-up"></i></span> Click me to upload image</label>
                                </div>
                                {image && <span style={{ color: 'red' }}>image is required</span>}
                            </div>
                            <div className="col-12 ">
                                <div class="form-group">
                                    <div className='d-flex' style={{ gap: "10px" }}>
                                        <input type="checkbox" name="" id="" onChange={handletermsAndCondition} />
                                        I Agree to the <a href="/termsandcondition"  >Terms And condition</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div>
                            <button type='submit' className=' mt-3 px-5 btn btn-success mx-auto d-flex' disabled={!isChecked}>Submit</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    );
}


export default SigenUp;