import React from 'react';
import chatImg from './../../Assets/Image/Leo.png';
import messageSend from './../../Assets/Icons/Send.jpeg';
import backButton from './../../Assets/Icons/back-button.png';
import { Link } from 'react-router-dom';

const MobileChat = () => {
    return (
        <div>
            <div className="col-12 p-0">

                <div className="chat">
                    <div className='ChatName p-3 mb-3 d-flex align-items-center'>
                        <Link to="/chat" >
                            <img className='backButton img-fuild' src={backButton} alt="" /></Link>
                        <h3 className='p-0 m-0'>Jeremy Renner</h3></div>
                    <div className='px-4'>

                        <div className='d-flex  m-2 align-items-cente'>
                            <div className='me-2'>     <img src={chatImg} alt="" /></div>
                            <div>
                                <     h6 className='reciveMessage p-2 m-0 ps-3' >hi</     h6>

                            </div>
                        </div>
                        <div className='d-flex  m-2 align-items-cente justify-content-end'>
                            <div>
                                <     h6 className='sendMessage p-2 m-0 ps-3' >hi</     h6>

                            </div>
                        </div>

                        <p className='d-flex justify-content-center text-gray'>Monday 8.55 pm</p>

                        <div className='d-flex  m-2 align-items-cente
                            '>
                            <div className='me-2'>     <img src={chatImg} alt="" /></div>
                            <div>
                                <     h6 className='reciveMessage p-2 m-0 ps-3' >hi</     h6>

                            </div>
                        </div>
                        <div className='d-flex  m-2 align-items-cente justify-content-end'>
                            <div>
                                <     h6 className='sendMessage p-2 m-0 ps-3 ' >hi</     h6>

                            </div>
                        </div>

                        <div className='d-flex  m-2 align-items-cente
                            '>
                            <div className='me-2'>     <img src={chatImg} alt="" /></div>
                            <div>
                                <     h6 className='reciveMessage p-2 m-0 ps-3' >hi</     h6>

                            </div>
                        </div>
                        <div className='d-flex  m-3 align-items-cente justify-content-end'>
                            <div>
                                <     h6 className='sendMessage p-2 m-0 ps-3 ' >hi</     h6>

                            </div>
                        </div>

                        <div className='d-flex  m-2 align-items-cente
                            '>
                            <div className='me-2'>     <img src={chatImg} alt="" /></div>
                            <div>
                                <     h6 className='reciveMessage p-2 m-0 ps-3' >hi</     h6>

                            </div>
                        </div>
                        <div className='d-flex  m-2 align-items-cente justify-content-end'>
                            <div>
                                <     h6 className='sendMessage p-2 m-0 ps-3 ' >hi</     h6>

                            </div>
                        </div>

                        <div className='d-flex  m-2 align-items-cente
                            '>
                            <div className='me-2'>     <img src={chatImg} alt="" /></div>
                            <div>
                                <     h6 className='reciveMessage p-2 m-0 ps-3' >hi</     h6>

                            </div>
                        </div>
                        <div className='d-flex  m-2 align-items-cente justify-content-end'>
                            <div>
                                <     h6 className='sendMessage p-2 m-0 ps-3 ' >hi</     h6>

                            </div>
                        </div>
                        <div className='d-flex  m-2 align-items-cente
                            '>
                            <div className='me-2'>     <img src={chatImg} alt="" /></div>
                            <div>
                                <     h6 className='reciveMessage p-2 m-0 ps-3' >hi</     h6>

                            </div>
                        </div>
                        <div className='d-flex  m-2 align-items-cente justify-content-end'>
                            <div>
                                <     h6 className='sendMessage p-2 m-0 ps-3 ' >hi</     h6>

                            </div>
                        </div>
                        <div className='d-flex  m-2 align-items-cente
                            '>
                            <div className='me-2'>     <img src={chatImg} alt="" /></div>
                            <div>
                                <     h6 className='reciveMessage p-2 m-0 ps-3' >hi</     h6>

                            </div>
                        </div>
                        <div className='d-flex  m-2 align-items-cente justify-content-end'>
                            <div>
                                <     h6 className='sendMessage p-2 m-0 ps-3 ' >hi</     h6>

                            </div>
                        </div>

                    </div>

                    <div className="messageSend">

                        <div class="input-group">
                            <textarea type="text" class="form-control" placeholder="Message" aria-label="Recipient's username" aria-describedby="button-addon2" />
                            <button class="btn  sendbtn" type="button" id="button-addon2"><img className='img-fluid ' src={messageSend} alt="" /></button>
                        </div>


                    </div>

                </div>

            </div>
        </div>
    );
};

export default MobileChat;