import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Autoplay, Navigation, EffectCoverflow, Pagination } from 'swiper'
import 'swiper/css/navigation'
import plusIcon from './../../../Assets/Icons/plus.png'
import burger from './../../../Assets/Image/Burger22.jpeg'

import bannerImg from './../../../Assets/Image/banner.jpg'
import airBnbImage from './../../../Assets/Image/airbnb.webp'
import res from './../../../Assets/Icons/res.png'

import './Recommendation.css'

const Recommendation = (props) => {
    const [fromHome, SetFromHome] = useState(true);
    useEffect(() => {
        console.log(props);
        if (props.from === 2) {
            SetFromHome(false);
        }
    })
    return (
        <div>
            <Swiper
                spaceBetween={30}
                grabCursor={false}
                centeredSlides={false}
                slidesPerView={"auto"}
                hashNavigation={{
                    watchState: true,
                }}
                // autoplay={{
                //     delay: 2000,
                //     disableOnInteraction: true,
                // }}
                breakpoints={{
                    300: {
                        slidesPerView: 2,
                        spaceBetween: 5,
                    },
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 5,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                    },
                }}
                navigation={true}
                modules={[Autoplay, Navigation, Pagination]}
                pagination={{
                    dynamicBullets: true,
                    clickable: true,
                }}
            >
                <SwiperSlide>
                    <div className="recommendationImage">
                        <img src={burger} alt="" className="img-fluid" />
                        <div className="recommendationImageDetailsDiv">
                            <div className="recommendationImageDetails ms-2 mt-1">
                                <h6>Pasta</h6>
                                <div className={`${fromHome ? "" : "d-none"}`}>
                                    <h6 className="d-flex justify-content-between  price  me-2 homePageCard">
                                        <div className='d-flex divrecommendationWithImage'>
                                            <div>
                                                <img src={res} alt="" className='recomendationHomeImage' />
                                            </div>
                                            <div>
                                                Pot Kettle black
                                            </div>

                                        </div>

                                        10.5$
                                    </h6>
                                </div>
                                <div className={`${fromHome ? "d-none" : ""}`}>
                                    <h6 className="d-flex justify-content-end price  me-2">
                                        10.5$
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="recommendationImage">
                        <img src={burger} alt="" className="img-fluid" />
                        <div className="recommendationImageDetailsDiv">
                            <div className="recommendationImageDetails ms-2 mt-1">
                                <h6>Pasta</h6>
                                <div className={`${fromHome ? "" : "d-none"}`}>
                                    <h6 className="d-flex justify-content-between  price  me-2 homePageCard">
                                        <div className='d-flex divrecommendationWithImage'>
                                            <div>
                                                <img src={res} alt="" className='recomendationHomeImage' />
                                            </div>
                                            <div>
                                                Pot Kettle black
                                            </div>

                                        </div>

                                        10.5$
                                    </h6>
                                </div>
                                <div className={`${fromHome ? "d-none" : ""}`}>
                                    <h6 className="d-flex justify-content-end price  me-2">
                                        10.5$
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="recommendationImage">
                        <img src={airBnbImage} alt="" className="img-fluid" />
                        <div className="recommendationImageDetailsDiv">
                            <div className="recommendationImageDetails ms-2 mt-1">
                                <h6>Pasta</h6>
                                <div className={`${fromHome ? "" : "d-none"}`}>
                                    <h6 className="d-flex justify-content-between  price  me-2 homePageCard">
                                        <div className='d-flex divrecommendationWithImage'>
                                            <div>
                                                <img src={res} alt="" className='recomendationHomeImage' />
                                            </div>
                                            <div>
                                                Pot Kettle black
                                            </div>

                                        </div>

                                        10.5$
                                    </h6>
                                </div>
                                <div className={`${fromHome ? "d-none" : ""}`}>
                                    <h6 className="d-flex justify-content-end price  me-2">
                                        10.5$
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="recommendationImage">
                        <img src={airBnbImage} alt="" className="img-fluid" />
                        <div className="recommendationImageDetailsDiv">
                            <div className="recommendationImageDetails ms-2 mt-1">
                                <h6>Pasta</h6>
                                <div className={`${fromHome ? "" : "d-none"}`}>
                                    <h6 className="d-flex justify-content-between  price  me-2 homePageCard">
                                        <div className='d-flex divrecommendationWithImage'>
                                            <div>
                                                <img src={res} alt="" className='recomendationHomeImage' />
                                            </div>
                                            <div>
                                                Pot Kettle black
                                            </div>

                                        </div>

                                        10.5$
                                    </h6>
                                </div>
                                <div className={`${fromHome ? "d-none" : ""}`}>
                                    <h6 className="d-flex justify-content-end price  me-2">
                                        10.5$
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="recommendationImage">
                        <img src={bannerImg} alt="" className="img-fluid" />
                        <div className="recommendationImageDetailsDiv">
                            <div className="recommendationImageDetails ms-2 mt-1">
                                <h6>Pasta</h6>
                                <div className={`${fromHome ? "" : "d-none"}`}>
                                    <h6 className="d-flex justify-content-between  price  me-2 homePageCard">
                                        <div className='d-flex divrecommendationWithImage'>
                                            <div>
                                                <img src={res} alt="" className='recomendationHomeImage' />
                                            </div>
                                            <div>
                                                Pot Kettle black
                                            </div>

                                        </div>

                                        10.5$
                                    </h6>
                                </div>
                                <div className={`${fromHome ? "d-none" : ""}`}>
                                    <h6 className="d-flex justify-content-end price  me-2">
                                        10.5$
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="recommendationImage">
                        <img src={bannerImg} alt="" className="img-fluid" />
                        <div className="recommendationImageDetailsDiv">
                            <div className="recommendationImageDetails ms-2 mt-1">
                                <h6>Pasta</h6>
                                <div className={`${fromHome ? "" : "d-none"}`}>
                                    <h6 className="d-flex justify-content-between  price  me-2 homePageCard">
                                        <div className='d-flex divrecommendationWithImage'>
                                            <div>
                                                <img src={res} alt="" className='recomendationHomeImage' />
                                            </div>
                                            <div>
                                                Pot Kettle black
                                            </div>

                                        </div>

                                        10.5$
                                    </h6>
                                </div>
                                <div className={`${fromHome ? "d-none" : ""}`}>
                                    <h6 className="d-flex justify-content-end price  me-2">
                                        10.5$
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default Recommendation;