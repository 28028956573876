import React, { useState } from 'react';
import './../PostDisplay.css'
import sendImg from './../../../Assets/Image/send.png'
import plusicon from './../../../Assets/Icons/picture.png'
import LeoImg from './../../../Assets/Image/Leo.png';
const CommentComponent = () => {
    const [showSend, setShowSend] = useState(false);

    return (
        <div className='d-flex commentComponentDiv'>
            <img src={LeoImg} alt="" className='user-img' style={{ marginRight: '5px' }} />
            <div className={`circularDiv d-flex justify-content-between ${showSend ? "" : ""}`} onClick={() => setShowSend(true)}>
                {/* <hr></hr> */}
                <textarea name="" id="" placeholder='Write a comment' className='form-control custom-textarea' style={{ flex: 1, margin: '0', padding: '0' }}></textarea>

                {/* <div contentEditable="true" className='editableP emoji-picker-container' data-placeholder="Make a new tag" data-emojiable="true"></div> */}
                <div className={`d-flex ${showSend ? "" : ""}`} style={{ gap: "10px", width: '5rem' }}>
                    {/* // {`${showSend ? "d-flex justify-content-end mt-2 sendDiv" : "d-none"}`}> */}
                    {/* <button className='btn-custom '>Send</button> */}
                    <label htmlFor="formId" style={{ margin: 'auto 0 auto auto' }}>
                        <input name="" type="file" id="formId" hidden />
                        <img src={plusicon} alt='' style={{ width: "1.5rem", margin: 'auto' }} />

                    </label>
                    <img src={sendImg} alt="" style={{ width: "1.5rem", margin: 'auto 0' }} />
                </div>
            </div>

        </div >
    );
};

export default CommentComponent;