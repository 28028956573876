import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { commentImgClick } from '../../../../actions'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import product2 from './../../../../Assets/Image/product (2).jpg'
import product3 from './../../../../Assets/Image/product (3).jpg'
import product4 from './../../../../Assets/Image/product (4).jpg'
import camera from '../../../../Assets/Icons/camera.png'
import addFile from '../../../../Assets/Icons/add-file.png'
import recentFile from '../../../../Assets/Icons/recent.png'
import videoCamera from '../../../../Assets/Icons/video-camera.png'
import voice from '../../../../Assets/Icons/voice.png'
import './CommentImages.css'
import imageOverLay from './../../../../Assets/Icons/imageIcon.png'
import imageOne from './../../../../Assets/Image/PastaOffer.jpg'
//image click event according to screen width start part 1
function useScreenWidth() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screenWidth;
}
//image click event according to screen width end part 1
const CommentImages = (props) => {
  const [remainingNumber, setremainingNumber] = useState('')
  const [imageClicked, setImageClicked] = useState(false)
  const dispatch = useDispatch()
  // const imagesObject = [
  //   { image: product2 },
  //   { image: product3 },
  //   { image: product4 },
  //   { image: product4 },
  //   { image: product4 },
  //   { image: product4 },
  // ]
  const imagesObject = [
    {
      original: product2,
      thumbnail: product2,
    },
    {
      original: product3,
      thumbnail: product3,
    },
    {
      original: product4,
      thumbnail: product4,
    },
    {
      original: imageOne,
      thumbnail: imageOne,
    },
    {
      original: imageOne,
      thumbnail: imageOne,
    },

  ]

  useEffect(() => {
    if (imagesObject.length > 4) {
      const num = imagesObject.length - 4

      setremainingNumber('+' + num)
    } else {
      setremainingNumber('')
    }
  }, [])

  const [selectedCommentImage, setSelectedCommentImage] = useState(null);
  const openCommentImageDrawer = (index) => {
    setSelectedCommentImage(index);
    dispatch(commentImgClick());
  };
  //image click event according to screen width start part 2 
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth <= 688;

  const handleClick = () => {
    if (!isMobile) {
      dispatch(commentImgClick());
    }
  };
  //image click event according to screen width end part 2 
  return (
    <div>
      {/* if==3 */}
      <div
        className={
          Object.keys(imagesObject).length === 3
            ? 'd-block container'
            : 'd-none'
        }
      >
        <div className="row imageDiv">
          {imagesObject.slice(0, 3).map((name, index) => (
            <div
              className="Commentimage col-6 col-xs-12 col-sm-6 col-md-6 col-lg-6 p-0"
              key={index}
              style={{ height: '100%' }}
            >
              <img src={name.original} alt="" className="img-fluid" style={{ paddingRight: '4px', paddingBottom: '4px' }} />
            </div>
          ))}
        </div>
      </div>
      {/* if===2 */}
      <div
        className={
          Object.keys(imagesObject).length === 2
            ? 'd-block container'
            : 'd-none'
        }
      >
        <div className="row imageDivForTwo">
          {imagesObject.slice(0, 2).map((name, index) => (
            <div
              className="Commentimage col-6 col-xs-12 col-sm-6 col-md-6 col-lg-6 p-0"
              key={index}
            >
              <img src={name.original} alt="" className="img-fluid" style={{ paddingRight: '4px' }} />
            </div>
          ))}
        </div>
      </div>
      {/* if>3 */}
      <div
        className={
          Object.keys(imagesObject).length > 3 ? 'd-block container' : 'd-none'
        }
      >
        <div className="row imageDivForFour" >
          {imagesObject.slice(0, 4).map((name, index) => (
            props.place == "post" ? (
              <div
                className="Commentimage col-6 col-md-3  imgPadding"
                key={index}
              >
                {imagesObject.length <= 4 && (
                  <img src={name.original} alt="" className="img-fluid" style={{ height: '100%' }} />
                )}
                {imagesObject.length > 4 && (
                  <div style={{ position: 'relative', display: 'inline-block', height: '100%' }}>
                    <img
                      src={name.original}
                      alt=""
                      className="img-fluid"
                      style={isMobile && index === 3 ? {} : null}
                      {...(isMobile ? { 'data-bs-toggle': 'offcanvas', 'data-bs-target': '#commentImageDrawer' } : null)}
                      onClick={handleClick}
                    />
                    {index === 3 && (
                      <div className="commentImgRemainingDiv">
                        <div className="remainingNumber ">
                          {/* <span>{remainingNumber}</span> */}
                          <img src={imageOverLay} alt="" style={{ width: "1.2rem" }} />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) :
              <div
                className="Commentimage col-6 imgPadding"
                key={index}
              >
                {imagesObject.length <= 4 && (
                  <img src={name.original} alt="" className="img-fluid" style={{ height: '100%' }} />
                )}
                {imagesObject.length > 4 && (
                  <div style={{ position: 'relative', display: 'inline-block', height: '100%' }}>
                    <img
                      src={name.original}
                      alt=""
                      className="img-fluid"
                      style={isMobile && index === 3 ? {} : null}
                      {...(isMobile ? { 'data-bs-toggle': 'offcanvas', 'data-bs-target': '#commentImageDrawer' } : null)}
                      onClick={handleClick}
                    />
                    {index === 3 && (
                      <div className="commentImgRemainingDiv">
                        <div className="remainingNumber ">
                          {/* <span>{remainingNumber}</span> */}
                          <img src={imageOverLay} alt="" style={{ width: "1.2rem" }} />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
          )
          )}
        </div>
      </div>
      {/* if ==1 */}
      <div
        className={
          Object.keys(imagesObject).length == 1 ? 'd-block container' : 'd-none'
        }
      >
        <div className="row imageDivForOne">
          {imagesObject.slice(0, 1).map((name, index) => (
            <div
              className="Commentimage col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0"
              key={index}
              style={{ textAlign: 'center' }}
            >
              <img
                src={name.original}
                alt=""
                className="img-fluid image1Size"
              />
            </div>
          ))}
        </div>
      </div>
      {/* <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#commentImageDrawer" aria-controls="offcanvasBottom">Toggle bottom offcanvas</button> */}

      {/* <div class="offcanvas offcanvas-bottom commentImageDrawer" tabindex="-1" id="commentImageDrawer" aria-labelledby="offcanvasBottomLabel" style={{ height: '29.5rem' }} data-bs-backdrop="true">
        <div class="offcanvas-header pt-2 pb-0">
          <hr className='commentImageDrawerHr m-auto' data-bs-dismiss="offcanvas" />
        </div>
        <div class="offcanvas-body small pt-0 mt-4">
          <div className='row'>
            <div className='col-12 d-flex fw-bold fs-6 justify-content-between my-2 mt-0' >
              <span>Photos & videos</span>
              <span style={{ color: '#2596be' }}>View gallery</span>
            </div>
            <div className="col-12">
              <div className="d-flex CommentImageDiv overflow-x-auto mb-2">
                <div className='d-flex' >
                  <div className='CommentAddImg m-1'>
                    <img className='h-100 object-fit-contain m-auto d-flex justify-content-center align-items-center' src={camera} alt='camera' style={{ width: '3rem' }} />
                  </div>
                  <div style={{ display: 'flex' }}>
                    {imagesObject.map((image, index) => (
                      <div className="imgCommentDiv m-1" key={index}>

                        <div className="overflow-hidden w-100 h-100 position-relative" style={{ borderRadius: '15px' }}>
                          <div className='imgtxt position-absolute bg-white d-flex justify-content-center align-items-center rounded-circle fw-bold'>
                            2
                          </div>
                          <img className='w-100 h-100 img-fluid' src={image.original} alt="image.original" onClick={() => dispatch(commentImgClick())} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row commentImgRowbtn'>
            <hr className='m-0' />
            <div className='btn text-start p-0'>
              <img className='commentImgDraware' src={voice} />
              <span className='commentImgDrawarespanbtn'>Record an audio clip</span>
            </div>
            <div className='btn text-start p-0'>
              <img className='commentImgDraware' src={videoCamera} />
              <span className='commentImgDrawarespanbtn'>Record a video clip</span>
            </div>
            <hr className='m-0' />
            <div className='btn text-start p-0'>
              <img className='commentImgDraware' src={recentFile} />
              <span className='commentImgDrawarespanbtn'>Recent files</span>
            </div>
            <div className='btn text-start p-0'>
              <img className='commentImgDraware' src={addFile} />
              <span className='commentImgDrawarespanbtn'>Upload a file</span>
            </div>
            <hr className='m-0' />
          </div>
        </div>
      </div> */}

      {/* image show */}
    </div>
  )
}

export default CommentImages
