import React, { useState, useEffect } from 'react'
import './PostDisplay.css'
import pkbImg from './../../Assets/Image/PKB.png'
import MoviePoster from './../../Assets/Image/MoviePoster 2.png'
import CommentDisplay from './CommentDisplay/CommentDisplay'
import bannerImg from './../../Assets/Image/banner.jpg'
import product from './../../Assets/Image/product (1).jpg'
import LeoImg from './../../Assets/Image/Leo.png'
import $ from 'jquery'
import ReplyComponent from './ReplyComponent/ReplyComponent'
import GalleryModal from '../GalleryModal/GalleryModal'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Autoplay, Navigation, Pagination } from 'swiper'
import 'swiper/css/navigation'
import removeIcon from './../../Assets/Icons/removeBlack.png'
import removeBlack from './../../Assets/Icons/remove.png'
import Postimages from './postimages/Postimages'
import CommentComponent from './CommentComponent/CommentComponent'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import p1 from '../../Assets/Image/1 44.png'
import p2 from '../../Assets/Image/1 51.png'
import p3 from '../../Assets/Image/1 52.png'
import p4 from '../../Assets/Image/1 54 (1).png'
import p5 from '../../Assets/Image/1 54.png'
import p6 from '../../Assets/Image/1 56.png'
import p7 from '../../Assets/Image/1 57 (1).png'
import p8 from '../../Assets/Image/1 57.png'
import p9 from '../../Assets/Image/1 89.png'
import p10 from '../../Assets/Image/1 44 (1).png'

import commentIcon from '../../Assets/Icons/chat-bubble.png'
import shareIcon from '../../Assets/Icons/share.png'
import likeIcon from '../../Assets/Icons/like.png'
import heart from './../../Assets/Icons/heart.png'
import info from './../../Assets/Icons/info.png'
import plus from './../../Assets/Icons/add.png'
import bookmark from './../../Assets/Icons/bookmark.png'
import bookmarks from './../../Assets/Icons/bookmarks.png'
import instBookmark from './../../Assets/Icons/bookmark-white.png'

function auto_grow(element) {
  element.style.height = '5px'
  element.style.height = element.scrollHeight + 'px'
}

const PostDisplay = () => {
  const [selectedImage, setSelectedImage] = useState('')
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const hideModalInfo = () => {
    window.$('#postModal').modal('hide')
    setShow(false)
  }
  const showModalInfo = (props) => {
    console.log('PostDisplay: ' + props)
    setSelectedImage(props)
    window.$('#postModal').modal('show')
    setShow(true);
  }
  //item show under Pot Kettle Black functionality start
  const foodItems = [
    { name: 'Burger', image: p1 },
    { name: 'Pizza', image: p2 },
    { name: 'Coffee', image: p3 },
    { name: 'Chocolate', image: p4 },
    { name: 'Shawarma', image: p5 },
    { name: 'Sushi', image: p6 },
    { name: 'Pasta', image: p7 },
    { name: 'Ice cream', image: p8 },
    { name: 'Sandwich', image: p9 },
    { name: 'Fruit Salad', image: p10 },
    { name: 'Pasta', image: p7 },
    { name: 'Ice cream', image: p8 },
    { name: 'Sandwich', image: p9 },
    { name: 'Fruit Salad', image: p10 },
  ];
  const visibleItems = 2;
  const remainingItems = foodItems.length - visibleItems;

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const handleModalClose = () => {
    if (showModal) {
      closeModal();
    }
  };
  //item show under Pot Kettle Black functionality end

  //item show under Pot Kettle Black functionality when hover on item start
  const [showHoverModal, setShowHoverModal] = useState(false);
  const handleHoverEnter = () => {
    setShowHoverModal(true);
  };

  const handleHoverLeave = () => {
    setShowHoverModal(false);
  };
  //item show under Pot Kettle Black functionality when hover on item start
  return (
    <div>
      <Modal show={show} onHide={hideModalInfo} backdrop="static" keyboard={false} fullscreen={true} className='postDisplayModal'>
        <Modal.Body className='postModal'>
          <div class=" gallaryViewModelHeader d-flex justify-content-between loginModalCloseBtn">
            <span></span>
            <span></span>
            <button type="button " class={'close '} onClick={hideModalInfo}>
              <span aria-hidden="true">
                <img src={removeIcon} className="removeImg desktopDisplay" style={{ height: '30px", width: "1rem' }} alt="" />
              </span>
            </button>
          </div>
          <button type="button " class={'close gallaryModelCloseButton'} onClick={hideModalInfo} style={{
            position: 'absolute', right: '0.8rem', top: '1rem', width: '44px', background: 'transparent', border: 'none',
          }}>
            <span aria-hidden="true">
              <img src={removeBlack} className="removeImg mobileDisplay " style={{ height: '30px", width: "1rem' }} alt="" />
            </span>
          </button>
          <div className='w-100 CommentComponentDiv fixed-bottom'>
            <CommentComponent />
          </div>
          <Swiper
            className="gallerySwiper"
            spaceBetween={30}
            grabCursor={false}
            centeredSlides={false}
            slidesPerView={1}
            hashNavigation={{
              watchState: true,
            }}
            navigation={true}
            modules={[Navigation, Pagination]}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
          >
            <SwiperSlide>
              {/* <PostModal selectedImage={bannerImg} /> */}
              <GalleryModal selectedImage={selectedImage} />
            </SwiperSlide>
          </Swiper>
        </Modal.Body>

      </Modal>
      {/* onClick={() => showModalInfo(bannerImg)} */}
      <div className="card PostCard" >
        <div className="card-body" onClick={handleModalClose}>
          <div className="d-flex align-items-center postHeader">
            <img src={pkbImg} alt="" className="postOwnerImg" />
            <div className='col'>
              <div className='d-flex flex-row justify-content-between' style={{ height: '1.9rem' }}>
                <h6>Pot Kettle Black</h6>
                {/* three dot start */}
                <div class="dropdown">
                  <span class="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style={{ cursor: 'pointer' }}>
                    <i class="fas fa-ellipsis-v"></i>
                  </span>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item" href="#">Edit post</a></li>
                    <li><a class="dropdown-item" href="#">Delete</a></li>
                    {/* <li><a class="dropdown-item" href="#">Subscribe</a></li> */}
                  </ul>
                </div>
                {/* <img src={instBookmark} alt='bookmark' style={{width:'1.3rem', height:'1.5rem'}}/> */}
                {/* three dot end */}
              </div>
              <div className=" d-flex" style={{ gap: '5px' }}>
                <img src={plus} alt='plus' style={{ width: '1.5rem', height: '1.5rem', marginRight: '2px', cursor: "pointer" }} onClick={openModal} />
                {foodItems.slice(0, visibleItems).map((item, index) => (
                  <div key={index} className="foodItem" onMouseEnter={handleHoverEnter} onMouseLeave={handleHoverLeave}>
                    {/* <img src={item.image} alt={item.name} className="foodImage" /> */}
                    <span> {item.name}, </span>
                  </div>
                ))}
                {remainingItems > 0 && (
                  <span>
                    and
                    <span className="moreItemsLink" onClick={openModal} onMouseEnter={handleHoverEnter} onMouseLeave={handleHoverLeave} >
                      {`  ${remainingItems} more`}
                    </span>
                  </span>
                )}
                {showHoverModal && (
                  <div className="hoverModal" onMouseEnter={handleHoverEnter} onMouseLeave={handleHoverLeave}>
                    <ul style={{ listStyleType: 'none', padding: '0' }}>
                      {foodItems.slice(0, 10).map((item, index) => (
                        <li key={index} className='d-flex align-items-center' style={{ margin: '0 0 5px' }}>
                          <span style={{ fontSize: '12px' }}>{item.name}</span>
                        </li>
                      ))}
                      {foodItems.length > 10 && (
                        <li className='d-flex align-items-center' style={{ margin: '0 0 5px' }}>
                          <span style={{ fontSize: '12px' }}>and {foodItems.length - 10} more</span>
                        </li>
                      )}
                    </ul>
                  </div>

                )}
              </div>
            </div>
            <Modal className='moreItemModal' show={showModal} onHide={closeModal} centered backdrop="static" keyboard={false}>
              <Modal.Header closeButton style={{ borderBottom: '0' }}>
                <Modal.Title>{foodItems.length} items</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ul style={{ listStyleType: 'none', padding: '0' }}>
                  {foodItems.map((item, index) => (
                    <li key={index} className='itemLi d-flex justify-content-between' style={{ margin: '0 0 15px', }}>
                      <div>
                        <img
                          src={item.image}
                          alt={item.name}
                          className="foodImage rounded-circle me-3"
                          style={{ width: '40px', height: '40px', }}
                        />
                        <span style={{ fontSize: '20px' }}>{item.name}</span>
                      </div>
                      {/* <Button
                        variant=""
                        className='itemButton ms-auto fw-bold'
                        onClick={closeModal}
                      >
                        View Item
                      </Button> */}
                      <div>
                        <img src={info} alt='info' style={{ width: '1.5rem', height: '1.5rem', marginRight: '5px' }} />
                        <img src={plus} alt='plus' style={{ width: '1.5rem', height: '1.5rem' }} />
                      </div>
                    </li>
                  ))}
                </ul>

              </Modal.Body>
              {/* <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                  Close
                </Button>
              </Modal.Footer> */}
            </Modal>

          </div>

          <div className="post ">
            <h4 className="ml-5">What’s the best movie for this dish?</h4>
          </div>
          <div className="postSlider">
            <Swiper
              spaceBetween={5}
              grabCursor={false}
              centeredSlides={false}
              slidesPerView={1}
              hashNavigation={{
                watchState: true,
              }}
              navigation={true}
              modules={[Autoplay, Navigation, Pagination]}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
              className="postSwiper"
            >
              <SwiperSlide> <img src={product} alt="" onClick={() => showModalInfo(bannerImg)} /></SwiperSlide>
              <SwiperSlide> <img src={bannerImg} alt="" onClick={() => showModalInfo(bannerImg)} /></SwiperSlide>
              <SwiperSlide> <img src={bannerImg} alt="" onClick={() => showModalInfo(bannerImg)} /></SwiperSlide>
              <SwiperSlide> <img src={bannerImg} alt="" onClick={() => showModalInfo(bannerImg)} /></SwiperSlide>
            </Swiper>
            {/* <Swiper
              className=""
              spaceBetween={30}
              grabCursor={false}
              centeredSlides={false}
              slidesPerView={1}
              navigation={true}
              modules={[Autoplay, Navigation, Pagination]}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
            >
              <SwiperSlide>
                <div className='discountImage'>
                  <img src={bannerImg} alt="" onClick={() => showModalInfo(bannerImg)} className='image-Discount' />
                </div>

              </SwiperSlide>
              <SwiperSlide>
                <div className='discountImage'>
                  <img src={bannerImg} alt="" onClick={() => showModalInfo(bannerImg)} className='image-Discount' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='discountImage'>
                  <img src={bannerImg} alt="" onClick={() => showModalInfo(bannerImg)} className='image-Discount' />
                </div>
              </SwiperSlide>
            </Swiper> */}

            {/* <Postimages /> */}
          </div>
          <div className='postLikeDislike'>
            {/* <hr style={{color:'#a39d9d'}}></hr> */}
            <div className='d-flex justify-content-between shared-MainPostLike'>
              <div className='d-flex flex-row'>
                {/* <div className='d-flex justify-content-between align-items-center ms-1'> */}
                <div style={{ marginRight: '1rem' }}>
                  <img src={heart} className='postRectImage' />
                </div>
                {/* <h5> Likes 20</h5> */}
                {/* </div> */}
                {/* <div className='d-flex justify-content-between align-items-center ms-1'> */}
                <div style={{ marginRight: '1rem' }}>
                  <img src={commentIcon} className='postRectImage' />
                </div>
                {/* <h5> Reply</h5>
              </div>
              <div className='d-flex justify-content-between align-items-center ms-1'> */}
                <div style={{ marginRight: '1rem' }}>
                  <img src={shareIcon} className='postRectImage' />
                </div>
              </div>
              {/* <div>
                <img src={instBookmark} alt='bookmark' style={{width:'1.5rem', height:'1.5rem'}}/>
                </div> */}
              {/* <h5> Share</h5> */}
              <img src={instBookmark} alt='bookmark' style={{ width: '1.1rem', height: '1.3rem', marginTop: '3px' }} />
            </div>
            {/* </div> */}
            <hr style={{ color: '#a39d9d' }}></hr>
          </div>
          <div className="postImg postComment">
            <CommentDisplay />
          </div>
          <div className="postReply">
            <ReplyComponent />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostDisplay
