import React, { useEffect, useState } from 'react';
import CheckOut from '../Pages/CheckOut/CheckOut';

const Auth = ({children}) => {
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        // console.log('token:', token)
        if(token){
            setAuthenticated(true)
        }
    }, [])


    if (authenticated) {
        return (
            <div>
                {children}
            </div>
        );
    }
    else {
        return (
            <span><CheckOut/></span>
        )
    }

};

export default Auth;