import React, { useState } from "react";
import './FilterDialogue.css';
function FilterDialogue() {

  const [sortOption, setSortOption] = useState("relevance");
  const [priceRange, setPriceRange] = useState([10.5, 1000]);

  const handleSortOptionChange = (event) => {
    setSortOption(event.target.value);
  };

  const handlePriceRangeChange = (event) => {
    const { target: { value, dataset: { index } } } = event;
    const newIndex = parseInt(index);
    setPriceRange(prevPriceRange => {
      const newPriceRange = [...prevPriceRange];
      newPriceRange[newIndex] = parseFloat(value);
      if (newPriceRange[0] > newPriceRange[1]) {
        newPriceRange[newIndex === 0 ? 1 : 0] = newPriceRange[newIndex];
      } else if (newPriceRange[1] < newPriceRange[0]) {
        newPriceRange[newIndex === 0 ? 1 : 0] = newPriceRange[newIndex];
      }
      return newPriceRange;
    });
  };
  return (
    <div>
      <div class="container">
        <div class="row">
          <div class="col">
            <div className="mb-3">
              <div className="fw-bold">Price Range</div>
              <div className="d-flex justify-content-between">
                <div className="small">{priceRange[0]}</div>
                <div className="small">{priceRange[1]}</div>
              </div>
              <div className="d-flex justify-content-between">
                <input type="range" className="form-range" min="10.5" max="1000" step="1" value={priceRange[0]} data-index="0" onChange={handlePriceRangeChange} />
                <input type="range" className="form-range" min="10.5" max="1000" step="1" value={priceRange[1]} data-index="1" onChange={handlePriceRangeChange} />
              </div>
            </div>
            <div class="mb-3">
              <div class="fw-bold" style={{ marginBottom: '0.7rem' }}>Offers</div>
              <div class="d-flex justify-content-center">
                <button type="button" class="btn me-3 btn-outline-secondary filterDialogueBtn" >Free Delivery</button>
                <button type="button" class="btn me-3 btn-outline-secondary filterDialogueBtn" >Offer</button>
                <button type="button" class="btn btn-outline-secondary filterDialogueBtn" >Online Payment Available</button>
              </div>
            </div>


            <div class="mb-3 filterDialogueRadio">
              <div class="fw-bold" style={{ marginBottom: '0.7rem' }}>Sort By</div>
              <div class="form-check d-flex flex-row-reverse" style={{ padding: '0' }}>
                <input class="form-check-input" type="radio" name="sortOption" id="relevance" value="relevance" checked style={{ marginLeft: 'auto' }} />
                <label class="form-check-label ms-2" for="relevance">Relevance (Default)</label>
              </div>
              <div class="form-check d-flex flex-row-reverse" style={{ padding: '0' }}>
                <input class="form-check-input" type="radio" name="sortOption" id="rating" value="rating" style={{ marginLeft: 'auto' }} />
                <label class="form-check-label ms-2" for="rating">Rating (High to Low)</label>
              </div>
              <div class="form-check d-flex flex-row-reverse" style={{ padding: '0' }}>
                <input class="form-check-input" type="radio" name="sortOption" id="delivery" value="delivery" style={{ marginLeft: 'auto' }} />
                <label class="form-check-label ms-2" for="delivery">Delivery Time</label>
              </div>
              <div class="form-check d-flex flex-row-reverse" style={{ padding: '0' }}>
                <input class="form-check-input" type="radio" name="sortOption" id="distance" value="distance" style={{ marginLeft: 'auto' }} />
                <label class="form-check-label ms-2" for="distance">Distance</label>
              </div>
            </div>


          </div>
        </div>
      </div>


    </div>
  );
}

export default FilterDialogue;