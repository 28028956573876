import React, { useEffect, useState } from 'react';
import { useGoogleLogin, GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

import { useNavigate } from 'react-router-dom';
// import axios from "axios"
import './Login.css'
import google from './../../Assets/Icons/google.png'
import email from './../../Assets/Icons/mail.png'
import Go5050 from './../../Assets/Icons/Go5050.png'
import sucessImg from './../../Assets/Image/check.png'
import Register from './Register/Register';
import { useSelector, useDispatch } from 'react-redux';
import { changeLoadFalse, changeLoadTrue, getChangeLog, openRegisterPageTrue, openRegisterPageFalse, openForgetPassword, closeForgetPassword } from "./../../actions/index"
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loadingGif from './../../Assets/Image/LoadingSpin.gif'


const Login = (props) => {


    const windowLoad = useSelector(state => state.windowLoad);

    const currentState = useSelector(state => state.changeLoad);
    const openRegisterPage = useSelector(state => state.openRegisterPage);

    const forgetPasswordToggle = useSelector(state => state.forgetPassword);

    const userName = useSelector(state => state.userDetails);
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [emailLogin, setEmailLogin] = useState(currentState);
    const [loginFailed, setloginFailed] = useState(openRegisterPage);
    const [loadedForTheFirstTime, setLoadedForTheFirstTime] = useState(false);

    const [forgetPasswordEmail, setforgetPasswordEmail] = useState('');

    const [userFound, setUserFound] = useState(false);

    const [forgetPassword, setforgetPassword] = useState(false);

    useEffect(() => {
        setEmailLogin(currentState);
        setloginFailed(openRegisterPage);
        setforgetPassword(false);
        if (windowLoad === true) {
            windowOpener.close();
        }
    }, []);

    const navigate = useNavigate();

    const apiAddress = process.env.REACT_APP_SECRET;
    const [emailValid, setemailValid] = useState(false);
    const [passwordValid, setpasswordValid] = useState(false);

    const [forgetemailValid, setForgetEmailValid] = useState(false);


    const ProceedLoginusiingAPI = (e) => {
        e.preventDefault();
        setLoading(true);
        console.log("apiAddress: " + apiAddress);
        var valid = true;
        if (username == undefined || username == '') {
            valid = false;

            setemailValid(false);

        }
        if (valid) {
            if (userFound) {
                if (password == undefined || password == '') {
                    valid = false;
                    setpasswordValid(false);
                }
                if (valid) {
                    var url = apiAddress + "/api/Login/loginAsync?userName=" + username + "&password=" + password
                    axios({
                        method: 'post',
                        url: url,
                    })
                        .then(function (response) {
                            console.log(response);
                            console.log("Data: " + response.data.token);
                            localStorage.setItem('token', response.data.token);
                            setLoading(false);
                            window.location.href = "/";
                        })
                        .catch(function (error) {
                            console.log(error);
                            setLoading(false);
                            if (error.response.status == 404) {
                                console.log("error.response.data.status: " + error.response.data.status);
                                if (error.response.data.status == "Password not found") {
                                    setPasswordNotFound(true);
                                }
                                else if (error.response.data.status == "Not activated") {
                                    toast.error("Not Activated", {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "colored",
                                    });
                                }
                                else if (error.response.data.status == "Email not verified") {
                                    toast.error("Please verify your email", {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "colored",
                                    });
                                }
                            }
                            if (error.response.status == 401) {
                                dispatch(openRegisterPageTrue());
                                setloginFailed(true);
                                console.error(error)
                                setPasswordNotFound(false);
                            }
                        });
                }

            }
            else {
                var url = apiAddress + "/api/Login/username?userName=" + username
                axios({
                    method: 'post',
                    url: url,
                })
                    .then(function (response) {
                        console.log(response);
                        setUserFound(true);
                        setLoading(false);
                        props.userFound(true)
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            dispatch(openRegisterPageTrue());
                            setloginFailed(true);
                            console.error(error)
                            setPasswordNotFound(false);
                            setLoading(false);
                        }
                    });
            }

        }
        else {
            setLoading(false);
        }


    };

    const [passwordNotFound, setPasswordNotFound] = useState(false);


    const responseFacebook = async (response) => {
        try {
            const { accessToken, username } = response;
            // Make a GET request to the Facebook API to get user data
            // const data = await axios.get(`https://graph.facebook.com/${username}?fields=id,name,email&access_token=${accessToken}`)
            // console.log(data);
        } catch (err) {
            console.log(err);
        }
    }
    const login = useGoogleLogin({
        onSuccess: async tokenResponse => {
            try {
            }
            catch (err) {
                console.data(err)
            }
        }
    });

    const register = async () => {
        // Get the email and password input values from the HTML form
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;

        try {
            // Send a POST request to the server with the email and password data
            const response = await fetch('https://localhost:7209/api/Authenticate/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });
            // Parse the response as JSON
            const result = await response.json();
            // If the response is not OK (i.e. there was an error), throw an error with the error message from the server
            if (!response.ok) {
                const errorMessage = result.message || 'Failed to create account';
                throw new Error(errorMessage);
            }

            // Display success message
            alert('Account created successfully!');

        } catch (error) {
            // If there was an error (e.g. server error or network error), display an error message to the user
            const errorMessage = error.message || 'Failed to create account';
            alert(`Error: ${errorMessage}`);
        }
    };

    const [windowOpener, setWindowOpener] = useState(null);
    const [loading, setLoading] = useState(false);

    const goToGo5050 = () => {
        const baseUrl = window.location.origin;
        var res = window.open("https://go5050.co.uk//externallogins?returnUrl=" + baseUrl + "/", "popup", "width=850,height=550,left=280,top=80");
        setWindowOpener(res);

        window.onmessage = function (e) {
            if (e.data) {
                res.close();
                window.location.href = "/";
            } else {
                console.log(e.data);
            }
        }
    }

    React.useEffect(() => {
        setUserFound(userFound);
    }, [userFound])


    React.useEffect(() => {
        setUserFound(props.userFoundFlag);
    }, [props.userFoundFlag])

    React.useEffect(() => {
        console.log(props.isForgetPasswordSubmited);
        setisForgetPasswordSubmited(props.isForgetPasswordSubmited);
        setloginFailed(false);
    }, [props.isForgetPasswordSubmited])

    const forgetPasswordEvent = () => {


        setUserFound(false)
        setforgetPassword(true);
        props.forgetEmail(true);

    }
    const [isForgetPasswordSubmited, setisForgetPasswordSubmited] = useState(false);
    const onForgetPasswordSubmit = (event) => {
        event.preventDefault();
        setForgetEmailValid(true)
        setLoading(true);
        if (forgetPasswordEmail == undefined || forgetPasswordEmail == "") {
            toast.error("Email field required", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setForgetEmailValid(false)
        }
        else {
            var url = apiAddress + "/api/AdminLogin/forgetpassword?userName=" + username
            axios({
                method: 'get',
                url: url,
            })
                .then(function (response) {

                    setLoading(false);
                    setisForgetPasswordSubmited(true);
                    dispatch(openForgetPassword());
                    setloginFailed(true);
                })
                .catch(function (error) {
                    // if (error.response.status == 401) {
                    //     dispatch(openRegisterPageTrue());
                    //     setloginFailed(true);
                    //     console.error(error)
                    //     setPasswordNotFound(false);
                    //     setLoading(false);
                    // }
                    setLoading(true);
                    console.log(error);
                    toast.error(error.response.data.status, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                });
        }
    }



    if (!loginFailed) {
        return (
            <div className='container login'>
                <ToastContainer />
                {userFound ? (
                    <>
                        <form onSubmit={ProceedLoginusiingAPI}>
                            <div className='col-12 mt-2'>
                                <div className="form-group " >
                                    {emailValid}
                                    <input type="text" className="form-control login-info" value={username} placeholder="Enter Email or User Name" onChange={(event) => { setUsername(event.target.value); setemailValid(true) }} hidden />
                                    <span className={`${emailValid ? "d-none" : ""}`} style={{ color: "red", fontSize: ".8rem" }}> Email field required *</span>
                                </div>
                            </div>
                            <div className='col-12 mt-2'>
                                <div className="form-group ">
                                    <input type="password" className="form-control login-info" value={password} placeholder="Password" onChange={(event) => { setPassword(event.target.value); setpasswordValid(true) }} />
                                    <span className={`${passwordNotFound ? "" : "d-none"}`} style={{ color: "red", fontSize: ".8rem" }}> Password not matched *</span>
                                    <span className={`${passwordValid ? "d-none" : ""}`} style={{ color: "red", fontSize: ".8rem" }}> Password field required *</span>
                                </div>
                                <span onClick={forgetPasswordEvent}>Forget Password?</span>
                            </div>
                            <div className='col-12 d-flex justify-content-center mt-2'>
                                {
                                    loading ? (
                                        <button className="btn btn-success font-weight-bold w-100 " style={{ width: "    width: 100%;", borderRadius: "8px", backgroundColor: "#3D1E19", pointerEvents: "none" }} type="submit">
                                            <img src={loadingGif} alt="" className='img-fluid' style={{ width: "2rem" }} /></button>
                                    ) : (
                                        <button className="btn btn-success font-weight-bold w-100 " style={{ width: "    width: 100%;", borderRadius: "8px", backgroundColor: "#3D1E19" }} type="submit" >
                                            {userFound ? "login" : "Continue"}</button>
                                    )
                                }

                            </div>
                        </form>

                    </>

                ) :
                    forgetPassword ? (
                        <>
                            <form onSubmit={onForgetPasswordSubmit}>
                                <p className='text-muted'>Enter the email address associated with your account, and we’ll email you a link to reset your password.</p >
                                <div className="form-group mb-3" >
                                    {emailValid}
                                    <input type="email" className="form-control login-info" value={forgetPasswordEmail} placeholder="Email" onChange={(event) => { setforgetPasswordEmail(event.target.value); setForgetEmailValid(true) }} />
                                    <span className={`${forgetemailValid ? "d-none" : ""}`} style={{ color: "red", fontSize: ".8rem" }}> Email field required *</span>
                                </div>
                                {loading ? (
                                    <button className="btn btn-success font-weight-bold w-100 " style={{ width: "    width: 100%;", borderRadius: "8px", backgroundColor: "#3D1E19", pointerEvents: "none" }} type="submit">
                                        <img src={loadingGif} alt="" className='img-fluid' style={{ width: "2rem" }} /></button>
                                ) : (
                                    <button className="btn btn-success font-weight-bold w-100 " style={{ width: "    width: 100%;", borderRadius: "8px", backgroundColor: "#3D1E19" }} type="submit" >
                                        Submit</button>
                                )}
                            </form>

                        </>
                    ) :
                        (<form onSubmit={ProceedLoginusiingAPI}>
                            <div className='container '>
                                <h3 className='goTasteH3'>
                                    Welcome To GoTaste
                                </h3>
                                <div className={'row mt-3 ' + (emailLogin ? "" : "d-none")}>
                                    <div className='col-12'>
                                        <div className="form-group " >
                                            {emailValid}
                                            <input type="text" className="form-control login-info" value={username} placeholder="Enter Email or User Name" onChange={(event) => { setUsername(event.target.value); setemailValid(true) }} />
                                            <span className={`${emailValid ? "d-none" : ""}`} style={{ color: "red", fontSize: ".8rem" }}> Email field required *</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={'row mt-2 ' + (emailLogin ? userFound ? "" : "d-none" : "d-none")}>
                                    <div className='col-12'>
                                        <div className="form-group ">
                                            <input type="password" className="form-control login-info" value={password} placeholder="Password" onChange={(event) => { setPassword(event.target.value); setpasswordValid(true) }} />
                                            <span className={`${passwordNotFound ? "" : "d-none"}`} style={{ color: "red", fontSize: ".8rem" }}> Password not matched *</span>
                                            <span className={`${passwordValid ? "d-none" : ""}`} style={{ color: "red", fontSize: ".8rem" }}> Password field required *</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={'row mt-2 ' + (emailLogin ? "" : "d-none")}>
                                    <div className='col-12'>
                                        {/* <span className='text-muted span-text'>We’ll call or text you to confirm your number. Standard message and data rates apply. <b>Privacy Policy</b></span> */}
                                        {/* <span className='text-muted span-text'>You will now be registered on GO5050. Use these credentials to sign into GO5050 and GOTASTE next time</span> */}
                                        {/* <span className='text-muted span-text'>lf an account is found, log in with your password or simply click on GO5050 below to continue with those same credentials. If no acount is found, you will be registered on the next screen to GOTASTE and GO5050 simultaneously</span> */}
                                        <span className='text-muted span-text'>Next, login with password or register directly on GoTaste and
                                            GO5050 simultaneously</span>
                                    </div>
                                </div>
                                <div className={'row mt-4 ' + (emailLogin ? "" : "d-none")}>
                                    <div className='col-12 d-flex justify-content-center'>
                                        {
                                            loading ? (
                                                <button className="btn btn-success font-weight-bold w-100 " style={{ width: "    width: 100%;", borderRadius: "8px", backgroundColor: "#3D1E19", pointerEvents: "none" }} type="submit">
                                                    <img src={loadingGif} alt="" className='img-fluid' style={{ width: "2rem" }} /></button>
                                            ) : (
                                                <button className="btn btn-success font-weight-bold w-100 " style={{ width: "    width: 100%;", borderRadius: "8px", backgroundColor: "#3D1E19" }} type="submit">
                                                    {userFound ? "login" : "Continue"}</button>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>

                        </form>)}



                <br className={" " + (emailLogin ? "" : "d-none")} />

                <>
                    <div className={'conatiner ' + (emailLogin ? "" : "d-none")}>
                        <div className='row'>
                            <div className='col-5'>
                                <hr />
                            </div>
                            <div className='col-2 d-flex justify-content-center align-items-center ortxt '>
                                <h4>Or</h4>
                            </div>
                            <div className='col-5'>
                                <hr />
                            </div>
                        </div>

                    </div>
                    <div className='container'>
                        <div className='row mt-2'>
                            <div className='co-12 w-100'>
                                <button className='btn login-with d-flex align-items-center w-100 loginPageExternalBtn relativeBtn' onClick={goToGo5050}>
                                    <img src={Go5050} alt="" className='go5050Img ' />
                                    <span className="mx-auto">Continue with Go5050</span>
                                </button>
                            </div>
                        </div>
                        <div className='row mt-2 d-none' >
                            <div className='co-12 w-100'>
                                <button className="btn login-with d-flex align-items-center w-100 loginPageExternalBtn relativeBtn" onClick={login}>
                                    <img src={google} className='googleImg ' />
                                    <span className="mx-auto">Continue with Google</span>
                                </button>
                            </div>
                        </div>
                        <div className={'row mt-2 ' + (emailLogin ? "d-none" : "")}>
                            <div className='co-12 w-100'>
                                <button className="btn login-with d-flex align-items-center w-100 loginPageExternalBtn relativeBtn" onClick={() => { setEmailLogin(!emailLogin); dispatch(changeLoadTrue()) }}>
                                    {/* This button component allows the user to login with their Google account. */}
                                    {/* <FcGoogle /> */}
                                    <img src={email} className='googleImg' />
                                    <span className="mx-auto">Continue with Email</span>
                                </button>
                            </div>
                        </div>
                    </div></>


            </div>
        );
    }
    else if (forgetPasswordToggle) {
        return (
            <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                <div>
                    <img src={sucessImg} alt="" style={{ width: "7rem" }} />
                </div>
                <h4 className='mt-2'>Password Changed successfully</h4>
                <span onClick={() => { window.location.href = "/" }}>Goto Home</span>
            </div>
        )

    }
    else {
        return (
            <Register></Register>
        );

    }
};

export default Login;