import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Autoplay, Navigation, EffectCoverflow, Pagination } from 'swiper'
import 'swiper/css/navigation'

import burger from './../../../Assets/Image/Burger22.jpeg'
import bannerImg from './../../../Assets/Image/PastaOffer.jpg'
import airBnbImage from './../../../Assets/Image/1 (2).jpg'
import chickenImg from './../../../Assets/Image/ChickenFry.jpg'
import res from './../../../Assets/Icons/restaurant (2).png'

// import './DiscountOffer.css'

const OtherOffers = (props) => {
    const [fromHome, SetFromHome] = useState(true);
    useEffect(() => {
        console.log(props);
        if (props.from === 2) {
            SetFromHome(false);
        }
    })
    return (
        <div>
            <Swiper
                spaceBetween={30}
                grabCursor={false}
                centeredSlides={false}
                slidesPerView={'auto'}
                hashNavigation={{
                    watchState: true,
                }}
                // autoplay={{
                //   delay: 2000,
                //   disableOnInteraction: true,
                // }}
                breakpoints={{
                    300: {
                        slidesPerView: 1,
                        spaceBetween: 5,
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 5,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                }}
                navigation={true}
                modules={[Autoplay, Navigation, Pagination]}
                pagination={{
                    dynamicBullets: true,
                    clickable: true,
                }}

            >
                <SwiperSlide>


                    <div className="discountImage ">

                        <img src={airBnbImage} alt="" className="img-fluid image-Discount" />
                        <div className="discountOfferDiv ">
                            <div className="discountOfferDivDetails ">
                                <div className='offer-Text'>
                                    <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                    <h6 className={`${fromHome ? "" : "d-none"}`} style={{ marginBottom: "0px" }}>
                                        <div className='d-flex' style={{ gap: "10px" }}>
                                            <img src={res} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                        </div>
                                    </h6>
                                </div>
                                <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                    <button className='btn btn-buyNow'>Order Now </button>
                                    <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="discountImage ">

                        <img src={bannerImg} alt="" className="img-fluid image-Discount" />
                        <div className="discountOfferDiv ">
                            <div className="discountOfferDivDetails   ">
                                <div className='offer-Text'>
                                    <h2><b>$10 off at Oven Baked Pasta</b></h2>
                                    <h6 className={`${fromHome ? "" : "d-none"}`} style={{ marginBottom: "0px" }}>
                                        <div className='d-flex' style={{ gap: "10px" }}>
                                            <img src={res} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                        </div>
                                    </h6>
                                </div>
                                <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                    <button className='btn btn-buyNow'>Order Now </button>
                                    <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="discountImage ">
                        <img src={chickenImg} alt="" className="img-fluid image-Discount" />
                        <div className="discountOfferDiv ">
                            <div className="discountOfferDivDetails ">
                                <div className='offer-Text'>
                                    <h2><b>$5 off at Crispy Chicken fry</b></h2>
                                    <h6 className={`${fromHome ? "" : "d-none"}`} style={{ marginBottom: "0px" }}>
                                        <div className='d-flex' style={{ gap: "10px" }}>
                                            <img src={res} alt="" className='resImg' /> Pot kettle black
                                        </div>
                                    </h6>
                                </div>
                                <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                    <button className='btn btn-buyNow'>Order Now </button>
                                    <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="discountImage ">
                        <img src={chickenImg} alt="" className="img-fluid image-Discount" />
                        <div className="discountOfferDiv ">
                            <div className="discountOfferDivDetails ">
                                <div className='offer-Text'>
                                    <h2><b>$5 off at Crispy Chicken fry</b></h2>
                                    <h6 className={`${fromHome ? "" : "d-none"}`} style={{ marginBottom: "0px" }}>
                                        <div className='d-flex' style={{ gap: "10px" }}>
                                            <img src={res} alt="" className='resImg' /> Pot kettle black
                                        </div>
                                    </h6>
                                </div>
                                <div className='OrderNowBtn'>
                                    <button className='btn btn-buyNow'>Order Now </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>





                {/* <SwiperSlide>
                    <div className="discountImage-offerCard2 color-Discount-green">
                        <div className='row'>
                            <div className='col-7'>
                                <div className='offer-Text'>
                                    <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                </div>
                                <div className='BuyNow'>
                                    <button className='btn btn-buyNow'>Order Now </button>
                                </div>
                            </div>
                            <div className='col'>
                                <img src={burger} alt="" className="img-fluid image-Discount-offerCard2" />
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="discountImage-offerCard2 color-Discount-coffee">
                        <div className='row'>
                            <div className='col-7'>
                                <div className='offer-Text'>
                                    <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                </div>
                                <div className='BuyNow'>
                                    <button className='btn btn-buyNow'>Order Now </button>
                                </div>
                            </div>
                            <div className='col'>
                                <img src={burger} alt="" className="img-fluid image-Discount-offerCard2" />
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="discountImage-offerCard2 color-Discount-yellow">
                        <div className='row'>
                            <div className='col-7'>
                                <div className='offer-Text '>
                                    <h2 className='yellowColor'><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                </div>
                                <div className='BuyNow'>
                                    <button className='btn btn-buyNow'>Order Now </button>
                                </div>
                            </div>
                            <div className='col'>
                                <img src={burger} alt="" className="img-fluid image-Discount-offerCard2" />
                            </div>
                        </div>
                    </div>
                </SwiperSlide> */}

            </Swiper>
        </div>
    );
};

export default OtherOffers;