
import './Navbar.css'
import './../Home/BannerSection/Banner.css'
import React, { useState, useEffect, useRef } from 'react';

import Login from './../LoginRegister/Login'
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Modal, Tab, Nav, Button } from 'react-bootstrap';
import $ from "jquery";
import backBtn from './../../Assets/Icons/back.png'
import logo from './../../Assets/Image/LogoTransparentBrown.png'
import logowhite from './../../Assets/Image/LogoTransparent.png'
import { useSelector, useDispatch } from 'react-redux';
import { changeLoadFalse, changeLoadTrue, openRegisterPageFalse, closeForgetPassword, openForgetPassword } from "./../../actions/index"

import searchIcont from './../../Assets/Icons/searchIcon2.png'
import searchIcontCoffee from './../../Assets/Icons/searchIcon.png'
import searchair from './../../Assets/Image/SearchAir.png'

import loginImg from './../../Assets/Image/login.png'
import loginDesktopImg from './../../Assets/Image/login-Desktop.png'
import homeImg from './../../Assets/Image/home.png'
import menuImg from './../../Assets/Image/menu.png'
import shoppingCart from './../../Assets/Icons/shopping-bag.png'

import leo from './../../Assets/Image/Leo.png'

import test from './../../Assets/Image/PastaOffer.jpg'
import fuchka from './../../Assets/Image/1 51.png'
import star from './../../Assets/Icons/starCoffe.png'
import PKB from './../../Assets/Image/PKB.png'
import pasta from './../../Assets/Image/Pasta 1.png'
import search from './../../Assets/Icons/searchIcon.png'
import Cart from '../Cart/Cart';
import Test9 from '../../Test9';
import SidebarLogin from './SidebarLogin';



const Navbar = (props) => {
    const currentState = useSelector(state => state.changeLoad);
    const isRegisterPage = useSelector(state => state.openRegisterPage);
    const isReloadPage = useSelector(state => state.testReducers);
    const forgetPasswordToggle = useSelector(state => state.forgetPassword);
    // console.log("currentState: " + currentState.changeLoad)
    const dispatch = useDispatch();
    const [userVal, setUserValue] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const navigate = useNavigate();

    const [navbarOnTop, setNavbarOnTop] = useState(true);
    const [scrollValue, setscrollValue] = useState(0);
    const [slideUp, setSlideUp] = useState(false);
    const navbarTopRef = useRef(null)
    const [showModal, setShowModal] = useState(false);
    const [allSelected, setAllSelected] = useState(false)
    const [foodSelected, setFoodSelected] = useState(false)
    const [resturantSelected, setRestaurantSelected] = useState(false)
    const [show, setShow] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const searchDialogdivRef = useRef(null);
    const [searchInput, setSearchInput] = useState('')
    const [category, setCategory] = useState('')
    const [defaultColor, setDefaultColor] = useState(false)

    //  test start
    const handleClickOutside = (event) => {
        if (searchDialogdivRef.current && !searchDialogdivRef.current.contains(event.target)) {
            setShowModal(false);
        }
    };



    useEffect(() => {
        if (props.from != undefined) {
            setDefaultColor(true);
        }
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, []);

    // test end

    function handleSearchIcon() {
        setShowModal(!showModal)
        setAllSelected(true)
    }

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.pageYOffset;
            setIsVisible(scrollPosition === 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function handleShow() {

        setShow(true);
    }
    useEffect(() => {

        // Check if user is authenticated
        const token = localStorage.getItem('token');

        let decoded = null;
        if (token != null) {
            try {
                decoded = jwt_decode(token);
                console.log(decoded);
            } catch (error) {
            }
            if (decoded != null || decoded != undefined) {
                setUserValue(decoded.UserName);
                setProfileImage(decoded.ProfileImage)
            }
        }
        window.addEventListener("scroll", (event) => {
            const filterBarRect = navbarTopRef.current.getBoundingClientRect();
            const isAboveViewport = filterBarRect.top === 0 ? true : false;
            setNavbarOnTop(isAboveViewport);
            var top = (filterBarRect.top * (-1));
            var scrollval = (scrollValue * (-1));
            if (top > scrollval) {
                setSlideUp(true);
            }
            else if (top <= 0) {
                setSlideUp(false);
                setShowModal(false);
            }
            else {
                setSlideUp(false);
                setShowModal(false);
            }

            var s = filterBarRect.top;
            setscrollValue(s);
        })
    });

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = `/`;
    }
    const goToHome = () => {
        window.location.href = `/`;
    }

    const [loginModalOpen, setLoginModalOpen] = useState(false);
    const hideModalInfo = () => {
        setLoginModalOpen(false);
        dispatch(changeLoadFalse());
        dispatch(openRegisterPageFalse());
        window.$("#exampleModalCenter").modal("hide");

    };
    const showModalInfo = () => {
        setLoginModalOpen(true);
        dispatch(changeLoadFalse());
        dispatch(openRegisterPageFalse());
        window.$("#exampleModalCenter").modal("show");

    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    // function handleSearchIcon(){
    //     setShowModal(!showModal)
    // }
    function handleAllButtonClick() {
        setShowModal(!showModal);
        setAllSelected(true)
        setFoodSelected(false)
        setRestaurantSelected(false)
        // window.$("#root").css("filter", "brightness(0.5)");
    }
    function handleFoodButtonClick() {
        setShowModal(!showModal);
        setAllSelected(false)
        setFoodSelected(true)
        setRestaurantSelected(false)
    }
    function handleRestaurantButtonClick() {
        setShowModal(!showModal);
        setAllSelected(false)
        setFoodSelected(false)
        setRestaurantSelected(true)
    }

    const handleInputChange = (event) => {

        setSearchInput(event.target.value);
        console.log(searchInput)
        if (searchInput === 'b') {
            setCategory('Burger')
        }
        else if (searchInput === 'p') {
            setCategory('Pizza')
        }
        else if (searchInput === 'pa') {
            setCategory('Pasta')
        }

    };

    const [passwordView, setPasswordView] = useState(false);
    const [isForgetPasswordSubmited, setisForgetPasswordSubmited] = useState(false);
    const handleUserFound = (props) => {
        console.log(props);
        setPasswordView(props);

    };

    props.handleNavbar(showModal)
    const [forgetPassword, setforgetPassword] = useState(false);

    return (
        <div className='Navbar-custom' >
            <div ref={navbarTopRef}>

            </div>
            <div className='desktopDisplay' >
                <div className={`navbarGoTaste  ${navbarOnTop ? defaultColor ? "backgroundWhite" : "backgroundTranparent" : "backgroundWhite"} ${slideUp ? "slideDown" : " slideUp "}`}  >
                    <div className='brand d-flex'>



                        <button class="btn me-4" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                            {defaultColor ? (
                                <div className="btn btn-buyNow w-100" style={{ color: "white" }}>Sign Up</div>
                            ) : (
                                <i class={`fa-solid fa-bars text-light   ${navbarOnTop ? defaultColor ? "text-dark" : "text-light" : "text-dark"}`}></i>
                            )}

                        </button>

                        {defaultColor ? (
                            <a className={` brandname ${navbarOnTop ? defaultColor ? "brandname-color-coffee" : "brandname-color-white" : "brandname-color-coffee"}`} ><img src={logo} alt='' style={{ width: "3rem" }} /> Go <b>Taste</b></a>
                        ) :
                            (
                                <>
                                    <a className={` brandname ${navbarOnTop ? defaultColor ? "brandname-color-coffee" : "brandname-color-white" : "brandname-color-coffee"}`} href="/">
                                        {navbarOnTop ? (
                                            <>
                                                <img src={logowhite} alt='' style={{ width: "3rem" }} /> Go <b>Taste</b>
                                            </>

                                        ) : (
                                            <>
                                                <img src={logo} alt='' style={{ width: "3rem" }} /> Go <b>Taste</b>
                                            </>

                                        )}

                                    </a>
                                </>

                            )
                        }

                    </div>



                    <div style={{ position: 'relative', width: '42rem' }} ref={searchDialogdivRef} >
                        <div className='searchInput '>
                            {showModal == false && !defaultColor &&
                                <div className={` ${navbarOnTop ? defaultColor ? "search-field-color-coffee " : "search-field-color-white " : "search-field-color-coffee "}search-field d-flex align-items-center`}>

                                    <>
                                        <button className='btn btn-Search-Prop' onClick={handleAllButtonClick}><b>All</b></button>
                                        <div class="vl"></div>
                                        <button className='btn btn-Search-Prop' onClick={handleFoodButtonClick}><b>Food</b></button>
                                        <div class="vl"></div>
                                        <button className='btn btn-Search-Prop' onClick={handleRestaurantButtonClick}><b>Restaurant</b></button>
                                        <div class="vl"></div>
                                        <button className='btn btn-Search-Prop'><b>Post</b></button>
                                    </>

                                    <button className='btn btn-search' onClick={handleSearchIcon}>
                                        <img src={searchIcont} alt="" className='searchIcon' />
                                    </button>

                                </div>
                            }
                            {showModal &&
                                <div className='search-field-color-white search-field d-flex align-items-center searchInputFieldParent'>
                                    <input
                                        type="text"
                                        placeholder="Search all"
                                        className='searchInputField'
                                        onChange={handleInputChange}
                                    />
                                    <button className='btn btn-search' onClick={handleSearchIcon}>
                                        <img src={searchIcont} alt="" className='searchIcon' />
                                    </button>
                                </div>
                            }
                        </div>
                        {(showModal && !slideUp) &&

                            <div id="searchDialogId">
                                <div className='container searchDialog '  >
                                    <ul class="nav nav-tabs pt-3" id="myTab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class={`nav-link ${allSelected ? " active" : ''}`} id="home-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="home" aria-selected="true">All</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class={`nav-link ${foodSelected ? " active" : ''}`} id="profile-tab" data-bs-toggle="tab" data-bs-target="#food" type="button" role="tab" aria-controls="profile" aria-selected="false">Food</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class={`nav-link ${resturantSelected ? " active" : ''}`} id="contact-tab" data-bs-toggle="tab" data-bs-target="#restaurant" type="button" role="tab" aria-controls="contact" aria-selected="false">Restaurant</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#posts" type="button" role="tab" aria-controls="contact" aria-selected="false">Posts</button>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div class={`tab-pane fade ${allSelected ? "show active" : ''}  `} id="all" role="tabpanel" aria-labelledby="home-tab">
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={fuchka} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>
                                                        <img src={star} alt='star' className='searchStar' />
                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#3D1E19' }}>4.5</p>
                                                    </div>
                                                </div>
                                                <div className='searchLabel'>
                                                    <p className='searchText' >Food</p>
                                                </div>

                                            </div>
                                            <hr />
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={pasta} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Pot kettle black</p>
                                                    </div>
                                                </div>
                                                <div className='searchLabelPost'>
                                                    <p className='searchText' >Post</p>
                                                </div>

                                            </div>
                                            <hr />
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={PKB} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Pot kettle black</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Burgers | pizza | Desserts</p>
                                                    </div>
                                                </div>
                                                <div className='searchLabelRestaurant'>
                                                    <p className='searchText' >Restaurant</p>
                                                </div>

                                            </div>
                                            <hr />
                                            {category === '' &&
                                                <div className='pt-2'>

                                                    <h5 style={{ fontWeight: '700' }}>Top Categories</h5>
                                                </div>
                                            }
                                            <div className='pt-2' style={{ marginLeft: '10px' }}>
                                                <div className='d-flex flex-row '>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />

                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? category : 'Chinese'}</p>

                                                </div>
                                                <hr />

                                                <div className='d-flex flex-row'>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? 'Cheese ' + category : 'Pizza'}</p>


                                                </div>
                                                <hr />
                                                <div className='d-flex flex-row'>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? 'Normal ' + category : 'Pasta'}</p>


                                                </div>
                                            </div>
                                        </div>
                                        <div class={`tab-pane fade ${foodSelected ? "show active" : ''} `} id="food" role="tabpanel" aria-labelledby="profile-tab">
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={fuchka} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>
                                                        <img src={star} alt='star' className='searchStar' />
                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#3D1E19' }}>4.5</p>
                                                    </div>
                                                </div>
                                                {/* <div className='searchLabel'>
                                                    <p className='searchText' >Food</p>
                                                </div> */}

                                            </div>
                                            <hr />
                                            {/* <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={pasta} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Pot kettle black</p>
                                                    </div>
                                                </div>
                                                <div className='searchLabelPost'>
                                                    <p className='searchText' >Post</p>
                                                </div>

                                            </div> */}
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={fuchka} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>
                                                        <img src={star} alt='star' className='searchStar' />
                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#3D1E19' }}>4.5</p>
                                                    </div>
                                                </div>
                                                {/* <div className='searchLabel'>
                                                    <p className='searchText' >Food</p>
                                                </div> */}

                                            </div>
                                            <hr />
                                            {/* <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={PKB} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Pot kettle black</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Burgers | pizza | Desserts</p>
                                                    </div>
                                                </div>
                                                <div className='searchLabelRestaurant'>
                                                    <p className='searchText' >Restaurant</p>
                                                </div>

                                            </div> */}
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={fuchka} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>
                                                        <img src={star} alt='star' className='searchStar' />
                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#3D1E19' }}>4.5</p>
                                                    </div>
                                                </div>
                                                {/* <div className='searchLabel'>
                                                    <p className='searchText' >Food</p>
                                                </div> */}

                                            </div>
                                            <hr />
                                            {category === '' &&
                                                <div className='pt-2'>

                                                    <h5 style={{ fontWeight: '700' }}>Top Categories</h5>
                                                </div>
                                            }
                                            <div className='pt-2' style={{ marginLeft: '10px' }}>
                                                <div className='d-flex flex-row '>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />

                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? category : 'Chinese'}</p>

                                                </div>
                                                <hr />

                                                <div className='d-flex flex-row'>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? 'Cheese ' + category : 'Pizza'}</p>


                                                </div>
                                                <hr />
                                                <div className='d-flex flex-row'>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? 'Normal ' + category : 'Pasta'}</p>


                                                </div>
                                            </div>
                                        </div>
                                        <div class={`tab-pane fade ${resturantSelected ? "show active" : ''} `} id="restaurant" role="tabpanel" aria-labelledby="contact-tab">

                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={PKB} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Pot kettle black</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Burgers | pizza | Desserts</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <hr />

                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={PKB} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Pot kettle black</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Burgers | pizza | Desserts</p>
                                                    </div>
                                                </div>


                                            </div>
                                            <hr />
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={PKB} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Pot kettle black</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Burgers | pizza | Desserts</p>
                                                    </div>
                                                </div>
                                                {/* <div className='searchLabelRestaurant'>
                                                    <p className='searchText' >Restaurant</p>
                                                </div> */}

                                            </div>
                                            <hr />
                                            {category === '' &&
                                                <div className='pt-2'>

                                                    <h5 style={{ fontWeight: '700' }}>Top Categories</h5>
                                                </div>
                                            }
                                            <div className='pt-2' style={{ marginLeft: '10px' }}>
                                                <div className='d-flex flex-row '>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />

                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? category : 'Chinese'}</p>

                                                </div>
                                                <hr />

                                                <div className='d-flex flex-row'>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? 'Cheese ' + category : 'Pizza'}</p>


                                                </div>
                                                <hr />
                                                <div className='d-flex flex-row'>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? 'Normal ' + category : 'Pasta'}</p>


                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="posts" role="tabpanel" aria-labelledby="contact-tab">

                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={pasta} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Pot kettle black</p>
                                                    </div>
                                                </div>


                                            </div>
                                            <hr />
                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={pasta} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Pot kettle black</p>
                                                    </div>
                                                </div>


                                            </div>
                                            <hr />

                                            <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                                <div className='searchContentMargin'>
                                                    <img src={pasta} alt='img' className='searchDialogImg'></img>
                                                </div>
                                                <div className='d-flex flex-column searchContentMargin' >
                                                    <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                    <div className='d-flex flex-row'>

                                                        <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Pot kettle black</p>
                                                    </div>
                                                </div>


                                            </div>
                                            <hr />
                                            {category === '' &&
                                                <div className='pt-2'>

                                                    <h5 style={{ fontWeight: '700' }}>Top Categories</h5>
                                                </div>
                                            }
                                            <div className='pt-2' style={{ marginLeft: '10px' }}>
                                                <div className='d-flex flex-row '>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />

                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? category : 'Chinese'}</p>

                                                </div>
                                                <hr />

                                                <div className='d-flex flex-row'>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? 'Cheese ' + category : 'Pizza'}</p>


                                                </div>
                                                <hr />
                                                <div className='d-flex flex-row'>
                                                    <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                                    <p style={{ fontSize: '14px', marginBottom: '0px' }}>{category ? 'Normal ' + category : 'Pasta'}</p>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                    <div className='d-flex cart-design-with-login'>

                        <div className={`${defaultColor ? "d-none" : ""}`}>
                            <Cart />
                        </div>
                        {/* <Cart /> */}
                        <div className='login-Registration  d-flex align-items-center'>


                            {/* <div className={` dropdown dropdownBtn ${userVal !== "" ? "changeBtnDisplay" : ""} ${navbarOnTop ? "dropdown-color-white" : ""}`}>
                                <button className={`btn  dropdown-toggle btnLogin `} type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <div className='d-flex dropdown-Icons'>
                                        <img src={menuImg} alt="" style={{ width: "1.5rem", height: "1.5rem" }} />
                                        <img src={loginDesktopImg} alt="" style={{ width: "2rem" }} />
                                    </div>

                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item dropdownItem" onClick={showModalInfo}>Login</a>
                                    <a className="dropdown-item dropdownItem" href='/register' >Add Your Restaurant</a>
                                </div>

                            </div> */}

                            <span className={"  banner-signUp " + (userVal !== "" ? "helloUserName" : "changeBtnDisplay ")} >Hello, {userVal}</span>
                            <button type="button" className={" btn btn-primary banner-signUp " + (userVal !== "" ? "" : "changeBtnDisplay")} onClick={handleLogout}>
                                Log Out
                            </button>
                        </div>
                    </div>



                </div>

            </div>
            <div className='mobileDisplay'>
                <div className={`navbarGoTaste ${navbarOnTop ? "backgroundTranparent" : "backgroundWhite"} ${slideUp ? "slideDown" : " slideUp "}`}  >

                    <button class="btn me-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                        <i class={`fa-solid fa-bars text-light   ${navbarOnTop ? "text-light" : "text-dark"}`}></i>
                    </button>


                    <div className='searchInput mobileDisplay'>
                        <div className={` ${navbarOnTop ? "search-field-color-white " : "search-field-color-coffee "}search-field d-flex align-items-center`}>
                            <button className='btn btn-search-Mobile'>
                                <div className='d-flex'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <div>
                                            <img src={searchIcontCoffee} alt="" className='searchIconcoffee' onClick={handleShow} />
                                        </div>

                                    </div>
                                    <div className='d-flex searchBtnText'>
                                        <b className='text-muted searchForFood'>Search For Food</b>
                                        <span>All | Food | Restaurant | Post</span>
                                    </div>
                                </div>


                            </button>
                            {/* <button className='btn btn-search'>
                            <img src={searchIcont} alt="" className='searchIcon' />
                        </button> */}
                        </div>

                    </div>


                </div>
            </div>


            {/* login */}
            <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-bs-backdrop='static'>
                <div className="modal-dialog modal-dialog-centered" role="document" >
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-between loginModalCloseBtn">
                            {
                                passwordView ? (
                                    <button type="button " className={"close-btn " + (isRegisterPage ? "d-none" : "")} onClick={() => { setPasswordView(false); setisForgetPasswordSubmited(false) }}>
                                        {isRegisterPage}
                                        <span aria-hidden="true"><img src={backBtn} alt="" style={{ width: "1.2rem" }} /></span>
                                    </button>
                                ) : forgetPassword ? forgetPasswordToggle ? "" : (
                                    <button type="button " className={"close-btn " + (isRegisterPage ? "d-none" : "")} onClick={() => { setforgetPassword(false); setPasswordView(true); setisForgetPasswordSubmited(false); dispatch(closeForgetPassword()) }}>
                                        {isRegisterPage}
                                        <span aria-hidden="true"><img src={backBtn} alt="" style={{ width: "1.2rem" }} /></span>
                                    </button>
                                ) : (
                                    <button type="button " className={"close-btn " + (isRegisterPage ? "d-none" : "")} onClick={hideModalInfo}>
                                        {isRegisterPage}
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                )
                            }

                            {/* <button type="button " className={"close " + (isRegisterPage ? "" : "d-none")} onClick={() => dispatch(openRegisterPageFalse())}>
                                {isRegisterPage}
                                <span aria-hidden="true"><img src={backBtn} alt="" style={{ width: "1.2rem" }} /></span>
                            </button> */}
                            <button type="button " className={"close " + (isRegisterPage ? "" : "d-none")} onClick={() => dispatch(openRegisterPageFalse())}>
                                {isRegisterPage}
                                <span aria-hidden="true"><img src={backBtn} alt="" style={{ width: "1.2rem" }} /></span>
                            </button>
                            {forgetPassword ? (
                                <h5 className={"modal-title " + (isRegisterPage ? "d-none" : "")} id="exampleModalLongTitle">Forget Password</h5>
                            ) : (
                                <>
                                    <h5 className={"modal-title " + (isRegisterPage ? "d-none" : "")} id="exampleModalLongTitle">Log in or sign up</h5>
                                    <h5 className={"modal-title " + (isRegisterPage ? "" : "d-none")} id="exampleModalLongTitle">Finish signing up</h5>
                                </>

                            )}

                            <span></span>
                        </div>
                        <div className="modal-body">
                            {loginModalOpen ? (
                                <Login loaded="false" userFound={handleUserFound} userFoundFlag={passwordView} forgetEmail={(props) => { setforgetPassword(props); setPasswordView(false) }} isForgetPasswordSubmited={isForgetPasswordSubmited} />
                            ) : ("loading")}

                        </div>

                    </div>
                </div>
            </div>




            {/* bottomBar */}
            <div className='mobileDisplay'>
                <div className={`bottomBar ${slideUp ? "slideDownBottom" : " slideUpBottom "}`}>
                    <div className='d-flex justify-content-center bottomBar-Item'>
                        <div className='d-flex explore align-items-center' onClick={() => {
                            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                        }}>
                            <img src={searchair} alt="" className='searchIconcoffee' />
                            <span>Explore</span>
                        </div>
                        <div className='d-flex explore align-items-center' onClick={() => { goToHome() }}>
                            <img src={homeImg} alt="" className='searchIconcoffee' />
                            <span>Home</span>
                        </div>
                        <div  >
                            {/* <img src={shoppingCart} alt="" className='searchIconcoffee' />
                            <span>Cart</span> */}
                            <Cart />
                        </div>
                        <div className='d-flex explore align-items-center'>
                            <div onClick={showModalInfo} className={`d-flex explore align-items-center ${userVal !== "" ? "d-none" : " changeBtnDisplay"}`}>
                                <img src={loginImg} alt="" className='searchIconcoffee' />
                                <span>Login</span>
                            </div>
                            <div className={`d-flex explore align-items-center ${userVal !== "" ? "" : "d-none"}`} onClick={handleLogout}>
                                <img src={profileImage} alt="" className=' profileImage' />
                                <span>{userVal}</span>
                            </div>
                            {/* <button type="button" className={` ${navbarOnTop ? "login-Registration-white " : "login-Registration-coffee  "} btn btn-primary banner-signUp " + ${userVal !== "" ? "changeBtnDisplay" : ""}`} 
                            >
                                <b>Log in or sign up</b>
                            </button> */}


                        </div>


                    </div>

                </div>
            </div>
            <Modal show={show} fullscreen='xxl-down' onHide={() => setShow(false)} className='modalParentDiv'>
                <Modal.Header closeButton >

                </Modal.Header>
                <Modal.Body >

                    {/* <div className='d-flex justify-content-between'>
                    <input
                        type="text"
                        placeholder="Search all"
                        className=''
                        />
                        <button className='btn btn-search'>
                                <img src={searchIcont} alt="" className='searchIcon' onClick={handleAllButtonClick} />
                        </button>
                    </div> */}



                    <>
                        <div className='d-flex mobileSearchBar'>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div>
                                    <img src={searchIcontCoffee} alt="" className='searchIconcoffee' onClick={handleShow} />
                                </div>

                            </div>

                            <input
                                type="text"
                                placeholder="Search all"
                                className='mobileSearchInput'
                            />

                        </div>
                        <div>
                            <ul class="nav nav-tabs pt-2" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class={`nav-link ${allSelected ? " active" : ''}`} id="home-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="home" aria-selected="true">All</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class={`nav-link ${foodSelected ? " active" : ''}`} id="profile-tab" data-bs-toggle="tab" data-bs-target="#food" type="button" role="tab" aria-controls="profile" aria-selected="false">Food</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class={`nav-link ${resturantSelected ? " active" : ''}`} id="contact-tab" data-bs-toggle="tab" data-bs-target="#restaurant" type="button" role="tab" aria-controls="contact" aria-selected="false">Restaurant</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#posts" type="button" role="tab" aria-controls="contact" aria-selected="false">Posts</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">

                                <div class={`tab-pane fade ${allSelected ? "show active" : ''}  `} id="all" role="tabpanel" aria-labelledby="home-tab">
                                    <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                        <div className='searchContentMargin'>
                                            <img src={fuchka} alt='img' className='searchDialogImg'></img>
                                        </div>
                                        <div className='d-flex flex-column searchContentMargin' >
                                            <p className='searchText searchTitile' >Chicken cheese burger</p>

                                            <div className='d-flex flex-row'>
                                                <img src={star} alt='star' className='searchStar' />
                                                <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#3D1E19' }}>4.5</p>
                                            </div>
                                        </div>
                                        <div className='searchLabel'>
                                            <p className='searchText' >Food</p>
                                        </div>

                                    </div>
                                    <hr style={{ marginBottom: '0px' }} />
                                    <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                        <div className='searchContentMargin'>
                                            <img src={pasta} alt='img' className='searchDialogImg'></img>
                                        </div>
                                        <div className='d-flex flex-column searchContentMargin' >
                                            <p className='searchText searchTitile' >Chicken cheese burger</p>

                                            <div className='d-flex flex-row'>

                                                <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Pot kettle black</p>
                                            </div>
                                        </div>
                                        <div className='searchLabelPost'>
                                            <p className='searchText' >Post</p>
                                        </div>

                                    </div>
                                    <hr style={{ marginBottom: '0px' }} />
                                    <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                        <div className='searchContentMargin'>
                                            <img src={PKB} alt='img' className='searchDialogImg'></img>
                                        </div>
                                        <div className='d-flex flex-column searchContentMargin' >
                                            <p className='searchText searchTitile' >Pot kettle black</p>

                                            <div className='d-flex flex-row'>

                                                <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Burgers | pizza | Desserts</p>
                                            </div>
                                        </div>
                                        <div className='searchLabelRestaurant'>
                                            <p className='searchText' >Restaurant</p>
                                        </div>

                                    </div>
                                    <hr />
                                    <div className='pt-2'>
                                        <h5 style={{ fontWeight: '700' }}>Top Categories</h5>
                                    </div>
                                    <div className='pt-2' style={{ marginLeft: '10px' }}>
                                        <div className='d-flex flex-row '>
                                            <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />

                                            <p style={{ fontSize: '14px', marginBottom: '0px' }}>Chinese</p>

                                        </div>
                                        <hr />

                                        <div className='d-flex flex-row'>
                                            <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                            <p style={{ fontSize: '14px', marginBottom: '0px' }}>Pizza</p>


                                        </div>
                                        <hr />
                                        <div className='d-flex flex-row'>
                                            <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                            <p style={{ fontSize: '14px', marginBottom: '0px' }}>Indian</p>


                                        </div>
                                    </div>
                                </div>

                                <div class={`tab-pane fade ${foodSelected ? "show active" : ''} `} id="food" role="tabpanel" aria-labelledby="profile-tab">
                                    <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                        <div className='searchContentMargin'>
                                            <img src={fuchka} alt='img' className='searchDialogImg'></img>
                                        </div>
                                        <div className='d-flex flex-column searchContentMargin' >
                                            <p className='searchText searchTitile' >Chicken cheese burger</p>

                                            <div className='d-flex flex-row'>
                                                <img src={star} alt='star' className='searchStar' />
                                                <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#3D1E19' }}>4.5</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{ marginBottom: '0px' }} />
                                    <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                        <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                            <div className='searchContentMargin'>
                                                <img src={fuchka} alt='img' className='searchDialogImg'></img>
                                            </div>
                                            <div className='d-flex flex-column searchContentMargin' >
                                                <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                <div className='d-flex flex-row'>
                                                    <img src={star} alt='star' className='searchStar' />
                                                    <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#3D1E19' }}>4.5</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <hr style={{ marginBottom: '0px' }} />
                                    <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                        <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                            <div className='searchContentMargin'>
                                                <img src={fuchka} alt='img' className='searchDialogImg'></img>
                                            </div>
                                            <div className='d-flex flex-column searchContentMargin' >
                                                <p className='searchText searchTitile' >Chicken cheese burger</p>

                                                <div className='d-flex flex-row'>
                                                    <img src={star} alt='star' className='searchStar' />
                                                    <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#3D1E19' }}>4.5</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <hr />
                                    <div className='pt-2'>
                                        <h5 style={{ fontWeight: '700' }}>Top Categories</h5>
                                    </div>
                                    <div className='pt-2' style={{ marginLeft: '10px' }}>
                                        <div className='d-flex flex-row '>
                                            <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />

                                            <p style={{ fontSize: '14px', marginBottom: '0px' }}>Chinese</p>

                                        </div>
                                        <hr />

                                        <div className='d-flex flex-row'>
                                            <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                            <p style={{ fontSize: '14px', marginBottom: '0px' }}>Pizza</p>


                                        </div>
                                        <hr />
                                        <div className='d-flex flex-row'>
                                            <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                            <p style={{ fontSize: '14px', marginBottom: '0px' }}>Indian</p>


                                        </div>
                                    </div>
                                </div>
                                <div class={`tab-pane fade ${resturantSelected ? "show active" : ''} `} id="restaurant" role="tabpanel" aria-labelledby="contact-tab">
                                    <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                        <div className='searchContentMargin'>
                                            <img src={PKB} alt='img' className='searchDialogImg'></img>
                                        </div>
                                        <div className='d-flex flex-column searchContentMargin' >
                                            <p className='searchText searchTitile' >Pot kettle black</p>

                                            <div className='d-flex flex-row'>

                                                <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Burgers | pizza | Desserts</p>
                                            </div>
                                        </div>


                                    </div>
                                    <hr style={{ marginBottom: '0px' }} />
                                    <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                        <div className='searchContentMargin'>
                                            <img src={PKB} alt='img' className='searchDialogImg'></img>
                                        </div>
                                        <div className='d-flex flex-column searchContentMargin' >
                                            <p className='searchText searchTitile' >Pot kettle black</p>

                                            <div className='d-flex flex-row'>

                                                <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Burgers | pizza | Desserts</p>
                                            </div>
                                        </div>


                                    </div>
                                    <hr style={{ marginBottom: '0px' }} />
                                    <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                        <div className='searchContentMargin'>
                                            <img src={PKB} alt='img' className='searchDialogImg'></img>
                                        </div>
                                        <div className='d-flex flex-column searchContentMargin' >
                                            <p className='searchText searchTitile' >Pot kettle black</p>

                                            <div className='d-flex flex-row'>

                                                <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Burgers | pizza | Desserts</p>
                                            </div>
                                        </div>


                                    </div>
                                    <hr />
                                    <div className='pt-2'>
                                        <h5 style={{ fontWeight: '700' }}>Top Categories</h5>
                                    </div>
                                    <div className='pt-2' style={{ marginLeft: '10px' }}>
                                        <div className='d-flex flex-row '>
                                            <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />

                                            <p style={{ fontSize: '14px', marginBottom: '0px' }}>Chinese</p>

                                        </div>
                                        <hr />

                                        <div className='d-flex flex-row'>
                                            <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                            <p style={{ fontSize: '14px', marginBottom: '0px' }}>Pizza</p>


                                        </div>
                                        <hr />
                                        <div className='d-flex flex-row'>
                                            <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                            <p style={{ fontSize: '14px', marginBottom: '0px' }}>Indian</p>


                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="posts" role="tabpanel" aria-labelledby="contact-tab">
                                    <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                        <div className='searchContentMargin'>
                                            <img src={pasta} alt='img' className='searchDialogImg'></img>
                                        </div>
                                        <div className='d-flex flex-column searchContentMargin' >
                                            <p className='searchText searchTitile' >Chicken cheese burger</p>

                                            <div className='d-flex flex-row'>

                                                <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Pot kettle black</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{ marginBottom: '0px' }} />
                                    <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                        <div className='searchContentMargin'>
                                            <img src={pasta} alt='img' className='searchDialogImg'></img>
                                        </div>
                                        <div className='d-flex flex-column searchContentMargin' >
                                            <p className='searchText searchTitile' >Chicken cheese burger</p>

                                            <div className='d-flex flex-row'>

                                                <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Pot kettle black</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{ marginBottom: '0px' }} />
                                    <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                                        <div className='searchContentMargin'>
                                            <img src={pasta} alt='img' className='searchDialogImg'></img>
                                        </div>
                                        <div className='d-flex flex-column searchContentMargin' >
                                            <p className='searchText searchTitile' >Chicken cheese burger</p>

                                            <div className='d-flex flex-row'>

                                                <p className='searchText searchratingText' style={{ marginBottom: '0px', color: '#80615b' }}>Pot kettle black</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='pt-2'>
                                        <h5 style={{ fontWeight: '700' }}>Top Categories</h5>
                                    </div>
                                    <div className='pt-2' style={{ marginLeft: '10px' }}>
                                        <div className='d-flex flex-row '>
                                            <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />

                                            <p style={{ fontSize: '14px', marginBottom: '0px' }}>Chinese</p>

                                        </div>
                                        <hr />

                                        <div className='d-flex flex-row'>
                                            <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                            <p style={{ fontSize: '14px', marginBottom: '0px' }}>Pizza</p>


                                        </div>
                                        <hr />
                                        <div className='d-flex flex-row'>
                                            <img src={search} alt='' style={{ width: '0.9rem', height: '0.9rem', marginRight: '1rem', marginTop: '3px' }} />
                                            <p style={{ fontSize: '14px', marginBottom: '0px' }}>Indian</p>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>





                </Modal.Body>
            </Modal>

            <SidebarLogin loginClick={showModalInfo}></SidebarLogin>

        </div >
    );
};

export default Navbar;