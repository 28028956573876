import React from 'react'

import img1 from "./../../../Assets/Image/airbnb.webp";

import img2 from "./../../../Assets/Image/aibnb2.webp";
import img3 from "./../../../Assets/Image/1 (2).jpg";
import cakeImg from './../../../Assets/Image/cake1 1.png';
import chickenImg from './../../../Assets/Image/delicious-chicken-table 3.png';
import kababImg from './../../../Assets/Image/Kabab 1.png';
import latteCoffeeImg from './../../../Assets/Image/latte-coffee-cup 1.png';
import pastaImg from './../../../Assets/Image/Pasta 1.png';
import soupImg from './../../../Assets/Image/Soup 3.png';
import bannerImg from './../../../Assets/Image/banner.jpg';
import plusIcon from './../../../Assets/Icons/plus.png'
import LeoImg from './../../../Assets/Image/Leo.png';
import "swiper/css/navigation";
import threeDot from './../../../Assets/Icons/more.png'
import bookmarkIcon from './../../../Assets/Icons/bookmarkDone.png'
import bookmarkDoneIcon from './../../../Assets/Icons/bookmark1.0.png'
import love from './../../../Assets/Icons/heart.png'
import comment from './../../../Assets/Icons/chat-bubble.png'
import star from './../../../Assets/Icons/starCoffe.png'


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, FreeMode } from "swiper";
import Rating from "react-rating";
import { useEffect, useState, useRef } from 'react';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import './Product.css'


function Product() {
  return (
    <>
    
    

         <div className="box searchProductWidth" >
                    <div className="foodcart  mb-3">

                        {/* <div className="d-flex justify-content-start ">
                            <h3 class="badge pill">Chef Special</h3>
                        </div> */}
                        <div className="d-flex justify-content-end cartIcon">
                            <img src={plusIcon} alt="" style={{ width: "20px" }} />
                        </div>

                        <Swiper
                            spaceBetween={2}
                            grabCursor={false}
                            centeredSlides={false}
                            slidesPerView={'auto'}
                            speed={10000}

                            cssMode={true}
                            pagination={{
                                dynamicBullets: true,
                            }}
                            // slidesPerView={1}
                            navigation={true}
                           
                            modules={[Pagination, Navigation]}
                            className="Productfoodcartimg"

                        >
                            <SwiperSlide >
                                <img src={img1} className="img-fluid " alt="..."  />

                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={img1} className="img-fluid" alt="..."  />

                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={img2} className="img-fluid" alt="..."  />

                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={img3} className="img-fluid" alt="..."  />

                            </SwiperSlide>
                        </Swiper>


                        {/* <div className='foodCartText mt-2'>
                            <div className="d-flex justify-content-between commentLove">
                                <div className="d-flex commentLove">
                                    <i className="fa-regular fa-heart "></i>
                                    <i className="fa-sharp fa-regular fa-comment-dots"></i>
                                  
                                    <div class="dropdown">
                                        <span class="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style={{ cursor: 'pointer' }}>
                                            <i class="fas fa-ellipsis-v"></i>
                                        </span>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a class="dropdown-item" href="#">Save</a></li>
                                            <li><a class="dropdown-item" href="#">Offers</a></li>
                                            <li><a class="dropdown-item" href="#">Subscribe</a></li>
                                        </ul>
                                    </div>
                                   
                                </div>
                                
                                <div className='rating-Font mb-1 d-flex align-items-center starRating'> <Rating className="ratingStar"
                                    initialRating={Number(4.5)}
                                    emptySymbol="far fa-star icon-color"
                                    fullSymbol="fas fa-star icon-color"
                                    readonly ></Rating>4.5
                                </div>
                            </div>
                            <div className="d-flex justify-content-between ">

                                <h3 className='m-0 p-0'>Bread Toast</h3>
                                <h4 className='m-0 p-0'>10.5$</h4>

                            </div>
                            <p className='my-1'>Topped with tomato, bocconcini...  </p>
                            <div className="d-flex justify-content-between align-items-center">

                                <div className='d-flex align-items-center'>
                                    <h6>Red Flame  Resta...</h6>
                                    <div className='d-flex align-items-center starRating'>

                                        <i className="fa-sharp fa-solid fa-star"></i>
                                        <h4 className='m-0 p-0'>4.5</h4>

                                    </div>
                                </div>

                                <button className='follow-BUtton btn ms-1'>Follow</button>


                            </div>




                            <div className='d-flex align-items-center'>
                                <img src={LeoImg} className='me-1 userImg' alt="" />
                                <p>Leo Love the art of travel. So many plac So many  foods... </p>
                            </div>


                        </div> */}
                      
                            <div className='searchProduct p-1' style={{  borderRadius:'10px'}}>
                               
                                    <div className='d-flex justify-content-between'>
                                     <div>
                                        <img src={love} alt='love' style={{width:'1rem', marginRight:'0.3rem'}}/>
                                        <img src={comment} alt='love' style={{width:'1rem'}}/>
                                      </div>
                                      <div>
                                       
                                        <img src={star} alt='love' style={{width:'1rem'}}/>
                                        <img src={star} alt='love' style={{width:'1rem'}}/>
                                        <img src={star} alt='love' style={{width:'1rem'}}/>
                                        <img src={star} alt='love' style={{width:'1rem'}}/>
                                        <img src={star} alt='love' style={{width:'1rem', marginRight:'2px'}}/>
                                       
                                        <span style={{color:'#3e1e19'}}>5.0</span>
                                      </div>
                                     
                                    </div>
                                    <div className='d-flex justify-content-between'>

                                        <span className='m-0 p-0 searchProductTitle'>Bread Toast</span>
                                        <span className='m-0 p-0' style={{fontWeight:'500', color:'#3e1e19'}}>10.5$</span>

                                    </div>

                                    <p className='my-1'>Topped with tomato, bocconcini...  </p>
                            <div className="d-flex justify-content-between ">

                               <div>
                                      <span className='searchProductRes'>Red Flame  Resta...</span>
                                    
                                     
                                        {/* <i className="fa-sharp fa-solid fa-star"></i> */}
                                        <img src={star} alt='love' style={{width:'1rem'}}/>
                                        <span style={{color:'#3e1e19', marginLeft:'2px'}}>4.5</span>
                                        </div>

                                       

                                        <button className=''>Follow</button>

                            </div>

                            <div className='d-flex justify-content-center'>
                                <img src={LeoImg} className='me-1 searchProductUser' alt=""  />
                                <p>Leo Love the art of travel. So many plac So many  foods... </p>
                            </div>

                               

                            </div>

                       
                    </div>
                    <hr className='mx-5  m-0 p-0 cartHR' />
         </div>
      

   

    </>
  )
}

export default Product