import React, { useState, useEffect } from 'react';
import './Banner.css'
import Login from './../../LoginRegister/Login'
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import $ from "jquery";
import backBtn from './../../../Assets/Icons/back.png'
import logo from './../../../Assets/Image/LogoTransparent.png'
import { useSelector, useDispatch } from 'react-redux';
import { changeLoadFalse, changeLoadTrue, openRegisterPageFalse } from "./../../../actions/index"
import Navbar from '../../Navbar/Navbar';
import TypewriterComponent from 'typewriter-effect';

const BannerSection = () => {
    

    
    return (
        <div>
            {/* <Navbar /> */}
            <section className="banner">
                <div className='d-flex typewriterDiv'>
                    <TypewriterComponent
                        options={{
                            strings: [
                                'Deliver food to your door step',
                                'Know your favorite food history',
                                'Fast delivery',
                                'Quick service',
                                'Authentic food',
                            ],
                            autoStart: true,
                            loop: true,
                            delay: 100,
                        }}
                    />
                </div>

            </section>

        </div>
    );
};

export default BannerSection;