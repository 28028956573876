import React from 'react';
import img1 from './../../../Assets/Image/image 30.png'
import img2 from './../../../Assets/Image/image 33.png'
import img3 from './../../../Assets/Image/image 35.png'

import './ProvidedServices.css'


const ProvidedServices = () => {
    return (
        <div>
            {/* sevices  */}
            <div class="row row-cols-1 row-cols-md-3 g-4 customContainer my-5 LandingSevices">
                <div class="col ">
                    <div class="card border-0 ">
                        <img src={img1} class="card-img-top" alt="..." />
                        <div class="card-body">
                            <h5 class="card-title">Feed your employees</h5>
                            <p class="card-text">Create a business count</p>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card border-0">
                        <img src={img2} class="card-img-top" alt="..." />
                        <div class="card-body">
                            <h5 class="card-title">Your restaurant, delivered</h5>
                            <p class="card-text">Add your restauant</p>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card border-0">
                        <img src={img3} class="card-img-top" alt="..." />
                        <div class="card-body">
                            <h5 class="card-title">Deliver with Go Taste</h5>
                            <p class="card-text">Signup to deliver</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProvidedServices;