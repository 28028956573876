import React from 'react';
import './chat.css';
import chatImg from './../../Assets/Image/Leo.png';
import messageSend from './../../Assets/Icons/Send.jpeg';
import { Link } from 'react-router-dom';

const Chat = () => {
    return (

        <>
            <div className="dextopChat">
                <div className="row">
                    <div className="col-3 cahtSidebar">
                        <div className="p-3">
                            <div className="p-3 fixedSearch">
                                <h2 className='ChatHeader'>Messages</h2>

                                <div class="pb-4">
                                    <input type="email" class="form-control" id="email" placeholder="Search" />
                                </div>
                            </div>


                            <div className='  d-flex  p-3 my-2 align-items-center  selecedCht
                            '>
                                <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                <div>
                                    <h4 className="m-0 p-0">Jeremy Renner</h4>
                                    <p className='lastMessage'>food is delivered  <span className='ms-1'>. 1 h</span></p>
                                </div>
                            </div>

                            <div className='  d-flex  p-3 my-2 align-items-center
                            '>
                                <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                <div>
                                    <h4 className="m-0 p-0">Jeremy Renner</h4>
                                    <p className='lastMessage'>food is delivered  <span className='ms-1'>. 1 h</span></p>
                                </div>
                            </div>

                            <div className='  d-flex  p-3 my-2 align-items-center
                            '>
                                <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                <div>
                                    <h4 className="m-0 p-0">Jeremy Renner</h4>
                                    <p className='lastMessage'>food is delivered  <span className='ms-1'>. 1 h</span></p>
                                </div>
                            </div>

                            <div className='  d-flex  p-3 my-2 align-items-center
                            '>
                                <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                <div>
                                    <h4 className="m-0 p-0">Jeremy Renner</h4>
                                    <p className='lastMessage'>food is delivered  <span className='ms-1'>. 1 h</span></p>
                                </div>
                            </div>

                            <div className='  d-flex  p-3 my-2 align-items-center
                            '>
                                <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                <div>
                                    <h4 className="m-0 p-0">Jeremy Renner</h4>
                                    <p className='lastMessage'>food is delivered  <span className='ms-1'>. 1 h</span></p>
                                </div>
                            </div>

                            <div className='  d-flex  p-3 my-2 align-items-center'>
                                <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                <div>
                                    <h4 className="m-0 p-0">Jeremy Renner</h4>
                                    <p className='lastMessage'>food is delivered  <span className='ms-1'>. 1 h</span></p>
                                </div>
                            </div>

                            <div className='d-flex p-3 align-items-center'>
                                <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                <div>
                                    <h4 className="m-0 p-0">Jeremy Renner</h4>
                                    <p className='lastMessage'>food is delivered  <span className='ms-1'>. 1 h</span></p>
                                </div>
                            </div>

                            <div className='  d-flex  p-3 my-2 align-items-center'>
                                <div className='me-2'>
                                    <img src={chatImg} alt="" /></div>
                                <div>
                                    <h4 className="m-0 p-0">Jeremy Renner</h4>
                                    <p className='lastMessage'>food is delivered  <span className='ms-1'>. 1 h</span></p>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="col-9 p-0">

                        <div className="chat">
                            <div className='ChatName p-2 mb-3 d-flex align-items-center'>
                                <div className='d-flex align-items-center'>
                                    <img src={chatImg} className=' me-3' alt="" />
                                    <div className=''>

                                        <h4 className='p-0 m-0'>Jeremy Renner</h4>
                                        <p className='p-0 m-0'>Active</p>
                                    </div>
                                </div>

                            </div>
                            <div className='px-4'>

                                <div className='d-flex  m-2 align-items-center'>
                                    <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                    <div>
                                        <h5 className='reciveMessage p-2 m-0 ps-3' >hi</h5>

                                    </div>
                                </div>
                                <p className='d-flex justify-content-center text-gray'>Monday 8.55 pm</p>
                                <div className='d-flex  m-2 align-items-center justify-content-end'>
                                    <div>
                                        <h5 className='sendMessage p-2 m-0 ps-3' >hi</h5>

                                    </div>
                                </div>

                                <div className='d-flex  m-2 align-items-center
                            '>
                                    <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                    <div>
                                        <h5 className='reciveMessage p-2 m-0 ps-3' >hi</h5>

                                    </div>
                                </div>
                                <div className='d-flex  m-2 align-items-center justify-content-end'>
                                    <div>
                                        <h5 className='sendMessage p-2 m-0 ps-3 ' >hi</h5>

                                    </div>
                                </div>

                                <div className='d-flex  m-2 align-items-center
                            '>
                                    <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                    <div>
                                        <h5 className='reciveMessage p-2 m-0 ps-3' >hi</h5>

                                    </div>
                                </div>
                                <div className='d-flex  m-2 align-items-center justify-content-end'>
                                    <div>
                                        <h5 className='sendMessage p-2 m-0 ps-3 ' >hi</h5>
                                    </div>
                                </div>

                                <div className='d-flex  m-2 align-items-center'>
                                    <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                    <div>
                                        <h5 className='reciveMessage p-2 m-0 ps-3' >hi</h5>

                                    </div>
                                </div>
                                <div className='d-flex  m-2 align-items-center justify-content-end'>
                                    <div>
                                        <h5 className='sendMessage p-2 m-0 ps-3 ' >hi</h5>
                                    </div>
                                </div>

                                <div className='d-flex  m-2 align-items-center
                            '>
                                    <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                    <div>
                                        <h5 className='reciveMessage p-2 m-0 ps-3' >hi</h5>

                                    </div>
                                </div>
                                <div className='d-flex  m-2 align-items-center justify-content-end'>
                                    <div>
                                        <h5 className='sendMessage p-2 m-0 ps-3 ' >hi</h5>

                                    </div>
                                </div>
                                <div className='d-flex  m-2 align-items-center
                            '>
                                    <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                    <div>
                                        <h5 className='reciveMessage p-2 m-0 ps-3' >hi</h5>

                                    </div>
                                </div>
                                <div className='d-flex  m-2 align-items-center justify-content-end'>
                                    <div>
                                        <h5 className='sendMessage p-2 m-0 ps-3 ' >hi</h5>

                                    </div>
                                </div>
                                <div className='d-flex  m-2 align-items-center
                            '>
                                    <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                    <div>
                                        <h5 className='reciveMessage p-2 m-0 ps-3' >hi</h5>

                                    </div>
                                </div>
                                <div className='d-flex  m-2 align-items-center justify-content-end'>
                                    <div>
                                        <h5 className='sendMessage p-2 m-0 ps-3 ' >hi</h5>

                                    </div>
                                </div>

                            </div>

                            <div className="messageSend">

                                <div class="input-group">
                                    <textarea type="text" class="form-control p-1" placeholder="Message" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                    <button class="btn  sendbtn" type="button" id="button-addon2">
                                        <i class="fa-solid fa-paper-plane"></i>
                                        {/* <img className='img-fluid ' src={messageSend} alt="" /> */}
                                    </button>
                                </div>


                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div className="mobileChat">
                <div className="row">
                    <div className="col-12 cahtSidebar">
                        <div className="p-3">
                            <div className="p-3 fixedSearch">
                                <h2 className='ChatHeader'>Messages</h2>

                                <div class="pb-4">
                                    <input type="email" class="form-control" id="email" placeholder="Search" />
                                </div>
                            </div>

                            <Link to="/chat/51531" className='text-decoration-none'>

                                <div className='  d-flex  p-3 my-2 align-items-center  selecedCht
                            '>
                                    <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                    <div>
                                        <h4 className="m-0 p-0">Jeremy Renner</h4>
                                        <p className='lastMessage'>food is delivered  <span className='ms-1'>. 1 h</span></p>
                                    </div>
                                </div>
                            </Link>

                            <Link to="/chat/51531" className='text-decoration-none'>
                                <div className='  d-flex  p-3 my-2 align-items-center
                            '>
                                    <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                    <div>
                                        <h4 className="m-0 p-0">Jeremy Renner</h4>
                                        <p className='lastMessage'>food is delivered  <span className='ms-1'>. 1 h</span></p>
                                    </div>
                                </div>
                            </Link>

                            <Link to="/chat/51531" className='text-decoration-none'>
                                <div className='  d-flex  p-3 my-2 align-items-center
                            '>
                                    <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                    <div>
                                        <h4 className="m-0 p-0">Jeremy Renner</h4>
                                        <p className='lastMessage'>food is delivered  <span className='ms-1'>. 1 h</span></p>
                                    </div>
                                </div>
                            </Link>

                            <Link to="/chat/51531" className='text-decoration-none'>
                                <div className='  d-flex  p-3 my-2 align-items-center
                            '>
                                    <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                    <div>
                                        <h4 className="m-0 p-0">Jeremy Renner</h4>
                                        <p className='lastMessage'>food is delivered  <span className='ms-1'>. 1 h</span></p>
                                    </div>
                                </div>
                            </Link>

                            <Link to="/chat/51531" className='text-decoration-none'>
                                <div className='  d-flex  p-3 my-2 align-items-center
                            '>
                                    <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                    <div>
                                        <h4 className="m-0 p-0">Jeremy Renner</h4>
                                        <p className='lastMessage'>food is delivered  <span className='ms-1'>. 1 h</span></p>
                                    </div>
                                </div>
                            </Link>

                            <Link to="/chat/51531" className='text-decoration-none'>
                                <div className='  d-flex  p-3 my-2 align-items-center'>
                                    <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                    <div>
                                        <h4 className="m-0 p-0">Jeremy Renner</h4>
                                        <p className='lastMessage'>food is delivered  <span className='ms-1'>. 1 h</span></p>
                                    </div>
                                </div>
                            </Link>

                            <Link to="/chat/51531" className='text-decoration-none'>
                                <div className='d-flex p-3 align-items-center'>
                                    <div className='me-2'>     <img src={chatImg} alt="" /></div>
                                    <div>
                                        <h4 className="m-0 p-0">Jeremy Renner</h4>
                                        <p className='lastMessage'>food is delivered  <span className='ms-1'>. 1 h</span></p>
                                    </div>
                                </div>
                            </Link>

                            <Link to="/chat/51531" className='text-decoration-none'>
                                <div className='  d-flex  p-3 my-2 align-items-center'>
                                    <div className='me-2'>
                                        <img src={chatImg} alt="" /></div>
                                    <div>
                                        <h4 className="m-0 p-0">Jeremy Renner</h4>
                                        <p className='lastMessage'>food is delivered  <span className='ms-1'>. 1 h</span></p>
                                    </div>
                                </div>
                            </Link>


                        </div>
                    </div>

                </div>
            </div>
        </>

    );
};

export default Chat;