import React, { useState } from 'react';
import Navbar from '../../Navbar/Navbar';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loadingGif from './../../../Assets/Image/loading.gif'
import errorImg from './../../../Assets/Image/exclamation.png'
import sucessImg from './../../../Assets/Image/check.png'

const NewPassword = () => {
    const [blur, setBlur] = useState(false)
    const searchDialogOpen = (NavbarData) => {
        setBlur(NavbarData)

    }

    const blurStyle = {
        filter: 'blur(4px)'
    };
    const [searchParams, setSearchParams] = useSearchParams()
    const apiAddress = process.env.REACT_APP_SECRET;
    const [error, setError] = useState(false)
    const [errorTxt, setErrorTxt] = useState("")
    const [loading, setLoading] = useState(false)
    React.useEffect(() => {
        var code = searchParams.get('code');
        setLoading(true);
        console.log(code);
        axios.get(apiAddress + "/api/AdminLogin/checkforgetpassword?code=" + code)
            .then((res) => {
                setLoading(false);
            }).catch((error) => {
                setLoading(false);
                toast.error(error.response.data.status, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setErrorTxt(error.response.data.status);
                setError(true);
            })
    }, [searchParams.get('code')])

    const [password, setpassword] = useState("")
    const [confirmPassword, setconfirmPassword] = useState("")
    const [successFullyChanged, setsuccessFullyChanged] = useState(false)
    const hnadleOnSubmit = (event) => {
        event.preventDefault();
        console.log(event);
        var code = searchParams.get('code');
        setLoading(true);
        console.log(code);
        axios.get(apiAddress + "/api/AdminLogin/changepassword?code=" + code + "&password=" + password)
            .then((res) => {
                setLoading(false);
                setsuccessFullyChanged(true);
            }).catch((error) => {
                setLoading(false);
                toast.error(error.response.data.status, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setErrorTxt(error.response.data.status);
                setError(true);
            })
    }

    return (
        <div>
            <ToastContainer />
            <Navbar handleNavbar={searchDialogOpen} from={"landingPage"} />
            <div style={{ height: "100vh" }}>

                <div className='d-flex justify-content-center h-100 align-items-center' >
                    {loading ? (
                        <img src={loadingGif} alt="" />
                    ) : (
                        error ? (
                            <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                                <div>
                                    <img src={errorImg} alt="" style={{ width: "7rem" }} />
                                </div>
                                <h4 className='mt-2'>{errorTxt}</h4>
                                <span onClick={() => {
                                    window.location.href = "/"
                                }}>Goto Home</span>
                            </div>

                        ) : successFullyChanged ? (
                            <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                                <div>
                                    <img src={sucessImg} alt="" style={{ width: "7rem" }} />
                                </div>
                                <h4 className='mt-2'>Password Changed successfully</h4>
                                <span onClick={() => {
                                    window.location.href = "/"
                                }}>Goto Home</span>
                            </div>
                        ) : (
                            <div className="card w-25" >
                                <div class="card-header text-center">
                                    <b>Update password</b>
                                </div>
                                <div className="card-body ">
                                    <form onSubmit={hnadleOnSubmit}>
                                        <div className='col-12'>
                                            <span>Password</span>
                                            <input type="password" name="" id="" className='form-control' value={password} onChange={(event) => { setpassword(event.target.value) }} required />
                                        </div>
                                        <div className='col-12 mt-3'>
                                            <span>Confirm Password</span>
                                            <input type="password" name="" id="" className='form-control' value={confirmPassword} onChange={(event) => { setconfirmPassword(event.target.value) }} required />
                                            <span className={`${password == confirmPassword ? "d-none" : ""} mt-2`} style={{ color: "red" }}>Password not matched</span>
                                        </div>
                                        <div className='col-12 mt-3'>
                                            <button className="btn btn-success font-weight-bold w-100 " style={{ width: "    width: 100%;", borderRadius: "8px", backgroundColor: "#3D1E19" }} type="submit">Update</button>
                                        </div>
                                    </form>
                                    {/* <p className="card-text">Must include at least one symbol or number and have at least 8 characters.</p> */}

                                </div>
                            </div>
                        )

                    )}

                </div>
            </div>

        </div>

    );
};

export default NewPassword;