import React ,{useState, useEffect} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Autoplay, Navigation, EffectCoverflow, Pagination } from 'swiper'
import 'swiper/css/navigation'
import store from './../../../Assets/Icons/shop.png'
import subscription from './../../../Assets/Icons/notification.png'
import Corporate from './../../../Assets/Icons/corporate.png'
import grid from './../../../Assets/Icons/app.png'
import BurgerOffer from './../../../Assets/Image/burgerOffer.jpg'
import close from './../../../Assets/Icons/close.png'
// import './MenuSlider.css'

function SearchSlider(props) {

    //swiper start
const [fromHome, SetFromHome] = useState(true);
useEffect(() => {
    console.log(props);
    if (props.from === 2) {
        SetFromHome(false);
    }
})
//swiper end

const [All, setAll]=useState(false)
const [Offer, setOffer]=useState(false)
const handleSearchSelect=(data)=>{
    if(data=='All'){
        setAll(true)
    }
    else if(data=='Offer'){
        setOffer(true)
    } 
}
const onClose=(data)=>{
   if(data=='All')
   {
    setAll(false)
   }
   else if(data=='Offer')
   {
    setOffer(false)
   }
}
  return (
    <>
    <div className='menuslider'>
          <div >
            
                <Swiper
                spaceBetween={30}
                grabCursor={false}
                centeredSlides={false}
                slidesPerView={'auto'}
                hashNavigation={{
                    watchState: true,
                }}
                // autoplay={{
                //   delay: 2000,
                //   disableOnInteraction: true,
                // }}
                breakpoints={{
                    300: {
                        slidesPerView: 1,
                        spaceBetween: 5,
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 5,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                }}
                navigation={true}
                modules={[Autoplay, Navigation, Pagination]}
                pagination={{
                    dynamicBullets: true,
                    clickable: true,
                }}

            >

<SwiperSlide>
                            
            <div
                    className="d-flex cardShadow  align-items-center justify-content-between p-3 pb-1 m-2 selectionSingleButton"
                    value={'store menue'}
                    // onClick={handleSearchClick}
                    onClick={()=>handleSearchSelect('All')}
                    >
                    <h4>All</h4>
                    <img
                        className="menu-selection-icon menuSelectionImage"
                        src={grid}
                        alt="..."
                    />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className='searchOfferDiv'
                onClick={()=>handleSearchSelect('Offer')}
                >
     
                <img src={BurgerOffer} alt='Offer' />
                <div className='searchOfferDiv-details'>
                    <h5>X-Mas offers 2</h5>
                    <div>
                        <p>10% discount on Pizza Family Size (12Inch) 
                         and Any Item on Chicken Section
                         </p>
                    </div>
                </div>
                </div>
                </SwiperSlide>
                <SwiperSlide>
                            <div
                    className="d-flex cardShadow  align-items-center justify-content-between p-3 pb-1 m-2 selectionSingleButton"
                    value={'store menue'}
                    // onClick={handleSearchClick}
                    >
                    <h4>Subscription</h4>
                    <img
                        className="menu-selection-icon menuSelectionImage"
                        src={subscription}
                        alt="..."
                    />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                            <div
                    className="d-flex cardShadow  align-items-center justify-content-between p-3 pb-1 m-2 selectionSingleButton"
                    value={'store menue'}
                    // onClick={handleSearchClick}
                    >
                    <h4>In Store Menu</h4>
                    <img
                        className="menu-selection-icon menuSelectionImage"
                        src={store}
                        alt="..."
                    />
                    </div>
                </SwiperSlide>
                </Swiper>
                </div>


               
                
           
    </div>


       <div className='d-flex justify-content-center m-4'>
            {Offer &&
                <div className='pl-2 ps-2 pe-2 selectedItem' >
                    <div className='p-1 d-flex justify-content-between' style={{gap:'10px'}}>
                        <p className=''>X-Mas offers</p>
                        <div onClick={()=>onClose('Offer')}>
                        <img  src={close} alt='close' className='' style={{width:'1rem', height:'1rem'}} />
                        </div>
                     </div>

                </div>
             }
             {All &&
                <div className='pl-2 ps-2 pe-2 selectedItem' style={{marginLeft:'5px'}}>
                    <div className='p-1 d-flex justify-content-between' style={{gap:'10px'}}>
                        <p className=''>All</p>
                        <div onClick={()=>onClose('All')}>
                        <img  src={close} alt='close' className='' style={{width:'1rem', height:'1rem'}} />
                        </div>
                     </div>

                </div>
             }
        </div>
    </>
  )
}

export default SearchSlider