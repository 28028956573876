import React, { useState, useRef } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './Pages/LoginRegister/Login'
import './App.css'
import './main.css'
import './responsive.css'
import About from './Pages/About'
import Users from './Pages/Users'
import Home from './Pages/Home/Home'
import Test from './Pages/Test'
import ProductPage from './Pages/ProductPage/ProductPage'
import Test2 from './Pages/Test2'
import Restaurant from './Pages/Restaurant/Restaurant'
import SuccessPage from './Pages/Success/SuccessPage'
import Test3 from './Pages/Test3'
import Refresh from './Pages/Success/Refresh'
import OrderPage from './Pages/OrderPage/OrderPage'
import PhotoGridGallery from './Pages/Restaurant/PhotoGridGallery/PhotoGridGallery'
import Offers from './Pages/Offers/Offers'
import AllPostDisplay from './Pages/PostDisplay/AllPostDisplay/AllPostDisplay'
import LandingPage from './Pages/LandingPage/LandingPage'
import Test5 from './TestFile/Test5'
import TestGallery from './Pages/Test5'
import TestSharePost from './Pages/TestSharePost'
import CheckOut from './Pages/CheckOut/CheckOut'
import SearchResult from './Pages/SearchBar/SearchResult/SearchResult'
import SubscriptionOffer from './Pages/Restaurant/subscriptionoffer/SubscriptionOffer'
import MenuSlider from './Pages/GalleryView/menuslider/MenuSlider'
import SearchPage from './Pages/SearchPage/SearchPage'
import SigenUp from './Pages/RestaurantRegistration/SigenUp'
import AllRestaurantOffers from './Pages/AllRestaurantOffers/AllRestaurantOffers'
import HorizontalPage from './Pages/HorizontalPage/HorizontalPage'
import Sauces from './Pages/OrderPage/OrderCart/Sauces/Sauces'
import Chat from './Pages/Chat/Chat'
import MobileChat from './Pages/Chat/MobileChat'
import Test6 from './Pages/Test6'
import Test7 from './Pages/Tes7'
import Test8 from './Pages/Test8'
import TestThumb from './TestThumb'
import Test9 from './Test9'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RegistrationSuccess from './Pages/LoginRegister/RegistrationSuccess/RegistrationSuccess'
import Auth from './hooks/Auth'
import PhotoGridgalleryView from './Pages/Restaurant/PhotoGridGallery/Components/PhotoGridgalleryView'
import TermsAndCondition from './Pages/TermsAndCondition/TermsAndCondition'
import Privacy from './Pages/Privacy/Privacy'
import NewPassword from './Pages/LoginRegister/NewPassword/NewPassword'


function App() {
  const myRef = useRef()
  // const [scrollTop, setScrollTop] = useState(0);

  return (
    <div>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/users" element={<Users />} />
            <Route path="/login" element={<Login />} />
            <Route path="/product-page" element={<ProductPage />} />
            <Route path="/test" element={<Test />} />
            <Route path="/test2" element={<Test2 />} />
            <Route path="/test3" element={<Test3 />} />
            <Route path="/restaurant" element={<Restaurant />} />
            <Route path="/login" element={<Login />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/refresh" element={<Refresh />} />
            <Route path="/orderpage" element={<OrderPage />} />
            <Route path="/landingPage" element={<LandingPage />} />
            <Route path="/gallerygrid" element={<PhotoGridGallery from={0} />} />
            <Route path="/offers" element={<Offers />} />
            <Route path="/allpost" element={<AllPostDisplay />} />
            <Route path="/test5" element={<Test5 />} />
            <Route path="/sharePost" element={<TestSharePost />} />
            <Route path="/checkout" element={<CheckOut />} />
            <Route path="/test" element={<TestGallery />} />
            <Route path="/searchresult" element={<SearchResult />} />
            <Route path="/subscriptionoffer" element={<SubscriptionOffer />} />
            <Route path="/menuslider" element={<MenuSlider />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/register" element={<SigenUp />} />
            <Route path="/restaurantoffer" element={<AllRestaurantOffers />} />
            <Route path="/horizontalpage" element={<HorizontalPage />} />
            <Route path="/sauces" element={<Sauces />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/chat/51531" element={<MobileChat />} />
            <Route path="/thumb" element={<TestThumb />} />
            <Route path="/test6" element={<Test6 />} />
            <Route path="/test7" element={<Test7 />} />
            <Route path="/test8" element={<Test8 />} />
            <Route path="/test9" element={<Test9 />} />
            <Route path="/testAuth" element={<Auth><TestThumb /></Auth>} />
            <Route path="/test10" element={<TestThumb />} />
            <Route path="/successfullyregistered" element={<RegistrationSuccess />} />
            <Route path="/photogridgalleryview" element={<PhotoGridgalleryView />} />
            <Route path="/termsandconditions" element={<TermsAndCondition />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/newpassword" element={<NewPassword />} />
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </div>
  )
}

export default App
