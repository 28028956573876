import React, { useRef, useState, useEffect } from 'react';
import PostDisplay from '../PostDisplay';
const AllPostDisplayComponent = (props) => {
    const ref = useRef();
    const [currentScrollPosition, setCurrentScrollPosition] = useState(3500);
    useEffect(() => {
        if (props.lazyLoad != "1") {
            window.addEventListener("scroll", (event) => {

                if (window.scrollY > currentScrollPosition) {
                    setSeeMore(seeMore + 5);
                    var sum = currentScrollPosition + 3500;
                    setCurrentScrollPosition(sum);
                }
            })
        }


    });
    const [seeMore, setSeeMore] = useState(5);
    return (
        <div>
            <div className='row'>
                <div className='col-12'>
                    {getValue(seeMore)}
                </div>
            </div>
            <div className='row loadingSpan' ref={ref} onClick={() => { setSeeMore(seeMore + 5) }}>
                <div className='col-12 d-flex justify-content-center'>
                    <span className='loadingSpan'>Loading more </span>
                </div>
            </div>
            <div className='row loadingSpan'>
                <div className='col-12'>
                    <span className=''></span>
                </div>
            </div>
        </div>
    );
};
const getValue = (props) => {
    let count = props;
    let cards = [];
    for (let index = 0; index < count; index++) {

        cards.push(<div>
            <PostDisplay></PostDisplay>
            <br></br>
        </div>)
    }
    return cards;
}
export default AllPostDisplayComponent;