import React, { useState } from 'react';
import p1 from './../../../../Assets/Image/banner.jpg'
import bin from './../../../../Assets/Icons/recycle-bin.png'
import './CardDetails.css'

const CardDetails = () => {

    const [showMinus, setShowMinus] = useState(true);
    const [quantity, setQuantity] = useState(1);

    const [showMore, setShowMore] = useState(false)

    const addOns = "Spicy (£2.00), Garlic sause (£1.50), Chesse sause (£1.50), Hot sause (£1.50)";




    const changeBinDisplay = (props) => {
        console.log(props);
        console.log(quantity);
        if (props === 1) {
            var q = quantity + 1;
            setQuantity(q)
            if (q > 1) {
                setShowMinus(false);
            }
            else if (q === 1) {
                console.log("Else");
                setShowMinus(true);
            }
        }
        else {
            var q = quantity - 1;
            if(quantity<1)
            {
                setQuantity(0)
            }
            else{
                setQuantity(q)
            }
            
            // if (q > 1) {
            //     setShowMinus(false);
            // }
            // else if (q === 1) {
            //     console.log("Else");
            //     setShowMinus(true);
            // }

        }


    }

    return (
        <div>
            <div className='d-flex mt-3 cartdetailsGrid justify-content-between'>
                <div className='d-flex itemDetailsAndImage'>
                    <div>
                        <img src={p1} alt="" style={{ height: "6rem" }} />
                    </div>
                    <div className='d-flex productDetails justify-content-center' >
                        <h5>Bread Toast</h5>

                        <p className='addOnCart'><b>Add On: </b>
                            {addOns}

                            {/* {showMore
                                ? addOns
                                : `${addOns.split(/\s+/).slice(0, 2).join(' ')}`}
                            <span
                                className="text-decoration-none ms-1"
                                onClick={() => setShowMore(!showMore)}
                                style={{
                                    color: '#3D1F1B',
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                }}
                            >
                                {showMore ? '' : '...'}
                            </span> */}
                        </p>
                    </div>
                </div>
                <div className='d-flex flex-column '>
                    <div className='d-flex  price-Div'>
                        <p className='price d-flex justify-content-end'>10.5$</p>
                        <div className='d-flex align-items-center quantity'>
                            {/* <img src={bin} alt="" className={`${showMinus ? "bin-img" : "d-none"} `} /> */}
                            {/* <span onClick={() => { changeBinDisplay(0) }} className={`${showMinus ? "d-none " : "plus-icon"} `}>-</span> */}
                            <span onClick={() => { changeBinDisplay(0) }} className= "plus-icon">-</span>
                            {quantity}
                            <span onClick={() => { changeBinDisplay(1) }} className='plus-icon'>+</span>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                     <img src={bin} alt="" style={{width:'1.5rem', height:'1.5rem'}} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardDetails;