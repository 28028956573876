import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './OrderCart.css'
import Sauces from './Sauces/Sauces'

function OrderCart(props) {
  const { foodItem } = props

  const [varient, setVarient] = useState(0)
  // const [sauce, setSauce] = useState(0)
  const [quantity, setQuantity] = useState(1)
  const [person, setPerson] = useState(1)
  const [total, setTotal] = useState(0)
  const [toggle, setToggle] = useState(false)
  const [saucestoggle, setsaucesToggle] = useState(false)
  const [varientHalfdis, setVarientHalfdis] = useState(false)
  const [varientThreedis, setVarientThreedis] = useState(false)
  const [varientFulldis, setVarientFulldis] = useState(false)
  const [variendis, setVarientdis] = useState(false)
  const [saucesdis, setSaucesdis] = useState(false)
  const [halfCheked, sethalfChecked] = useState(false)
  const [threeCheked, setthreeChecked] = useState(false)
  const [fullCheked, setfullChecked] = useState(false)
  //saucces lazy loading test start
  const [sauces, setSauces ]= useState([
    { name: 'Tomato Sauce', price: 5 , amount:0},
    { name: 'Barbecue Sauce', price: 6, amount:0 },
    { name: 'Mustard Sauce', price: 10, amount:0 },
    { name: 'Tamarind Sauce', price: 12, amount:0 },
    { name: 'Chili Sauce', price: 15, amount:0 },
    { name: 'Olive Sauce', price: 20, amount:0 },
    { name: 'Garlic sauce', price: 20, amount:0 },
  ])

  const [visibleSauces, setVisibleSauces] = useState(sauces.slice(0, 5))

  const handleShowMore = () => {
    const remainingSauces = sauces.slice(
      visibleSauces.length,
      visibleSauces.length + 5,
    )
    if (remainingSauces.length > 0) {
      setVisibleSauces([...visibleSauces, ...remainingSauces.slice(0, 5)])
    }
  }
  //sauces lazy loading end

  //checkbox testing start
  const [selectedItems, setSelectedItems] = useState([])

  const handleCheckboxChange = (event) => {
    const { value } = event.target

    setSelectedItems((selectedItems) => {
      if (selectedItems.includes(value)) {
        return selectedItems.filter((item) => item !== value)
      } else {
        return [...selectedItems, value]
      }
    })
  }
  //checkbox testing end

  const addQuantity = () => {
    setQuantity(quantity + 1)
  }
  const removeQuantity = () => {
    if (quantity > 0) {
      setQuantity((prevQuantity) => prevQuantity - 1)
    }
  }
  const handleClick = () => {
    setPerson(1)
  }
  const handleClick2 = () => {
    setPerson(2)
  }
  const handleClick3 = () => {
    setPerson(4)
  }
  const toggleHandler = () => {
    setToggle(!toggle)
  }
  const saucestoggleHandler = () => {
    setsaucesToggle(!saucestoggle)
  }
  const varientHandler = (e) => {
    setVarient(e.target.value)
    // setVarientdis(true)
  }

  //radio button on double click start
  const halfDoubleClick = () => {
    sethalfChecked(!halfCheked)
    setthreeChecked(false)
    setfullChecked(false)
    setVarient(0)
    setVarientHalfdis(!varientHalfdis)
    setVarientThreedis(false)
    setVarientFulldis(false)
  }
  const threeDoubleClick = () => {
    setthreeChecked(!threeCheked)
    sethalfChecked(false)

    setfullChecked(false)
    setVarient(0)
    setVarientHalfdis(false)
    setVarientThreedis(!varientThreedis)
    setVarientFulldis(false)
  }
  const fullDoubleClick = () => {
    setfullChecked(!fullCheked)
    sethalfChecked(false)
    setthreeChecked(false)

    setVarient(0)
    setVarientHalfdis(!varientHalfdis)
    setVarientThreedis(false)
    setVarientFulldis(false)
  }

  //radio button on double click end

  useEffect(() => {
    //radio button double click portion start
    if (varientHalfdis || varientThreedis || varientFulldis) {
      setVarientdis(true)
    } else {
      setVarientdis(false)
    }
    //radio button double click portion end

    if (selectedItems.length !== 0) {
      setSaucesdis(true)
    } else {
      setSaucesdis(false)
    }
    setTotal(0)
    const sum = selectedItems.reduce((total, num) => total + parseInt(num), 0)

    setTotal((parseInt(varient) + sum) * quantity * person)
  })
//sauces quantity start
const [sacesQunatity, setsacesQunatity]=useState(0);
const changeQuantity=(types)=>{
  if(types=='plus'){
    setsacesQunatity(sacesQunatity +1);
  }
  else if(types=='minus'){
    if(sacesQunatity<1){
      setsacesQunatity(0)
    }
    else{
      setsacesQunatity(sacesQunatity -1)
    }
  }
}



//sauces quantity end


const saucesCallback = (id, amount) => {
  console.log(amount);
  const newSauces = [...sauces];

  const updatedSauces = newSauces.map((item, index) => {
    if (index === id) {
      return { ...item, amount: amount };
    }
    return item;
  });

  console.log(updatedSauces[id].amount);
  setSauces(updatedSauces);

};
console.log(sauces[0].amount)
   
 


  return (
    <div className="card OrderCartDetails Orderfoodcard col-12 ">
      <div className="card-body">
        <div className="">
          <h4 className="textColor card-title">Build your order</h4>

          <div className="row toggleSection">
            <div className=" col-12 p-0 " id="accordionExample">
             
              <div className="form-check form-switch d-flex justify-content-between toggleTittle">
                <p
                  className="form-check-label ordercartTitle"
                  for="flexSwitchCheckDefault"
                >
                  Select Varient
                </p>
                <input
                  className="form-check-input toggleSwitch"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  disabled={variendis}
                  onChange={toggleHandler}
                />
              </div>
              
              <div className={`${toggle ? '  wholeTable' : 'd-none'}`}>
                <table className="table table-borderless orderCart">
                  <thead>
                    <tr>
                      <th className="thTitle"> Select one</th>
                      <th className="dollarCenter ">1 required</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="form-check p-0 ms-4 checkBoxOrderPage">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios1"
                            value={10}
                           
                            checked={halfCheked}
                            onClick={halfDoubleClick}
                            onChange={varientHandler}
                          />
                        
                            <label
                              className="form-check-label "
                              for="exampleRadios1"
                            >
                              Half
                            </label>
                           
                         
                        </div>
                      </td>
                      <td className="dollarCenter">10$</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="checkBoxOrderPage form-check p-0 ms-4">
                          <input
                            className="form-check-input p-0"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios2"
                            value={15}
                            // onChange={(e) => setVarient(e.target.value)}
                            checked={threeCheked}
                            onClick={threeDoubleClick}
                            onChange={varientHandler}
                          />
                          <label
                            className="form-check-label"
                            for="exampleRadios2"
                          >
                            Three quarter
                          </label>
                        </div>
                      </td>
                      <td className="dollarCenter">15$</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="checkBoxOrderPage form-check p-0 ms-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios3"
                            value={20}
                            // onChange={(e) => setVarient(e.target.value)}
                            checked={fullCheked}
                            onClick={fullDoubleClick}
                            onChange={varientHandler}
                          />
                          <label
                            className="form-check-label"
                            for="exampleRadios3"
                          >
                            Full
                          </label>
                        </div>
                      </td>
                      <td className="dollarCenter">20$</td>
                    </tr>
                  </tbody>
                </table>
              </div>
             
              <div className="form-check form-switch d-flex justify-content-between toggleTittle ">
                <p
                  className="form-check-label ordercartTitle "
                  for="flexSwitchCheckDefault"
                >
                  Sauces
                </p>
                <input
                  className="form-check-input toggleSwitch"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  disabled={saucesdis}
                  onClick={saucestoggleHandler}
                />
                
              </div>
              <div
                className={`${saucestoggle ? ' mt-2 wholeTable ' : 'd-none'}`}
              >
                <table className="table table-borderless orderCart">
                  <thead>
                    <tr>
                      <th className="thTitle"> Select any</th>
                    </tr>
                  </thead>
                  <tbody>
                    {visibleSauces.map((sauce,index) => (
                      <tr >
                        {/* <td className="tdTitle"> */}
                          {/* <div className="checkBoxOrderPage form-check p-0 ms-4">
                            <input
                           
                              className="form-check-input "
                              type="checkbox"
                              name="RadioSauce"
                              id="Sauce1"
                              value={sauce.price}
                              checked={sauce === 5}
                              onChange={(e) => setSauce(e.target.value)}
                              onChange={handleCheckboxChange}
                            />
                            
                            <label className="form-check-label " for="Sauce1">
                              {sauce.name}
                            </label>
                            <p>{sauce.price}$</p>
                          </div> */}
                          {/* <div className='d-flex justify-conetnt-between'>  */}
                            {/* <div className='d-flex flex-column'>
                              <h6>{sauce.name}</h6>
                              <p>{sauce.price}</p>
                            </div> */}
                        {/* </td> */}
                        {/* <td className="dollarCenter"> */}
                            {/* <div className='d-flex align-items-center ' style={{gap:'5px'}}>
                              
                                <div onClick={() => { changeQuantity('minus') }} className= " plusCircle" ><p>-</p></div>
                                {sacesQunatity}
                                <div onClick={() => { changeQuantity('plus') }} className=' plusCircle'><p>+</p></div>
                            </div> */}
                           {/* </div>  */}
                        {/* </td> */}
                        <div>
                          <Sauces name={sauce.name} price={sauce.price} amount={sauce.amount} id={index} saucesCallback={saucesCallback}></Sauces>
                        </div>
                      </tr>
                    ))}
                    {/* <tr>
                      <td className="seeMoretd">
                        {visibleSauces.length < sauces.length && (
                          <div className="">
                            <a className=" seemore " onClick={handleShowMore}>
                              Show More
                            </a>
                          </div>
                        )}
                      </td>
                    </tr> */}
                    {/* <tr>
                      <td className="tdTitle">
                        <div className="checkBoxOrderPage form-check p-0 ms-4">
                          <input
                            className="form-check-input p-0"
                            type="checkbox"
                            name="RadioSauce"
                            id="Sauce2"
                            value={15}
                            // onChange={(e) => setSauce(e.target.value)}
                            onChange={handleCheckboxChange}
                          />
                          <label className="form-check-label" for="Sauce2">
                            Chilli sauce
                          </label>
                        </div>
                      </td>
                      <td className="dollarCenter">15$</td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* portion size start */}
          <div className="pt-3 ">
            <strong className="titleColor">Portion size</strong>

            {/* <div className="row"> */}
            <div className="col-12  d-flex justify-content-between">
              <button
                className=" portionButton rounded-2"
                onClick={handleClick}
              >
                For 1
              </button>
              <button
                className="portionButton rounded-2"
                onClick={handleClick2}
              >
                For 2
              </button>

              <button
                className="portionButton rounded-2"
                onClick={handleClick3}
              >
                For 4
              </button>
            </div>
            {/* </div> */}
          </div>
          {/* portion size end */}

          {/* Quantity */}
          <div className="pt-3 ">
            <strong className="ordercartTitle">Quantity</strong>
            <div className="card border-0 ">
              <div className="card-body">
                <div className="row">
                  <div className="col-12  d-flex justify-content-center">
                    <button
                      className=" quantityBtn rounded-2 "
                      onClick={removeQuantity}
                    >
                      -
                    </button>

                    <strong className="pt-3 ml-3">{quantity}</strong>
                    <button
                      className=" quantityBtn  rounded-2 "
                      onClick={addQuantity}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* quantity end */}
          {/* portion size start */}
          {/* <div className="pt-3 ">
            <strong className="titleColor">Portion size</strong>

            <div className="row">
              <div className="col-12  d-flex justify-content-between">
                <button
                  className="btn dsrButton rounded-2"
                  onClick={handleClick}
                >
                  For 1
                </button>
                <button
                  className="btn dsrButton rounded-2"
                  onClick={handleClick2}
                >
                  For 2
                </button>

                <button
                  className="btn dsrButton rounded-2"
                  onClick={handleClick3}
                >
                  For 4
                </button>
              </div>
            </div>
          </div> */}
          {/* portion size end */}

          <div className="pt-3">
            <div className="col-12 mb-3">
              <h5 className=" titleColor">Special instruction</h5>
              <div className="">
                <input
                  type="text"
                  className="form-control inputField"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
              </div>
              <div className="cartButton d-flex justify-content-evenly">
                <button className="  AddTocart rounded-2" type="button">
                  Add for <strong>{total}$</strong>
                </button>

                <Link to="/Offers">
                  <button className=" AddTocart rounded-2" type="button ">
                    Offers
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderCart
