import React from 'react';
import sixthImg from './../Assets/Image/1 54.png'
import ningthImg from './../Assets/Image/Pasta 1.png'
import seventhImg from './../Assets/Image/1 57.png'
import eighthImg from './../Assets/Image/Soup 3.png'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Autoplay, Navigation, EffectCoverflow, Pagination } from 'swiper'
import 'swiper/css/navigation'
import TestThumb from '../TestThumb';
import Tes7 from './Tes7';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';

const Test6 = () => {
    return (
        <div>
            <div className="recommendationDiv">
                <h3 className="brandName">Recommendation</h3>
                <div className="d-flex justify-content-center flex-wrap Recommendation-carason">
                    <Swiper
                        className="p-5"
                        spaceBetween={30}
                        grabCursor={false}
                        centeredSlides={false}
                        slidesPerView={'auto'}
                        hashNavigation={{
                            watchState: true,
                        }}
                        // autoplay={{
                        //     delay: 2000,
                        //     disableOnInteraction: true,
                        // }}
                        breakpoints={{
                            300: {
                                slidesPerView: 1,
                                spaceBetween: 5,
                            },
                            640: {
                                slidesPerView: 3,
                                spaceBetween: 5,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            },
                        }}
                        navigation={true}
                        modules={[Autoplay, Navigation, Pagination]}
                        pagination={{
                            dynamicBullets: true,
                            clickable: true,
                        }}
                    >
                        <SwiperSlide>
                            <div className=' h-100'>
                                <img src={sixthImg} alt="" className="img-fluid w-100 h-100 " />
                                <div className="subscriptionDiv ">
                                    <div className="subscriptionDivDetails ">
                                        <div className=' p-2 d-flex  justify-content-between'>
                                            <div className='d-flex flex-column'>
                                                <h3 >Platinum</h3>
                                                <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                            </div>
                                            <div className='d-flex flex-column '>
                                                {/* <p>Ratings 4.5/5</p> */}
                                                <button className='btn btn-buyNow'>Order Now </button>
                                                <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                    <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>


                        <SwiperSlide>
                            <div className='d-flex h-100 '>
                                <div className='sliderCart'>
                                    <img src={sixthImg} alt="" className="img-fluid sliderCart w-100 h-100" />
                                    <div className="subscriptionDiv ">
                                        <div className="subscriptionDivDetails ">
                                            <div className=' p-2 '>
                                                <div className=''>
                                                    <h3 >Platinum</h3>
                                                    <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                                </div>
                                                <div className=''>
                                                    {/* <p>Ratings 4.5/5</p> */}
                                                    <button className='btn btn-buyNow'>Order Now </button>
                                                    <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                        <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='sliderCart'>
                                    <img src={sixthImg} alt="" className="img-fluid sliderCart w-100 h-100" />
                                    <div className="subscriptionDiv ">
                                        <div className="subscriptionDivDetails ">
                                            <div className=' p-2 '>
                                                <div className=''>
                                                    <h3 >Platinum</h3>
                                                    <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                                </div>
                                                <div className=''>
                                                    {/* <p>Ratings 4.5/5</p> */}
                                                    <button className='btn btn-buyNow'>Order Now </button>
                                                    <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                        <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>


                        <SwiperSlide>
                            <div className='d-flex h-100 '>
                                <div className='sliderCart'>
                                    <img src={sixthImg} alt="" className="img-fluid sliderCart w-100 h-100" />
                                    <div className="subscriptionDiv ">
                                        <div className="subscriptionDivDetails ">
                                            <div className=' p-2 '>
                                                <div className=''>
                                                    <h3 >Platinum</h3>
                                                    <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                                </div>
                                                <div className=''>
                                                    {/* <p>Ratings 4.5/5</p> */}
                                                    <button className='btn btn-buyNow'>Order Now </button>
                                                    <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                        <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='h-100 threecardSIdecartDiv'>
                                    <div className='sliderCart threecardSIdecart mb-1'>
                                        <img src={sixthImg} alt="" className="img-fluid sliderCart h-100 w-100" />
                                        <div className="subscriptionDiv ">
                                            <div className="subscriptionDivDetails ">
                                                <div className=' p-2 '>
                                                    <div className=''>
                                                        <h3 >Platinum</h3>
                                                        <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                                    </div>
                                                    <div className=''>
                                                        {/* <p>Ratings 4.5/5</p> */}
                                                        <button className='btn btn-buyNow'>Order Now </button>
                                                        <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                            <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sliderCart threecardSIdecart mt-1'>
                                        <img src={sixthImg} alt="" className="img-fluid sliderCart w-100 h-100" />
                                        <div className="subscriptionDiv ">
                                            <div className="subscriptionDivDetails ">
                                                <div className=' p-2 '>
                                                    <div className=''>
                                                        <h3 >Platinum</h3>
                                                        <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                                    </div>
                                                    <div className=''>
                                                        {/* <p>Ratings 4.5/5</p> */}
                                                        <button className='btn btn-buyNow'>Order Now </button>
                                                        <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                            <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='h-100'>
                                <div className="thumbslider">
                                    <Carousel autoPlay interval="500" transitionTime="500">
                                        <div className='sliderCart h-100'>
                                            <img src={sixthImg} alt="" className="img-fluid sliderCart w-100 h-100" />
                                            <div className="subscriptionDiv ">
                                                <div className="subscriptionDivDetails ">
                                                    <div className=' p-2 d-flex  justify-content-between'>
                                                        <div className='d-flex flex-column'>
                                                            <h3 >Platinum</h3>
                                                            <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                                        </div>
                                                        <div className='d-flex flex-column '>
                                                            {/* <p>Ratings 4.5/5</p> */}
                                                            <button className='btn btn-buyNow'>Order Now </button>
                                                            <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                                <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='sliderCart'>
                                            <img src={sixthImg} alt="" className="img-fluid sliderCart h-100 w-100" />
                                            <div className="subscriptionDiv ">
                                                <div className="subscriptionDivDetails ">
                                                    <div className=' p-2 d-flex  justify-content-between'>
                                                        <div className='d-flex flex-column'>
                                                            <h3 >Platinum</h3>
                                                            <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                                        </div>
                                                        <div className='d-flex flex-column '>
                                                            {/* <p>Ratings 4.5/5</p> */}
                                                            <button className='btn btn-buyNow'>Order Now </button>
                                                            <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                                <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='sliderCart'>
                                            <img src={sixthImg} alt="" className="img-fluid sliderCart h-100 w-100" />
                                            <div className="subscriptionDiv ">
                                                <div className="subscriptionDivDetails ">
                                                    <div className=' p-2 d-flex  justify-content-between'>
                                                        <div className='d-flex flex-column'>
                                                            <h3 >Platinum</h3>
                                                            <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                                        </div>
                                                        <div className='d-flex flex-column '>
                                                            {/* <p>Ratings 4.5/5</p> */}
                                                            <button className='btn btn-buyNow'>Order Now </button>
                                                            <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                                <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='sliderCart'>
                                            <img src={sixthImg} alt="" className="img-fluid sliderCart w-100 h-100" />
                                            <div className="subscriptionDiv ">
                                                <div className="subscriptionDivDetails ">
                                                    <div className=' p-2 d-flex  justify-content-between'>
                                                        <div className='d-flex flex-column'>
                                                            <h3 >Platinum</h3>
                                                            <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                                        </div>
                                                        <div className='d-flex flex-column '>
                                                            {/* <p>Ratings 4.5/5</p> */}
                                                            <button className='btn btn-buyNow'>Order Now </button>
                                                            <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                                <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Carousel>
                                </div>
                            </div>
                        </SwiperSlide>
                        {/* 
                        <SwiperSlide>
                            <div className="discountImage ">
                                <img src={sixthImg} alt="" className="img-fluid image-Discount" />
                                <div className="discountOfferDiv ">
                                    <div className="discountOfferDivDetails ">
                                        <div className='offer-Text'>
                                            <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                            <h6 style={{ marginBottom: "0px" }}>
                                                <div className='d-flex' style={{ gap: "10px" }}>
                                                    <img src={sixthImg} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                                </div>
                                            </h6>
                                        </div>
                                        <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                            <button className='btn btn-buyNow'>Order Now </button>
                                            <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>


                        <SwiperSlide>
                            <div className='d-flex'>
                                <div className="discountImage ">

                                    <img src={sixthImg} alt="" className="img-fluid image-Discount" />
                                    <div className="discountOfferDiv ">
                                        <div className="discountOfferDivDetails ">
                                            <div className='offer-Text'>
                                                <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                                <h6 style={{ marginBottom: "0px" }}>
                                                    <div className='d-flex' style={{ gap: "10px" }}>
                                                        <img src={sixthImg} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                                    </div>
                                                </h6>
                                            </div>
                                            <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                                <button className='btn btn-buyNow'>Order Now </button>
                                                <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="discountImage ">

                                    <img src={sixthImg} alt="" className="img-fluid image-Discount" />
                                    <div className="discountOfferDiv ">
                                        <div className="discountOfferDivDetails ">
                                            <div className='offer-Text'>
                                                <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                                <h6 style={{ marginBottom: "0px" }}>
                                                    <div className='d-flex' style={{ gap: "10px" }}>
                                                        <img src={sixthImg} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                                    </div>
                                                </h6>
                                            </div>
                                            <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                                <button className='btn btn-buyNow'>Order Now </button>
                                                <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>


                        <SwiperSlide>
                            <div className='d-flex divThreeImg'>
                                <div className="discountImage ">

                                    <img src={sixthImg} alt="" className="img-fluid image-Discount" />
                                    <div className="discountOfferDiv ">
                                        <div className="discountOfferDivDetails ">
                                            <div className='offer-Text'>
                                                <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                                <h6 style={{ marginBottom: "0px" }}>
                                                    <div className='d-flex' style={{ gap: "10px" }}>
                                                        <img src={sixthImg} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                                    </div>
                                                </h6>
                                            </div>
                                            <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                                <button className='btn btn-buyNow'>Order Now </button>
                                                <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='div2Img'>

                                    <div className="discountImage sideDiv">

                                        <img src={sixthImg} alt="" className="img-fluid image-Discount" />
                                        <div className="discountOfferDiv ">
                                            <div className="discountOfferDivDetails ">
                                                <div className='offer-Text'>
                                                    <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                                    <h6 style={{ marginBottom: "0px" }}>
                                                        <div className='d-flex' style={{ gap: "10px" }}>
                                                            <img src={sixthImg} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                                        </div>
                                                    </h6>
                                                </div>
                                                <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                                    <button className='btn btn-buyNow'>Order Now </button>
                                                    <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="discountImage sideDiv mt-3">

                                        <img src={sixthImg} alt="" className="img-fluid image-Discount" />
                                        <div className="discountOfferDiv ">
                                            <div className="discountOfferDivDetails ">
                                                <div className='offer-Text'>
                                                    <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                                    <h6 style={{ marginBottom: "0px" }}>
                                                        <div className='d-flex' style={{ gap: "10px" }}>
                                                            <img src={sixthImg} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                                        </div>
                                                    </h6>
                                                </div>
                                                <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                                    <button className='btn btn-buyNow'>Order Now </button>
                                                    <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>


                        <SwiperSlide>
                            <TestThumb></TestThumb>
                        </SwiperSlide> */}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default Test6;