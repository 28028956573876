const initialState = false

const windowLoad = (state = initialState, action) => {
    switch (action.type) {
        case 'closeWindow':
            return action.payload

        default:
            return state
    }
}
export default windowLoad