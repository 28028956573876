import React, { useState } from 'react';
// import './Test5.css'
import StickyBox from "react-sticky-box";
import { Button, Image, Space } from 'antd';

const Test5 = () => {


    return (
        <Image.PreviewGroup
            preview={{
                onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
            }}
        >
            <Image width={200} src="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg" />
            <Image
                width={200}
                src="https://gw.alipayobjects.com/zos/antfincdn/aPkFc8Sj7n/method-draw-image.svg" />
        </Image.PreviewGroup>
    );
};
export default Test5;