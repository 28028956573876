import React from 'react';
import './Test5.css'

const Test5 = () => {
    // simpleStickySidebar('.sidebar-inner', {
    //     container: '.sidebar',
    //     topSpace: 20,
    //     bottomSpace: 20
    // });
    // simpleStickySidebar('.sidebar-inner', {
    //     container: '.sidebar'
    // });
    return (
        <div>
            <div class="container">
                <header class="main-header">
                    <div class="container"><h3>Simple Sticky Sidebar</h3></div>
                </header>
                <div class="area"><h1>Simple Sticky Sidebar</h1></div>
                <div class="row">
                    <div class="col-lg-3">
                        <div class="sidebar">
                            <div class="sidebar-inner">
                                <div class="text">Sidebar Content</div>
                                <div class="text">Sidebar Content</div>
                                <div class="text">Sidebar Content</div>
                                <div class="text">Sidebar Content</div>
                                <div class="text">Sidebar Content</div>
                                <div class="text">Sidebar Content</div>
                                <div class="text">Sidebar Content</div>
                                <div class="text">Sidebar Content</div>
                                <div class="text">Sidebar Content</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div class="search-content">
                            <div class="content">
                                <div class="text">content</div>
                                <div class="text">content</div>
                                <div class="text">content</div>
                                <div class="text">content</div>
                                <div class="text">content</div>
                                <div class="text">content</div>
                                <div class="text">content</div>
                                <div class="text">content</div>
                                <div class="text">content</div>
                                <div class="text">content</div>
                                <div class="text">content</div>
                                <div class="text">content</div>
                                <div class="text">content</div>
                                <div class="text">content</div>
                                <div class="text">content</div>
                                <div class="text">content</div>
                                <div class="text">content</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Test5;

// function setStyle(element, cssProperty) {
//     for (var property in cssProperty) {
//         element.style[property] = cssProperty[property];
//     }
// }

// function destroySticky(element) {
//     setStyle(element, {
//         top: '',
//         left: '',
//         bottom: '',
//         width: '',
//         position: ''
//     });
// }

// function getOffset(el) {
//     el = el.getBoundingClientRect();
//     return {
//         left: el.left + window.scrollX,
//         top: el.top + window.scrollY
//     }
// }

// function simpleStickySidebar(element, options) {

//     // Global options
//     var sticky = document.querySelector(element); // Sticky sidebar
//     var container = document.querySelector(options.container); // Sticky sidebar container
//     var topSpace = options.topSpace ? options.topSpace : 0; // Top spacing after sticky
//     var bottomSpace = options.bottomSpace ? options.bottomSpace : 0; // Bottom spacing after sticky

//     // vars
//     var $window = window; // window
//     var stickyHeight = sticky.getBoundingClientRect().height; // Sticky sidebar height
//     var stickyOffsetTop = getOffset(sticky).top; // Sticky sidebar top offset
//     var stickyOffsetBottom = getOffset(sticky).top + sticky.getBoundingClientRect().height; // Sticky sidebar bottom offset
//     var stickyOffsetLeft = getOffset(sticky).left; // Sticky sidebar left offset
//     var topFixed = false; // checkpoint
//     var bottomFixed = false; // checkpoint
//     var lastScrollVal = 0; // checkpoint

//     var getStickyHeight = function () {
//         return document.querySelector(element).getBoundingClientRect().height;
//     };

//     // scrolling
//     window.addEventListener('scroll', function (event) {
//         var scrollTop = window.scrollY;
//         // when scroll position touch the "Sidebar"
//         if (scrollTop > stickyOffsetTop - topSpace) {
//             // if "Sidebar" smaller than viewport
//             if (getStickyHeight() <= $window.innerHeight - topSpace) {
//                 // fix "Sidebar" from top
//                 setStyle(sticky, {
//                     top: topSpace + "px",
//                     left: stickyOffsetLeft + "px",
//                     bottom: '',
//                     width: sticky.getBoundingClientRect().width + "px",
//                     position: 'fixed'
//                 });
//             }
//             else {
//                 // scrolling down
//                 if (scrollTop > lastScrollVal) {
//                     // if "Sidebar" fixed from top during scrolling down
//                     if (topFixed) {
//                         // get new offset of "Sidebar"
//                         var absoluteStickyOffsetTop = getOffset(sticky).top;

//                         setStyle(sticky, {
//                             top: absoluteStickyOffsetTop - getOffset(container).top + "px",
//                             left: '',
//                             bottom: '',
//                             width: '',
//                             position: 'absolute'
//                         });
//                         topFixed = false;
//                     }
//                     // make "Sidebar" fixed from bottom when bottom area visible in viewport
//                     if (scrollTop > stickyOffsetBottom - $window.innerHeight) {
//                         setStyle(sticky, {
//                             top: '',
//                             left: stickyOffsetLeft + "px",
//                             bottom: bottomSpace + "px",
//                             width: sticky.getBoundingClientRect().width + "px",
//                             position: 'fixed'
//                         });
//                         bottomFixed = true;
//                     }
//                 } else { // scrolling up
//                     // get new offset of "Sidebar" during scrolling up
//                     var absoluteStickyOffsetTop = getOffset(sticky).top;
//                     //  if "Sidebar" fixed from bottom, stop sticky to its position
//                     if (bottomFixed) {
//                         setStyle(sticky, {
//                             top: absoluteStickyOffsetTop - getOffset(container).top + "px",
//                             left: '',
//                             bottom: '',
//                             width: '',
//                             position: 'absolute'
//                         });
//                         bottomFixed = false;
//                     }
//                     // make "Sidebar" fixed from top when top area visible in viewport
//                     if (scrollTop < absoluteStickyOffsetTop - topSpace) {
//                         setStyle(sticky, {
//                             top: topSpace + "px",
//                             left: stickyOffsetLeft + "px",
//                             bottom: '',
//                             width: sticky.getBoundingClientRect().width + "px",
//                             position: 'fixed'
//                         });
//                         topFixed = true;
//                     }
//                 }
//                 lastScrollVal = scrollTop;
//             }
//         } else {
//             // make sidebar to default position
//             destroySticky(sticky);
//         }
//     });
// }