import React, { useEffect, useRef, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { useSelector, useDispatch } from 'react-redux'
import { commentImgClick } from '../../../actions'
import './RestaurantDetailView.css'
import PostDisplay from './../../PostDisplay/PostDisplay'
import starImg from './../../../Assets/Image/Star 14.png'
import GalleryView from '../../GalleryView/GalleryView'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Autoplay, Navigation, EffectCoverflow, Pagination } from 'swiper'
import 'swiper/css/navigation'
import ningthImg from './../../../Assets/Image/Pasta 1.png'
import sixthImg from './../../../Assets/Image/1 54.png'
import seventhImg from './../../../Assets/Image/1 57.png'
import eighthImg from './../../../Assets/Image/Soup 3.png'
import chickenImg from './../../../Assets/Image/1 (1).jpg'
import ManuSelection from '../MenuSelection/MenuSelection'
// import { ReactPhotoCollage } from 'react-photo-collage'
import pkbImg from './../../../Assets/Image/PKB.png'
import gridImg from './../../../Assets/Icons/visualization.png'
import listImg from './../../../Assets/Icons/list.png'
import viewMenu from './../../../Assets/Icons/Viewmenu.png'
import removeIcon from './../../../Assets/Icons/remove.png'
import closeIcon from './../../../Assets/Icons/removeBlack.png'
import 'react-image-gallery/styles/css/image-gallery.css'
import ShareYourthoughts from './../ShareYourThoughts/ShareYourThoughts'
import chat from './../../../Assets/Icons/chat.png'
import AllPosts from './../../PostDisplay/AllPostDisplay/AllPostDisplay'
import SearchBar from '../../SearchBar/SearchBar'
import product2 from './../../../Assets/Image/product (2).jpg'
import product3 from './../../../Assets/Image/product (3).jpg'
import product4 from './../../../Assets/Image/product (4).jpg'
import locationPin from './../../../Assets/Icons/location-pin.png'
import clock from './../../../Assets/Icons/clock.png'

import plusIcon from './../../../Assets/Icons/plus.png'
import burger from './../../../Assets/Image/Burger22.jpeg'

import bannerImg from './../../../Assets/Image/banner.jpg'
import airBnbImage from './../../../Assets/Image/airbnb.webp'
import DiscountOffer from '../DiscountOffer/DiscountOffer'

import featured from './../../../Assets/Image/feature.png'
import post from './../../../Assets/Image/social-media.png'
import galleryIcon from './../../../Assets/Icons/image-gallery.png'
import SharePostDisplay from '../../PostDisplay/SharePostDisplay/SharePostDisplay'
import Recommendation from '../Recommendation/Recommendation'
import SubscriptionOffer from '../subscriptionoffer/SubscriptionOffer'

const RestaurantDetailView = ({ test3Ref }) => {
  const comment = useSelector((state) => state.commentText)
  const dispatch = useDispatch()
  const commentimageClicked = useSelector((state) => state.CommentImgShow)
  const [isShowMore, setIsShowMore] = useState(false)
  const [viewSelector, setViewSelector] = useState(1)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [showMore, setShowMore] = useState(false)
  const [date, setDate] = useState(new Date())
  const [isOpen, setIsOpen] = useState(false)
  const [follower, setFollower] = useState(1.1)
  const [following, setFollowing] = useState(586)

  const address = "Barton Arcade, Manchester M3 2BW, United Kingdom";


  useEffect(() => {
    if (date.getHours() > 9 && date.getHours() < 21) {
      setIsOpen(true)
    }
    else {
      setIsOpen(false)
    }
  })

  const imagesObject2 = [
    {
      original: product2,
      thumbnail: product2,
    },
    {
      original: product3,
      thumbnail: product3,
    },
    {
      original: product4,
      thumbnail: product4,
    },
    {
      original: product4,
      thumbnail: product4,
    },
  ]

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore)
  }

  const imagesObject = [
    { image: airBnbImage },
    // { image: seventhImg },
    // { image: eighthImg },
    // { image: ningthImg },
    // { image: sixthImg },
  ]
  // console.log(Object.keys(imagesObject).length);
  // const [arrayCount, setArrayCount] = useState(Object.keys(imagesObject).length);
  // const setting = {
  //   width: '800px',
  //   height: ['20rem', '170px'],
  //   layout: [1, 3],
  const photos = [
    {
      original: 'https://picsum.photos/id/1015/1000/600/',
      thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1019/1000/600/',
      thumbnail: 'https://picsum.photos/id/1019/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1015/1000/600/',
      thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1019/1000/600/',
      thumbnail: 'https://picsum.photos/id/1019/250/150/',
    },
  ]
  // showNumOfRemainingPhotos: true

  const showModalInfo = () => {
    window.$('#shareYourThoughtsModal').modal('show')
  }
  const hideModalInfo = () => {
    window.$('#shareYourThoughtsModal').modal('hide')
    if (comment !== '') {
      confirmAlert({
        title: 'Do you realy want to leave this page?',
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              window.$('#shareYourThoughtsModal').modal('hide')
            },
          },
          {
            label: 'No',
            onClick: () => {
              window.$('#shareYourThoughtsModal').modal('show')
            },
          },
        ],
      })
    } else {
      window.$('#shareYourThoughtsModal').modal('hide')
    }
  }
  // Scroll to the Test3 component
  const ScrollToMenu = () => {

    test3Ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const longText =
    "Details: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.";
  const maxLength = 200;
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const displayText = isExpanded ? longText : longText.slice(0, maxLength);

  const myRef = useRef();
  const filterBottomRef = useRef(null);
  const [intersecting, setIntersecting] = useState(false);
  const [scrollValue, setscrollValue] = useState(0);
  const [slideUp, setSlideUp] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      if (filterBottomRef.current !== undefined) {
        const filterBarRect = filterBottomRef.current.getBoundingClientRect();
        const isAboveViewport = filterBarRect.top < 0 ? true : filterBarRect.top === 0 ? false : false;
        setIntersecting(isAboveViewport);
        var top = (filterBarRect.top * (-1));
        var scrollval = (scrollValue * (-1));
        if (top > scrollval) {
          setSlideUp(true);
        }
        else {
          setSlideUp(false);
        }
        var s = filterBarRect.top;
        setscrollValue(s);
      }

    })

  });




  return (
    <div className="customContainer ">
      <div className={`d-flex justify-content-center  ${intersecting ? slideUp ? "d-none" : "d-block" : "d-none"}`} ref={myRef}>
        <div className={`d-flex justify-content-center  viewMenuMessage
           ${intersecting ? slideUp ? "" : "slide-up-nav" : "slide-up"}`}

        >
          <div className="buttonDiv">
            <button className="btn" onClick={ScrollToMenu}>
              <img src={viewMenu} alt='viewMenu' style={{ width: '14px' }} />
              <span> View Menu</span>
            </button>
          </div>
          <div className="buttonDiv">
            <button className="btn ">
              <img src={chat} alt='viewMenu' style={{ width: '18px' }} />
              <span> Message</span>
            </button>
          </div>
        </div>
      </div>
      <div
        class="modal fade "
        id="shareYourThoughtsModal"
        // tabindex="-1"
        // role="dialog"
        // aria-labelledby="exampleModalCenterTitle"
        // aria-hidden="true"

        data-bs-backdrop="static"
      >
        <div
          class="modal-dialog  modal-dialog-centered galleryModal"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header d-flex justify-content-between loginModalCloseBtn">
              <span></span>
              <span className="createPostModal">Create post</span>
              <button type="button " class={'close '} onClick={hideModalInfo}>
                <span aria-hidden="true">
                  <img src={removeIcon} style={{ height: '30px' }} alt="" />
                </span>
              </button>
            </div>
            <div class="modal-body">
              {/* <Login loaded="false" /> */}
              {/* <GalleryModal selectedImage={selectedImage} /> */}
              <ShareYourthoughts />
            </div>
          </div>
        </div>
      </div>
      <div className="card RestaurantDetailCard">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div className='d-flex flex-row'>
              <h3 className="brandName">
                Pot Kettle Black
                {/* <button className='followBtn-ResturantPage followBtnDesktop' style={{ fontSize: '12px' }}> Follow</button> */}

              </h3>
              <div className='d-flex flex-row followBtnDesktop' style={{ marginTop: '10px', marginLeft: '0.5rem', fontSize: '0.8rem' }}>
                <p className='d-flex align-items-center' style={{ marginLeft: '5px', marginTop: '2px' }}><span style={{ marginRight: '8px' }}>{follower}M followers </span><span>{following}k following</span></p>
                <button className='followBtn-ResturantPage followBtnDesktop' style={{ fontSize: '12px' }}> Follow</button>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <img src={starImg} alt="" className="starImage" />
              <h3 className="ratingNumber">4.5</h3>
            </div>
          </div>
          <div className='follower d-flex flex-row justify-content-between mobileViewForFollow'>
            {/* <p className='d-flex align-items-center'>{follower}M followers <hr className='followHr bg-dark opacity-100 my-0 mx-2'/> {following}k following</p> */}
            <p className='d-flex align-items-center '><span className='mobileDisplay' style={{ marginRight: '8px' }}>{follower}M followers </span><span className='mobileDisplay'>{following}k following</span></p>
            <button className='followBtn-ResturantPage mobileDisplay' style={{ fontSize: '12px' }}> Follow</button>
          </div>
          <div className="d-flex ResturentCategory">
            <h6>Burgers | </h6>
            <h6>&nbsp; Pizza | </h6>
            <h6>&nbsp; Deserts</h6>
          </div>
          <div className="d-flex location">
            <div>
              <img src={locationPin} alt="" style={{ width: "1rem" }} />
            </div>
            {screenWidth < 688 ? (
              <p className=" " onClick={() => setShowMore(!showMore)}>
                {showMore
                  ? address
                  : `${address.split(/\s+/).slice(0, 2).join(' ')}`}
                <a
                  className="text-decoration-none ms-1"
                  // onClick={() => setShowMore(!showMore)}
                  style={{
                    color: '#3D1F1B',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }}
                >
                  {showMore ? '' : '...'}
                </a>
              </p>
            ) : <p> Barton Arcade, Manchester M3 2BW, United Kingdom</p>}
            {/* <p> Barton Arcade, Manchester M3 2BW, United Kingdom</p> */}
          </div>
          <div className='d-flex align-items-center opening'>
            <div className='d-flex align-items-center opening-hour'>
              <div>
                <img src={clock} alt="" style={{ width: "1rem" }} />
              </div>
              <div className='operningHourText'>
                <p style={{ marginBottom: "0px" }}> <span className='desktopDisplay-Opening'>Opening hour :</span>
                  {/* {window.innerWidth < 688 ? (<br />) : (<b></b>)} */}
                  <b> 9:00 AM-9:00 PM</b>
                </p>
              </div>
            </div>

            <div>
              <span className={`${isOpen ? "open-close open" : "d-none"} `}>Open</span>
              <span className={`${isOpen ? "d-none" : "open-close close"} `}>Closed</span>
            </div>
          </div>
          <div className="d-flex ResturentDetails">
            <p onClick={toggleExpand}>
              {/* Details: Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unkown printer took a
              galley of type */}
              {displayText}
              {!isExpanded && longText.length > maxLength && '...'}
            </p>
          </div>
          <div className="d-flex justify-content-end mobileMarginTop">
            <div className="buttonDiv">
              <button className="btn" onClick={ScrollToMenu}>
                <img src={viewMenu} alt='viewMenu' style={{ width: '14px' }} />
                <span> View Menu</span>
              </button>
            </div>
            <div className="buttonDiv">
              <button className="btn ">
                <img src={chat} alt='viewMenu' style={{ width: '18px' }} />
                <span> Message</span>
              </button>
            </div>
          </div>

          <div ref={filterBottomRef}>

          </div>
        </div>
      </div>
      <div className=" d-flex justify-content-center align-items-center shareYourthoughtsDiv">
        <img
          src={pkbImg}
          alt=""
          className="rounded-img"
          style={{ width: '43px' }}
        />
        <div className="">
          <button
            className="btn-custom shareYourthoughts resturentPageWhatsOnYourMindBtn"
            onClick={() => {
              showModalInfo()
            }}
          >
            What’s on your mind?
          </button>
        </div>
      </div>
      {/* featured */}
      <div className="d-flex justify-content-around mb-3 mt-3 viewSelector FeatureDivCategory mobileDisplay-featured">
        <img
          src={gridImg}
          alt=""
          className={`${viewSelector === 1 ? '' : 'selectedCategoryFeature'}`}
          onClick={() => {
            setViewSelector(1)
          }}
        />
        <img
          src={post}
          alt=""
          className={`${viewSelector === 2 ? '' : 'selectedCategoryFeature'}`}
          onClick={() => {
            setViewSelector(2)
          }}
        />
        <img
          src={galleryIcon}
          alt=""
          className={`${viewSelector === 3 ? '' : 'selectedCategoryFeature'}`}
          onClick={() => {
            setViewSelector(3)
          }}
        />

      </div>

      {/* grid */}
      <div
        className={`row row-gallery-postview ${viewSelector === 1 ? '' : 'd-none'
          }`}
      >
        <div className='d-flex justify-content-around CategoryFeatureDiv desktopDisplay'>
          <div className='d-flex align-items-center FeatureDiv mb-2'>
            <div>
              <img src={featured} alt="" className='FeaturedImg' />
            </div>
            <div>
              <h6 className='FeaturedText'>Featured</h6>
            </div>

          </div>
          <div></div>
        </div>
        <div className="col-12 col-md-6 padding-zero">
          <GalleryView></GalleryView>
        </div>
        <div className="col-12 col-md-6 ">
          <div className="row">
            <div className="col-12">
              {/* <br /> */}
              <PostDisplay></PostDisplay>
              <br className='desktopDisplay' />
              <div className='desktopDisplay'>
                <SharePostDisplay></SharePostDisplay>
              </div>

              <a href="allpost" className="allPostATag mt-2">
                See More
              </a>
            </div>
          </div>
        </div>
        {/* <div className="col-12 mobileDisplay">
          <PostDisplay></PostDisplay>
        </div> */}
        <div className="col-12"></div>
      </div>
      <div
        className={`row row-gallery-postview  ${viewSelector === 2 ? '' : 'd-none'
          }`}
      >
        <div className="col-12 ">
          <PostDisplay></PostDisplay>
          <br />
          <SharePostDisplay></SharePostDisplay>
          <a href="allpost" className="allPostATag">
            See More
          </a>
        </div>

      </div>
      <div className={`row row-gallery-postview ${viewSelector === 3 ? '' : 'd-none'}`}>
        <div className='d-flex justify-content-around CategoryFeatureDiv desktopDisplay'>
          <div className='d-flex align-items-center FeatureDiv mb-2'>
            <div>
              <img src={featured} alt="" className='FeaturedImg' />
            </div>
            <div>
              <h6 className='FeaturedText'>Featured</h6>
            </div>

          </div>
          <div></div>
        </div>
        <div className="d-flex flex-wrap" style={{ gap: '1rem', justifyContent: 'center', padding: "0" }}>
          <div className="card albumCard">
            <a href="" className="card-link">
              <hr className='albumHr2' />
              <hr className='albumHr1' />
              <div className="aspect-ratio-container">
                <img src={product2} className="card-img-top albumnCardImg" alt="Album Image" />
              </div>
            </a>
            <div className="card-body albumnCardBody">
              <p className="card-title" style={{ fontSize: '12px' }}>Mobile Uploads</p>
              <p className="card-text" style={{ fontSize: '10px' }}>25 items</p>
            </div>
          </div>
          <div className="card albumCard">
            <a href="" className="card-link">
              <hr className='albumHr2' />
              <hr className='albumHr1' />
              <div className="aspect-ratio-container">
                <img src={product3} className="card-img-top albumnCardImg" alt="Album Image" />
              </div>
            </a>
            <div className="card-body albumnCardBody">
              <p className="card-title" style={{ fontSize: '12px' }}>Timeline Photos</p>
              <p className="card-text" style={{ fontSize: '10px' }}>15 items</p>
            </div>
          </div>
          <div className="card albumCard">
            <a href="" className="card-link">
              <hr className='albumHr2' />
              <hr className='albumHr1' />
              <div className="aspect-ratio-container">
                <img src={product4} className="card-img-top albumnCardImg" alt="Album Image" />
              </div>
            </a>
            <div className="card-body albumnCardBody">
              <p className="card-title" style={{ fontSize: '12px' }}>Profile Pictures</p>
              <p className="card-text" style={{ fontSize: '10px' }}>10 items</p>
            </div>
          </div>
          <div className="card albumCard">
            <a href="" className="card-link">
              <hr className='albumHr2' />
              <hr className='albumHr1' />
              <div className="aspect-ratio-container">
                <img src={chickenImg} className="card-img-top albumnCardImg" alt="Album Image" />
              </div>
            </a>
            <div className="card-body albumnCardBody">
              <p className="card-title" style={{ fontSize: '12px' }}>Chicken Album</p>
              <p className="card-text" style={{ fontSize: '10px' }}>5 items</p>
            </div>
          </div>
        </div>


      </div>
      <div className='pt-4 subscribtionContainer' >
        <SubscriptionOffer />
      </div>

      <div className="discountDiv">
        <h3 className="brandName">Discount Offer </h3>


        <DiscountOffer from={2} />


      </div>
      <div className="recommendationDiv">
        <h3 className="brandName">Recommendation</h3>
        <div className="">
          <Recommendation from={2} />
        </div>
      </div>
      <div className='mt-4'>
        <ManuSelection />
      </div>

      {/* comment image show */}
      {
        commentimageClicked && (
          <div className="image-gallery-container ">
            <button
              className="commentImgShowButton"
              onClick={() => dispatch(commentImgClick())}
            >
              <img src={removeIcon} alt="Close" />
            </button>
            <div className="commentImg">
              <ImageGallery
                items={imagesObject2}
                // startIndex={selectedImage}
                showFullscreenButton={false}
                showPlayButton={false}
              />
            </div>
          </div>
        )
      }
    </div >
  )
}

export default RestaurantDetailView