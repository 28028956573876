import React ,{useState, useEffect} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Autoplay, Navigation, EffectCoverflow, Pagination } from 'swiper'
import 'swiper/css/navigation'
import store from './../../../Assets/Icons/shop.png'
import subscription from './../../../Assets/Icons/notification.png'
import Corporate from './../../../Assets/Icons/corporate.png'
import './MenuSlider.css'

//react slick start
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

//react slick end

function MenuSlider(props) {

    //swiper start
const [fromHome, SetFromHome] = useState(true);
useEffect(() => {
    console.log(props);
    if (props.from === 2) {
        SetFromHome(false);
    }
})
//swiper end

//slick start
const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "10%",
    slidesToShow: 2,
    speed: 500
  };

//slick end
  return (
    <div className='menuslider'>
          <div >
            
                <Swiper
                // spaceBetween={30}
                // grabCursor={false}
                // centeredSlides={false}
                // slidesPerView={'auto'}
                // hashNavigation={{
                //     watchState: true,
                // }}
                
                // breakpoints={{
                //     300: {
                //         slidesPerView: 2,
                //         spaceBetween: 5,
                //     },
                //     640: {
                //         slidesPerView: 2,
                //         spaceBetween: 5,
                //     },
                //     768: {
                //         slidesPerView: 3,
                //         spaceBetween: 10,
                //     },
                // }}
                // navigation={true}
                // modules={[Autoplay, Navigation, Pagination]}
                // pagination={{
                //     dynamicBullets: true,
                //     clickable: true,
                // }}
                className='menusliderDiv'
                spaceBetween={30}
                grabCursor={false}
                centeredSlides={false}
                slidesPerView={"auto"}
                hashNavigation={{
                    watchState: true,
                }}
                // autoplay={{
                //     delay: 2200,
                //     disableOnInteraction: true,
                // }}
                breakpoints={{
                    300: {
                        slidesPerView: 2,
                        spaceBetween: 5,

                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 5,

                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    1024: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    1200: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    1400: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                }}

            >

     <SwiperSlide>
                            
            <div
                    className="d-flex cardShadow cardMargin align-items-center justify-content-around  pb-1  selectionSingleButton"
                    value={'store menue'}
                    
                    >
                    <h4>In Store Menu</h4>
                    <img
                        className="menu-selection-icon "
                        src={store}
                        alt="..."
                    />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                            <div
                    className="d-flex cardShadow cardMargin align-items-center justify-content-around  pb-1  selectionSingleButton"
                    value={'store menue'}
                    
                    >
                    <h4>Corporate Menu</h4>
                    <img
                        className="menu-selection-icon "
                        src={Corporate}
                        alt="..."
                    />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                            <div
                    className="d-flex cardShadow cardMargin align-items-center justify-content-around  pb-1  selectionSingleButton"
                    value={'store menue'}
                   
                    >
                    <h4>Subscription</h4>
                    <img
                        className="menu-selection-icon "
                        src={subscription}
                        alt="..."
                    />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                            <div
                    className="d-flex cardShadow cardMargin align-items-center justify-content-around  pb-1  selectionSingleButton"
                    value={'store menue'}
                    
                    >
                    <h4>In Store Menu</h4>
                    <img
                        className="menu-selection-icon "
                        src={store}
                        alt="..."
                    />
                    </div>
                </SwiperSlide>
                </Swiper>
                </div>

                {/* slick start */}
                {/* <Slider {...settings}>
                    <div>
                            <div
                            className="d-flex cardShadow cardMargin  align-items-center justify-content-around  pb-1  selectionSingleButton"
                            value={'store menue'}
                            
                            >
                            <h4>In Store Menu</h4>
                            <img
                                className="menu-selection-icon menuSelectionImage"
                                src={store}
                                alt="..."
                            />
                            </div>
                    </div>
                    <div>
                            <div
                                className="d-flex cardShadow cardMargin align-items-center justify-content-around  pb-1  selectionSingleButton"
                                value={'store menue'}
                                
                                >
                                <h4>Corporate Menu</h4>
                                <img
                                    className="menu-selection-icon menuSelectionImage"
                                    src={Corporate}
                                    alt="..."
                                />
                            </div>
                    </div>
                    <div>
                        <div
                            className="d-flex cardShadow cardMargin align-items-center justify-content-around  pb-1  selectionSingleButton"
                            value={'store menue'}
                        
                            >
                            <h4>Subscription</h4>
                            <img
                                className="menu-selection-icon menuSelectionImage"
                                src={subscription}
                                alt="..."
                            />
                        </div>
                    </div>
                    <div>
                        <div
                            className="d-flex cardShadow cardMargin align-items-center justify-content-around  pb-1  selectionSingleButton"
                            value={'store menue'}
                            
                            >
                            <h4>In Store Menu</h4>
                            <img
                                className="menu-selection-icon menuSelectionImage"
                                src={store}
                                alt="..."
                            />
                            </div>
                    </div>
                    <div>
                        <div
                            className="d-flex cardShadow cardMargin align-items-center justify-content-around  pb-1  selectionSingleButton"
                            value={'store menue'}
                            
                            >
                            <h4>In Store Menu</h4>
                            <img
                                className="menu-selection-icon menuSelectionImage"
                                src={store}
                                alt="..."
                            />
                            </div>
                    </div>
                    <div>
                       <div
                            className="d-flex cardShadow cardMargin align-items-center justify-content-around  pb-1  selectionSingleButton"
                            value={'store menue'}
                            
                            >
                            <h4>In Store Menu</h4>
                            <img
                                className="menu-selection-icon menuSelectionImage"
                                src={store}
                                alt="..."
                            />
                            </div>
                    </div>
                </Slider> */}
                
           
    </div>
  )
}

export default MenuSlider