import React from 'react';
import pkblogo from './../../Assets/Image/LogoTransparentBrown.png'
import androidButton from './../../Assets/Image/androidDownload.png'
import iosButton from './../../Assets/Image/iosDownload.png'
import Mastercard from './../../Assets/Icons/card.png'
import visaCard from './../../Assets/Icons/visa.png'
import amexCard from './../../Assets/Icons/Amex.png'
import TermsAndConditions from './../../Assets/Image/TermsAndConditions.pdf'
import './sidebarlogin.css'
const SidebarLogin = (props) => {

    const callLoginModal = () => {
        props.loginClick()
    }

    return (
        <div className='d-flex'>
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                {/* <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div> */}
                <div class="offcanvas-body offsetSignup">
                    <div className='d-flex justify-content-start align-items-start' style={{ flexDirection: "column" }}>
                        {/* <div className="btn w-100 SignUp my-1">Sign Up</div> */}
                        {/* <div className="btn w-100 login my-1" onClick={() => { callLoginModal() }}>login</div> */}
                        {/* <p className='btn p-0'>Create a business account</p> */}
                        <p className='btn p-0' onClick={() => { callLoginModal() }}>I am a User</p>
                        <p className='btn p-0' onClick={() => { window.location.href = "/register" }}>Add your restaurant</p>
                        <p className='btn p-0' onClick={() => { window.location.href = "http://localhost:3001/loginforcourier" }}>Sign up to deliver</p>
                    </div>
                    <div className='bottom mb-4'>
                        <div>

                            <div className='d-flex p-3 justify-content-center align-items-center' style={{ gap: "10px" }}>
                                <div>
                                    <img src={pkblogo} className="d-flex mx-auto logoSideBar" alt="" />
                                </div>
                                <p className='p-0 m-0'><b>There's more to love in the app.</b></p>
                            </div>
                            <div className='d-flex align-items-center justify-content-center' style={{ gap: "10px" }}>
                                <img src={Mastercard} alt="" style={{ width: "2rem" }} />
                                <img src={visaCard} alt="" style={{ width: "2rem" }} />
                                <img src={amexCard} alt="" style={{ width: "2rem" }} />
                            </div>
                            <div className='d-flex'>
                                <button className='btn btn-light  w-50 m-1'><img src={androidButton} className='img-fluid' alt="" /></button>
                                <button className='btn btn-light  w-50 m-1'><img src={iosButton} className='img-fluid' alt="" /></button>

                            </div>
                            <div className='d-flex flex-column pb-mb-3 justify-content-center align-items-center' style={{ gap: "10px" }}>
                                <a href="/termsandconditions" style={{ textDecoration: "none" }}>Terms and conditions</a>
                                <a href="/privacy" style={{ textDecoration: 'none' }}>Privacy</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default SidebarLogin;