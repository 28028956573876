import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import BannerSection from './../Home/BannerSection/BannerSection'
import FilterBar from '../Restaurant/MenuSelection/FilterBar/FilterBar';
import Test3 from '../Test3';


import starImg from './../../Assets/Image/Star 14.png'
import viewMenu from './../../Assets/Icons/Viewmenu.png'
import 'react-image-gallery/styles/css/image-gallery.css'

import chat from './../../Assets/Icons/chat.png'

import locationPin from './../../Assets/Icons/location-pin.png'
import clock from './../../Assets/Icons/clock.png'
import MenuSelection from './../Restaurant/MenuSelection/MenuSelection'
import search from './../../Assets/Icons/searchIcon.png'
import grid from './../../Assets/Icons/app.png'
import close from './../../Assets/Icons/close.png'
import BurgerOffer from './../../Assets/Image/burgerOffer.jpg'
import './SearchPage.css'
import Navbar from '../Navbar/Navbar';
import SearchSlider from './SearchSlider/SearchSlider';
import MenuSlider from '../GalleryView/menuslider/MenuSlider';



function SearchPage() {
    const test3Ref = useRef(null);
   
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [showMore, setShowMore] = useState(false)
    const [date, setDate] = useState(new Date())
    const [isOpen, setIsOpen] = useState(false)
    const [follower, setFollower] = useState(1.1)
    const [following, setFollowing] = useState(586)
  
    const address = "Barton Arcade, Manchester M3 2BW, United Kingdom";


    const ScrollToMenu = () => {

        test3Ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      };

      //search start
const [isVisisbleDiv, setIsvisibleDiv]=useState(false)
const [isClick, setIsClick]=useState(false)
const [dialogData, setDialogData]=useState('')
const [searchPlaceholder, setSearchPlaceholder] = useState('Search')



const handleSearch=(event)=>{
  var data=event.target.value
  if(data!=''){
   setIsvisibleDiv(true)
  }
   else{
     setIsvisibleDiv(false)
     
   }
   
 
   setDialogData(data)
 }
 const handleIsClick=()=>{
  //  setIsClick(!isClick)
  setIsvisibleDiv(false)
  
    setDialogData('')
 
 }


 const searchDialogdivRef = useRef(null);

 const handleClickOutside = (event) => {
   if (searchDialogdivRef.current && !searchDialogdivRef.current.contains(event.target)) {
     setIsvisibleDiv(false);
   }
 };

//search end
const [All, setAll]=useState(false)
const [Offer, setOffer]=useState(false)
const handleSearchSelect=(data)=>{
    if(data=='All'){
        setAll(true)
    }
    else if(data=='Offer'){
        setOffer(true)
    } 
}
const onClose=(data)=>{
   if(data=='All')
   {
    setAll(false)
   }
   else if(data=='Offer')
   {
    setOffer(false)
   }
}
  return (
    <div >
        
         <BannerSection></BannerSection>




<div className='customContainer'>
    <div className="card RestaurantDetailCard ">
            <div className="card-body">
            <div className="d-flex justify-content-between">
                <div className='d-flex flex-row'>
                <h3 className="brandName">
                    Pot Kettle Black
                    {/* <button className='followBtn-ResturantPage followBtnDesktop' style={{ fontSize: '12px' }}> Follow</button> */}

                </h3>
                <div className='d-flex flex-row followBtnDesktop' style={{ marginTop: '10px', marginLeft: '0.5rem', fontSize: '0.8rem' }}>
                    <p className='d-flex align-items-center' style={{ marginLeft: '5px', marginTop: '2px' }}><span style={{ marginRight: '8px' }}>{follower}M followers </span><span>{following}k following</span></p>
                    <button className='followBtn-ResturantPage followBtnDesktop' style={{ fontSize: '12px' }}> Follow</button>
                </div>
                </div>
                <div className="d-flex justify-content-between">
                <img src={starImg} alt="" className="starImage" />
                <h3 className="ratingNumber">4.5</h3>
                </div>
            </div>
            <div className='follower d-flex flex-row justify-content-between mobileViewForFollow'>
                {/* <p className='d-flex align-items-center'>{follower}M followers <hr className='followHr bg-dark opacity-100 my-0 mx-2'/> {following}k following</p> */}
                <p className='d-flex align-items-center '><span className='mobileDisplay' style={{ marginRight: '8px' }}>{follower}M followers </span><span className='mobileDisplay'>{following}k following</span></p>
                <button className='followBtn-ResturantPage mobileDisplay' style={{ fontSize: '12px' }}> Follow</button>
            </div>
            <div className="d-flex ResturentCategory">
                <h6>Burgers | </h6>
                <h6>&nbsp; Pizza | </h6>
                <h6>&nbsp; Deserts</h6>
            </div>
            <div className="d-flex location">
                <div>
                <img src={locationPin} alt="" style={{ width: "1rem" }} />
                </div>
                {screenWidth < 688 ? (
                <p className=" ">
                    {showMore
                    ? address
                    : `${address.split(/\s+/).slice(0, 2).join(' ')}`}
                    <a
                    className="text-decoration-none ms-1"
                    onClick={() => setShowMore(!showMore)}
                    style={{
                        color: '#3D1F1B',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                    }}
                    >
                    {showMore ? '' : '...'}
                    </a>
                </p>
                ) : <p> Barton Arcade, Manchester M3 2BW, United Kingdom</p>}
                {/* <p> Barton Arcade, Manchester M3 2BW, United Kingdom</p> */}
            </div>
            <div className='d-flex align-items-center opening'>
                <div className='d-flex align-items-center opening-hour'>
                <div>
                    <img src={clock} alt="" style={{ width: "1rem" }} />
                </div>
                <div className='operningHourText'>
                    <p style={{ marginBottom: "0px" }}> <span className='desktopDisplay-Opening'>Opening hour :</span>
                    {/* {window.innerWidth < 688 ? (<br />) : (<b></b>)} */}
                    <b> 9:00 AM-9:00 PM</b>
                    </p>
                </div>
                </div>

                <div>
                <span className={`${isOpen ? "open-close open" : "d-none"} `}>Open</span>
                <span className={`${isOpen ? "d-none" : "open-close close"} `}>Closed</span>
                </div>
            </div>
            <div className="d-flex ResturentDetails">
                <p>
                Details: Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's standard
                dummy text ever since the 1500s, when an unkown printer took a
                galley of type
                </p>
            </div>
            <div className="d-flex justify-content-end mobileMarginTop">
                <div className="buttonDiv">
                <button className="btn" onClick={ScrollToMenu}>
                    <img src={viewMenu} alt='viewMenu' style={{ width: '14px' }} />
                    <span> View Menu</span>
                </button>
                </div>
                <div className="buttonDiv">
                <button className="btn ">
                    <img src={chat} alt='viewMenu' style={{ width: '18px' }} />
                    <span> Message</span>
                </button>
                </div>
            </div>
            </div>
    </div>
</div>

<div className='mt-4'>
    <div className='customContainer'>
        <div className='row align-items-center' >
      <div className='col-12 col-md-3'>
       <div className='d-flex flex-column' ref={searchDialogdivRef}>
        <input
          type="text"
          className="searchBar"
          placeholder={searchPlaceholder}
          onChange={handleSearch}
          onClick={handleIsClick}
        />
        {(isVisisbleDiv ) &&
          <div className='col-12 col-md-12'>
          <div className='searchBottommDiv'>
              <div className='pt-4 d-flex ' style={{paddingLeft:'6px'}}>
                <img src={search} alt='img' style={{width:'1rem', height:'1rem', marginTop:'2px', marginRight:'10px'}}/>
                {dialogData}
              </div>
              <hr/>
              <div style={{paddingLeft:'6px'}}>
              <img src={search} alt='img' style={{width:'1rem', height:'1rem', marginTop:'2px', marginRight:'10px'}}/>
                {'Cheese ' +dialogData}
              </div>
              <hr/>
              <div style={{paddingLeft:'6px'}}>
              <img src={search} alt='img' style={{width:'1rem', height:'1rem', marginTop:'2px', marginRight:'10px'}}/>
                  {'Normal ' +dialogData}
              </div>
              <hr/>
              <div style={{paddingLeft:'6px', }}>
              <img src={search} alt='img' style={{width:'1rem', height:'1rem', marginTop:'2px', marginRight:'10px'}}/>
                <b>Search for {dialogData}</b>
              </div>
          </div>
          </div>
            }
       
        </div>
     </div>
     <div className='col-12 col-md-9'>
        <SearchSlider/>
     </div>
       </div>
    </div>
        {/* <div className='d-flex justify-content-between align-items-center' style={{marginLeft:'10rem', marginRight:'10rem', }}>
            <div className='col-12 col-md-3' style={{width:'35%'}}>
        <div className='d-flex flex-column' ref={searchDialogdivRef}>
            <input
            type="text"
            className="searchBar"
            placeholder={searchPlaceholder}
            onChange={handleSearch}
            onClick={handleIsClick}
            />
            {(isVisisbleDiv ) &&
            <div className='col-12 col-md-12'>
            <div className='searchBottommDiv'>
                <div className='pt-4 d-flex ' style={{paddingLeft:'6px'}}>
                    <img src={search} alt='img' style={{width:'1rem', height:'1rem', marginTop:'2px', marginRight:'10px'}}/>
                    {dialogData}
                </div>
                <hr/>
                <div style={{paddingLeft:'6px'}}>
                <img src={search} alt='img' style={{width:'1rem', height:'1rem', marginTop:'2px', marginRight:'10px'}}/>
                    {'Cheese ' +dialogData}
                </div>
                <hr/>
                <div style={{paddingLeft:'6px'}}>
                <img src={search} alt='img' style={{width:'1rem', height:'1rem', marginTop:'2px', marginRight:'10px'}}/>
                    {'Normal ' +dialogData}
                </div>
                <hr/>
                <div style={{paddingLeft:'6px', }}>
                <img src={search} alt='img' style={{width:'1rem', height:'1rem', marginTop:'2px', marginRight:'10px'}}/>
                    <b>Search for {dialogData}</b>
                </div>
            </div>
            </div>
                }
        
            </div>
            </div>

            <div className='d-flex justify-content-between align-items-center allcard'
              
              onClick={()=>handleSearchSelect('All')}
              >
               
                    <div className='p-2'>
                        <p className='m-0'>All</p>
                    </div>
                    <div className='p-2'>
                        <img src={grid} alt='grid' style={{width:'2.5rem', height:'2.5rem'}}/>
                    </div>
                

            </div>
            <div className='searchOfferDiv'
            onClick={()=>handleSearchSelect('Offer')}
            >
             
                <img src={BurgerOffer} alt='Offer' />
                <div className='searchOfferDiv-details'>
                    <h5>X-Mas offers 2</h5>
                    <div>
                        <p>10% discount on Pizza Family Size (12Inch) 
                         and Any Item on Chicken Section
                         </p>
                    </div>
                </div>
            
            </div>
         
           
        </div> */}
        

        <div className='d-flex justify-content-center m-4'>
            {Offer &&
                <div className='pl-2 ps-2 pe-2 selectedItem' >
                    <div className='p-1 d-flex justify-content-between' style={{gap:'10px'}}>
                        <p className=''>X-Mas offers</p>
                        <div onClick={()=>onClose('Offer')}>
                        <img  src={close} alt='close' className='' style={{width:'1rem', height:'1rem'}} />
                        </div>
                     </div>

                </div>
             }
             {All &&
                <div className='pl-2 ps-2 pe-2 selectedItem' style={{marginLeft:'5px'}}>
                    <div className='p-1 d-flex justify-content-between' style={{gap:'10px'}}>
                        <p className=''>All</p>
                        <div onClick={()=>onClose('All')}>
                        <img  src={close} alt='close' className='' style={{width:'1rem', height:'1rem'}} />
                        </div>
                     </div>

                </div>
             }
        </div>

</div>
<div className='customContainer'>
<FilterBar></FilterBar>
</div>
<Test3></Test3>
    </div>
  )
}

export default SearchPage