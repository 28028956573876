import React from 'react'
import { useDispatch } from 'react-redux'
import pkbImg from './../../../Assets/Image/PKB.png'
import './ShareYourThoughts.css'
import plusicon from './../../../Assets/Icons/picture.png'
import { setCommentText } from '../../../actions'

const ShareYourthoughts = () => {
  const dispatch = useDispatch()
  const handleChange = (event) => {
    dispatch(setCommentText(event.target.value))
  }

  return (
    <div>
      <div className="d-flex flex-column">
        <div className="d-flex shareYourThoughtspopUpImg">
          <img src={pkbImg} alt="" style={{ width: '43px' }} />
          <h6>Pot Kettle Black</h6>
        </div>
        <textarea
          style={{ width: '100%', height: '10rem', border: 'none' }}
          className="form-control whatsonyourMindtextArea"
          placeholder="What’s on your mind?"
          onChange={handleChange}
        ></textarea>
        <div className="d-flex justify-content-between addToYourPost">
          <h6>Add to your post</h6>
          <label htmlFor="formId">
            <input name="" type="file" id="formId" hidden />
            <img src={plusicon} alt="" style={{ width: '2rem' }} />
          </label>
        </div>

        <button className="btn-custom mt-3">Post</button>
      </div>
    </div>
  )
}

export default ShareYourthoughts
