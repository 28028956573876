import React, { useState } from 'react'
import './landingPage.css'
import TypewriterComponent from 'typewriter-effect'
import onlineOrder from '../../Assets/Image/online_food.gif'
import hrs from '../../Assets/Image/45233.jpg'
import subscribe from '../../Assets/Image/homepage-bg-new.png'
import inventory from '../../Assets/Image/2108.w023.n001.843B.p1.843.jpg'
import indian from '../../Assets/Image/Wavy_F&D-01_Single-03.jpg'
import yourMenu from '../../Assets/Image/restaurant_menu.gif'
import map from '../../Assets/Image/map.png'
import img1 from '../../Assets/Image/67Z_2112.w009.n001.83B.p14.83.jpg'
import go5050 from '../../Assets/Image/Go5050.png'
import img2 from '../../Assets/Image/5913.jpg'
import img3 from '../../Assets/Image/Go5050Image.jpg'
import faqImg from '../../Assets/Image/faq.svg'
import happyCustomer from '../../Assets/Image/happyCustomer.jpg'

import partner1 from '../../Assets/Image/PKB.png'
import partner2 from '../../Assets/Image/PKB.png'
import partner3 from '../../Assets/Image/PKB.png'
import partner4 from '../../Assets/Image/PKB.png'
import partner5 from '../../Assets/Image/PKB.png'

import user1 from '../../Assets/Image/user-review (1).avif'
import user2 from '../../Assets/Image/user-review (2).avif'
import user3 from '../../Assets/Image/user-review (1).jpg'
import user4 from '../../Assets/Image/user-review (3).avif'
import user5 from '../../Assets/Image/user-review (2).jpg'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { EffectCoverflow, Pagination, Autoplay, Navigation } from 'swiper'
import 'swiper/css/navigation'
import Rating from 'react-rating'
import Navbar from './../Navbar/Navbar'
import mapsImg from '../../Assets/Image/Maps.jpg'

const LandingPage = () => {

  const [blur, setBlur] = useState(false)
  const searchDialogOpen = (NavbarData) => {
    setBlur(NavbarData)

  }

  return (
    <div className="LandingPage">
      <Navbar handleNavbar={searchDialogOpen} from={"landingPage"} />
      <div className="HeroSection">


        <div className="row p-2 p-md-5 align-items-center">
          <div className="col-md-6 col-12 p-5">
            <h1 className='text-black'>GoTaste is <b className='transparentColor'>FREE</b>. We are the Cheapest delivery app</h1>
            <p className='text-black'>No service charge for users or restaurants so you don’t pay more to order online than in-store. Restaurants can now use their online offerings to boost sales and expand their business instead of being hampered by punitive charges.
            </p>
            <button className="btn btn-buyNow text-white w-50" onClick={() => { window.location.href = "/register" }}>Register Now</button>
          </div>
          <div className="col-md-6 col-12 p-5 d-flex justify-content-center align-items-center">
            <img className=' rounded shadow topImg' src={happyCustomer} alt="" />
          </div>
        </div>
      </div>

      {/* sevices  */}
      <div class="row row-cols-1 row-cols-md-3 g-4 customContainer my-5 LandingSevices">
        <div class="col ">
          <div class="card border-0 h-100 Custom-card-design">
            <div className='px-4 pt-4 landingPageImage'>
              <img src={img1} class="card-img-top rounded-xl" alt="..." />
            </div>

            <div class="card-body px-4 pt-4">
              <h5 class="card-title">Order from online, in-store, or even wholesale menus</h5>
              <p class="card-text">Restaurants can now handle multiple menu types, giving you more flexibility and choices for your orders, whether you are an individual or a business placing bulk orders.
              </p>
            </div>
            <div className='carf-footer px-4 pt-4'>

            </div>
          </div>
        </div>

        <div class="col ">
          <div class="card border-0 h-100 Custom-card-design">
            <div className='px-4 pt-4 landingPageImage'>
              <img src={img2} class="card-img-top" alt="..." />
            </div>

            <div class="card-body px-4 pt-4">
              <h5 class="card-title">No more queues, waiting to order at the till or at your table</h5>
              <p class="card-text">You can order directly from the app when at your table or collecting in the restaurant, no need to wait for the waiter to come to you.
                Forgot your bank card to pay? Order from the app using your stored method of payment.
              </p>
            </div>
            <div className='carf-footer px-4 pt-4'>

            </div>
          </div>
        </div>

        <div class="col ">
          <div class="card border-0 h-100 Custom-card-design">
            <div className='px-4 pt-4 landingPageImage'>
              <img src={img3} class="card-img-top" alt="..." />
            </div>

            <div class="card-body px-4 pt-4">
              <h5 class="card-title">Free streaming and lattes when you register</h5>
              <p class="card-text">Join GoTaste and get free months of TV and Music streaming and a free drink every day at Pot Kettle Black when you order with our Partner G05050.
              </p>
            </div>
            <div className='carf-footer px-4 pt-4'>

            </div>
          </div>
        </div>
      </div>



      {/* Order With Us */}
      <div className="customContainer my-5 custom-bg-color-1 custom-height100vh">
        <h2 className="SecondaryHeader d-flex justify-content-center">
          Order With Us
        </h2>
        <hr className="SecondaryHeaderHr" />
        <div className="row p-md-5">
          {/* <div className="col-12 col-md-6">
            <img src={onlineOrder} className='img-fluid rounded' alt="" />
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
            <div>
              <h4>Never wonder what menu items look again - GoTaste is social media but better</h4>
              <p>Easily search and find any item on a restaurant’s paper menu on their GoTaste page. Follow them for al food updates and order items directly from any related post on their page.

              </p>
            </div>
          </div> */}
          <div className="col-12 ">
            <div className='customContainer '>
              <div className='row custom-order-us Custom-card-design p-5 px-md-5 py-md-5 py-lg-5 px-lg-5'>
                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                  <div>
                    <h4><b>Never wonder what menu items look like again - GoTaste is social media but better</b></h4>
                    <p>Easily search and find any item on a restaurant’s paper menu on their GoTaste page. Follow them for al food updates and order items directly from any related post on their page.

                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <img src={onlineOrder} className='img-fluid rounded-custom orderImg' alt="" />
                </div>

              </div>
            </div>


          </div>
        </div>



      </div>
      {/* your menu  */}
      <div className="customContainer my-5">
        <h2 className="SecondaryHeader d-flex justify-content-center">
          Your Menu
        </h2>
        <hr className="SecondaryHeaderHr" />
        <div className="row p-5">

          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
            <div>
              <h4>Menu and shop items are displayed in vivid pictures on beautifully designed cards</h4>
              {/* <p>Customers can scroll through multiple food pictures like they would on any social media app; they can  also like, comment, favourite and order! No more squinting trying to see what a dish looks like as is common on most delivery app.
              </p> */}
              <p>Showcase your menus to users the way it was meant to be seen in glorious frame wide image slideshows.</p>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <img src={yourMenu} className='img-fluid rounded-custom orderImg' alt="" />
          </div>
        </div>



      </div>
      <div className="customContainer my-5">

        <div className="row p-5 text-center">

          <div className="col-12 col-md-4 d-flex justify-content-center align-items-center">
            <div>
              <h4>Food subscriptions are here for repeat visits</h4>
              <p>Get many more reasons for that repeat visit at your favourite local haunt by subscribing to any of their food and their drinks subscriptions on GoTaste.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4 d-flex justify-content-center align-items-center">
            <div>
              <img src={subscribe} className='img-fluid' alt="" />
            </div>
          </div>
          <div className="col-12 col-md-4 d-flex justify-content-center align-items-center">
            <div>
              <h4>Flexible and seasonal offers, (set) menus, loyalty schemes and more</h4>
              <p>Give more choice to users on what and when to consume at your restaurant, with an easy-to-use intuitive dashboard for easy setup of seasonal offers set menus and more schedule control like that Halloween pumpkin latte or Christmas set menu.
              </p>
            </div>
          </div>


        </div>



      </div>



      <div className="customContainer my-5 background-color-2 pt-5 pb-2">
        <div className="rounded-custom orderImg bg-white shadow p-5">
          {/* <h2 className="SecondaryHeader d-flex justify-content-center">
            Restaurants now appear listed alongside a map
          </h2> */}

          <div className="row">

            <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
              <div>
                <h2 className="SecondaryHeader">
                  Restaurants now appear listed alongside a map
                </h2>
                <p>You can now search for restaurants city-wide, at specific locations or near you, and the results will also appear on a beautiful map to help you choose.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
              <img src={mapsImg} className='img-fluid rounded-custom orderImg' alt="" />
            </div>

          </div>
        </div>
        <div class="row row-cols-1 row-cols-md-3 g-4  my-5 LandingSevices ">
          <div class="col">

            <div class="card border-0 h-100 Custom-card-design">
              <div className='px-4 pt-4 landingPageImage'>
                <img src={inventory} class="card-img-top" alt="..." />
              </div>

              <div class="card-body px-4 pt-4">
                <h5 class="card-title">Easily manage your inventory</h5>
                <p class="card-text">Items automatically appear as ‘sold out’ when your stock reaches nil, and automatically renew at your desired time according to your inventory schedule. You are fully in control.

                </p>
              </div>
              <div className='carf-footer px-4 pt-4'>

              </div>
            </div>


            {/* <div class="card border-0 h-100 Custom-card-design">
              <img src={inventory} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Easily manage your inventory</h5>
                <p class="card-text">Items automatically appear as ‘sold out’ when your stock reaches nil, and automatically renew at your desired time according to your inventory schedule. You are fully in control.

                </p>
              </div>
            </div> */}
          </div>

          <div class="col ">
            <div class="card border-0 h-100 Custom-card-design">
              <div className='px-4 pt-4 landingPageImage'>
                <img src={hrs} class="card-img-top" alt="..." />
              </div>

              <div class="card-body px-4 pt-4">
                <h5 class="card-title">Receive your funds in 48hrs</h5>
                <p class="card-text"> keep on top of your cash flow with fund withdrawals in just 48hrs for restaurants and couriers.
                </p>
              </div>
              <div className='carf-footer px-4 pt-4'>

              </div>
            </div>
            {/* <div class="card border-0 h-100 Custom-card-design">
              <img src={hrs} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Receive your funds in 48hrs</h5>
                <p class="card-text"> keep on top of your cashflow with fund withdrawals in just 48hrs for restaurants and couriers.
                </p>
              </div>
            </div> */}
          </div>

          <div class="col ">
            <div class="card border-0 h-100 Custom-card-design">
              <div className='px-4 pt-4 landingPageImage'>
                <img src={indian} class="card-img-top" alt="..." />
              </div>

              <div class="card-body px-4 pt-4">
                <h5 class="card-title">Indie chefs and couriers are welcome</h5>
                <p class="card-text"> We are keen to onboard all aspiring independent chefs and the many couriers vital to online delivery. You also get paid within 48hrs, so register now with GoTaste!

                </p>
              </div>
              <div className='carf-footer px-4 pt-4'>

              </div>
            </div>

            {/* <div class="card border-0 h-100 Custom-card-design">
              <img src={indian} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Indie chefs and couriers are welcome</h5>
                <p class="card-text">we are keen to onboard all aspiring independent chefs and the many couriers without which online delivery wouldn’t be possible. You will also get paid within 48hrs so register today for a thriving enterprise with GoTaste!

                </p>
              </div>
            </div> */}
          </div>
        </div>


      </div>


      {/* <div class="row row-cols-1 row-cols-md-3 g-4 customContainer my-5 LandingSevices ">
        <div class="col">
          <div class="card shadow rounded  h-100 border-0">
            <img src={inventory} class="card-img-top" alt="..." />
            <div class="card-body">
              <h5 class="card-title">Easily manage your inventory</h5>
              <p class="card-text">Items automatically appear as ‘sold out’ when your stock reaches nil, and automatically renew at your desired time according to your inventory schedule. You are fully in control.

              </p>
            </div>
          </div>
        </div>

        <div class="col ">
          <div class="card shadow rounded  h-100 border-0">
            <img src={hrs} class="card-img-top" alt="..." />
            <div class="card-body">
              <h5 class="card-title">Receive your funds in 48hrs</h5>
              <p class="card-text"> keep on top of your cashflow with fund withdrawals in just 48hrs for restaurants and couriers.
              </p>
            </div>
          </div>
        </div>

        <div class="col ">
          <div class="card shadow rounded  h-100 border-0 d-flex">
            <img src={indian} class="card-img-top" alt="..." />
            <div class="card-body">
              <h5 class="card-title">Indie chefs and couriers are welcome</h5>
              <p class="card-text">we are keen to onboard all aspiring independent chefs and the many couriers without which online delivery wouldn’t be possible. You will also get paid within 48hrs so register today for a thriving enterprise with GoTaste!

              </p>
            </div>
          </div>
        </div>
      </div> */}
      {/* Out Partners  */}

      <div class="customContainer my-5">
        <div className="my-5">
          <h2 className="SecondaryHeader d-flex justify-content-center">
            Out Partners{' '}
          </h2>
          <hr className="SecondaryHeaderHr" />
        </div>

        {/* <Swiper
          className=""
          spaceBetween={10}
          grabCursor={false}
          centeredSlides={false}
          slidesPerView={'auto'}
          hashNavigation={{
            watchState: true,
          }}
          autoplay={{
            delay: 1000,
            disableOnInteraction: true,
          }}
          breakpoints={{
            300: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1200: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1400: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
          }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        >

          <SwiperSlide>
            <div className="partnerImg">
              <img src={partner1} className="card-img-top w-50" alt="..." />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="partnerImg">
              <img src={go5050} className="card-img-top w-50" alt="..." />
            </div>
          </SwiperSlide>
        </Swiper> */}
        <div className='d-flex justify-content-center flex-wrap' style={{ gap: "10px" }}>
          <div className="">
            <img src={partner1} className="partnerImg" alt="..." />
          </div>
          <div className="">
            <img src={go5050} className="partnerImg" alt="..." />
          </div>
        </div>

      </div>

      {/* Reviews */}

      <div className="customContainer productPageContainer  my-5">
        <div className="my-5">
          <h2 className="SecondaryHeader d-flex justify-content-center">
            Reviews
          </h2>
          <hr className="SecondaryHeaderHr" />
        </div>

        <div className="productpageSlider">
          <Swiper
            className="landing-review-container"
            spaceBetween={30}
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            modules={[EffectCoverflow, Autoplay, Pagination]}
          >
            <SwiperSlide className="sliderBox">
              <div>
                <img className="img-fluid" src={user1} alt="" />

                <h4 className="text-center">Leo</h4>
                <Rating
                  className="landingStar"
                  initialRating={Number(4.5)}
                  emptySymbol="far fa-star icon-color"
                  fullSymbol="fas fa-star icon-color"
                  readonly
                ></Rating>

                <p className="p-2">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="sliderBox">
              <div>
                <img className="img-fluid" src={user2} alt="" />

                <h4 className="text-center">Leo</h4>
                <Rating
                  className="landingStar"
                  initialRating={Number(4.5)}
                  emptySymbol="far fa-star icon-color"
                  fullSymbol="fas fa-star icon-color"
                  readonly
                ></Rating>

                <p className="p-2">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="sliderBox">
              <div>
                <img className="img-fluid" src={user3} alt="" />

                <h4 className="text-center">Leo</h4>
                <Rating
                  className="landingStar"
                  initialRating={Number(4.5)}
                  emptySymbol="far fa-star icon-color"
                  fullSymbol="fas fa-star icon-color"
                  readonly
                ></Rating>

                <p className="p-2">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="sliderBox">
              <div>
                <img className="img-fluid" src={user4} alt="" />

                <h4 className="text-center">Leo</h4>
                <Rating
                  className="landingStar"
                  initialRating={Number(4.5)}
                  emptySymbol="far fa-star icon-color"
                  fullSymbol="fas fa-star icon-color"
                  readonly
                ></Rating>

                <p className="p-2">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="sliderBox">
              <div>
                <img className="img-fluid" src={user5} alt="" />

                <h4 className="text-center">Leo</h4>
                <Rating
                  className="landingStar"
                  initialRating={Number(4.5)}
                  emptySymbol="far fa-star icon-color"
                  fullSymbol="fas fa-star icon-color"
                  readonly
                ></Rating>

                <p className="p-2">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      {/* faq  */}
      <div className="row customContainer my-5">
        <div className="col-md-6 mx-auto d-flex justify-content-center ">
          <img src={faqImg} className="img-fluid" alt="" style={{
            height: "27rem"
          }} />
        </div>

        <div className="col-md-6 ">
          <h2>Frequently Asked Questions</h2>

          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Can I order with GoTaste when in store and beat the queue?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, just navigate to the ‘In-store’ menu of the restaurant and provide your table number when placing your order or opt for ‘takeaway’. No need to wait for a waiter to come to you. Don’t forget to follow the restaurant for regular updates!
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Can I carry over leftover items from 1 subscription period to the next one?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, you can, and any redemption limit placed by the restaurant (e.g. 2 items to be redeemed per day) applies.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Can I schedule items availability?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  yes, you can schedule when your restaurant opens/closes, when menu items/sections are available. You can also schedule inventory stock levels, so that items are automatically marked as ‘sold out’ if the stock is depleted, or back to ‘available’ if you’ve scheduled a replenishment for a particular time.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Can I follow restaurants to get updates and order directly from their wall posts?
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Absolutely! Follow your favourite restaurant to get notified about all their latest food/lattes news (such as their best items ideas for a date), and order directly from the post if your senses are titillated.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Can I use my existing account with the free streaming months offer?
                </button>
              </h2>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, for music subscriptions, nothing changes, simply use the upgrade link in your GO5050 sharing group to upgrade your existing individual account. For TV subscriptions, some providers allow to easily port your profile watch history to the new account you join from your GO5050 sharing group, meaning nothing changes for you. Once the offer expires, you will pay 80% less on average when you manage your streaming subscriptions on GO5050.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Want to learn more  */}
      <div className="row customContainer my-5">
        <div className="col-md-12 mx-auto ">
          <h2>Want to learn more?</h2>
          <div className="container">
            <div className="row" style={{ alignItems: 'center' }}>
              <div className="col-0 col-md-3"></div>
              <div className="col-0 col-md-5">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Your  Email"
                />
              </div>
              <div className="col-0 col-md-2">
                <button className="btn btn-search-home subscribe-btn">
                  Subscribe
                </button>
              </div>
              <div className="col-0 col-md-2"></div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer  */}
      <hr></hr>
      <div className="row customContainer my-5">
        <div className="row">
          <div className="col-md-6 mx-auto ">
            <h2>
              Go <b>Taste</b>
            </h2>
          </div>
          <div className="col-0 col-md-3"></div>
          <div className="col-md-3 mx-auto my-3">
            <div className="row">
              <div className="col-12 ">
                <h5 className="font-size-for-footer">Get Help</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-12 ">
                <h5 className="font-size-for-footer">Login</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h5 className="font-size-for-footer">Register</h5>
              </div>
            </div>
          </div>
        </div>

        <hr></hr>
        <div className="row">
          <div className="col-md-6 mx-auto d-flex" style={{ gap: '10px' }}>
            <i class="fa-brands fa-facebook font-size-for-social-media"></i>
            <i class="fa-brands fa-twitter font-size-for-social-media"></i>
            <i class="fa-brands fa-instagram font-size-for-social-media"></i>
          </div>
          <div className="col-0 col-md-3"></div>
          <div className="col-md-3 mx-auto my-3">
            <div className="row">
              <div className="col-12">
                <div className="d-flex" style={{ gap: '10px' }}>
                  <h5 className="font-size-for-footer">Privacy Policy</h5>
                  <h5 className="font-size-for-footer">Terms</h5>
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex" style={{ gap: '10px' }}>
                  <h5 className="font-size-for-footer">
                    © 2023 Go <b>Taste</b>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPage
