import React from 'react'
import Navbar from '../Navbar/Navbar'

function Privacy() {
    return (
        <div>
            {/* <Navbar handleNavbar={searchDialogOpen} from={"landingPage"} /> */}
            <div className='container ' style={{ marginTop: "5rem" }}>
                <h2 className='text-center'><b>GO TASTE Privacy and Cookies policies </b></h2>

                <p className='text-justify mt-4 detailsBody' style={{ textAlign: "justify" }}>
                    We are GO TASTE. In this privacy notice we will refer to ourselves as we’, ‘us’ or ‘our
                    ’.
                    You can get hold of us in any of the following ways:
                    (a) by e-mailing us at info@gotaste.uk
                    We take the privacy, including the security, of personal information we hold about youseriously. This privacy notice is designed to inform you about how we collect personalinformation about you and how we use that persoal information. You should read this privacynotice carefully so that you know and can understand why and how we use the personalinformation we collect and hold about you.
                    OR
                    If you have any questions about this privacy notice or issues arising from it then you shoulcontact us at the above email address,
                    We may issue you with other privacy notices from time to time, including when we collepersonal information from you. This privacy notice is intended to supplement these and doesnot override them.


                    We may update this privacy notice from time to tim




                </p>
                <ol className=' ol-head-roman'>
                    <li> <h4 className=' mt-4'><b>Key Definition</b></h4></li>
                    <p>The key terms that we use throughout this privacy notice are defined below, for ease</p>
                    {/* <ol className='ol-head-secondary '>
                <ul className='ol-head-third detailsBody'>
                    <li style={{ textAlign: "justify" }}>1.1. Company details: GO TASTE is a company registered in England and Wales with registered company number 14793234 whose registered office is at 1602 49 Goulden Street Manchester,England,M4 5EN </li>
                    <li style={{ textAlign: "justify" }}>1.2. Product Orders: We provide a way for you to communicate your orders (“Order” or "Orders") for products (“Product” or "Products") to  restaurants, stores and other businesses in the UK (“Business” or "Businesses") displayed on the Website. The legal contract for the supply and purchase of Products is between you and the Business that you place your Order with and we will conclude the sale of Products on behalf of, and as agent for, the Businesses in all cases </li>
                    <li style={{ textAlign: "justify" }}>1.3 Delivery Services: Where a Business on our Website provides their own delivery service in order to bring the Product(s) you order to you, the Business may charge you a delivery fee (which we will collect on behalf of the Business). In some cases, we provide the delivery services, and in those cases we may charge you the delivery fee ourselves (in respect of that delivery fee, as principal, rather than as agent for the Business).   </li>
                </ul>
                <li><h5 className=' mt-4'><b>WEBSITE ACCESS AND TERMS</b></h5></li>
                <ul className='ol-head-third detailsBody'>
                    <li style={{ textAlign: "justify" }}>2.1. Website access: You may access some areas of the Website without making an Order or registering your details with us. Most areas of the Website are open to everyone.</li>
                    <li style={{ textAlign: "justify" }}>2.2. Acceptance of terms: By accessing any part of the Website, you indicate that you accept these Website Terms. If you do not accept these Website Terms, you should leave the Website immediately, and you will not be able to order any Products through the Website. </li>
                    <li style={{ textAlign: "justify" }}>2.3. Revision of terms: We may revise these Website Terms at any time. You should check the Website regularly to review the current Website Terms, because they are binding on you. You will be subject to the policies and terms and conditions in force at the time that you place an Order through us.</li>
                    <li style={{ textAlign: "justify" }}>2.4. Responsibility: You are responsible for making all arrangements necessary for you to have access to the Website. You are also responsible for ensuring that all persons who access the Website through your Internet connection are aware of these Website Terms and that they comply with them.</li>
                    <li style={{ textAlign: "justify" }}>2.5 Information you provide: You confirm that all information provided by you to us (including to our Customer Care team) is true and accurate in all respects and at all times.</li>
                </ul>

                <li><h5 className=' mt-4'><b>YOUR STATUS</b></h5></li>
                <ul className='ol-head-third detailsBody'>
                    <li style={{ textAlign: "justify" }}>3.1. Capacity and age: By placing an Order through the Website, you warrant that:</li>
                    <ul>
                        <li style={{ textAlign: "justify" }}>3.1.1. You are legally capable of entering into binding contracts with the Business; and</li>
                        <li style={{ textAlign: "justify" }}>3.1.2. You are at least 18 years old.</li>
                    </ul>
                    <li style={{ textAlign: "justify" }}>3.2. You acknowledge and agree that if you have a specific allergy or intolerance, you will contact the Business directly to check that the Product is suitable for you, before placing your order directly with them. </li>
                    <li style={{ textAlign: "justify" }}>3.3. Alcohol, tobacco products, and other items with a legal age restriction (“Age Restricted Items”):</li>
                    <ul>
                        <li style={{ textAlign: "justify" }}>3.3.1. You acknowledge and agree that: it is an offence for any person under the minimum legal age to buy, or attempt to buy certain Age Restricted Items in the UK, or for any person to buy, or attempt to buy certain Age Restricted Items in the UK on behalf of any person who is under the minimum legal age.</li>
                        <li style={{ textAlign: "justify" }}>3.3.2. If your Order includes any Age Restricted Items, you will be asked to provide proof of your age on collection or delivery of your Order. If you can’t provide proof that you meet the relevant minimum legal age requirement or there’s reason to believe that the Age Restricted Items you ordered were bought on behalf of someone under the minimum legal age, you may be refused collection or delivery of your Order, including such Age Restricted Items.</li>
                        <li style={{ textAlign: "justify" }}>3.3.3 You may also be refused collection or delivery of Age Restricted Items if you are, or appear to be, drunk, intoxicated or otherwise under the influence. </li>
                    </ul>
                </ul>

                <li><h5 className=' mt-4'><b>HOW TO MAKE AN ORDER AND HOW IT IS PROCESSED</b></h5></li>
                <ul className='ol-head-third detailsBody'>
                    <li style={{ textAlign: "justify" }}>4.1. Compiling your Order: Once you have selected the Products you wish to order from your chosen Business and provided the other required information, you will be given the opportunity to submit your Order by clicking or selecting the "proceed", "place my order" or similar button. It is important that you check all the information that you enter and correct any errors before clicking or selecting this button; once you do so you will be entering into a contract with the Business and errors cannot be corrected (subject to paragraph 4.2. below). Where you have not logged in or created an account previously, then you may have the option to continue with your Order as a guest. By electing to complete your Order as a guest, you are agreeing that Just Eat will create a temporary GOTASTE guest account for you which will, unless otherwise stated in these Website Terms, be accessible only (a) from the device which you use to place the Order; and (b) for a period of up to twelve months following the date of the Order.  Your access to your temporary guest account will expire on the “Expiry Date” which shall be the earlier of (i) the date falling six months after the date on which you last accessed the temporary guest account; (ii) the date falling twelve months after the date on which the temporary guest account was created; and (iii) the date on which you logout of your temporary guest account, or clear the cache on the device which you used when the temporary guest account was created.</li>

                    <li style={{ textAlign: "justify" }}>4.2 Amending or cancelling your Order: Once you have submitted your Order and your payment has been authorised, you will not be entitled to change or cancel your Order, nor will you be entitled to a refund (please refer to paragraphs 4.4 and 5.6 for details of the process relating to rejected Orders and refunding of payment). If you wish to change or cancel your Order, you may contact the Business if their contact details are available. Or you may contact our Customer Care team as described in paragraph 6.3 and they will attempt to contact the Business in order to communicate your requests. However, there is no guarantee that we will be able to reach the Business or that the Business will agree to your requests as they may have already started processing your Order. </li>
                    <li style={{ textAlign: "justify" }}>4.3. Payment authorisation: Where any payment you make is not authorised, your Order will not be processed or communicated to the relevant Business.</li>
                    <li style={{ textAlign: "justify" }}>4.4. Processing your Order and Business rejections: On receipt of your Order, we will send it to the relevant Business and will notify you by email that your Order has been received and is being processed. Please note that any confirmation page that you may see on the Website and any Order confirmation email that you may receive each confirm that you have a contract for the sale of Products with a Business but does not necessarily mean that your Order will be fulfilled by the Business. We encourage all our Businesses to accept all Orders and to communicate any rejection promptly, and we will notify you (generally by email) as soon as reasonably practicable if a Business rejects your Order. However, Businesses have the ability to reject Orders at any time because they are too busy, due to weather conditions or for any other reason. In doing so, Businesses will be in breach of their agreement with you and any payment made in respect of the order will be returned to you in accordance with paragraph 5.6 below.</li>
                    <li style={{ textAlign: "justify" }}>4.5. Delivery of your Order: Estimated times for deliveries and collections are provided by the Businesses and are only estimates. Neither we nor the Businesses guarantee that Orders will be delivered or will be available for collection within the estimated times. By selecting to have your Order delivered, you are confirming that You will be present at the delivery address you indicated in order to receive the Order. If you are not present at the delivery address when the Order is delivered, and the Order is being delivered by us (rather than the Business itself) we will make reasonable efforts to contact you in order to determine where to leave the Order. If we are unable to contact you, we may leave the Order at a reasonable location outside, close to the delivery address. We will not be responsible for the Order (including the quality or safety of the contents of the Order when you find it) after delivering the Order. Please note that when the Business is delivering the Order itself, rather than using our delivery services, the Business will decide whether to leave the Order outside the delivery address if you are not present.</li>
                    <li style={{ textAlign: "justify" }}>4.6. Commercial agency: For the avoidance of doubt, any orders processed by GO TASTE through the Website are processed by GO TASTE in its capacity as agent of the relevant Business.</li>


                </ul>


                <li><h5 className=' mt-4'><b>PRICE AND PAYMENT</b></h5></li>
                <ul className='ol-head-third detailsBody'>
                    <li style={{ textAlign: "justify" }}>5.1. VAT and additional costs: Prices will be as quoted on the Website. These prices include VAT but may exclude delivery costs (if you opt for delivery instead of collection) and any administration or service charge. These will be added to the total amount due where applicable. Where the Business that you place your Order with provides its own delivery service, the Business may charge you a delivery fee and/or a service charge (which we will collect on behalf of the Business, as the Business’s agent). Where the Business that you place your Order with receives delivery services provided by us, we may charge you a delivery fee and/or service charge ourselves (in respect of that delivery fee and/or service charge only, as principal, rather than as commercial agent for the Business). The delivery fee and service charge applying to your Order may vary depending on a number of factors, including your location, the Business you select, and the value of the items in your Order, but these costs will always be shown to you before you complete and pay for your Order.</li>

                    <li style={{ textAlign: "justify" }}>5.2. Incorrect pricing: This Website contains a large number of items and it is possible that some of the items may include incorrect prices. If the correct price for an Order is higher than the price stated on the Website, we will normally contact you before the relevant Order is dispatched. In such an event, neither we nor the relevant Business is under any obligation to ensure that the Order is provided to you at the incorrect lower price or to compensate you in respect of incorrect pricing.</li>
                    <li style={{ textAlign: "justify" }}>5.3. Payment methods: Payment for Orders must be made by an accepted credit or debit card through the Website or in cash to the Business at the point of delivery to, or collection by, you.</li>
                    <li style={{ textAlign: "justify" }}>5.4. Card payments: If you pay by credit or debit card, you may be required to show the card to the Business at the time of delivery or collection as proof of identification and so that they can check that the card corresponds with the receipt data for the Order. Please note that from time to time there may be delays with the processing of card payments and transactions; this may result in payments taking up to sixty (60) days to be deducted from your bank account or charged to your credit or debit card.</li>
                    <li style={{ textAlign: "justify" }}>5.5. Credit and discount vouchers: A credit or discount may apply to your Order if you use a promotional voucher or code recognised by the Website and endorsed by GO TASTE, and you pay for any balance by credit or debit card. Please refer to our Voucher Terms & Conditions for the full terms and conditions applicable to the use of credit and discount vouchers. Please note that because of standard banking procedures, your bank or card issuer will initially "ring-fence" the full amount of the Order (before any credit or discount) in your account for between 3 to 5 working days (or longer, depending on your bank or card issuer), and this amount will therefore be unavailable in your account for that period. The credit or discount will be applied at the time your bank or card issuer transfers the funds for your Order to us, at which point the credit or discounted amount will not be transferred to us and will instead be released by your bank or card issuer back into your available balance. You acknowledge and agree that neither we nor the relevant Business will be responsible or liable to you in relation to this delay by your bank or card issuer in the release of funds back into your account. Where you use the Website as a guest, any account credit which you accrue against your temporary guest account can only be redeemed for an Order placed via your temporary guest account and cannot be transferred for use on another account.</li>
                    <li style={{ textAlign: "justify" }}>5.6. Rejected Orders: Because of standard banking procedures, once you have submitted an Order that you are paying for by credit or debit card and your payment has been authorised, your bank or card issuer will "ring-fence" the full amount of your Order. If your Order is subsequently rejected by the Business (as described in paragraph 4.4 above) or cancelled for any other reason, your bank or card issuer will not transfer the funds for the Order to us, and will instead release the relevant amount back into your available balance. However, this may take between 3 to 5 working days (or longer, depending on your bank or card issuer). You acknowledge and agree that neither we nor the relevant Business will be responsible or liable to you in relation to this delay by your bank or card issuer in the release of funds back </li>
                </ul>


                <li><h5 className=' mt-4'><b>CUSTOMER CARE</b></h5></li>
                <ul className='ol-head-third detailsBody'>
                    <li style={{ textAlign: "justify" }}>6.1. General: Customer care is extremely important to us. Subject to paragraphs 6.5 and 11, our Customer Care team will therefore try to assist you where possible if you have any problems with your Order. You can contact our Customer Care team by clicking or selecting the "Need help?", "Help" or similar button or by calling the telephone number shown on the Website.</li>

                    <li style={{ textAlign: "justify" }}>6.2. Questions about your Order: If your Order is taking longer than expected or you have any other problems with your Order, you can contact our Customer Care Team as described above and one of our Customer Care Advisers will attempt to contact the Business in order to follow up on your query.</li>
                    <li style={{ textAlign: "justify" }}>6.3. Changing or cancelling your Order: If you wish to change or cancel your Order after it has been submitted and payment has been authorised, you may contact the Business using the contact details where available on the Website. Alternatively you may contact our Customer Care team as described above and they will attempt to contact the Business in order to communicate your requests. However, there is no guarantee that we will be able to reach the Business or that the Business will agree to your requests as they may have already started processing your Order.</li>
                    <li style={{ textAlign: "justify" }}>6.4. Complaints or feedback: In the event that you are dissatisfied with the quality of any Products or the service provided by a Business, please consider providing feedback in the form of ratings, comments and reviews on the Website (together, "Reviews") to reflect your experience. The Reviews are an important part of our quality control process. You are responsible for ensuring that all Reviews you provide are accurate and based on your own, genuine experience.</li>
                    <li style={{ textAlign: "justify" }}>6.5. Compensation: The legal contract for the supply and purchase of Products is between you and the Business that you place your Order with. We have no control over Businesses and the quality of the Products or service that they provide, and GO TA has no responsibility or liability for providing any compensation to you.
                        If you are dissatisfied with the quality of any Products or the service provided by a Business and wish to seek a refund, a proportionate price reduction or any other compensation, please let us know within 24 hours of placing your Order. Depending on the reported issue, you may be able to send your request for compensation via our Website, or we may ask you to contact the Business directly to file your request and, where appropriate, you will need to follow the Business's own complaint procedures.
                    </li>
                    <li style={{ textAlign: "justify" }}>6.6. Processing refund requests: Where we are processing refund requests, please be aware that we may ask you to provide reasonable evidence (such as photographs) demonstrating a reported issue with your Order. In addition and prior to processing any refund request, we may take other relevant factors into account, such as the details of your Order and its delivery, your account data and ordering history, transaction data and information received from the Business.
                    </li>
                    <li style={{ textAlign: "justify" }}>6.7. Statutory Rights: The foregoing clauses in this CUSTOMER CARE section do not affect your legal and statutory rights when ordering Products or services via our Website.
                    </li>
                </ul>


                <li><h5 className=' mt-4'><b>LICENCE</b></h5></li>
                <ul className='ol-head-third detailsBody'>
                    <li style={{ textAlign: "justify" }}>7.1. Terms of permitted use: You are permitted to use the Website and print and download extracts from the Website for your own personal non-commercial use on the following basis:</li>
                    <ul>
                        <li style={{ textAlign: "justify" }}>7.1.1. You must not misuse the Website (including by hacking or \"scraping\").</li>
                        <li style={{ textAlign: "justify" }}>7.1.2. Unless otherwise stated, the copyright and other intellectual property rights in the Website and in material published on it (including without limitation photographs and graphical images) are owned by us or our licensors. These works are protected by copyright laws and treaties around the world and all rights are reserved. For the purposes of these Website Terms, any use of extracts from the Website other than in accordance with paragraph 7.1 is prohibited.</li>
                        <li style={{ textAlign: "justify" }}>7.1.3. You must not modify the digital or paper copies of any materials that you print off in accordance with paragraph 7.1 and you must not use any pictures, photographs or any other graphics, video or audio sequences separately from any accompanying text.</li>
                        <li style={{ textAlign: "justify" }}>7.1.4. You must ensure that our status as the author of the material on the Website is always acknowledged.</li>
                        <li style={{ textAlign: "justify" }}>7.1.5. You are not allowed to use any of the materials on the Website or the Website itself for commercial purposes without obtaining a licence from us to do so.</li>
                    </ul>
                    <li style={{ textAlign: "justify" }}>7.2. Limitation on use: Except as stated in paragraph 7.1, the Website may not be used, and no part of the Website may be reproduced or stored in any other website or included in any public or private electronic retrieval system or service, without our prior written permission.</li>
                    <li style={{ textAlign: "justify" }}>7.3. Reservation of rights: Any rights not expressly granted in these Website Terms are reserved.</li>

                </ul>


                <li><h5 className=' mt-4'><b>WEBSITE ACCESS</b></h5></li>
                <ul className='ol-head-third detailsBody'>
                    <li style={{ textAlign: "justify" }}>8.1. Website availability: While we try to ensure the Website is normally available twenty four (24) hours a day, we do not undertake any obligation to do so, and we will not be liable to you if the Website is unavailable at any time or for any period.</li>

                    <li style={{ textAlign: "justify" }}>8.2. Suspension of access: Access to the Website may be suspended temporarily at any time and without notice.</li>
                    <li style={{ textAlign: "justify" }}>8.3. Information security: The transmission of information via the Internet is not completely secure. Although we take the steps required by law to protect your information, we cannot guarantee the security of your data transmitted to the Website; any transmission is at your own risk.</li>

                </ul>

                <li><h5 className=' mt-4'><b>VISITOR MATERIAL AND REVIEWS</b></h5></li>
                <ul className='ol-head-third detailsBody'>
                    <li style={{ textAlign: "justify" }}>9.1. General:</li>
                    <ul>
                        <li style={{ textAlign: "justify" }}>9.1.1. Other than personally identifiable information, which is covered under our Privacy notice, any material you post, upload or transmit or upload to the Website (including without limitation Reviews) Visitor Material will be considered non-confidential and non-proprietary. By posting, uploading or transmitting any Visitor Material, you represent and warrant that you own or otherwise control all of the rights to such Visitor Material. You agree that we will have no obligations with respect to any Visitor Material, and that we and anyone we designate will be free to copy, disclose, distribute, incorporate and otherwise use any Visitor Material and all data, images, sounds, text and other things embodied in it for any and all commercial or non-commercial purposes.</li>
                        <li style={{ textAlign: "justify" }}>9.1.2. You represent and warrant that any Visitor Material you post, upload or transmit does not and will not breach any of the restrictions in paragraphs 9.2 to 9.3 below.</li>
                    </ul>
                    <li style={{ textAlign: "justify" }}>9.2. Visitor Material Policy: You are prohibited from posting, uploading or transmitting to or from the Website any Visitor Material (including any Reviews) that: </li>

                    <ul>
                        <li style={{ textAlign: "justify" }}>9.2.1. breaches any applicable local, national or international law;</li>
                        <li style={{ textAlign: "justify" }}>9.2.2. is unlawful or fraudulent;</li>
                        <li style={{ textAlign: "justify" }}>9.2.3. amounts to unauthorised advertising; or </li>
                        <li style={{ textAlign: "justify" }}>9.2.4. contains viruses or any other harmful programs.</li>
                    </ul>


                    <li style={{ textAlign: "justify" }}>9.3. Visitor Reviews Policy: In particular (but without limitation), any Reviews that you submit through the Website must not:</li>

                    <ul>
                        <li style={{ textAlign: "justify" }}>9.3.1. contain any defamatory, obscene or offensive material;</li>
                        <li style={{ textAlign: "justify" }}>9.3.2. promote violence or discrimination;</li>
                        <li style={{ textAlign: "justify" }}>9.3.3. infringe the intellectual property rights of another person </li>
                        <li style={{ textAlign: "justify" }}>9.3.4. breach any legal duty owed to a third party (such as a duty of confidence);</li>

                        <li style={{ textAlign: "justify" }}>9.3.5. promote illegal activity or invade another\'s privacy;</li>
                        <li style={{ textAlign: "justify" }}>9.3.6. give the impression that they originate from us; or</li>
                        <li style={{ textAlign: "justify" }}>9.3.7. be used to impersonate another person or to misrepresent your affiliation with another person.</li>
                    </ul>
                    <li style={{ textAlign: "justify" }}>9.4. Removal of Reviews: The prohibited acts listed in paragraphs 9.2 and 9.3 above are non-exhaustive. We reserve the right (but do not undertake, except as required by law, any obligation) and have the sole discretion to remove or edit at any time any Reviews or other Visitor Material posted, uploaded or transmitted to the Website that we determine breaches a prohibition in paragraphs 9.2 or 9.3 above, is otherwise objectionable or may expose us or any third parties to any harm or liability of any type, or for any other reason.</li>

                    <li style={{ textAlign: "justify" }}>9.5. Use of Reviews: The Reviews and other Visitor Material contained on the Website are for information purposes only and do not constitute advice from us. Reviews and Visitor Material reflect the opinions of customers who have ordered through the Website or other third parties, and any statements, advice or opinions provided by such persons are theirs only. Accordingly, to the fullest extent permitted by law, we assume no responsibility or liability to any person for any Reviews or other Visitor Material, including without limitation any mistakes, defamation, obscenity, omissions or falsehoods that you may encounter in any such materials.</li>

                    <li style={{ textAlign: "justify" }}>9.6. Images: Any images of food or other items displayed on the Website are provided as a design feature of the Website only and may not be either (a) an image of an item prepared or produced by the Business from which you choose to order; or (b) representative of the Product you receive from a Business.</li>

                    <li style={{ textAlign: "justify" }}>9.7. Liability: You agree to indemnify us against any losses, damages and claims (and all related costs) incurred by or made against us by a Business or any other third party arising out of or in connection with any Reviews or other Visitor Material that you provide in breach of any of the representations and warranties, agreements or restrictions set forth in this paragraph 9.</li>

                    <li style={{ textAlign: "justify" }}>9.8. Disclosure to authorities and courts: You acknowledge that we will fully co-operate with any competent authority requesting or directing us to disclose the identity or location of anyone posting any Reviews or other Visitor Material in breach of paragraph 9.2 or 9.3 or any other applicable restriction and you release us to the fullest extent permitted by law from all liability in relation to such disclosure.</li>
                </ul>



                <li><h5 className=' mt-4'><b>LINKS TO AND FROM OTHER WEBSITES</b></h5></li>
                <ul className='ol-head-third detailsBody'>
                    <li style={{ textAlign: "justify" }}>10.1. Third party websites: Links to third party websites on the Website are provided solely for your convenience. If you use these links, you leave the Website. We have not reviewed and do not control any of these third party websites (and are not responsible for these websites or their content or availability). We do not endorse or make any representation about these websites, their content, or the results from using such websites or content. If you decide to access any of the third party websites linked to the Website, you do so entirely at your own risk.</li>
                    <li style={{ textAlign: "justify" }}>10.2. Linking permission: You may link to the Website's homepage (www.just-eat.co.uk), provided that: </li>
                    <ul>
                        <li style={{ textAlign: "justify" }}>10.2.1. you do so in a fair and legal way which does not damage or take advantage of our reputation;</li>
                        <li style={{ textAlign: "justify" }}>10.2.2. you do not establish a link from a website that is not owned by you or in a way that suggests a form of association with or endorsement by us where none exists;</li>
                        <li style={{ textAlign: "justify" }}>10.2.3. any website from which you link must comply with the content standards set out in these Website Terms (in particular paragraph 9 (Visitor Materials and Reviews));</li>
                        <li style={{ textAlign: "justify" }}>10.2.4. we have the right to withdraw linking permission at any time and for any reason.</li>
                    </ul>


                </ul>

                <li><h5 className=' mt-4'><b>DISCLAIMERS</b></h5></li>
                <ul className='ol-head-third detailsBody'>
                    <li style={{ textAlign: "justify" }}>11.1. Website information: While we try to ensure that information on the Website is correct, we do not promise it is accurate or complete. We may make changes to the material on the Website, or to the functionality, Products and prices described on it, at any time without notice. The material on the Website may be out of date, and we make no commitment to update that material.</li>
                    <li style={{ textAlign: "justify" }}>11.2. Allergy, dietary and other Product information: When a Business signs up with us, they have to provide us with up-to-date Product information. We then include this on their dedicated page on the Website. Where this information includes allergy or other dietary information, we will do our best to republish this information on the website or app exactly as it has been provided to us by the Business. If you have, or someone you are ordering for has, a concern about allergies, intolerances or dietary preferences, you should always contact the Business directly before placing your order. Please do not use the "leave a note for the Business" box for your allergies or intolerances, please contact the Business directly.</li>
                    <li style={{ textAlign: "justify" }}>11.3. Business actions and omissions:  The legal contract for the supply and purchase of Products is between you and the Business that you place your Order with. We have no control over the actions or omissions of any Businesses. Without limiting the generality of the foregoing, you acknowledge and accept the following by using the Website:</li>
                    <ul>
                        <li style={{ textAlign: "justify" }}>11.3.1. We do not give any undertaking that the Products ordered from any Business through the Website will be of satisfactory quality or suitable for your purpose and we disclaim any such warranties</li>
                        <li style={{ textAlign: "justify" }}>11.3.2. Estimated times for deliveries and collections are provided by the Businesses and are only estimates. Neither we nor the Businesses guarantee that Orders will be delivered or will be available for collection within the estimated times.</li>
                        <li style={{ textAlign: "justify" }}>11.3.3. We encourage all our Businesses to accept all Orders and to communicate any rejection promptly, and we will notify you (generally by email) as soon as reasonably practicable if a Business rejects your Order. However, we do not guarantee that Businesses will accept and fulfil all Orders, and Businesses have the discretion to reject Orders at any time because they are too busy, if you fail to provide proof of age for purchases of Age Restricted Items when required, due to weather conditions or for any other reason. Businesses will not be obliged to deliver an Order to an address outside of their set delivery radius, which may change from time to time.</li>
                        <li style={{ textAlign: "justify" }}>11.3.4. The foregoing disclaimers do not affect your statutory rights against any Business.</li>
                    </ul>
                    <li style={{ textAlign: "justify" }}>11.4. Exclusion of terms: We provide you with access to and use of the Website on the basis that, to the maximum extent permitted by law, we exclude all representations, warranties, conditions, undertakings and other terms in relation to the Website and your use of it (including any representations, warranties, conditions, undertakings and other terms which might otherwise apply to the Website and your use of it, or be otherwise implied or incorporated into these Website Terms, by statute, common law or otherwise ).</li>

                </ul>


                <li><h5 className=' mt-4'><b>LIABILITY</b></h5></li>
                <ul className='ol-head-third detailsBody'>
                    <li style={{ textAlign: "justify" }}>12.1. General: Nothing in these Website Terms excludes or limits our liability for death or personal injury arising from our negligence, our liability for fraudulent misrepresentation, or any other liability which cannot be excluded or limited under applicable law. Nothing in these Website Terms affects your statutory rights.</li>
                    <li style={{ textAlign: "justify" }}>12.2. Exclusion of liability: Subject to clause 12.1, we will under no circumstances whatever be liable to you, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with the Website (including the use, inability to use or the results of use of the Website) for:</li>
                    <ul>
                        <li style={{ textAlign: "justify" }}>12.2.1. any loss of profits, sales, business, or revenue;</li>
                        <li style={{ textAlign: "justify" }}>12.2.2. loss or corruption of data, information or software;.</li>
                        <li style={{ textAlign: "justify" }}>12.2.3. loss of business opportunity;</li>
                        <li style={{ textAlign: "justify" }}>12.2.4. loss of anticipated savings;</li>
                        <li style={{ textAlign: "justify" }}>12.2.5. loss of goodwill; or</li>
                        <li style={{ textAlign: "justify" }}>12.2.6. any indirect or consequential loss.</li>
                    </ul>
                    <li style={{ textAlign: "justify" }}>12.3. Limitation of liability: Subject to clauses 11, 12.1 and 12.2, our total liability to you in respect of all other losses arising under or in connection with the Website or your use of it, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, shall in no circumstances exceed twice the value of your Order or £100, whichever is lower.</li>
                    <li style={{ textAlign: "justify" }}>12.4. Additional costs: You assume full and sole responsibility for any additional or associated costs that you may incur in connection with or as a result of your use of the Website, including without limitation costs relating to the servicing, repair or adaptation of any equipment, software or data that you may own, lease, license or otherwise use.</li>

                </ul>
                <li><h5 className=' mt-4'><b>TERMINATION</b></h5></li>
                <ul className='ol-head-third detailsBody'>
                    <li style={{ textAlign: "justify" }}>13.1. Grounds for termination: We may terminate or suspend (at our absolute discretion) your right to use the Website immediately by notifying you in writing (including by email) if we believe in our sole discretion that:</li>
                    <ul>
                        <li style={{ textAlign: "justify" }}>13.1.1. you have used the Website in breach of paragraph 7.1 (License);</li>
                        <li style={{ textAlign: "justify" }}>13.1.2. you have posted Reviews or other Visitor Material in breach of paragraphs 9.2 or 9.3 (Visitor Material and Reviews);</li>
                        <li style={{ textAlign: "justify" }}>13.1.3. you have breached paragraph 10.2 (Links to and from other websites);</li>
                        <li style={{ textAlign: "justify" }}>13.1.4. you have breached any other material terms of these Website Terms;</li>
                        <li style={{ textAlign: "justify" }}>13.1.5. there is misuse, unauthorised, fraudulent or otherwise suspicious activity taking place on your account; or </li>
                        <li style={{ textAlign: "justify" }}>13.1.6. you have significantly or repeatedly breached paragraph 2.5 (Information).</li>
                    </ul>
                    <li style={{ textAlign: "justify" }}>13.2. Obligations upon termination: Upon termination or suspension you must immediately destroy any downloaded or printed extracts from the Website.</li>

                </ul>
                <li><h5 className=' mt-4'><b>WRITTEN COMMUNICATIONS</b></h5></li>
                <ul className='ol-head-third detailsBody'>
                    <li style={{ textAlign: "justify" }}>14.1. Applicable laws require that some of the information or communications we send to you should be in writing. When using the Website or ordering Products via the Website, you accept that communication with us will be mainly electronic. We will contact you by email or provide you with information by posting notices on the Website. For contractual purposes, you agree to this electronic means of communication and you acknowledge that all contracts, notices, information and other communications that we provide to you electronically comply with any legal requirement that such communications be in writing. This condition does not affect your statutory rights.</li>

                </ul>

                <li><h5 className=' mt-4'><b>EVENTS OUTSIDE OUR CONTROL</b></h5></li>
                <ul className='ol-head-third detailsBody'>
                    <li style={{ textAlign: "justify" }}>15.1. We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under these Website Terms that is caused by events outside our reasonable control ("Force Majeure Event").</li>
                    <li style={{ textAlign: "justify" }}> 15.2. A Force Majeure Event includes any act, event, non-occurrence, omission or accident beyond our reasonable control and includes in particular (without limitation) the following:</li>
                    <ul>
                        <li style={{ textAlign: "justify" }}>15.2.1. strikes, lock-outs or other industrial action;</li>
                        <li style={{ textAlign: "justify" }}>15.2.2. civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, epidemic or pandemic, war (whether declared or not) or threat or preparation for war;</li>
                        <li style={{ textAlign: "justify" }}>15.2.3. fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster;</li>
                        <li style={{ textAlign: "justify" }}>15.2.4. impossibility of the use of railways, shipping, aircraft, motor transport or other means of public or private transport;</li>
                        <li style={{ textAlign: "justify" }}>15.2.5. impossibility of the use of public or private telecommunications networks; and </li>
                        <li style={{ textAlign: "justify" }}>15.2.6. the acts, decrees, legislation, regulations or restrictions of any government.</li>
                    </ul>
                    <li style={{ textAlign: "justify" }}>15.3. Our performance under these Website Terms is deemed to be suspended for the period that any Force Majeure Event continues, and we will have an extension of time for performance for the duration of that period. We will use our reasonable endeavours to bring any Force Majeure Event to a close or to find a solution by which our obligations under these Website Terms may be performed despite the Force Majeure Event.</li>
                </ul>

                <li><h5 className=' mt-4'><b>ADDITIONAL TERMS</b></h5></li>
                <ul className='ol-head-third detailsBody'>
                    <li style={{ textAlign: "justify" }}>16.1. Privacy Notice: We are committed to protecting your privacy and security. All personal data that we collect from you will be processed in accordance with our Privacy Notice. You should review our Privacy notice </li>

                    <li style={{ textAlign: "justify" }}>16.2. Other terms: You should also review our cookies policy for information regarding how and why we use cookies to improve the quality of the Website and your use of it, our Voucher terms and conditions for information regarding the use of credits and promotional discounts on the Website,. All of these are incorporated into these Website Terms by this reference.</li>
                    <li style={{ textAlign: "justify" }}>16.3. Severability: If any of these Website Terms are determined by any competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will to that extent be severed from the remaining terms, conditions and provisions which will continue to be valid to the fullest extent permitted by law.</li>
                    <li style={{ textAlign: "justify" }}>16.4. Entire agreement: These Website Terms and any document expressly referred to in them constitute the whole agreement between you and us and supersede all previous discussions, correspondence, negotiations, previous arrangement, understanding or agreement between us relating to the subject matter of any contract.</li>
                    <li style={{ textAlign: "justify" }}>16.5. No waiver: Any failure or delay by you or us in enforcing (in whole or in part) any provision of these Website Terms will not be interpreted as a waiver of your or our rights or remedies.</li>
                    <li style={{ textAlign: "justify" }}>16.6. Assignment: You may not transfer any of your rights or obligations under these Website Terms without our prior written consent. We may transfer any of our rights or obligations under these Website Terms without your prior written consent to any of our affiliates or any business that we enter into a joint venture with, purchase or are sold to</li>
                    <li style={{ textAlign: "justify" }}>16.7. Headings: The headings in these Website Terms are included for convenience only and shall not affect their interpretation.</li>
                </ul>


                <li><h5 className=' mt-4'><b>GOVERNING LAW AND JURISDICTION</b></h5></li>
                <ul className='ol-head-third detailsBody'>
                    <li style={{ textAlign: "justify" }}>17.1. These Website Terms shall be governed by and construed in accordance with English law. You can bring legal proceedings in respect of Website Terms in the English courts. If you live in a part of the United Kingdom other than England or any other Member State of the European Union, you can bring legal proceedings in respect of these Website Terms in either the English courts or the courts of your home country. </li>

                    <li style={{ textAlign: "justify" }}>17.2 As a consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident.  Nothing in these Website Terms, including the paragraph above, affects your rights as a consumer to rely on such mandatory provisions of local law.</li>

                </ul>
            </ol> */}
                    <p><strong>Data Controller:</strong> under UK data protection law, this is the organisation or person responsiblfor deciding how personal information is collected and stored and how it is used. </p>
                    <p><strong>Data Processor:</strong> a Data Controller may appoint another organisation or person to carr out certain tasks in relation to the personal informatio n o n behal f of , an d o n th e writtinstructions of, the Data Controller. (This might be the hosting of a site containing personadata, for example.</p>
                    <p><strong>Personal Information:</strong> in this privacy notice we refer to your personal data as ‘personal informatio’. ‘Personal informatio’ means any information from which a living individual canbe identified. It does not apply to information which has been anonymise</p>
                    <p><strong>Special Informatio –</strong> certain very sensitive personal informatio n require s extr a protectiunder data protection law. Snsitive data includes information relating to health, racial aethnic origin, political opinions, religious and similar beliefs, trade union membership, sex lifeand sexual orientation and also includes genetic information and biometric informatio</p>
                    <li> <h4 className=' mt-4'><b>Details of personal information which we collect and hold about you</b></h4></li>
                    <p>We will retain any information related to you we hold on our servers for no more than 60 daysonce we receive a deletion request from you.</p>
                    <ol className='ol-head-secondary detailsBody'>

                        <p><b>2.1</b> The types of personal data we collect about you may differ according towhether you are a person or a business entity and the relationship betweeus. </p>


                    </ol>
                    <li><h4 className=' mt-4'><b>Details of special information which we collect and hold about you</b></h4></li>
                    <ol className='ol-head-secondary detailsBody'>
                        <p><b>3.1</b> Special information is explained in section 1 above. We do not collect or holany special information about yo</p>
                        <p><b>3.2</b> We do not collect information from you relatin g t o crimina l convictio ns offences.</p>
                    </ol>
                    <li><h4 className=' mt-4'><b>Details of how and why we use personal informatio</b></h4></li>
                    <ol className='ol-head-secondary detailsBody'>
                        <p> <b>4.1</b> We are only able to use your personal information for certain legal reason set out in data protection law. There are legal reasons under data protection law other than thoslisted below, but in most cases, we will use your personal information for the followinglegal reasons: </p>
                        <ol className='ol-head-secondary detailsBody'>
                            <p><strong><b>(a)</b> Contract Reason:</strong> this is in order to perform our obligations to you under acontract we have entered into with you;</p>
                            <p><strong><b>(b)</b> Legitimate Interests Reaso:</strong> this is where the use of your personal informationis necessary for our (or a third party’s) legitimate intersts, so long as that legitimate interest does nt override your fundamental rights, freedoms or interests. </p>
                            <p><strong><b>(c)</b> Legal Obligation Reaso:</strong> this is where we have to use your personal information in order to perform a legal obligation by which we are boundand</p>
                            <p><strong><b>(d)</b> Consent Reason:</strong> this is where you have given us your consent to use your personal information for a specific reason or specific reaso</p>
                        </ol>
                        <p><b>4.2</b> So that we are able to provide you with services, we will need your personal information. If you do not provide us with the required personal informationwe may be prevented from supplying the services. </p>
                        <p><b>4.3</b> It is important that you keep your personal information up to date. If any ofyour personal information changes, please contact us as soon as possible tolet us know. If you do not do this then we may be prevented from supplying the services to you </p>
                        <p><b>4.4</b> Where we rely on consent for a specific purpose as the legal reason forprocessing your personal information, you have the right under dataprotection law to withdraw your consent at any time . I f yo u d o wis h twithdraw your consent, please contact us using the details set out at the beginning of this notice. If we receive a request from you withdrawingyour consent to a specific purpose, we will stop processing your personalinformation for that purpose, unless we have another legal reason forprocessing your personal information, in which case, we will confir m thareason to you.</p>
                        <p><b>4.5</b> We have explained below the different purposes for which we use yourpersonal information and, in each case, the legal reason(s) allowing us to useyour personal information. Please also note the following:</p>
                        <ol className='ol-head-secondary detailsBody'>
                            <p><b>(a)</b> if we use the Legitimate Interests Reason as the legal reason forwhich we can use your personal information, we have also explained what that legitimate interesis; and </p>
                            <p><b>(b)</b> for some of the purposes we may have listed more than one legal reason on which we can use your personal information, because the legal reason may b differentin different circumstances. If you need confirmation of the specific legal reathat we are relying on to use your personal data for that purpose, please contact us using the contact details set out at the start of this privacy notice.</p>
                        </ol>
                    </ol>
                    <div class="col-md-12">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Purpose</th>
                                    <th>Legal Reason(s) for using the personal informatio</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>To enrol you as a customer </td>
                                    <td>Contract Reason.<br />
                                        Legitimate Interests Reason (in order to [offer yoother goods, services and/or digital content which helps us to develop our business) </td>
                                </tr>
                                <tr>
                                    <td>To process your order, which includes taking payment from you, advising you of any updates in relation to your order or any enforcement action against you to recoverpayment. </td>
                                    <td>Contract Reason.<br />
                                        Legitimate Interests Reason (in order to recovermoney which you owe us)</td>
                                </tr>
                                <tr>
                                    <td>
                                        To manage our contract with you and to notifyyou of any changes
                                    </td>
                                    <td>
                                        Contract Reason.<br />
                                        Legal Obligation Re
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        To comply with audit and accounting matte
                                    </td>
                                    <td>
                                        Legal Obligation Reaso
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        For record keeping, [including in relation toany guarantees or warranties provided as partof the sale of goods, services and/or digital conten
                                    </td>
                                    <td>
                                        Contract Reason.<br />
                                        Legal Obligation Reason
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        To improve the services which we supply
                                    </td>
                                    <td>
                                        Legitimate Interests Reason (in order to improvethe services for future customers and to grow our business
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        To recommend and send communications toyou about services that you may be interested in. More details about marketin are set out in section 10 below]
                                    </td>
                                    <td>
                                        Legitimate Interests Reason (in order to grow ourbusiness)
                                        Consent Reason
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        To ensure the smooth running and correct operation of our website
                                    </td>
                                    <td>
                                        Legitimate Interests Reason (to ensure our websiteruns correctly)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        To understand how customers and visitors to our website use the website and interact with it via data analysis
                                    </td>
                                    <td>
                                        Legitimate Interests Reason (to improve and growour business, including our website, and to understand our customer’s needs, desires and requirements)
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ol className='ol-head-secondary detailsBody'>
                            <ol className='ol-head-secondary detailsBody'>
                                <p><b>4.6</b>  Sometimes we may anonymise personal information so that you can no longebe identified from it anuse this for our own purposes. In addition, sometimewe may use some of your personal information together with other people’spersonal information to give us statistical information for our own purposeBecause this is grouped together wither other personal information and youare not identifiable from that combined data we are able to use thi</p>
                                <p><b>4.7</b>  Under data protection laws we can only use your personal information for thpurposes we have told you about, unless we consider that the new purpose is compatible with the purpose(s) which we told you about. If we want to useyour personal information for a different purpose which we do not think icompatible with the purpose(s) which we told you about then we will contactyou to explain this and what legal reason is in place to allow us to do this.</p>

                            </ol>

                        </ol>
                        <li><h4 className=' mt-4'><b>Details of how and why we use personal informatio</b></h4></li>
                        <ol className='ol-head-secondary detailsBody'>
                            <p><b>5.1</b> We usually collect Identity Information, contact Information, Transaction Informadirectly from you when you fill out a fom, purchase services, contact us by e-mail, in writing or otherwise. This includes the personal information which you provide to uwhen you register</p>
                            <p><b>5.2</b> We may receive some of your personal information from third parties or publicly availablsources. This includes:</p>
                            <ol className='ol-head-secondary detailsBody'>
                                <p><b>(a)</b>  Payment Information from our selected thir-party suppliers, such as Citizenspayment </p>

                            </ol>
                        </ol>
                        <li><h4 className=' mt-4'><b>Details about who personal Information may e shared with</b></h4></li>
                        <ol className='ol-head-secondary detailsBody'>
                            <p><b>6.1</b> We may need to share your personal information with other organisations opeople. These organisations include</p>
                            <ol className='ol-head-secondary detailsBody'>
                                <p><b>(a)</b> Third parties who [are not part of our group. These ]may include:</p>
                                <ol className='ol-head-secondary detailsBody'>
                                    <p><b>(i)</b> payment providers, who are based in the UK;</p>
                                    <p><b>(ii)</b> Government bodies and regulatory bodies: such as HMRC, fraud prevention agencies who are based in the UK</p>
                                    <p><b>(iii)</b> Our advisors: such as lawyers, accountants, auditors, insurance companies who are based in the UK;</p>
                                </ol>
                                <p><b>(b)</b> any organisations which propose to purchase our business ad assets in which case we may disclose your personal information to thepotential purchaser</p>
                            </ol>
                            <p><b>6.2</b> Depending on the circumstances, the organisations or people who we shareyour personal information with will be acting as either Data Processors or DatControllers. Where we share your personal information with a Data Processorwe will ensure that we have in place contracts, which set out the responsibilities and obligations of us and them, including in respect of securitof personal informatio</p>
                            <p><b>6.3</b> We do not sell or trade any of the personal information which you haveprovided to us</p>
                        </ol>
                        <li><h4 className=' mt-4'><b>Details about transfers to countries outside of the EEA</b></h4></li>
                        <ol className='ol-head-secondary detailsBody'>
                            <p><b>7.1</b> [We do not transfer your personal information outside of the EEA]</p>
                        </ol>
                        <li><h4 className=' mt-4'><b>Details about how long we will hold your personal informatio</b></h4></li>
                        <ol className='ol-head-secondary detailsBody'>
                            <p><b>8.1</b>  We will only hold your personal data for as long as is necessary. How long is necessary will depend upon the purposes for which we collected the personal information (seesection 4 above) and whether we are under any legal oligation to keep the personalinformation (such as in relation to accounting or auditing records or for tax reasonsWe may also need to keep personal information in case of any legal claims, includingin relation to any guarantees or warranties which have provided with the services.
                                We have set out above the details of our retention periods for different types of dataYou can find them in in section 2 and also in sectio</p>
                        </ol>

                        <li><h4 className=' mt-4'><b>YOUR RIGHTS UNDER DATA PROTECTION LAW</b></h4></li>
                        <ol className='ol-head-secondary detailsBody'>
                            <p><b>9.1</b> Under data protection laws you have certain rights in relation to your personal informatioas follows: </p>
                            <ol className='ol-head-secondary detailsBody'>
                                <p><b>(a) Right to request access: </b>  (this is often called ‘subject access’). This is the rightto obtain from us a copy of the personal information which we hold aboutyou. We must also provide you with certain other information in response tothese requests to help you understand how your personal information isbeing used</p>
                                <p><b>(b) Right to correction </b>  this is the right to request that any incorrect personal data is corrected and that any incomplete personal data is completed</p>
                                <p><b>(c) Right to erasure:</b> (this is often called the “right to be forgotten” This right only applies in certain circumstances. Where it does apply, you have the right to request us to erase all of your personal information.</p>
                                <p><b>(d) Right to restrict processing:</b>  this right only applies in certain circumstances. Where it does apply, you have the right to request us to restrict the processing of your personal information</p>
                                <p><b>(e) Right to data portability:</b> this right allows you to request us to transfer your personal information to someone else</p>
                                <p><b>(f) Right to object:</b>  you have the right to object to us processing your personal informationfor anything other than transactiona purposes. You also have the right to object to us processing personal information where our legalreason for doing so is the Legitimate Interests Reason (see section 4 aboveand there is something about your particular situation which means that yowant to object to us processing your personal information. In certaincircumstances you have the right to object to processing where such processing consists of profilin. </p>

                            </ol>
                            <p><b>9.2 </b> In addition to the rights set out in section 9.1, where we rely on consent athe legal reason for using your personal information, you have the right towithdraw your consent. Further details about this are set out in section 4.</p>
                            <p><b>9.3 </b> If you want to exercise any of the above rights in relation to your personalinformation, please contact us using the details set out at the beginning of this notice.If you do make a request then please note:</p>
                            <ol className='ol-head-secondary detailsBody'>
                                <p><b>(a) </b> we may need certain information from you so that we can verify youridentity</p>
                                <p><b>(b) </b> we do not charge a fee for exercising your rights unless your request is unfounded or excessive; and </p>
                                <p><b>(c) </b> if your request is unfounded or excessive then we may refuse to deal with your request.</p>
                            </ol>
                        </ol>
                        <li><h4 className=' mt-4'><b>Marketin</b></h4></li>
                        <ol className='ol-head-secondary detailsBody'>
                            <p><b>10.1 </b> You will not receive marketin from us about similar goods and services. </p>
                            <p><b>10.2 </b> However, we will give you the opportunity to manage how or if we market to you using Push-notifications in the event of such a policy changIn any e-mail which we send to you, we provide a link to either unsubscribe or opt-out, or to change your marketin preferences. If you have an account with us, you can login to your account and manage your preferences there too. To change your marketin preferences, and/or to request that we stop processing your personal information formarketin purposes , you can always contact us on the details set out at the beginning of this notice</p>
                            <p><b>10.3 </b> If you do request that we stop marketin to you, this will not prevent us from sending communications to you which are not to do with marketin (for example in relation to services which you have purchased from us)</p>
                            <p><b>10.4 </b> We do not pass your personal information on to any third partie s fomarketin purpo</p>
                        </ol>
                        <li><h4 className=' mt-4'><b>Complaints </b></h4></li>
                        <ol className='ol-head-secondary detailsBody'>
                            <p>If you are unhappy about the way that we have handled or used your personal information, you have the right to complain to the UK supervisory authority for dataprotection, which is the Information Commissioner’s OffiICO). Please do contact us in the first instance if you wish to raise any queries or make a complaint in respectof our handling or use of your personal information, so that we have the opportunityto discuss this with you and to take steps to resolve the position. You ca contact us using the details set out at the beginning of this privacy notic.</p>
                        </ol>
                        <li><h4 className=' mt-4'><b>Third Party Websites</b></h4></li>
                        <ol className='ol-head-secondary detailsBody'>
                            <p>Our website may contain links to third party websites. If you click and follow those links then these will take you to the third party website. Those third party websites may collect personal information from you and you will need to check their privacynotices to understand how your personal information is collected and used by the</p>
                        </ol>
                    </div>
                </ol>
                <h5>COOKIE POLICY </h5>
                <p className='text-secondary'>Last updated Aug 29, 2023 </p>
                <p>This Cookie Policy explains how GO TASTE uses cookies and similar technologies to recognize you when you visit our websites at
                    <a href='https://GOTASTE.uk'> https://GOTASTE.uk</a> It explains what these technologies are and why we
                    use them, as well as your rights to control our use of them.
                    In some cases we may use cookies to collect personal information, or that becomes personal information if we combine it with other information.</p>
                <h5>What are cookies?</h5>
                <p className='text-secondary'>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.
                    Cookies set by the website owner GO TASTE are called "first party cookies". Cookies set by parties other than the website owner are called "third party cookies". Third party cookies enable third party features or functionality to be provided on or through the website (e.g. like advertising, interactive content and analytics). The parties that set these third party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.</p>
                <h5>Why do we use cookies? </h5>
                <p className='text-secondary'>We use first and third party cookies for several reasons. Some cookies are required for technical reasons in order for our Website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable to track and target the interests of our users to enhance the experience on our Online Properties.
                    Third parties serve cookies through our Website for essential purposes only such as security authentication. This is described in more detail below. The specific types of first and third party cookies served through our Websites and the purposes they perform are described below.</p>
                <h5>How can I control cookies?</h5>
                <p className='text-secondary'>You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent manager. The Cookie Consent modal allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.
                    The Cookie Consent Manager can be found in the notification banner and on our website. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted. You may
                    also set or amend your web browser controls to accept or refuse cookies. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser's help menu for more information.
                </p>
                <h5>Essential website cookies: </h5>
                <p className='text-secondary'>These cookies are strictly necessary to provide you with services available through our Website and to use some of its features, such as access to secure areas.</p>
                <p className='text-secondary'>Name: ARRAffinity</p>
                <p className='text-secondary'>Purpose: Used by websites running on the Windows Azure cloud platform f or load balancing. Provider: .go55050.co.uk </p>
                <p className='text-secondary'>Service: Azure <a href='#' style={{ textDecoration: 'none' }}>View Service Privacy Policy</a></p>
                <p className='text-secondary'>Country: United Kingdom</p>
                <p className='text-secondary'>Type: http_cookie</p>
                <p className='text-secondary'>Expires in: session</p>

                <p className='text-secondary'>Name: ARRAffinitySameSite </p>
                <p className='text-secondary'>Purpose: __________ </p>
                <p className='text-secondary'>Provider: .GOTASTE.uk</p>
                <p className='text-secondary'>Service: __________ </p>
                <p className='text-secondary'>Country: United Kingdom</p>
                <p className='text-secondary'>Type: server_cookie</p>
                <p className='text-secondary'>Expires in: session</p>

                <h5>What about other tracking technologies, like web beacons?</h5>
                <p className='text-secondary'>Cookies are not the only way to recognize or track visitors to a website. We may use other, similar technologies from time to time, like web beacons (sometimes called "tracking pixels" or "clear gifs"). These are tiny graphics files that contain a unique identifier that enable us to recognize when someone has visited our Websites or opened an e-mail including them. This allows us, for example, to monitor the traffic patterns of users from one pagewithin a website to another, to deliver or communicate with cookies, to understand whether you have come to the website from an online advertisement displayed on a third-party website, to improve site performance. In many instances, these technologies are reliant on cookies to function properly, and so declining cookies will impair their functioning. </p>

                <h5>Do you use Flash cookies or Local Shared Objects? </h5>
                <p className='text-secondary'>Websites may also use so-called "Flash Cookies" (also known as Local Shared Objects or "LSOs") to, among other things, collect and store information about your use of our services, fraud prevention and for other site operations. If you do not want Flash Cookies stored on your computer, you can adjust the settings of your Flash player to block Flash Cookies storage using the tools contained in the <a href='#' style={{ textDecoration: 'none' }}>Website Storage Settings Panel.</a> You can also </p>
                <p className='text-secondary'>control Flash Cookies by going to the<a href='#' style={{ textDecoration: 'none' }}> Global Storage Settings Panel</a> and following the instructions (which may include instructions that explain, for example, how to delete existing Flash Cookies (referred to "information" on the Macromedia site), how to prevent Flash LSOs from being placed on your computer without your being asked, and (for Flash Player 8 and later) how to block Flash Cookies that are not being delivered by the operator of the page you are on at the time).
                    Please note that setting the Flash Player to restrict or limit acceptance of Flash Cookies may reduce or impede the functionality of some Flash applications, including, potentially, Flash applications used in connection with our services or online content. </p>
                <h5>Do you serve targeted advertising? </h5>
                <p className='text-secondary'>Currently we do not serve targeted advertising.
                    In the future, Third parties may serve cookies on your computer or mobile device to serve advertising through our Website and you will be notified of this and given the option to reject third party cookies. These companies may use information about your visits to this and other websites in order to provide relevant advertisements about goods and services that you may be interested in. They may also employ technology that is used to measure the effectiveness of advertisements. This can be accomplished by them using cookies or web beacons to collect information about your visits to this and other sites in order to provide relevant advertisements about goods and services of potential interest to you. The information collected through this process does not enable us or them to identify your name, contact details or other details that directly identify you unless you choose to provide these.</p>
                <h5>How often will you update this Cookie Policy?</h5>
                <p className='text-secondary'>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons.
                    Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.
                    The date at the top of this Cookie Policy indicates when it was last updated. </p>
                <h5>Where can I get further information?</h5>
                <p className='text-secondary'>If you have any questions about our use of cookies or other technologies, please email us at info@gotaste.uk</p>

            </div>

        </div>
    )
}

export default Privacy