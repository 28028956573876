import axios from 'axios';


export async function uploadFiles(filesData) {
    console.log(filesData);
    const apiAddress = process.env.REACT_APP_SECRET;

    var data = [];

    // filesData.map(async(dt, index) => {
        // console.log(dt.length);
        const formData = new FormData();
        formData.append('file', filesData);
       await axios({
            method: 'post',
            url: apiAddress + '/api/FileUpload/addfile',
            headers: {
                'Content-Type': 'multipart/form-data' // <- HERE
            },
            data: formData
        })
            .then((response) => {
                console.log(response)
                data.push( response.data.url);
                console.log(response.data.url)
            })
            .catch((error) => {
                console.log(error);

            });
    // })

    return data

}