import React, { useEffect, useState, useRef } from 'react';
import BannerSection from './../Home/BannerSection/BannerSection'
import RestaurantDetailView from './RestaurantDetailView/RestaurantDetailView'
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import Test3 from '../Test3';
import FilterBar from './MenuSelection/FilterBar/FilterBar';
import './MenuSelection/FilterBar/FilterBar.css'
import './MenuSelection/BottomBar/BottomBar'
import BottomBar from './MenuSelection/BottomBar/BottomBar';
import Cart from '../Cart/Cart';
import Navbar from '../Navbar/Navbar';
import menuIcon from './../../Assets/Icons/menu2.png'
import gridIcon from './../../Assets/Icons/Viewmenu.png'
import GalleryView from '../GalleryView/GalleryView';


const Restaurant = () => {
  const [isMenuSelected, setMenuSelected] = useState(true)

  const onMenuiconClick = () => {
    setMenuSelected(!isMenuSelected)
  }
  //   const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false);
  // const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 767);
  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsSmallScreen(window.innerWidth < 767);
  //   };

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  const navigate = useNavigate();
  // Check if user is authenticated
  const token = localStorage.getItem('token');
  let decoded = null;
  try {
    decoded = jwt_decode(token);
    console.log(decoded);
  } catch (error) {
    // Invalid token
  }
  // Define logout function
  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  }
  const isAuthenticated = decoded && decoded.UserName === 'testT';

  // FiterBar PopUp at the top of screen functionality
  const [isFilterBarFixed, setIsFilterBarFixed] = useState(false);
  const filterBarRef = useRef(null);
  let prevScrollY = 0;
  const [isScrollingUp, setIsScrollingUp] = useState(false);

  const handleScroll = () => {
    const filterBarRect = filterBarRef.current.getBoundingClientRect();
    const isAboveViewport = filterBarRect.top < 0 ? true : filterBarRect.top === 0 ? false : false;

    if (isAboveViewport) {
      setIsFilterBarFixed(true);
      // when sccroll up then top is 69 px/ when scroll down top 0
      const scrollingUp = window.scrollY < prevScrollY;
      setIsScrollingUp(scrollingUp);
    } else {
      setIsFilterBarFixed(false);
    }

    prevScrollY = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Scroll to Menu onclick View Menu from RestaurantDetailView 
  const test3Ref = useRef(null);
  const [blur, setBlur] = useState(false)
  const searchDialogOpen = (NavbarData) => {
    setBlur(NavbarData)

  }

  const blurStyle = {
    filter: 'blur(4px)'
  };
  return (
    <div className="overFlowhidden">
      <Navbar handleNavbar={searchDialogOpen} />
      {/* <Cart /> */}
      <div className={`${blur ? "BackBlur" : ""}`}>

        <BannerSection></BannerSection>


        {/* Scroll to Menu onclick View Menu from RestaurantDetailView  */}
        <RestaurantDetailView test3Ref={test3Ref} ></RestaurantDetailView>
        <div
          ref={test3Ref}
          className={`customContainer row-filterbar ${isFilterBarFixed ? 'fixed bg-white position-fixed start-0 w-100' : ''}`}
          style={{ top: isFilterBarFixed && isScrollingUp ? '107px' : '0px' }}
        >
          <FilterBar isFilterBarFixed={isFilterBarFixed} />
        </div>

        <div ref={filterBarRef}></div>
        <div className='d-none'>


        </div>
        <div className='customContainer  d-flex justify-content-end ' onClick={onMenuiconClick}>
          <img src={isMenuSelected ? menuIcon : gridIcon} alt='menu' className='mobileDisplay' style={{ width: isMenuSelected ? '2rem' : '1.5rem', height: isMenuSelected ? '2rem' : '1.5rem' }} />
        </div>
        {isMenuSelected ?
          <Test3 /> : <GalleryView type={'under'} />
        }
        <BottomBar />
      </div>


    </div>
  );
};

export default Restaurant;
