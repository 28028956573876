import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { setSearchName } from './../../../../actions/index'
import './BottomBar.css'
import './BottomBar.css'
import FilterDialogue from '../FilterDialogue/FilterDialogue'
import subscription from '../../../../Assets/Icons/notification.png'
import subscriptionWhite from '../../../../Assets/Icons/notification-white.png'
import Corporate from '../../../../Assets/Icons/corporate.png'
import CorporateWhite from '../../../../Assets/Icons/corporate-white.png'
import store from '../../../../Assets/Icons/shop.png'
import storeWhite from '../../../../Assets/Icons/shop-white.png'
import filterWhite from '../../../../Assets/Icons/filter-white.png'
import filter from '../../../../Assets/Icons/filter.png'
import home from '../../../../Assets/Icons/home.png'
import homeWhite from '../../../../Assets/Icons/homeWhite.png'
import pkbImg from '../../../../Assets/Image/PKB.png'
import restaurant from '../../../../Assets/Icons/restaurant.png'
import restaurantWhite from '../../../../Assets/Icons/restaurantWhite.png'
import { useNavigate } from 'react-router-dom';


const BottomBar = ({ isModalVisible, toggleModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 767)
  const bottomsheetRef = useRef(null)
  const startYRef = useRef(null)




  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 767)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const [selectedBtn, setSelectedBtn] = useState(0)

  useEffect(() => {
    const bottomsheet = bottomsheetRef.current
    let startY

    const handleTouchStart = (e) => {
      startY = e.touches[0].clientY
      startYRef.current = startY
    }

    const handleTouchEnd = (e) => {
      const endY = e.changedTouches[0].clientY
      const diffY = endY - startYRef.current

      if (diffY > 50) {
        toggleModal(false)
      }
    }
    if (window.innerWidth < 767) {
      bottomsheet.addEventListener('touchstart', handleTouchStart)
      bottomsheet.addEventListener('touchend', handleTouchEnd)

      return () => {
        bottomsheet.removeEventListener('touchstart', handleTouchStart)
        bottomsheet.removeEventListener('touchend', handleTouchEnd)
      }
    }
  }, [toggleModal])

  const goToHome = () => {
    navigate("/home");
  }

  function handleButtonClick(index) {
    setSelectedBtn(index)
    const dropdownItems = document.querySelectorAll('.bottomsheetBtn')

    dropdownItems.forEach((item) => {
      item.classList.remove('selected')
    })

    const selectedDropdownItem = document.querySelector(
      `.bottomsheetBtn:nth-child(${index})`,
    )
    selectedDropdownItem.classList.add('selected')
    if (index === 5) {
      setShowModal(true)
    }
  }
  // BottomBar closing functionality when click outside of BottomBar
  const [showModal, setShowModal] = useState(false)

  const bottomBarRef = useRef(null);

  const closeModal = () => {
    document.body.classList.remove('modal-open');
    toggleModal(false);
  };
  const closeFilterModal = () => {
    document.body.classList.remove('modal-open');
    setShowModal(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isModalVisible &&
        bottomBarRef.current &&
        !bottomBarRef.current.contains(event.target)
      ) {
        closeModal();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isModalVisible, toggleModal]);


  return (
    <div>
      {isSmallScreen && (
        <div ref={bottomBarRef} className={`bottomBarContainer ${isModalVisible ? 'visible' : ''}`}>
          <div ref={bottomsheetRef} className={`bottomsheet bg-white vw-100 fixed-bottom w-100 d-flex flex-column overflow-hidden 
          ${isModalVisible ? 'visible' : ''}`}>
            <div className=" bottomsheetSlideBtn d-flex align-items-center h-100" onClick={() => toggleModal(!isModalVisible)}></div>
            <div className=" bottomsheetBtn d-flex align-items-center h-100" onClick={() => {
              handleButtonClick(2)
              dispatch(setSearchName('Store menue'))
            }}>
              <img className="BottomBarImg" src={selectedBtn === 2 ? storeWhite : store} alt="In Store Menu" />
              <span className=" mx-auto">In Store Menu</span>
            </div>
            <div className=" bottomsheetBtn d-flex align-items-center h-100" onClick={() => {
              handleButtonClick(3)
              dispatch(setSearchName('corporate menue'))
            }}>
              <img className="BottomBarImg" src={selectedBtn === 3 ? CorporateWhite : Corporate} alt="Corporate Menu" />
              <span className=" mx-auto">Corporate Menu</span>
            </div>
            <div className=" bottomsheetBtn d-flex align-items-center h-100" onClick={() => {
              handleButtonClick(4)
              dispatch(setSearchName('subscription menue'))
            }} >
              <img className="BottomBarImg" src={selectedBtn === 4 ? subscriptionWhite : subscription} alt="Subscription" />
              <span className=" mx-auto">Subscription</span>
            </div>
            <div className=" bottomsheetBtn d-flex align-items-center h-100" onClick={() => handleButtonClick(5)}>
              <img className="BottomBarImg" src={selectedBtn === 5 ? filterWhite : filter} alt="Filters" />
              <span className=" mx-auto">Filters</span>
            </div>
            <div className="homeRestaurantDiv  d-flex align-items-center h-100" >
              <img className="homeRestaurant" src={selectedBtn === 6 ? homeWhite : home} alt=".." onClick={() => goToHome()} />
              <img className="homeRestaurant" src={selectedBtn === 6 ? pkbImg : pkbImg} style={{ width: '25px' }} alt=".." onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }} />
            </div>
          </div>
        </div>
      )}
      <div className={`modal-backdrop fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }}></div>
      <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none', zIndex: 9999 }}
        onClick={(e) => { if (e.target === e.currentTarget) { closeFilterModal() } }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content model-content-filterDialogue">
            <div className="modal-header p-1 ps-3">
              <div
                className="btn btn-close-filterdialogue justify-content-center align-content-center" onClick={closeFilterModal}>
                <span aria-hidden="true">&times;</span>
              </div>
              <h5 className="modal-title m-auto">Filters</h5>
            </div>
            <div className="modal-body">
              <FilterDialogue />
            </div>
            <div className="modal-footer">
              <div className="btn btn-close-filterdialogue me-auto fs-6" onClick={closeFilterModal}>
                <span aria-hidden="true">Clear all</span>
              </div>
              <div className="btn btn-close-filterdialogueApply ms-auto fs-6 w-25" onClick={closeFilterModal}>
                <span aria-hidden="true">Apply</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BottomBar
