import React, { useState } from 'react';
import BannerSection from './BannerSection/BannerSection';
import FoodHistory from './FoodHistory/FoodHistory'
import FoodSelection from './FoodSelection/FoodSelection';
import ProvidedServices from './ProvidedServices/ProvidedServices';
import Navbar from './../Navbar/Navbar';
import './Home.css'
import Cart from './../Cart/Cart'

const Home = () => {
    const [blur, setBlur] = useState(false)
    const searchDialogOpen = (NavbarData) => {
        setBlur(NavbarData)

    }

    const blurStyle = {
        filter: 'blur(4px)'
    };
    return (
        <div >
            <Navbar handleNavbar={searchDialogOpen} />
            {/* <Cart /> */}
            <div className={`${blur ? "BackBlur" : ""}`}>
                <BannerSection></BannerSection>
                <ProvidedServices></ProvidedServices>
                <FoodHistory></FoodHistory>
                <FoodSelection></FoodSelection>
            </div>
        </div>
    );
};

export default Home;