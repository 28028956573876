import React from 'react'
import Select from 'react-select'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Autoplay, Navigation, EffectCoverflow, Pagination } from 'swiper'
import 'swiper/css/navigation'


import '../../Home/FoodSelection/FoodSelection.css'
import Corporate from './../../../Assets/Icons/corporate.png'
import store from './../../../Assets/Icons/shop.png'

import '../RestaurantDetailView/RestaurantDetailView.css'
import FoodMenuSelection from './FoodMenuSelection'
import FilterBar from './FilterBar/FilterBar'
import subscription from './../../../Assets/Icons/notification.png'
import { useEffect, useState, useRef } from 'react'
import shop from './../../../Assets/Icons/shopping-cart.png'
import search from './../../../Assets/Icons/searchIcon.png'
import './MenuSelection.css'
import MenuSlider from '../../GalleryView/menuslider/MenuSlider'

const ManuSelection = (props) => {
  const containerRef = useRef(null)
  const [scrollAmount, setScrollAmount] = useState(0)
  const [searchPlaceholder, setSearchPlaceholder] = useState('Search')

  //   const [storePlaceholder, setStorePlaceholder] = useState('')
  //   const [corporatePlaceholder, setCorporatePlaceholder] = useState('')
  //   const [subscriptionPlaceholder, setSubscriptionPlaceholder] = useState('')

  const handleScrollRight = () => {
    const container = containerRef.current
    const containerWidth = container.clientWidth
    const maxScrollAmount = container.scrollWidth - containerWidth
    const newScrollAmount = Math.min(
      scrollAmount + containerWidth,
      maxScrollAmount,
    )
    container.scrollTo({ left: newScrollAmount, behavior: 'smooth' })
    setScrollAmount(newScrollAmount)
  }
  const myRef = useRef()
  const [intersectings, setIntersectings] = useState(false)
  const [filterBars, setFilterBars] = useState(false)

  const [storeSelection, setstoreSelection] = useState(false)
  const [CorporateSelection, setCorporateSelection] = useState(false)
  const [shopSelection, setshopSelection] = useState(false)
  const [subcriptionSelection, setsubcriptionSelection] = useState(false)

  const [lastScrollValues, setLastScrollValues] = useState(0)

  const [dropdownOpen, setDropdownOpen] = useState(false)
 
  //const [showButton, setShowButton] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setShowButton(window.scrollY > 3000 && window.innerWidth < 500);
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // setTimeout(() => {
  //   const row = document.querySelector('.row-filterbar');
  //   if (row) {
  //     const offsetTop = row.offsetTop + 180;

  //     window.addEventListener('scroll', () => {
  //       if (window.scrollY > offsetTop) {
  //         row.classList.add('fixed');
  //       } else {
  //         row.classList.remove('fixed');
  //       }
  //     });
  //   }
  // }, 1);

  // const toggleDropdown = () => {
  //   setDropdownOpen(!dropdownOpen);
  // };

  // const handleButtonClick = (buttonIndex) => {
  //   console.log(`Button ${buttonIndex} clicked!`);
  // };
  // function handleScroll() {
  //   if (window.scrollY > 2480) {
  //     setFilterBars(true);
  //   } else {
  //     setFilterBars(false);
  //   }
  //   setLastScrollValues(window.scrollY);
  // }

  // useEffect(() => {
  //   if (window.innerWidth < 767) {
  //     window.addEventListener("scroll", handleScroll);
  //   }
  //   // return () => {
  //   //   window.removeEventListener("scroll", handleScroll);
  //   // };
  // }, []);

  const handleSearchClick = () => {
    // const value = e.target.value
    setSearchPlaceholder('Search store menue')
  }
  const handleCorporateClick = () => {
    setSearchPlaceholder('Search corporate menue')
  }
  const handleSubscriptionClick = () => {
    setSearchPlaceholder('Search subscription')
  }
  //   const handleCorporateClick = () => {
  //     setCorporatePlaceholder(corporatePlaceholder + ' corporate menue')
  //   }
  //   const handleSubscriptionClick = () => {
  //     setSubscriptionPlaceholder(subscriptionPlaceholder + ' subscription')
  //   }

//select start
const [selectedOption, setSelectedOption] = useState(null)
const options = [
  { value: 'barger', label: 'Barger' },
  { value: 'pizza', label: 'Pizza' },
  { value: 'chicken', label: 'Chicken' },
]
function handleOptionChange(selectedOption) {
  setSelectedOption(selectedOption)
}

//select end

//search start
const [isVisisbleDiv, setIsvisibleDiv]=useState(false)
const [isClick, setIsClick]=useState(false)
const [dialogData, setDialogData]=useState('')



const handleSearch=(event)=>{
  var data=event.target.value
  if(data!=''){
   setIsvisibleDiv(true)
  }
   else{
     setIsvisibleDiv(false)
     
   }
   
 
   setDialogData(data)
 }
 const handleIsClick=()=>{
  //  setIsClick(!isClick)
  setIsvisibleDiv(false)
  
    setDialogData('')
 
 }




//search end

//  search modal outside click start

const searchDialogdivRef = useRef(null);

const handleClickOutside = (event) => {
  if (searchDialogdivRef.current && !searchDialogdivRef.current.contains(event.target)) {
    setIsvisibleDiv(false);
  }
};



useEffect(() => {
  document.addEventListener('mousedown', handleClickOutside);

  return () => {
      document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

// search modal outside click end

//swiper start
const [fromHome, SetFromHome] = useState(true);
useEffect(() => {
    console.log(props);
    if (props.from === 2) {
        SetFromHome(false);
    }
})
//swiper end
  return (
    <>
    <div className='row align-items-center' >
      <div className='col-12 col-md-3'>
       <div className='d-flex flex-column' ref={searchDialogdivRef}>
        <input
          type="text"
          className="searchBar"
          placeholder={searchPlaceholder}
          onChange={handleSearch}
          onClick={handleIsClick}
        />
        {(isVisisbleDiv ) &&
          <div className='col-12 col-md-12'>
          <div className='searchBottommDiv'>
              <div className='pt-4 d-flex ' style={{paddingLeft:'6px'}}>
                <img src={search} alt='img' style={{width:'1rem', height:'1rem', marginTop:'2px', marginRight:'10px'}}/>
                {dialogData}
              </div>
              <hr/>
              <div style={{paddingLeft:'6px'}}>
              <img src={search} alt='img' style={{width:'1rem', height:'1rem', marginTop:'2px', marginRight:'10px'}}/>
                {'Cheese ' +dialogData}
              </div>
              <hr/>
              <div style={{paddingLeft:'6px'}}>
              <img src={search} alt='img' style={{width:'1rem', height:'1rem', marginTop:'2px', marginRight:'10px'}}/>
                  {'Normal ' +dialogData}
              </div>
              <hr/>
              <div style={{paddingLeft:'6px', }}>
              <img src={search} alt='img' style={{width:'1rem', height:'1rem', marginTop:'2px', marginRight:'10px'}}/>
                <b>Search for {dialogData}</b>
              </div>
          </div>
          </div>
            }
       
        </div>
     </div>
     <div className='col-12 col-md-9'>
        <MenuSlider/>
     </div>
    </div>
    </>
  )
}

export default ManuSelection
