import React,{useEffect, useState} from 'react'
import './Sauces.css'


function Sauces(props) {
    const [amount, setAmount]=useState(0)
    const [selectedItem, setSelectedItem]=useState({name:'', amount:0})
    const[id, setId]=useState(props.id)
    //sauces quantity start

    
const [sacesQunatity, setsacesQunatity]=useState(0);

const changeQuantity=(types)=>{
  
  if(types=='plus'){
    setsacesQunatity(sacesQunatity +1);
  }
  else if(types=='minus'){
    if(sacesQunatity<1){
      setsacesQunatity(0)
    }
    else{
      setsacesQunatity(sacesQunatity -1)
    }
  }
 
 
}

//sauces quantity end
useEffect(() => {
    setAmount(sacesQunatity*props.price)
    setSelectedItem({name:props.name, amount:amount})
   
},[sacesQunatity])
useEffect(() => {
    
    setSelectedItem({name:props.name, amount:amount})
    props.saucesCallback(id, amount)
   
},[amount])

  return (
    <>
    <div className='d-flex justify-content-between'>
      
        <div className='d-flex flex-column'>
            <h6>{props.name}</h6>
            <div className='d-flex'>
                {amount==0?
                <p>{props.price}$</p> : <p>{amount}$</p>
                }
                {/* <div className='saucesTag'>
                    <p className='searchText' >Normal</p>
                </div>
                <div className='saucesTag'>
                    <p className='searchText' >Midium 5$</p>
                </div>
                <div className='saucesTag'>
                    <p className='searchText' >Spicy 10$</p>
                </div> */}
            </div>
        </div>
        
        <div className='d-flex' style={{gap:'10px'}}>
    {sacesQunatity>0 &&
      <>
        <div onClick={() => { changeQuantity('minus') }} className= " plusCircle" ><p>-</p></div>
   
        {sacesQunatity} 
        </>
    }   
        <div onClick={() => { changeQuantity('plus') }} className=' plusCircle'><p>+</p></div>
        </div>
        
    </div>
    {sacesQunatity>0 &&
    <div style={{marginLeft:'1.5rem'}}>
    <div className="form-check p-0 ms-4 checkBoxOrderPage">
        <input
          className="form-check-input "
          type="radio"
          name="exampleRadios"
          id="exampleRadios1"
          value={10}
          
          // checked={halfCheked}
          // onClick={halfDoubleClick}
          // onChange={varientHandler}
        />
          <div className='d-flex justify-content-between'>
              <label
                className="form-check-label "
                for="exampleRadios1"
              >
                Normal
              </label>
              <p>10$</p>
        </div>
  </div>
  <div className="form-check p-0 ms-4 checkBoxOrderPage">
        <input
          className="form-check-input "
          type="radio"
          name="exampleRadios"
          id="exampleRadios1"
          value={10}
          
          // checked={halfCheked}
          // onClick={halfDoubleClick}
          // onChange={varientHandler}
        />
          <div className='d-flex justify-content-between'>
              <label
                className="form-check-label "
                for="exampleRadios1"
              >
                Midium
              </label>
              <p>10$</p>
        </div>
  </div>
  <div className="form-check p-0 ms-4 checkBoxOrderPage">
        <input
          className="form-check-input "
          type="radio"
          name="exampleRadios"
          id="exampleRadios1"
          value={10}
          
          // checked={halfCheked}
          // onClick={halfDoubleClick}
          // onChange={varientHandler}
        />
          <div className='d-flex justify-content-between'>
              <label
                className="form-check-label "
                for="exampleRadios1"
              >
                Spicy
              </label>
              <p>10$</p>
        </div>
  </div>
  </div>
}
    </>
  )
}

export default Sauces