import React from 'react';
import Slider from "react-slick";
import img1 from './Assets/Image/1 (1).jpg'
import img2 from './Assets/Image/user-review (2).avif'
import pkb from './Assets/Image/PKB.png'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './testThumb.css'
const TestThumb = () => {
    const settings = {
        customPaging: function (i) {
            console.log("i: " + i);
            return (
                <a>
                    <div className='thumb'>
                        <div className="discountImage">
                            {
                                i == 0 ? (
                                    <>
                                        <img src={img1} alt="" className="img-fluid image-Discount" />
                                        <div className="discountOfferDiv ">
                                            <div className="discountOfferDivDetails ">
                                                <div className='offer-Text'>
                                                    <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                                    <h6 style={{ marginBottom: "0px" }}>
                                                        <div className='d-flex' style={{ gap: "10px" }}>
                                                            <img src={img1} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                                        </div>
                                                    </h6>
                                                </div>
                                                <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                                    <button className='btn btn-buyNow'>Order Now </button>
                                                    <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : i == 1 ? (
                                    <>
                                        <img src={img2} alt="" className="img-fluid image-Discount" />
                                        <div className="discountOfferDiv ">
                                            <div className="discountOfferDivDetails ">
                                                <div className='offer-Text'>
                                                    <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                                    <h6 style={{ marginBottom: "0px" }}>
                                                        <div className='d-flex' style={{ gap: "10px" }}>
                                                            <img src={img1} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                                        </div>
                                                    </h6>
                                                </div>
                                                <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                                    <button className='btn btn-buyNow'>Order Now </button>
                                                    <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <img src={img2} alt="" className="img-fluid image-Discount" />
                                        <div className="discountOfferDiv ">
                                            <div className="discountOfferDivDetails ">
                                                <div className='offer-Text'>
                                                    <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                                    <h6 style={{ marginBottom: "0px" }}>
                                                        <div className='d-flex' style={{ gap: "10px" }}>
                                                            <img src={img1} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                                        </div>
                                                    </h6>
                                                </div>
                                                <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                                    <button className='btn btn-buyNow'>Order Now </button>
                                                    <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                        </div>
                    </div>
                </a>

            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div className='thumbSlider'>
            <Slider {...settings}>
                <div>
                    <div className="discountImage ">

                        <img src={img1} alt="" className="img-fluid image-Discount" />
                        <div className="discountOfferDiv ">
                            <div className="discountOfferDivDetails ">
                                <div className='offer-Text'>
                                    <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                    <h6 style={{ marginBottom: "0px" }}>
                                        <div className='d-flex' style={{ gap: "10px" }}>
                                            <img src={pkb} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                        </div>
                                    </h6>
                                </div>
                                <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                    <button className='btn btn-buyNow'>Order Now </button>
                                    <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="discountImage ">

                        <img src={img2} alt="" className="img-fluid image-Discount" />
                        <div className="discountOfferDiv ">
                            <div className="discountOfferDivDetails ">
                                <div className='offer-Text'>
                                    <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                    <h6 style={{ marginBottom: "0px" }}>
                                        <div className='d-flex' style={{ gap: "10px" }}>
                                            <img src={pkb} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                        </div>
                                    </h6>
                                </div>
                                <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                    <button className='btn btn-buyNow'>Order Now </button>
                                    <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="discountImage ">

                        <img src={img2} alt="" className="img-fluid image-Discount" />
                        <div className="discountOfferDiv ">
                            <div className="discountOfferDivDetails ">
                                <div className='offer-Text'>
                                    <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                    <h6 style={{ marginBottom: "0px" }}>
                                        <div className='d-flex' style={{ gap: "10px" }}>
                                            <img src={pkb} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                        </div>
                                    </h6>
                                </div>
                                <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                    <button className='btn btn-buyNow'>Order Now </button>
                                    <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    );
};

export default TestThumb;