import React, { useEffect, useRef, useState } from 'react';
import './Cart.css'
import bag from './../../Assets/Icons/bag.png'
import shoppingCart from './../../Assets/Icons/shopping-bag.png'
import CardDetails from './Components/CardDetails/CardDetails';
import cancelIcon from './../../Assets/Icons/close.png'

const Cart = () => {
    const [showDetails, setShowDetails] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const cardDetails = useRef(null);
    const cart = useRef(null);
    const navbarTopRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                showDetails &&
                cardDetails.current &&
                !cardDetails.current.contains(event.target) &&
                !cart.current.contains(event.target)
            ) {
                setShowDetails(false)
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    });
    const [navbarOnTop, setNavbarOnTop] = useState(true);
    const [scrollValue, setscrollValue] = useState(0);
    const [slideUp, setSlideUp] = useState(true);
    useEffect(() => {
        window.addEventListener("scroll", (event) => {
            const filterBarRect = navbarTopRef.current.getBoundingClientRect();
            const isAboveViewport = filterBarRect.top === 0 ? true : false;
            setNavbarOnTop(isAboveViewport);
            var top = (filterBarRect.top * (-1));
            var scrollval = (scrollValue * (-1));
            if (top > scrollval) {
                setSlideUp(true);
                setShowDetails(false)
            }
            else if (top <= 0) {
                setSlideUp(true);
            }
            else {
                setSlideUp(false);
                setShowDetails(false)
            }

            var s = filterBarRect.top;
            setscrollValue(s);
        })

    });
    const showCartDetails = () => {
        console.log("showDetails: " + showDetails);
        if (!showDetails) {
            setShowDetails(true)
        }
        else {
            setShowDetails(false)
        }
    };

    return (
        <div className='cart d-flex align-items-end '>
            <div ref={navbarTopRef}>

            </div>
            <div className={`${showDetails ? "cart-details" : "d-none"} ${slideUp ? "" : " d-none"} `} ref={cardDetails}>
                <div className='d-flex justify-content-center mt-1'>
                    <h6 style={{ fontWeight: "bold", color: "#3D1E19" }}>Your Order from Pot Kettle Black</h6>
                </div>
                <div className='card-item-details'>
                    <CardDetails />
                    <CardDetails />
                    <CardDetails />
                    <CardDetails />

                </div>
                <div className='p-1'>
                    <hr />
                    <div className='d-flex justify-content-between mb-2'>
                        <h6>Subtotal</h6>
                        <h6>10.5$</h6>
                    </div>
                    <div className='d-flex justify-content-between mb-2'>
                        <h6>Vat</h6>
                        <h6>10.5$</h6>
                    </div>
                    <div className='d-flex justify-content-between' >
                        <h6><b>Total</b></h6>
                        <h6>21.0$</h6>
                    </div>
                    <div className='d-flex justify-content-between' >
                        <a className='btn btn-checkout' href='/checkout'>Checkout</a>
                    </div>
                </div>
            </div>
            <div ref={cart}>
                <div className='mobileCart'>
                    <div className={`d-flex explore align-items-center`} style={{ marginTop: "-.6rem" }} onClick={() => { showCartDetails() }} >
                        <img src={shoppingCart} alt="" className='searchIconcoffee' />
                        <span>Cart</span>
                        {/* <Cart /> */}
                    </div>
                </div>
                <div className='desktopCart'>
                    <div className='popup' onClick={() => { showCartDetails() }} >
                        <img src={bag} alt="" />
                        {/* <img src={cancelIcon} alt="" className={`${showDetails ? "" : "d-none"}`} /> */}
                    </div>
                </div>
            </div>



        </div>
    );
};

export default Cart;