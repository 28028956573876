import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Autoplay, Navigation } from 'swiper'
import 'swiper/css/navigation'
import './Suggestion.css'

import starImg from './../../../Assets/Image/Star 14.png'
import img1 from './../../../Assets/Image/1 (1).jpg'

function Suggestion() {
  return (
    <div>
      <div className="row suggestionswiperDiv desktopDisplay">
        <h3 className="col-12 header">Suggestions</h3>
        <div className="col-12 swiperDiv">
          <Swiper
            className="pt-2 suggestionSwiper"
            spaceBetween={18}
            grabCursor={false}
            centeredSlides={false}
            slidesPerView={2}
            hashNavigation={{
              watchState: true,
            }}
            // autoplay={{
            //   delay: 2200,
            //   disableOnInteraction: true,
            // }}




            navigation={true}
            modules={[Autoplay, Navigation]}
          >
            {/* this part will loop  */}
            <SwiperSlide>
              <div className="card  cardtest2">
                <img
                  src={img1}
                  className="card-img-top card-img-test2"
                  alt="..."
                />
                <div className="card-body pl-0">
                  <div className="d-flex justify-content-between">
                    <h5
                      className="card-title swiperH5"
                      style={{ fontSize: '1rem' }}
                    >
                      Cheese-Stuffed Meatballs
                    </h5>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        src={starImg}
                        style={{ width: '25px' }}
                        className=" "
                      />
                      <h4
                        className="m-0 p-0 cardtest2-font-size"
                        style={{ fontSize: '1rem' }}
                      >
                        4.5
                      </h4>
                    </div>
                    <h4
                      className="card-test2-font-size"
                      style={{ fontSize: '1rem', marginBottom: '0px' }}
                    >
                      10.5$
                    </h4>
                  </div>

                  <p
                    className="card-text card-test2-font-size-p mt-2"
                    style={{ fontSize: '.8rem' }}
                  >
                    Ground beef, string cheese, ground....
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card  cardtest2 ">
                <img
                  src={img1}
                  className="card-img-top card-img-test2"
                  alt="..."
                />
                <div className="card-body pl-0">
                  <div className="d-flex justify-content-between">
                    <h5
                      className="card-title swiperH5"
                      style={{ fontSize: '1rem' }}
                    >
                      Cheese-Stuffed Meatballs
                    </h5>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        src={starImg}
                        style={{ width: '25px' }}
                        className=" "
                      />
                      <h4
                        className="m-0 p-0 card-test2-font-size"
                        style={{ fontSize: '1rem' }}
                      >
                        4.5
                      </h4>
                    </div>
                    <h4
                      className="card-test2-font-size"
                      style={{ fontSize: '1rem', marginBottom: '0px' }}
                    >
                      10.5$
                    </h4>
                  </div>

                  <p
                    className="card-text card-test2-font-size-p mt-2"
                    style={{ fontSize: '.8rem' }}
                  >
                    Ground beef, string cheese, ground....
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card cardtest2 ">
                <img
                  src={img1}
                  className="card-img-top card-img-test2"
                  alt="..."
                />
                <div className="card-body pl-0">
                  <div className="d-flex justify-content-between">
                    <h5
                      className="card-title swiperH5"
                      style={{ fontSize: '1rem' }}
                    >
                      Cheese-Stuffed Meatballs
                    </h5>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        src={starImg}
                        style={{ width: '25px' }}
                        className=" "
                      />
                      <h4
                        className="m-0 p-0 card-test2-font-size"
                        style={{ fontSize: '1rem' }}
                      >
                        4.5
                      </h4>
                    </div>
                    <h4
                      className="card-test2-font-size"
                      style={{ fontSize: '1rem', marginBottom: '0px' }}
                    >
                      10.5$
                    </h4>
                  </div>

                  <p
                    className="card-text card-test2-font-size-p mt-2"
                    style={{ fontSize: '.8rem' }}
                  >
                    Ground beef, string cheese, ground....
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card cardtest2 ">
                <img
                  src={img1}
                  className="card-img-top card-img-test2"
                  alt="..."
                />
                <div className="card-body pl-0">
                  <div className="d-flex justify-content-between">
                    <h5
                      className="card-title swiperH5"
                      style={{ fontSize: '1rem' }}
                    >
                      Cheese-Stuffed Meatballs
                    </h5>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        src={starImg}
                        style={{ width: '25px' }}
                        className=" "
                      />
                      <h4
                        className="m-0 p-0 card-test2-font-size"
                        style={{ fontSize: '1rem' }}
                      >
                        4.5
                      </h4>
                    </div>
                    <h4
                      className="card-test2-font-size"
                      style={{ fontSize: '1rem', marginBottom: '0px' }}
                    >
                      10.5$
                    </h4>
                  </div>

                  <p
                    className="card-text card-test2-font-size-p mt-2"
                    style={{ fontSize: '.8rem' }}
                  >
                    Ground beef, string cheese, ground....
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="mobileDisplay">
        <h3 className="col-12 header">Suggestions</h3>
        <div>
          <Swiper
            className=""
            spaceBetween={10}
            grabCursor={false}
            centeredSlides={false}
            slidesPerView={2}
            hashNavigation={{
              watchState: true,
            }}
            // autoplay={{
            //   delay: 2200,
            //   disableOnInteraction: true,
            // }}

            navigation={true}
            modules={[Autoplay, Navigation]}
          >
            {/* this part will loop  */}
            <SwiperSlide>
              <div className="card card-Test2">
                <img
                  src={img1}
                  className="card-img-top card-img-test2"
                  alt="..."
                />
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <h5
                      className="card-title swiperH5"
                      style={{ fontSize: '1rem' }}
                    >
                      Cheese-Stuffed Meatballs
                    </h5>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        src={starImg}
                        style={{ width: '25px' }}
                        className=" "
                      />
                      <h4
                        className="m-0 p-0 card-test2-font-size"
                        style={{ fontSize: '1rem' }}
                      >
                        4.5
                      </h4>
                    </div>
                    <h4
                      className="card-test2-font-size"
                      style={{ fontSize: '1rem', marginBottom: '0px' }}
                    >
                      10.5$
                    </h4>
                  </div>

                  <p
                    className="card-text card-test2-font-size-p mt-2"
                    style={{ fontSize: '.8rem' }}
                  >
                    Ground beef, string cheese, ground....
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card card-Test2">
                <img
                  src={img1}
                  className="card-img-top card-img-test2"
                  alt="..."
                />
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <h5
                      className="card-title swiperH5"
                      style={{ fontSize: '1rem' }}
                    >
                      Cheese-Stuffed Meatballs
                    </h5>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        src={starImg}
                        style={{ width: '25px' }}
                        className=" "
                      />
                      <h4
                        className="m-0 p-0 card-test2-font-size"
                        style={{ fontSize: '1rem' }}
                      >
                        4.5
                      </h4>
                    </div>
                    <h4
                      className="card-test2-font-size"
                      style={{ fontSize: '1rem', marginBottom: '0px' }}
                    >
                      10.5$
                    </h4>
                  </div>

                  <p
                    className="card-text card-test2-font-size-p mt-2"
                    style={{ fontSize: '.8rem' }}
                  >
                    Ground beef, string cheese, ground....
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card card-Test2">
                <img
                  src={img1}
                  className="card-img-top card-img-test2"
                  alt="..."
                />
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <h5
                      className="card-title swiperH5"
                      style={{ fontSize: '1rem' }}
                    >
                      Cheese-Stuffed Meatballs
                    </h5>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        src={starImg}
                        style={{ width: '25px' }}
                        className=" "
                      />
                      <h4
                        className="m-0 p-0 card-test2-font-size"
                        style={{ fontSize: '1rem' }}
                      >
                        4.5
                      </h4>
                    </div>
                    <h4
                      className="card-test2-font-size"
                      style={{ fontSize: '1rem', marginBottom: '0px' }}
                    >
                      10.5$
                    </h4>
                  </div>

                  <p
                    className="card-text card-test2-font-size-p mt-2"
                    style={{ fontSize: '.8rem' }}
                  >
                    Ground beef, string cheese, ground....
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card card-Test2">
                <img
                  src={img1}
                  className="card-img-top card-img-test2"
                  alt="..."
                />
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <h5
                      className="card-title swiperH5"
                      style={{ fontSize: '1rem' }}
                    >
                      Cheese-Stuffed Meatballs
                    </h5>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        src={starImg}
                        style={{ width: '25px' }}
                        className=" "
                      />
                      <h4
                        className="m-0 p-0 card-test2-font-size"
                        style={{ fontSize: '1rem' }}
                      >
                        4.5
                      </h4>
                    </div>
                    <h4
                      className="card-test2-font-size"
                      style={{ fontSize: '1rem', marginBottom: '0px' }}
                    >
                      10.5$
                    </h4>
                  </div>

                  <p
                    className="card-text card-test2-font-size-p mt-2"
                    style={{ fontSize: '.8rem' }}
                  >
                    Ground beef, string cheese, ground....
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div >
  )
}

export default Suggestion
