const initialState = false;

const openRegisterPage = (state = initialState, action) => {
    switch (action.type) {
        case "openRegisterPageTrue": return true;
        case "openRegisterPageFalse": return false;
        // case "getChangeLog": return state;
        default: return state;
    }
}
export default openRegisterPage;