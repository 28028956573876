import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import { Autoplay, Navigation } from 'swiper';
import { Link } from 'react-router-dom';
import burger from './../Assets/Image/Burger22.jpeg'
import bannerImg from './../Assets/Image/PastaOffer.jpg'
import airBnbImage from './../Assets/Image/1 (2).jpg'
import chickenImg from './../Assets/Image/ChickenFry.jpg'
import res from './../Assets/Icons/res.png'
import down from './../Assets/Icons/chevron.png'
import up from './../Assets/Icons/chevron2.png'

const Test8 = () => {
    return (
        < div className="newThumb bg-dark h-100">

            <div className='rounded thumbCart thumbCartmainDiv'>
                <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                <img src={bannerImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />
                <div className="subscriptionDiv">
                    <div className="subscriptionDivDetails">
                        <div className=' p-2 d-flex  justify-content-between'>
                            <div className='d-flex flex-column'>
                                <h3 >Platinum</h3>
                                <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                            </div>
                            <div className='d-flex flex-column '>

                                <button className='btn btn-buyNow'>Order Now </button>
                                <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                    <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Swiper
                className='m-2  px-3'
                spaceBetween={30}
                grabCursor={false}
                centeredSlides={false}
                slidesPerView={"auto"}
                hashNavigation={{
                    watchState: true,
                }}
                // autoplay={{
                //     delay: 2200,
                //     disableOnInteraction: true,
                // }}
                breakpoints={{
                    300: {
                        slidesPerView: 1,
                        spaceBetween: 5,

                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 5,

                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 5,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 5,
                    },
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 5,
                    },
                    1400: {
                        slidesPerView: 3,
                        spaceBetween: 5,
                    },

                }}
                navigation={true}
                modules={[Autoplay, Navigation]}
            >

                {/* this part will loop  */}
                <SwiperSlide>
                    <div className=' rounded thumbCart'>
                        <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                        <img src={chickenImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />


                    </div>

                </SwiperSlide>

                {/* this part will loop  */}


                {/* this part will be deleted  */}
                <SwiperSlide>
                    <div className='rounded thumbCart'>
                        <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                        <img src={bannerImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />

                    </div>

                </SwiperSlide>
                <SwiperSlide>
                    <div className='rounded thumbCart'>
                        <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                        <img src={chickenImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />

                    </div>

                </SwiperSlide>
                <SwiperSlide>
                    <div className='  rounded thumbCart'>
                        <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                        <img src={bannerImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />


                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='rounded thumbCart'>
                        <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                        <img src={chickenImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />


                    </div>

                </SwiperSlide>
                <SwiperSlide>
                    <div className='rounded thumbCart'>
                        <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                        <img src={bannerImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />


                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='rounded thumbCart'>
                        <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                        <img src={chickenImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />


                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='rounded thumbCart'>
                        <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                        <img src={bannerImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />


                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='rounded thumbCart'>
                        <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                        <img src={chickenImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />


                    </div>

                </SwiperSlide>
                <SwiperSlide>
                    <div className='rounded thumbCart'>
                        <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                        <img src={bannerImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />


                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default Test8;