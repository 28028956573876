import changeLoad from './changeLoad'
import testReducers from './testReducers'
import openRegisterPage from './openRegisterPage'
import changeSearchName from './changeSearchName'
import commentText from './commentText'
import searchiconClick from './searchiconClick'
import CommentImgShow from './commentImgShow'
import windowLoad from './windowLoad'
import forgetPassword from './forgetPassword'
import { combineReducers } from 'redux'

const rootReducers = combineReducers({
  changeLoad,
  testReducers,
  openRegisterPage,
  changeSearchName,
  commentText,
  searchiconClick,
  CommentImgShow,
  windowLoad,
  forgetPassword
})
export default rootReducers
