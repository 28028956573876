import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import { Autoplay, Navigation } from 'swiper';
import "swiper/css/navigation";
import './FoodHistory.css';
import cakeImg from './../../../Assets/Image/cake1 1.png';
import chickenImg from './../../../Assets/Image/delicious-chicken-table 3.png';
import kababImg from './../../../Assets/Image/Kabab 1.png';
import latteCoffeeImg from './../../../Assets/Image/latte-coffee-cup 1.png';
import pastaImg from './../../../Assets/Image/Pasta 1.png';
import soupImg from './../../../Assets/Image/Soup 3.png';
import bannerImg from './../../../Assets/Image/banner.jpg';
import DiscountOffer from './../../Restaurant/DiscountOffer/DiscountOffer'
import Recommendation from '../../Restaurant/Recommendation/Recommendation';
import { useNavigate } from 'react-router-dom';
import GalleryView from '../../GalleryView/GalleryView';
import PostDisplay from '../../PostDisplay/PostDisplay';


const FoodHistory = () => {

    const navigate = useNavigate();

    return (
        <div className="customContainer my-5">

            <h2 className='MainHeader '>Good Afternoon</h2>
            <Swiper
                className='FoodHistory'
                spaceBetween={30}
                grabCursor={false}
                centeredSlides={false}
                slidesPerView={"auto"}
                hashNavigation={{
                    watchState: true,
                }}
                // autoplay={{
                //     delay: 2200,
                //     disableOnInteraction: true,
                // }}
                breakpoints={{
                    300: {
                        slidesPerView: 2,
                        spaceBetween: 5,

                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 5,

                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                    },
                    1024: {
                        slidesPerView: 5,
                        spaceBetween: 10,
                    },
                    1200: {
                        slidesPerView: 6,
                        spaceBetween: 10,
                    },
                    1400: {
                        slidesPerView: 7,
                        spaceBetween: 10,
                    },

                }}
                navigation={true}
                modules={[Autoplay, Navigation]}
            >

                {/* this part will loop  */}
                <SwiperSlide>
                    <div className="card   FoodHistoryCard" onClick={() => { window.location.href = "/product-page" }}>
                        <img src={cakeImg} className="card-img-top" alt="..." />
                        <div className="cardText p-2">
                            <h5 className="card-title">Cake</h5>
                            <p className="card-text">Cakes come in so many... </p>

                        </div>
                    </div>

                </SwiperSlide>

                {/* this part will loop  */}


                {/* this part will be deleted  */}
                <SwiperSlide>
                    <div className="card   FoodHistoryCard" onClick={() => { window.location.href = "/Restaurant" }}>
                        <img src={chickenImg} className="card-img-top" alt="..." />
                        <div className="cardText p-2">
                            <h5 className="card-title">Burger</h5>
                            <p className="card-text">Barbecue chicken consists...</p>

                        </div>
                    </div>

                </SwiperSlide>
                <SwiperSlide>
                    <div className="card   FoodHistoryCard">
                        <img src={kababImg} className="card-img-top" alt="..." />
                        <div className="cardText p-2">
                            <h5 className="card-title">Kabab</h5>
                            <p className="card-text">kebab, also spelled kebob...</p>

                        </div>
                    </div>

                </SwiperSlide>
                <SwiperSlide>
                    <div className="card   FoodHistoryCard">
                        <img src={latteCoffeeImg} className="card-img-top" alt="..." />
                        <div className="cardText p-2">
                            <h5 className="card-title">Latte Coffee</h5>
                            <p className="card-text">A latte begins with the...</p>

                        </div>
                    </div>

                </SwiperSlide>
                <SwiperSlide>
                    <div className="card   FoodHistoryCard">
                        <img src={pastaImg} className="card-img-top" alt="..." />
                        <div className="cardText p-2">
                            <h5 className="card-title">Pasta</h5>
                            <p className="card-text">The word pasta is generally...</p>

                        </div>
                    </div>

                </SwiperSlide>
                <SwiperSlide>
                    <div className="card   FoodHistoryCard">
                        <img src={soupImg} className="card-img-top" alt="..." />
                        <div className="cardText p-2">
                            <h5 className="card-title">Soup</h5>
                            <p className="card-text">Soup is a primarily liquid...</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="card   FoodHistoryCard">
                        <img src={bannerImg} className="card-img-top" alt="..." />
                        <div className="cardText p-2">
                            <h5 className="card-title">Soup</h5>
                            <p className="card-text">Ground beef, string cheese...</p>

                        </div>
                    </div>

                </SwiperSlide>
                <SwiperSlide>
                    <div className="card   FoodHistoryCard">
                        <img src={soupImg} className="card-img-top" alt="..." />
                        <div className="cardText p-2">
                            <h5 className="card-title">Soup</h5>
                            <p className="card-text">Ground beef, string cheese...</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="card   FoodHistoryCard">
                        <img src={bannerImg} className="card-img-top" alt="..." />
                        <div className="cardText p-2">
                            <h5 className="card-title">Burger</h5>
                            <p className="card-text">Ground beef, string cheese...</p>

                        </div>
                    </div>

                </SwiperSlide>
                <SwiperSlide>
                    <div className="card   FoodHistoryCard">
                        <img src={soupImg} className="card-img-top" alt="..." />
                        <div className="cardText p-2">
                            <h5 className="card-title">Soup</h5>
                            <p className="card-text">Ground beef, string cheese...</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="card   FoodHistoryCard">
                        <img src={bannerImg} className="card-img-top" alt="..." />
                        <div className="cardText p-2">
                            <h5 className="card-title">Burger</h5>
                            <p className="card-text">Ground beef, string cheese...</p>

                        </div>
                    </div>

                </SwiperSlide>

                {/* this part will be deleted  */}
            </Swiper>



<div className='row'>
            <div className="col-12 col-md-6 padding-zero">
          <GalleryView type={'home'}></GalleryView>
        </div>
        <div className="col-12 col-md-6 ">
          <div className="row">
            <div className="col-12">
              {/* <br /> */}
              <PostDisplay></PostDisplay>
              <br className='desktopDisplay' />
              {/* <div className='desktopDisplay'>
                <SharePostDisplay></SharePostDisplay>
              </div> */}

              <a href="allpost" className="allPostATag mt-2">
                See More
              </a>
            </div>
          </div>
        </div>
 </div>




            <div className='mt-2'>
                <div className='d-flex align-item-center justify-content-between'>
                    <h3 className="brandName ">Discount Offer </h3>
                    <h5 className="brandName " style={{ paddingBottom: "0" }}>See more </h5>
                </div>

                <DiscountOffer from={1} />
            </div>
            <div className='mt-5'>
                <h3 className="brandName">Recommendation</h3>
                <div className="">
                    <Recommendation from={1} />
                </div>
            </div>

        </div>
    );

};

export default FoodHistory;