import React, { useRef, useState } from 'react';
import './CheckOut.css'
import Navbar from '../Navbar/Navbar';
import pkbImg from './../../Assets/Image/aibnb2.webp'
import locationIcon from './../../Assets/Icons/location-pin.png'
import standardShipping from './../../Assets/Icons/standard-shipping.png'
import priorityShipping from './../../Assets/Icons/delivered.png'
import onlinePayment from './../../Assets/Icons/credit-cards-payment.png'
import CardDetails from '../Cart/Components/CardDetails/CardDetails';
import instruction from './../../Assets/Icons/instructions.png'
import { useEffect } from 'react';
import { Accordion } from 'react-bootstrap';

const CheckOut = () => {
    const test3Ref = useRef(null);
    const [blur, setBlur] = useState(false)
    const searchDialogOpen = (NavbarData) => {
        setBlur(NavbarData)
    }

    const blurStyle = {
        filter: 'blur(4px)'
    };
    const [navbarOnTop, setNavbarOnTop] = useState(true);
    const [scrollValue, setscrollValue] = useState(0);
    const [slideUp, setSlideUp] = useState(false);
    const checkOutTopRef = useRef(null)
    useEffect(() => {
        window.addEventListener("scroll", (event) => {
            const filterBarRect = checkOutTopRef.current.getBoundingClientRect();
            const isAboveViewport = filterBarRect.top === 0 ? true : false;
            setNavbarOnTop(isAboveViewport);
            var top = (filterBarRect.top * (-1));
            var scrollval = (scrollValue * (-1));
            if (top > scrollval) {
                setSlideUp(true);
            }
            else if (top <= 0) {
                setSlideUp(false);
            }
            else {
                setSlideUp(false);
            }

            var s = filterBarRect.top;
            setscrollValue(s);
        })
    });

    return (
        <div>
            <Navbar handleNavbar={searchDialogOpen} />
            <div ref={checkOutTopRef}>

            </div>
            <div className={`${blur ? "BackBlur" : ""}`}>
                <div className='container checkout-container'>
                    <div className='row'>
                        <div className='col-md-7'>
                            {/* <div className='col-12'>
                                <div className='d-flex align-items-center restaurantNameDisplay'>
                                    <img src={pkbImg} className='checkOutRestaurantImage' alt='res' />
                                    <h4 className='restaurantName'>Pot kettle black</h4>
                                </div>
                            </div> */}
                            <div className='col-12'>
                                <div className='checkOutCard'>
                                    <div className='d-flex justify-content-between'>
                                        <h4 className='DeliveryHeader' style={{ padding: "0", margin: "0" }}>Order Summary</h4>
                                        {/* <button className='btn'>Edit Order</button> */}
                                    </div>

                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Pot Kettle black</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='deliveryDetails  '>
                                                    <CardDetails />
                                                </div>
                                                <div className='hr-padding'>
                                                    <hr></hr>
                                                </div>
                                                <div className='deliveryDetails  '>
                                                    <div className='d-flex locaionDetails'>

                                                        <div className='d-flex align-items-center'>
                                                            <img src={onlinePayment} alt="" style={{ width: "1.5rem" }} />
                                                        </div>
                                                        <div className='d-flex addressCheckOut justify-content-center'>
                                                            <h5>Add coupon code</h5>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <button className='btn'> Add</button>
                                                    </div>

                                                </div>
                                                <hr></hr>
                                                <h4 className='DeliveryHeader'>Delivery estimate</h4>

                                                <div className='deliveryDetails roundedCorner '>
                                                    <div className='d-flex locaionDetails'>

                                                        <div className='d-flex align-items-center'>
                                                            <img src={priorityShipping} alt="" style={{ width: "1.5rem" }} />
                                                        </div>
                                                        <div className='d-flex addressCheckOut justify-content-center'>
                                                            <h5>Priority</h5>
                                                            <h6 className='city' style={{ padding: "0", margin: "0" }}>10-20 min</h6>

                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <button className='btn'> +£1.99</button>
                                                    </div>

                                                </div>
                                                <br></br>
                                                <div className='deliveryDetails selected-roundedCorner'>
                                                    <div className='d-flex locaionDetails'>

                                                        <div className='d-flex align-items-center'>
                                                            <img src={standardShipping} alt="" style={{ width: "1.5rem" }} />
                                                        </div>
                                                        <div className='d-flex addressCheckOut justify-content-center'>
                                                            <h5>Standard</h5>
                                                            <h6 className='city' style={{ padding: "0", margin: "0" }}>15-25 min</h6>

                                                        </div>
                                                    </div>


                                                </div>
                                                <div className='hr-padding'>
                                                    <hr></hr>
                                                </div>
                                                <div className='deliveryDetails'>
                                                    <div className='d-flex locaionDetails'>

                                                        <div className='d-flex align-items-center'>
                                                            <img src={instruction} alt="" style={{ width: "1.5rem" }} />
                                                        </div>
                                                        <div className='d-flex addressCheckOut justify-content-center'>
                                                            <h5 style={{ padding: "0", margin: "0" }}>Add instruction</h5>

                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <button className='btn'> Add </button>
                                                    </div>

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>KFC</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='deliveryDetails  '>
                                                    <CardDetails />
                                                </div>
                                                <div className='hr-padding'>
                                                    <hr></hr>
                                                </div>
                                                <div className='deliveryDetails  '>
                                                    <div className='d-flex locaionDetails'>

                                                        <div className='d-flex align-items-center'>
                                                            <img src={onlinePayment} alt="" style={{ width: "1.5rem" }} />
                                                        </div>
                                                        <div className='d-flex addressCheckOut justify-content-center'>
                                                            <h5>Add coupon code</h5>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <button className='btn'> Add</button>
                                                    </div>

                                                </div>
                                                <hr></hr>
                                                <h4 className='DeliveryHeader'>Delivery estimate</h4>

                                                <div className='deliveryDetails roundedCorner '>
                                                    <div className='d-flex locaionDetails'>

                                                        <div className='d-flex align-items-center'>
                                                            <img src={priorityShipping} alt="" style={{ width: "1.5rem" }} />
                                                        </div>
                                                        <div className='d-flex addressCheckOut justify-content-center'>
                                                            <h5>Priority</h5>
                                                            <h6 className='city' style={{ padding: "0", margin: "0" }}>10-20 min</h6>

                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <button className='btn'> +£1.99</button>
                                                    </div>

                                                </div>
                                                <br></br>
                                                <div className='deliveryDetails selected-roundedCorner'>
                                                    <div className='d-flex locaionDetails'>

                                                        <div className='d-flex align-items-center'>
                                                            <img src={standardShipping} alt="" style={{ width: "1.5rem" }} />
                                                        </div>
                                                        <div className='d-flex addressCheckOut justify-content-center'>
                                                            <h5>Standard</h5>
                                                            <h6 className='city' style={{ padding: "0", margin: "0" }}>15-25 min</h6>

                                                        </div>
                                                    </div>


                                                </div>
                                                <div className='hr-padding'>
                                                    <hr></hr>
                                                </div>
                                                <div className='deliveryDetails'>
                                                    <div className='d-flex locaionDetails'>

                                                        <div className='d-flex align-items-center'>
                                                            <img src={instruction} alt="" style={{ width: "1.5rem" }} />
                                                        </div>
                                                        <div className='d-flex addressCheckOut justify-content-center'>
                                                            <h5 style={{ padding: "0", margin: "0" }}>Add instruction</h5>

                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <button className='btn'> Add </button>
                                                    </div>

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>


                                    <hr></hr>
                                    <h4 className='DeliveryHeader'>Delivery details</h4>
                                    <div className='deliveryDetails'>
                                        <div className='d-flex locaionDetails'>

                                            <div className='d-flex align-items-center'>
                                                <img src={locationIcon} alt="" style={{ width: "1.5rem" }} />
                                            </div>
                                            <div className='d-flex addressCheckOut justify-content-center'>
                                                <h5 style={{ padding: "0", margin: "0" }}>London</h5>
                                                <h6 className='city' style={{ padding: "0", margin: "0" }}>UK</h6>

                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            <button className='btn'> Edit</button>
                                        </div>

                                    </div>


                                    <br></br>
                                    <hr></hr>
                                    <h4 className='DeliveryHeader'>Payment</h4>

                                    <div className='deliveryDetails  '>
                                        <div className='d-flex locaionDetails'>

                                            <div className='d-flex align-items-center'>
                                                <img src={onlinePayment} alt="" style={{ width: "1.5rem" }} />
                                            </div>
                                            <div className='d-flex addressCheckOut justify-content-center'>
                                                <h5>Online</h5>
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            <button className='btn'> Edit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-md-5'>
                            <div className='sticky-position'>
                                <div className='col-12'>
                                    <div className={`d-flex align-items-center restaurantNameDisplay btnCheckOut ${slideUp ? "btnCheckOutMarginTopZero " : "btnCheckOutMarginTop"}`}>
                                        <button className='btn btn-success w-100 btn-placeOrder'>Place Order</button>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='checkOutCard sticky-position'>
                                        <h4 className='DeliveryHeader'>Order Total</h4>
                                        <div className='d-flex justify-content-between ms-3 orderDetails'>
                                            <h5>Subtotal</h5>
                                            <h5>£25.83</h5>
                                        </div>
                                        <div className='d-flex justify-content-between ms-3 orderDetails'>
                                            <h5>Discount</h5>
                                            <h5>£2.50</h5>
                                        </div>
                                        <div className='d-flex justify-content-between ms-3 orderDetails'>
                                            <h5>After discount</h5>
                                            <h5>£25.33</h5>
                                        </div>
                                        <div className='d-flex justify-content-between ms-3 orderDetails'>
                                            <h5>SC</h5>
                                            <h5>£1.50</h5>
                                        </div>
                                        <div className='d-flex justify-content-between ms-3 orderDetails'>
                                            <h5>VAT</h5>
                                            <h5>£1.50</h5>
                                        </div>
                                        <div className='d-flex justify-content-between ms-3 orderDetails'>
                                            <h5>Delivery Fee</h5>
                                            <h5>£1.50</h5>
                                        </div>
                                        <div className='d-flex justify-content-between ms-3 orderDetails'>
                                            <h5>Platform Fee</h5>
                                            <h5>£1.50</h5>
                                        </div>
                                        <hr></hr>
                                        <div className='d-flex justify-content-between ms-3 orderDetails'>
                                            <h5>Total</h5>
                                            <h5>£29.33</h5>
                                        </div>
                                        <div className='d-flex justify-content-between ms-3 orderDetails warning-allergies' style={{ textAlign: "justify" }}>
                                            <p>
                                                ALLERGIES: If you or someone you’re ordering for has an allergy, please contact the merchant directly to let them know.
                                                If you’re not around when the delivery person arrives, they’ll leave your order at the door. By placing your order, you agree to take full responsibility for it once it’s delivered. Orders containing alcohol or other restricted items may not be eligible for leave at door and will be returned to the store if you are not available.
                                                Whilst we, and our restaurant partners, have safety measures to mitigate food safety risk, couriers may be delivering more than one order so we cannot eliminate the risk of cross-contamination from allergens.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default CheckOut;