import React, { useEffect, useState } from 'react'
import { Gallery } from 'react-grid-gallery'
import movieImg from './../../../Assets/Image/aibnb2.webp'
// import removeIcon from './../../../Assets/Icons/remove.png'
import GalleryModal from './../../GalleryModal/GalleryModal'
import aibnd from './../../../Assets/Image/airbnb.webp'
import aibnd2 from './../../../Assets/Image/aibnb2.webp'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import toastImg from './../../../Assets/Image/1 (2).jpg'
import p1 from './../../../Assets/Image/product (1).jpg'
import removeWhiteIcon from './../../../Assets/Icons/removeBlack.png'
import './PhotoGridGallery.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import { Autoplay, Navigation, Pagination } from 'swiper';
import "swiper/css/navigation";
import removeIcon from "./../../../Assets/Icons/removeBlack.png"
import removeBlack from "./../../../Assets/Icons/remove.png"
import locationPin from './../../../Assets/Icons/location-pin.png'
import clock from './../../../Assets/Icons/clock.png'
import starImg from './../../../Assets/Image/Star 14.png'
import pkbLogo from './../../../Assets/Image/PKB.png'
import { Modal } from 'react-bootstrap'
import GalleryView from '../../GalleryView/GalleryView'
import PhotoGridgalleryView from './Components/PhotoGridgalleryView'


const PhotoGridGallery = (props) => {

  const [images, setImages] = useState(

    [
      {
        src: 'https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg',
        width: 500,
      },
      {
        src: 'https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg',
        width: 500,
      },

      {
        src: movieImg,
        width: 500,
      },
      {
        src: 'https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg',
        width: 500,
      },
      {
        src: 'https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg',
        width: 500,
      },

      {
        src: p1,
        width: 500,
      },
      {
        src: aibnd,
        width: 500,
      },


      {
        src: toastImg,
        width: 500,
      },
    ]
  )
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [showMore, setShowMore] = useState(false)
  const [date, setDate] = useState(new Date())
  const [isOpen, setIsOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [follower, setFollower] = useState(1.1)

  const address = "Barton Arcade, Manchester M3 2BW, United Kingdom";

  const selectedImg = (props) => {
    console.log(props);
    setSelectedImage(props);
    console.log("selectedImage: " + props);
    window.$("#photoGalleryModal").modal("show");
  };
  useEffect(() => {
    console.log(props.from);
    if (props.from === 1) {
      var s = images.slice(0, 6);
      setImages(s);
    }

  }, [props.from])


  const [selectedImage, setSelectedImage] = useState('')
  const hideModalInfo = () => {
    window.$('#photoGalleryModal').modal('hide')
  }

  return (
    <div className="photoGridContainer mt-1">
      <div className='d-flex justify-content-center'>
        <img src={pkbLogo} alt="" className='coverImg' />
      </div>

      <div className="card restaurantDetailsCardGalleryGrid">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h3 className="brandName">
              Pot Kettle Black
              <button className='followBtn-ResturantPage followBtnDesktop' style={{ fontSize: '12px' }}> Follow</button>
            </h3>
            <div className="d-flex justify-content-between">
              <img src={starImg} alt="" className="starImage" />
              <h3 className="ratingNumber">4.5</h3>
            </div>
          </div>
          <div className='follower d-flex justify-content-between'>
            <p>{follower}M followers</p>
            <button className='followBtn-ResturantPage mobileDisplay' style={{ fontSize: '12px' }}> Follow</button>
          </div>
          <div className="d-flex ResturentCategory">
            <h6>Burgers | </h6>
            <h6>&nbsp; Pizza | </h6>
            <h6>&nbsp; Deserts</h6>
          </div>
          <div className="d-flex location">
            <div>
              <img src={locationPin} alt="" style={{ width: "1rem" }} />
            </div>
            {screenWidth < 688 ? (
              <p className=" ">
                {showMore
                  ? address
                  : `${address.split(/\s+/).slice(0, 2).join(' ')}`}
                <a
                  className="text-decoration-none ms-1"
                  onClick={() => setShowMore(!showMore)}
                  style={{
                    color: '#3D1F1B',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }}
                >
                  {showMore ? '' : '...'}
                </a>
              </p>
            ) : <p> Barton Arcade, Manchester M3 2BW, United Kingdom</p>}
            {/* <p> Barton Arcade, Manchester M3 2BW, United Kingdom</p> */}
          </div>
          <div className='d-flex align-items-center opening'>
            <div className='d-flex align-items-center opening-hour'>
              <div>
                <img src={clock} alt="" style={{ width: "1rem" }} />
              </div>
              <div className='operningHourText'>
                <p style={{ marginBottom: "0px" }}> <span className='desktopDisplay-Opening'>Opening hour :</span>
                  {/* {window.innerWidth < 688 ? (<br />) : (<b></b>)} */}
                  <b> 9:00 AM-9:00 PM</b>
                </p>
              </div>
            </div>


          </div>
          <div className="d-flex ResturentDetails">
            <p>
              Details: Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unkown printer took a
              galley of type
            </p>
          </div>

        </div>
      </div>

      <br />
      <br />
      <div class="modal fade" id="photoGalleryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body galleryModalBody">
              <div class=" gallaryViewModelHeader d-flex justify-content-between loginModalCloseBtn">
                <span></span>
                <span></span>
                <button type="button " class={'close '} onClick={hideModalInfo}>
                  <span aria-hidden="true">
                    <img src={removeIcon} className="removeImg desktopDisplay" style={{ height: '30px", width: "1rem' }} alt="" />
                  </span>
                </button>
              </div>
              <button type="button " class={"close gallaryModelCloseButton"} onClick={hideModalInfo} style={{ position: "absolute", right: "0.8rem", top: "1rem", width: "44px", background: "transparent", border: "none" }}>
                <span aria-hidden="true">
                  <img src={removeBlack} className="removeImg mobileDisplay " style={{ height: '30px", width: "1rem' }} alt="" /></span>
              </button>
              <div className='gallerySwiperDiv'>
                <Swiper
                  className='gallerySwiper swiperGallery'
                  spaceBetween={30}
                  grabCursor={false}
                  centeredSlides={false}
                  slidesPerView={1}
                  hashNavigation={{
                    watchState: true,
                  }}
                  navigation={true}
                  modules={[Navigation, Pagination]}
                  pagination={{
                    dynamicBullets: true,
                    clickable: true,
                  }}
                >
                  <SwiperSlide>
                    <GalleryModal selectedImage={selectedImage} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <GalleryModal selectedImage={selectedImage} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <GalleryModal selectedImage={selectedImage} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <GalleryModal selectedImage={selectedImage} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <GalleryModal selectedImage={selectedImage} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <GalleryModal selectedImage={selectedImage} />
                  </SwiperSlide>
                </Swiper>
              </div>


            </div>

          </div>
        </div>
      </div>

      <Modal
        show={openModal}
        backdrop="static"
        keyboard={false}
        fullscreen={true}
        className='postDisplayModal'
      >
        <Modal.Body className='galleryModalBody'>
          <div class=" gallaryViewModelHeader d-flex justify-content-between loginModalCloseBtn">
            <span></span>
            <span></span>
            <button type="button " class={'close '} onClick={hideModalInfo}>
              <span aria-hidden="true">
                <img src={removeIcon} className="removeImg desktopDisplay" style={{ height: '30px", width: "1rem' }} alt="" />
              </span>
            </button>
          </div>
          <button type="button " class={"close gallaryModelCloseButton"} onClick={hideModalInfo} style={{ position: "absolute", right: "0.8rem", top: "1rem", width: "44px", background: "transparent", border: "none" }}>
            <span aria-hidden="true">
              <img src={removeBlack} className="removeImg mobileDisplay " style={{ height: '30px", width: "1rem' }} alt="" /></span>
          </button>
          <div className='gallerySwiperDiv'>
            <Swiper
              className='gallerySwiper swiperGallery'
              spaceBetween={30}
              grabCursor={false}
              centeredSlides={false}
              slidesPerView={1}
              hashNavigation={{
                watchState: true,
              }}
              navigation={true}
              modules={[Navigation, Pagination]}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
            >
              <SwiperSlide>
                <GalleryModal selectedImage={selectedImage} />
              </SwiperSlide>
              <SwiperSlide>
                <GalleryModal selectedImage={selectedImage} />
              </SwiperSlide>
              <SwiperSlide>
                <GalleryModal selectedImage={selectedImage} />
              </SwiperSlide>
              <SwiperSlide>
                <GalleryModal selectedImage={selectedImage} />
              </SwiperSlide>
              <SwiperSlide>
                <GalleryModal selectedImage={selectedImage} />
              </SwiperSlide>
              <SwiperSlide>
                <GalleryModal selectedImage={selectedImage} />
              </SwiperSlide>
            </Swiper>
          </div>
        </Modal.Body>

      </Modal>

      {/* 
       style={{ width: "100%", display: "block" }}
      <Gallery images={images} onClick={args => selectedImg(args)} enableImageSelection={false} /> */}

      {/* <Masonry columnsCount={1} gutter="5px">
        <div className="masonry">
          {images.map((image, i) => (
            <img
              key={i}
              src={image.src}
              alt=""
              onClick={() => selectedImg(image.src)}
              className="imgsizeMansonry"
            />
          ))}
        </div>
      </Masonry> */}

      {/* <GalleryView></GalleryView> */}
      <PhotoGridgalleryView></PhotoGridgalleryView>
    </div>
  )
}

export default PhotoGridGallery
